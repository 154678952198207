import React, { useState, useEffect } from 'react'
import { ShortDateTime } from '../../shared/MKDateTime'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AuthorizedPut, AuthorizedDelete } from '../../AuthorizedRequest'
import { useHistory } from 'react-router-dom'
import {DeleteButton} from "../../shared/Buttons";


export const EmailRow = ({index, conversation, onRowClick, onConversationDeleted, ...props}) =>
{
    const [email, setEmail] = useState(conversation[0])
    const [isRead, setIsRead] = useState(false)
    const history = useHistory()

    useEffect(() =>
    {
        setEmail(conversation[0])
        setIsRead(conversation[0].isRead)
    }, [conversation])

    const onRowClickInternal = e => {
        markAsReadSilent(email) 
        onRowClick(email)
    }

    const markAsRead = (email) => (e) =>
    {
        e.stopPropagation()
        setIsRead(true)
        AuthorizedPut(email, `api/Support/MarkEmailRead`, history)
            .then(result =>
            {
                if (result)
                {
                    setEmail(email)
                }
            })
    }

    const markAsUnread = (email) => (e) =>
    {
        e.stopPropagation()
        setIsRead(false)
        AuthorizedPut(email, `api/Support/MarkEmailUnread`, history)
            .then(result =>
            {
                if (result)
                {
                    setEmail(email)
                }
            })
    }

    // When ticket is open, it will be automatically set to read status
    function markAsReadSilent(email)
    {
        AuthorizedPut(email, `api/Support/MarkEmailRead`, history)
    }

    const deleteEmailConversation = (conversationId) => (e) => {
        AuthorizedDelete(null, 'api/Support/MarkEmailConversationDeleted/' + conversationId)
            .then(r => {
                if (r?.conversationId)
                {
                    onConversationDeleted(conversationId)
                }
            })
    }

    return (
        <>
            <tr key={index+"_1"} className={`tr-link ${isRead === false ? " unread" : ""}`}>
                <td style={{minWidth:'80px'}} onClick={onRowClickInternal}>
                    {email.from ? email.from.emailAddress.name : "N/A"}
                </td>
                <td onClick={onRowClickInternal}>
                    {email.subject}
                </td>
                <td className="text-end" style={{minWidth:'100px'}} onClick={onRowClickInternal}>
                    <ShortDateTime>{email.sentDateTime}</ShortDateTime>
                </td>
                <td className="td-actions text-end">
                    {isRead === false ? 
                    <button className='btn no-margin shadow-none btn-link' title="Mark as Read" onClick={markAsRead(email)}>
                        <FontAwesomeIcon icon='envelope-open' />
                    </button>
                    :
                    <button className='btn no-margin shadow-none btn-link' title="Mark as Unread" onClick={markAsUnread(email)}>
                        <FontAwesomeIcon icon='envelope' />
                    </button>
                    }
                    <DeleteButton onDelete={deleteEmailConversation(email.conversationId)} entityName="EMail" iconClassName="" buttonClassName="btn no-margin shadow-none btn-link"/>
                </td>
            </tr>
            <tr key={index+"_2"} className={`tr-link ${isRead === false ? " unread" : ""}`} onClick={(e) => onRowClick(email)}>
                <td colSpan={2} className="re-message">{email.bodyPreview}</td>
                <td className="re-message"> </td>
                <td className="re-message"> </td>
            </tr>
        </>
    )
}