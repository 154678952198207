import React, {useEffect, useState} from 'react';
import {Card} from "../shared/Card";
import {AuthorizedGet, AuthorizedPost} from "../AuthorizedRequest";
import MKPaginationUncounted, {mkPagedResultUncounted} from "../shared/MKPaginationUncounted";
import {composeQueryString, useQueryStringAndSessionState} from "../shared/Utils";
import {useHistory} from "react-router-dom";
import {mkPaginationObject} from "../shared/MKPagination";
import {AddButtonText} from "../shared/Buttons";
import ApiKeyList from "./ApiKeyList";

const load = (pagination, history) =>
    // Make a new request and populate the URL with the relevant search terms
    AuthorizedGet('api/ApiKey?' + composeQueryString(pagination), history);

const initialPaginationValues = Object.assign({}, mkPaginationObject);

export default function ApiKeys(props) {
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [pagination, setPagination] = useQueryStringAndSessionState(() => Object.assign({}, initialPaginationValues), history, history.location.search, 'paginationValues');
    const [pagedResult, setPagedResult] = useState(mkPagedResultUncounted);
 
    // load whenever paginationValues change
    useEffect(() => {
        load(pagination, history)
            .then(setPagedResult)
            .then(_ => setLoading(false))
    }, [pagination, history])

    const create = () =>
    {
        setPagination(Object.assign({}, initialPaginationValues));
        history.push('/ApiKeys/Create')
    };
    
    const onPageClick = (e, pageNumber) => setPagination({
        ...pagination,
        pageNumber
    });

    const onDelete = id => () => {
        setLoading(true);
        AuthorizedPost(id, `api/ApiKey/${id}/Delete`)
            .then(() => 
                setPagination({
                    ...pagination,
                })
            );
    }
    
    return (
        <div>
            <div>
                <div className="row align-items-center">
                    <div className="col-md-8">
                        <h3>Api Keys</h3>
                    </div>
                    <div className="col-md-4 text-end">
                        <AddButtonText onClick={create} toolTip="Add New">Add New</AddButtonText>
                    </div>
                </div>

                <Card>
                    <ApiKeyList items={pagedResult.items} loading={loading} onDelete={onDelete} />
                    {
                        !loading && <MKPaginationUncounted currentPageCount={pagedResult.currentPageCount} currentPage={pagedResult.pageNumber} onClick={onPageClick} itemsName="Api Keys"/>
                    }
                </Card>
            </div>
        </div>
    );
}
