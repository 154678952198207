import React, {useEffect, useState} from "react";
import {useSessionState} from "./Utils";
import {AuthorizedGet} from "../AuthorizedRequest";
import {useHistory} from "react-router-dom";
import Select from "react-select"


export default function SelectAgents({
                                         selectedAgentId,
                                         onChange = (agentId, agentDisplayName) => {
                                         },
                                         recentAgentIdsList = [],
                                         processing = false,
                                         readOnly = false,
                                         placeholder = 'Assign to...',
                                         showCleaner1stItem = false
                                     })
{
    const [list, setList] = useSessionState(null, 'mkAgentsList', 24 * 60);
    const [loadingList, setLoadingList] = useState(false);
    const history = useHistory();
    
    useEffect(() => {
        if (list == null)
        {
            setLoadingList(true);
            AuthorizedGet('api/Agents/AgentsIdAndDisplayName', history)
                .then(result => {
                    if (result) {
                        setList(result);
                    }
                })
                .finally(() => setLoadingList(false));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); 
    
    useEffect(() => {
        if (list && selectedAgentId && list.findIndex(x => x.id === selectedAgentId) === -1)
        {   //The user may have been deleted or deactivated
            AuthorizedGet('api/Agents/AgentIdAndDisplayName/' + selectedAgentId, history) 
                .then(resultSelected => {
                    if (resultSelected)
                    {
                        setList([resultSelected, ...list]);
                    }
                });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [list, selectedAgentId]);
    
    function mapListItem(item) {
        return item ? {value: item.id, label: item.displayName} : null;
    }
    
    const getOptions = () => {
        let lst = list ?? [];
        if (recentAgentIdsList?.length > 0) {
            let l = recentAgentIdsList.filter(x => x !== selectedAgentId);
            lst = [...l.map(x => lst.find(y => y.id === x)), ...lst.filter(x => !l.find(y => y === x.id))];
        }
        let lstMapped = lst.map(mapListItem);
        if (showCleaner1stItem)
            lstMapped.unshift({value: '', label: <>[ <i>Clear</i> ]</>})
        return lstMapped;
    };

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            textAlign: 'left',
        })
    };   
    
    return (
        <Select
            value={mapListItem(list?.find(x => x.id === selectedAgentId))}
            onChange={selectedOption => onChange(selectedOption.value, selectedOption.label)}
            options={getOptions()}
            isSearchable={true}
            isDisabled={loadingList}
            isLoading={loadingList || processing}
            placeholder={<i>{placeholder}</i>}
            styles={customStyles}
        />
    );
}