import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { AuthorizedGet, AuthorizedPut } from '../AuthorizedRequest'
import TargetedOfferForm from "./TargetedOfferForm";

export default function TargetedOfferEdit()
{
    const [loading, setLoading] = useState(true)
    const [targetedOffer, setTargetedOffer] = useState()
    const history = useHistory()
    let { targetedOfferId } = useParams()
        
    // Fetch 
    useEffect(() => 
    {
        AuthorizedGet(`api/TargetedOffer/${targetedOfferId}`, history)
            .then(result => {
                if (result) {
                    setTargetedOffer(result);
                }
                setLoading(false);
            })
    }, [targetedOfferId, history]);

    // Save changes
    const saveMethod = values => 
    {        
        return AuthorizedPut(values, 'api/TargetedOffer', history)
            .then(result => {
                if (result) {
                    setTargetedOffer(result)
                }
                return result
            });
    };

    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <TargetedOfferForm targetedOffer={targetedOffer} saveMethod={saveMethod} loading={loading} />
    );
}