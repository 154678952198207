import React, {useEffect, useState} from 'react'
import authService from "../api-authorization/AuthorizeService";
import {Loading} from "../shared/Loading";
import {useToasts} from "react-toast-notifications";
import AnalyticsSettings from "./AnalyticsSettings";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {ErrorDisplay} from "../shared/FormElements";
import {IconButton, UploadButtonText} from "../shared/Buttons";

export default function Analytics() {
    const [loading, setLoading] = useState(true);
    const [analyticsUrl, setAnalyticsUrl] = useState(null);
    const { addToast, removeToast } = useToasts();
    const [settingsModalOpen, setSettingsModalOpen] = useState(false);

    const toggleModal = () => {
        setSettingsModalOpen(v => !v);
    }
    
    useEffect(() =>
        {
            let toastId = null;
            authService.getAnalyticsUrl()
                .then(url => {
                    if (url)
                    {
                        setAnalyticsUrl(url);
                    }
                    else 
                    {
                        addToast(<span title="AnalyticsUrl configuration field must be configured">Analytics is not configured</span>, {
                            appearance: 'warning',
                            autoDismiss: false,
                        }, id => toastId = id);
                    }
                    setLoading(false);
                });
            
            return () => { if (toastId) removeToast(toastId) }; 
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);
    
    if (loading)
        return <Loading/>
    
    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <div>
            <div class='row'>
                <div style={{display: 'flex', justifyContent: 'end'}}>
                    <div>
                        <IconButton onClick={toggleModal} toolTip="Analytics Settings" icon="cog">Settings</IconButton>
                    </div>
                </div>
                
            </div>
            <Modal isOpen={settingsModalOpen} toggle={toggleModal} contentClassName="card">
                <ModalHeader toggle={toggleModal}>Analytics Settings</ModalHeader>
                <ModalBody>
                    <AnalyticsSettings />
                </ModalBody>
            </Modal>
            <iframe width="1320" height="720" allow="fullscreen"
                    src={analyticsUrl} title="Analytics">
            </iframe>
        </div>
    );
}
