import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { AuthorizedGet } from '../AuthorizedRequest'
import { Card } from '../shared/Card'
import { composeQueryString, mapQueryStringValuesToObject, useSessionState, useQueryStringAndSessionState, useCache } from '../shared/Utils'
import TeamsList from './TeamsList';
import TeamsSearch from './TeamsSearch'
import { teamsSearchCriteriaObject } from './TeamsSearchCriteria'
import { CollapseSearchButton, AddButtonText } from '../shared/Buttons'
import { Collapse } from 'reactstrap';
import MKPaginationUncounted, { mkPagedResultUncounted } from "../shared/MKPaginationUncounted";


export default function Teams(props)
{    
    const initialEmptyValues = Object.assign({}, teamsSearchCriteriaObject);

    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [initialValues, setInitialValues] = useQueryStringAndSessionState(() => Object.assign({}, initialEmptyValues), history, history.location.search, 'teamsInitialValues');
    const [pagedResult, setPagedResult] = useSessionState(mkPagedResultUncounted, 'teams');
    const [searchCollapsed, setSearchCollapsed] = useCache('teamsSearchCollapsed', false);
    
    useEffect(() =>
    {
        //setLoading(false)
        if (mapQueryStringValuesToObject(initialEmptyValues, history.location.search))   // There is allowed search parameters in QueryString
        {             
            if (JSON.stringify(initialEmptyValues) !== JSON.stringify(initialValues))  // Those parameters means a different search tan the one saved in sessionStorage
            {
                searchTeams(initialEmptyValues);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    const searchTeams = (searchValues, formikBag = { setSubmitting: x => {} }, pageNumber = 1) => {
        searchValues.pageNumber = pageNumber;
        setLoading(true);
        setInitialValues(Object.assign({}, searchValues));
        AuthorizedGet('api/Team/Search?' + composeQueryString(searchValues), history)
            .then(result => 
            {
                setPagedResult(result ?? mkPagedResultUncounted);
                formikBag.setSubmitting(false);
                setLoading(false);
            });
    };
    
    const onPageClick = (e, pageNumber) => searchTeams(initialValues, undefined, pageNumber);    

    const toggleSearch = e =>  {
        e.preventDefault();
        setSearchCollapsed(!searchCollapsed);
    };


    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const paginationComponent = !loading && <MKPaginationUncounted currentPageCount={pagedResult.currentPageCount} currentPage={pagedResult.pageNumber} onClick={onPageClick} itemsName="Teams"/>;

    return (
        <div>
            <div className="row align-items-center">
                <div className="md-8 col">
                    <h3>&nbsp;Teams</h3>
                </div>
                <div className="md-4 col text-end">
                    <AddButtonText onClick={() => history.push('/teams/create')} toolTip="Add New Team">Add New</AddButtonText>
                    &nbsp;
                    <CollapseSearchButton collapsed={searchCollapsed} onClick={toggleSearch}/>
                </div>
            </div>

            <Collapse isOpen={!searchCollapsed}>
                <Card>
                    <TeamsSearch handleSearch={searchTeams} initialValues={initialValues}/>
                </Card>
            </Collapse>

            <Card>
                {paginationComponent}
                <TeamsList teams={pagedResult.items} loading={loading}/>
                {paginationComponent}
            </Card>
        </div>
    )
}
