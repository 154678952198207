import React, {forwardRef, useRef, useState} from 'react';
import {jsonPathSetValue, jsonPathToValue, jsonSecureParse} from "../../shared/Utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {AuthorizedPost} from "../../AuthorizedRequest";
import {useHistory} from "react-router-dom";

export default function UserPrefsDashboardItem({ userId, item, itemValue, valueSaved }) {
    const [editing, setEditing] = useState(false);
    let inputRef = useRef();
    const history = useHistory();

    const getDisplayValue = () => {
        if (item.jsonPath && itemValue) {
            return jsonPathToValue(jsonSecureParse(itemValue), item.jsonPath);
        }
        return itemValue;
    };

    const ToggleEdit = e => {
        e.preventDefault();
        if (editing) { //Cancel editing
            inputRef.current.value = getDisplayValue();
        } else {
            setTimeout(() => inputRef.current.focus(), 0);
        }
        setEditing(!editing);
        e.stopPropagation();
    };

    const SaveClick = e => {
        e.preventDefault();
        let newValue = inputRef.current.value;

        if (item.jsonPath && itemValue) {
            let itemValueJson = jsonSecureParse(itemValue);
            jsonPathSetValue(itemValueJson, item.jsonPath, newValue);
            newValue = JSON.stringify(itemValueJson);
        }

        let postBody = [
            {
                key: item.key,
                value: newValue
            }
        ];

        AuthorizedPost(postBody, 'api/User/SaveUserPrefs/' + userId, history)
            .then(result => {
                valueSaved?.(postBody, result);
            });

        setEditing(!editing);
        e.stopPropagation();
    };

    return (
        <tr>
            <td title={item.key}>{item.displayName ?? item.key}</td>
            <td style={{width: '40%'}}>
                <InputComponent value={getDisplayValue()} ref={inputRef} treatAs={item.treatAs} editing={editing}
                                title={itemValue}/>
            </td>
            <td width="50px">
                {item.editable &&
                    <Buttons editing={editing} saveClick={SaveClick} toggleEdit={ToggleEdit}/>
                }
            </td>
        </tr>
    );
}

const InputComponent = forwardRef(({value, treatAs, editing, title}, ref) =>
{
    return treatAs == 1 ? /* 1 => StringMultiline; */
        <textarea defaultValue={value} ref={ref} className="form-control" readOnly={!editing} title={title} rows="3" />
        :
        <input defaultValue={value} ref={ref} type={treatAs == 2 ? 'number' : 'text'}  className="form-control" readOnly={!editing} title={title} />
});

function Buttons({editing, toggleEdit, saveClick}) 
{    
    return editing ?
        <>
            <button title="Save" className="icon-button" onClick={saveClick}>
                <FontAwesomeIcon icon="save"/>
            </button>
            <button title="Cancel" className="icon-button" onClick={toggleEdit}>
                <FontAwesomeIcon icon="times"/>
            </button>
        </>
        :
        <button title="Edit" className="icon-button" onClick={toggleEdit}>
            <FontAwesomeIcon icon="edit"/>
        </button>
}