import {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {AuthorizedGet, AuthorizedPut} from "../AuthorizedRequest";
import NewsAlertForm from "./NewsAlertForm";


export default function NewsAlertEdit()
{
    const [loading, setLoading] = useState(true);
    const [newsAlert, setNewsAlert] = useState()
    const history = useHistory()
    let {newsAlertId} = useParams()

    // Fetch NewsAlerts
    useEffect(() =>
    {
        AuthorizedGet(`api/NewsAlert/${newsAlertId}`, history)
            .then(result =>
            {
                if (result)
                {
                    setNewsAlert(result)
                }
                setLoading(false)
            })
    }, [newsAlertId, history])

    const saveMethod = values => {
        return AuthorizedPut(values, 'api/NewsAlert', history)
            .then(result =>
            {
                if (result)
                {
                    setNewsAlert(result);
                }
                return result;
            });
    };
    
    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <NewsAlertForm newsAlert={newsAlert} saveMethod={saveMethod} loading={loading} />
    );
}