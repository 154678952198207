import React, { useState, useEffect } from 'react'
import { AuthorizedGet, AuthorizedPut } from '../AuthorizedRequest'
import { useHistory, useParams } from 'react-router-dom'
import { Loading } from '../shared/Loading'
import LeaderboardDetails from "./LeaderboardDetails";

export default function LeaderboardTemplateEdit({afterSavePushURL = null})
{
    let { leaderboardTemplateId } = useParams();
    const [leaderboard, setLeaderboard] = useState(null);
    const [loading, setLoading] = useState(true);
    const history = useHistory();

    // Fetch
    useEffect(() =>
    {
        AuthorizedGet(`api/Leaderboard/GetTemplate/${leaderboardTemplateId}`, history)
            .then(result => 
            {
                if (result)
                    setLeaderboard(result);
                setLoading(false);
            });
    }, [history, leaderboardTemplateId]);

    const save = (values, actions) =>
    {
        AuthorizedPut(values, 'api/Leaderboard/TemplateUpdate', history)
            .then(result =>
            {
                actions.setSubmitting(false)                
                if (result)
                {
                    actions.setTouched({});
                    if (afterSavePushURL)
                        history.push(afterSavePushURL);
                }
            })
    }

    if (loading)
        return <Loading />
    
    if (!leaderboard)
        return null
    
    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <LeaderboardDetails leaderboard={leaderboard} saveMethod={save} title="Leaderboard Template" idLabel="L. Template Id" />
    );
}
