import React, {useEffect} from 'react'
import {CardStats} from '../shared/CardStats'
import {TabControl} from '../shared/TabControl'
//import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

export default function Dashboard() {
    // const [activeUsers, setActiveUsers] = useState(null)
    // const [newUsers, setNewUsers] = useState(null)
    // const [range, setRange] = useState(1)
    //const [loading, setLoading] = useState(true)

    // Fetch segments
    useEffect(() => {
        //setLoading(false);
    }, []);
    
    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <div>
            <div className="row align-items-center">
                <div className="md-8 col">
                    <h3 className="m-2">&nbsp;Dashboard</h3>
                </div>
                <div className="md-4 col text-end">
                </div>
            </div>

            <br/>
            <TabControl onChange={() => {}} 
                        items={[{name: "Today (24h)", isActive: true}, {name: "Last Week", isActive: false}]}/>
            <br/>
            <div className="row">
                <CardStats statsTitle="Active Users" value={10} icon="nc-icon nc-single-02"/>
                <CardStats statsTitle="New Users" value={5} icon="nc-icon nc-single-02"/>
                <CardStats statsTitle="Transactions" value={3} icon="nc-icon nc-basket"/>
            </div>
        </div>
    );
}
