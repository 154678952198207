import React, { useState, useEffect } from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import { AuthorizedGet } from '../../AuthorizedRequest'
import { Card } from '../../shared/Card'
import TransactionsSearch from '../elements/TransactionsSearch'
import TransactionsList from '../elements/TransactionsList'
import { transactionsSearchCriteriaObject } from '../elements/TransactionsSearchCriteria'
import { receiptsSearchCriteriaObject } from '../elements/ReceiptsSearchCriteria'
import { composeQueryString, mapQueryStringValuesToObject, useSessionState, useQueryStringAndSessionState, useCache } from '../../shared/Utils'
import { CollapseSearchButton } from '../../shared/Buttons'
import { Collapse } from 'reactstrap';
import MKPaginationUncounted, {mkPagedResultUncounted} from "../../shared/MKPaginationUncounted";

export default function Transactions(props)
{
    const initialEmptyValues = Object.assign({}, Object.assign(transactionsSearchCriteriaObject, receiptsSearchCriteriaObject));

    let history = useHistory()
    let location = useLocation()

    const [loading, setLoading] = useState(false);
    const [initialValues, setInitialValues] = useQueryStringAndSessionState(() => Object.assign({}, initialEmptyValues), history, location.search, 'transactionsInitialValues');
    const [pagedResult, setPagedResult] = useSessionState(mkPagedResultUncounted, 'transactions');
    const [searchCollapsed, setSearchCollapsed] = useCache('transactionsSearchCollapsed', false);
    
    useEffect(() =>
    {
        if (mapQueryStringValuesToObject(initialEmptyValues, location.search))   // There is allowed search parameters in QueryString
        {             
            if (JSON.stringify(initialEmptyValues) !== JSON.stringify(initialValues))  // Those parameters means a different search tan the one saved in sessionStorage
            {
                searchTransactions(initialEmptyValues);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    const searchTransactions = (searchValues, formikBag = { setSubmitting: x => {} }, pageNumber = 1) =>
    {
        searchValues.pageNumber = pageNumber;
        setLoading(true);
        setInitialValues(Object.assign({}, searchValues));

        const queryString = composeQueryString(searchValues)

        AuthorizedGet('api/Transaction/Search?' + queryString, history)
            .then(result => 
            {
                formikBag.setSubmitting(false);
                setPagedResult(result ?? mkPagedResultUncounted);
                setLoading(false);
            });
    };
    
    const onPageClick = (e, pageNumber) => searchTransactions(initialValues, undefined, pageNumber);    

    const toggleSearch = e =>
    {
        e.preventDefault();
        setSearchCollapsed(!searchCollapsed);
    };


    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const paginationComponent = !loading && <MKPaginationUncounted currentPage={pagedResult.pageNumber} currentPageCount={pagedResult.currentPageCount} onClick={onPageClick}/>;

    return (
        <div>
            <Card headerComponent={
                <div className="row">
                    <div className="col-md-10">
                        <h5 className="mt-2 mb-0">
                            Search Parameters
                        </h5>                        
                    </div>
                    <div className="col-md-2 text-end">
                        <CollapseSearchButton collapsed={searchCollapsed} onClick={toggleSearch}/>
                    </div>
                </div>
            }>                
                <Collapse isOpen={!searchCollapsed}>
                    <TransactionsSearch handleSearch={searchTransactions} initialValues={initialValues}/>
                </Collapse>
            </Card>
            <div>
                <Card>
                    {paginationComponent}
                    <TransactionsList transactions={pagedResult.items} loading={loading}/>
                    {paginationComponent}
                </Card>
            </div>
        </div>
    );
}
