import React, {useState, useEffect} from 'react'
import ReactMarkdown from 'react-markdown'
import {Loading} from './shared/Loading'

export default function Home() {
    const [releaseNotes, setReleaseNotes] = useState(null);

    useEffect(() => {
        fetch("./ReleaseNotes.md")
            .then(response => response.text())
            .then(result => setReleaseNotes(result));
    }, []);

    return (
        <div>
            <h3>Welcome to new Administration Panel</h3>
            <br/>
            <h5>Release Notes</h5>
            {releaseNotes ?
                <ReactMarkdown children={releaseNotes}/>
                :
                <Loading/>
            }
        </div>
    );
}
