import React, { useState, useEffect } from 'react'
import {Field, useField} from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Subject} from 'rxjs'
import { debounceTime } from 'rxjs/operators'
import {
    TextFieldInputColumn,
    ToggleInputManaged
} from '../../shared/FormElements'
import { Loading } from '../../shared/Loading'


export default function SegmentFilter({filter, ...props})
{
    const [userPrefKeyObservable] = useState(new Subject())
    const [validationStatus, setValidationStatus] = useState("Not Validated")

    useEffect(() => 
    {
        userPrefKeyObservable.pipe(debounceTime(1000))
        .subscribe(prefKey =>
        { 
            setValidationStatus("Validating")

            if (!prefKey)
            {
                setValidationStatus("Not Validated")
                return
            }

            //Now that the keys suggestion is an aggregation of userPref keys rather then from a single user, we can validate against that
            //console.log("Need to validate key " + prefKey)
            var userPrefType = props.validateUserPrefKey(prefKey)
            if (userPrefType !== null)
            {
                setValidationStatus("Validated")
            }
            else
            {
                setValidationStatus("Not Validated")
            }
            // AuthorizedGet('api/UserSegment/ValidateUserPrefKey/' + encodeURIComponent(prefKey), props.history)
            // .then(result =>
            // {
            //     console.log("userpref validation result " + JSON.stringify(result))
            //     if (result)
            //     {
            //         setValidationStatus("Validated")
            //         updateType(result.type)
            //     }
            //     else
            //     {
            //         setValidationStatus("Not Validated")
            //     }
            // })
        })

        // trigger key validation on load
        validateUserPrefKey(filter.userPrefKey)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const validateUserPrefKey = (key) =>
    {
        if (key === "")
        {
            setValidationStatus("Required")
        }
        else if (validationStatus !== "Validated")
        {
            userPrefKeyObservable.next(key)
        }
    }

    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    if (filter == null)
    {
        return <p>No filter</p>
    }

    return (
        <div className="row align-items-center">
        <Field name={props.prefix + "asOr"} component={ToggleInputManaged} labelText="As OR" column={1}/>
        <Field name={props.prefix + "userPrefKey"} component={TextFieldInputSpinner} labelText="User Pref Key" readOnly={false} column={3} 
            error={ validationStatus } validate={validateUserPrefKey} setValidationStatus={setValidationStatus}/>
        <Field name={props.prefix + "filterOperator"} component={SelectInput} options={props.filterOperators} labelText="Operator" readOnly={false} span = '2' 
            value={filter.operator}/>
        <Field name={props.prefix + "filterValue"} component={TextFieldInputColumn} labelText="Value" readOnly={false} column={4}/>
        <div className='col-md-2'>
            <button className='btn mt-md-3 shadow-none btn-link' title="Remove" onClick={() => props.removeFilter(props.index)} type="button">
                <FontAwesomeIcon className='red' icon='trash' />
            </button>
        </div>
    </div>)
}

const TextFieldInputSpinner = ({ field, ...props }) => {

    const [/* input */, /* meta */, helpers] = useField(field.name);
    const {setValue, setTouched} = helpers

    return (
        <div className={`col-md-${props.column ? props.column : 12}`}>
            <div className="form-group">
                <div className="row">
                    <label>{props.labelText + (props.error ? (": " + props.error) : "")}</label>
                    <Loading size={20} loading={props.error === "Validating"} margin={1}/>
                </div>
                <input {...field} className={"form-control" + (props.error === "Not Validated" ? " error" : "")}
                       readOnly={props.readOnly}
                       onChange={(e) => {setValue(e.target.value); setTouched(true); props.setValidationStatus("Not Validated")}}
                       onKeyPress={e => {if (e.key === 'Enter') e.preventDefault()}}/>
            </div>
        </div>
    )
}

const SelectInput = ({ field, ...props }) =>
{
    const [/* input */, /* meta */, helpers] = useField(field.name);
    const {setValue, setTouched} = helpers

    return (
    <div className={"col-md-" + props.span + " d-table-cell align-middle"}>
        <div className="form-group">
            <label>{props.labelText}</label>
            <select {...field} className="form-control"
                    readOnly={props.readOnly}
                    disabled={props.readOnly}
                    onChange={(e) => {setValue(e.target.value); setTouched(true);}}>
                    {props.options && props.options.map((option, index) =>  <option key={index} value={index}>{option}</option>)}
            </select>
        </div>
    </div>
)}