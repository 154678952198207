import React, {useEffect, useRef, useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import { AuthorizedGet } from '../../AuthorizedRequest'

export default function PrefKeysSuggestions({keys, ...props})
{
    const [filteredKeys, setFilteredKeys] = useState(null);
    const filterAction = useRef(null);

    // useEffect(() =>
    // {
    //     AuthorizedGet('api/UserSegment/PrefKeySuggestions', props.history)
    //         .then(result =>
    //         {
    //             setKeys(result);
    //         });
    // }, []);

    useEffect(() => filterActionReset, []);

    const copyKey = (key) => e =>
    {
        navigator.clipboard.writeText(key);
    }

    function filterActionReset() {
        if (filterAction.current)
        {
            clearTimeout(filterAction.current);
            filterAction.current = null;
        }
    }

    const filter = e => {
        filterActionReset();
        let fValue = e.target.value;
        filterAction.current = setTimeout(() => filterSearch(fValue), 700);
    }

    const filterSearch = fValue => {
        filterAction.current = null;
        if (fValue) {
            let searchExp = fValue.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); //Escaping RegEx special characters
            let regex = new RegExp(searchExp, "i");
            setFilteredKeys(keys.filter(key => regex.test(key)));
        } else {
            setFilteredKeys(null);
        }
    };
    
    
    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    if (keys == null)
    {
        return <p>No Suggestions</p>
    }

    const keysToDisplay = filteredKeys ?? keys;
    
    return (
        <>
            <div className="form-group">
                <label>Search</label>
                <input className="col-md-5" onChange={filter} placeholder="Type to search"/>
            </div>
            <br/>
            <div>
                <table className='table table-sm table-striped table-hover table-responsive'>
                    <tbody>
                        {keysToDisplay.map((key, index) => 
                        <tr key={index + key}>
                            <td>
                                {key.key}
                            </td>
                            <td className="text-end">
                                <button className='btn btn-sm btn-link no-margin shadow-none' title="Copy"
                                        onClick={copyKey(key.key)}>
                                    <FontAwesomeIcon icon='copy'/>
                                </button>
                            </td>
                        </tr>)
                        }
                    </tbody>
                </table>
            </div>
        </>
        )
}
