import React, {useEffect, useState, useRef} from 'react'
import {useField} from 'formik'
import {InputGroup, InputGroupAddon} from 'reactstrap'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import UserSelectModal from "./UserSelectModal";

export const UserIdFieldInputColumn = ({field, form: {touched, errors}, labelText, placeholder, readOnly, toolTip, searchCriteriaObject, searchTitle = 'Search user', column = 12, ...props}) => {

    const ref = useRef();
    const [modal, setModal] = useState(false);
    const [, , {setValue, setTouched, /* setError */}] = useField(field);

    useEffect(() => {
        if (props.autofocus)  // autoFocus attribute not working with Formik
            ref.current.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const toggleModal = () => setModal(!modal);

    const onUserIdSelected = userId => {
        if (userId !== null) {
            setValue(userId);
        }
        toggleModal();
    };

    const buttonOnClick = e => {
        e.preventDefault();
        setTouched(false);
        toggleModal();
    };

    return (
        <>
            <div className={`col-md-${column}`}>
                <div className="form-group">
                    <label title={toolTip}>{labelText}</label>
                    <InputGroup>
                        <input {...field} placeholder={props.placeholder}
                               className={props.errors ? "form-control error" : "form-control"}
                               readOnly={readOnly} title={toolTip} ref={ref}/>
                        <InputGroupAddon addonType="append">
                            <button className="btn btn-primary inline-button mt-0 pt-0 ps-3 pe-3" title={searchTitle}
                                    onClick={buttonOnClick} disabled={readOnly} style={{height: '30px'}}>
                                <FontAwesomeIcon icon="user-edit"/>
                            </button>
                        </InputGroupAddon>
                    </InputGroup>
                </div>
            </div>

            <UserSelectModal isOpen={modal} {...{onUserIdSelected, searchTitle, searchCriteriaObject}} />
        </>
    );
};