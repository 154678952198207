import React, { useState, useEffect, useRef } from 'react'
import { useHistory, useParams} from "react-router-dom"
import {AuthorizedGet, AuthorizedPost, AuthorizedPut} from '../AuthorizedRequest'
import { jsonSecureParse } from '../shared/Utils'
import { JSONEditorReadOnly } from '../shared/JSONEditor'
import { Card } from '../shared/Card'
import { Loading } from '../shared/Loading'
import { formatSizeBytes } from '../shared/SizeBytes'
import { shortYearDateTime, getDateTimeToolTip } from '../shared/MKDateTime'
import { PlatformIconText } from '../shared/FormElementsDropdowns'
import AssetBundleDetailsBundles from './AssetBundleDetailsBundles'
import AssetBundleIsActive from "./AssetBundleIsActive";
import {descriptionToolTip} from "./AssetBundleUploadNew";
import {InputGroup, InputGroupAddon} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
    
export default function AssetBundleDetails(props)
{
    const [assetBundle, setAssetBundle] = useState(null);
    const [loading, setLoading] = useState(true);
    let { platform, appVersion, bundleVersion } = useParams();
    const history = useHistory();
    const jsonRef = useRef(null);
    const descriptionRef = useRef();

    // Fetch 
    useEffect(() => {
        AuthorizedGet(`api/AssetBundle/${appVersion}/${bundleVersion}/${platform}`, history)
            .then(result => {
                jsonRef.current = jsonSecureParse(result.json);
                setAssetBundle(result);
                setLoading(false);
            });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onDescriptionEditClick = e => {
        e.preventDefault();
        let text = prompt('Enter new description', descriptionRef.current.value);
        if (text != null) {
            descriptionRef.current.value = '[ ... Processing ... ]';
            AuthorizedPut(text, `api/AssetBundle/UpdateDescription/${appVersion}/${bundleVersion}/${platform}`, history)
                .then(result => {
                    descriptionRef.current.value = result == null ? text : result;
                });
        }
    };

    if (loading)
        return <Loading />

    if (!assetBundle)
        return null
    
    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <>
            <Card title="Asset Bundle Details">
                <div className="row">
                    <div className="col-md-3">
                        <div className="form-group">
                            <label>App Version</label>
                            <input className="form-control text-monospace" value={assetBundle.version} readOnly={true}/>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <label>Bundle Version</label>
                            <input className="form-control text-monospace" value={assetBundle.bundleVersion}
                                   readOnly={true}/>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <label>Platform</label>
                            <span className="form-control" disabled>
                            <PlatformIconText>{assetBundle.platform}</PlatformIconText>
                        </span>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <label>Active</label>
                            <AssetBundleIsActive assetBundle={assetBundle}/>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label title={descriptionToolTip}>Description</label>
                            <InputGroup>
                                <input ref={descriptionRef} className="form-control" value={assetBundle.description}
                                       title={descriptionToolTip} readOnly={true}/>
                                <InputGroupAddon addonType="append">
                                    <button className="btn btn-primary inline-button mt-0 pt-0 ps-3 pe-3"
                                            title="Edit Description"
                                            onClick={onDescriptionEditClick} style={{height: '30px'}}>
                                        <FontAwesomeIcon icon="pen-square"/>
                                    </button>
                                </InputGroupAddon>
                            </InputGroup>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <label>Build Time</label>
                            <input className="form-control" value={shortYearDateTime(assetBundle.buildTime)}
                                   readOnly={true} title={getDateTimeToolTip(assetBundle.buildTime)}/>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <label>Size</label>
                            <input className="form-control" value={formatSizeBytes(assetBundle.byteLength)}
                                   title={assetBundle.byteLength + ' Bytes'} readOnly={true}/>
                        </div>
                    </div>
                </div>
            </Card>

            <span/>
            <Card title="Bundles">
                <AssetBundleDetailsBundles bundles={jsonRef.current?.bundles ?? jsonRef.current?.Bundles ?? []}/>
            </Card>

            <span/>
            <Card title="Manifests">
                <JSONEditorReadOnly maxLines={40} value={jsonRef.current ?? {}}/>
            </Card>
        </>
    );
}