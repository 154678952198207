import React, {useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import SweetAlert from 'react-bootstrap-sweetalert'


export const SubmitButton = ({isSubmitting, errors, touched, forceTouched, column}) => {
    return <BaseSubmitButton {...{isSubmitting, errors, touched, forceTouched, column}} icon="save" text=" Save"
                             isSubmittingText=" Saving..." unTouchedText=" Saved"/>
}

export const CreateButton = ({isSubmitting, errors, touched, forceTouched, column}) => {
    return <BaseSubmitButton {...{isSubmitting, errors, touched, forceTouched, column}} icon="save" text=" Create"
                             isSubmittingText=" Creating..." unTouchedText=" Create"/>
}

export const SendButton = ({isSubmitting, errors, touched, forceTouched, column}) => {
    return <BaseSubmitButton {...{isSubmitting, errors, touched, forceTouched, column}} icon="save" text=" Send"
                             isSubmittingText=" Sending..."/>
}

export const SearchButton = ({isSubmitting, errors, touched, forceTouched, column}) => {
    return <BaseSubmitButton {...{isSubmitting, errors, touched, forceTouched, column}} icon="search" text=" Search"
                             isSubmittingText=" Searching..."/>
}

export const BaseSubmitButton = ({isSubmitting, errors, touched, forceTouched, column = 2, icon, text, isSubmittingText, unTouchedText}) => {
    const hasErrors = errors && Object.entries(errors).length !== 0;
    const isTouched = forceTouched || (touched && Object.entries(touched).length !== 0);
    
    return (
        <button type="submit" className={"btn btn-primary col-md-" + column}
                disabled={isSubmitting || hasErrors || !isTouched}>
            <FontAwesomeIcon icon={isSubmitting ? "spinner" : icon} spin={isSubmitting}/>
            {isSubmitting ? isSubmittingText : !isTouched && unTouchedText ? unTouchedText : text}
        </button>
    )
}

export const AddButton = ({onClick, disabled = false, toolTip = "Add"}) => {
    return (
        <button type="button" className="btn btn-sm shadow-none btn-link m-0" 
                onClick={onClick} title={toolTip} disabled={disabled}>
            <FontAwesomeIcon className="green" icon="plus" size="2x" />
        </button>
    )
}

export const AddButtonText = ({onClick, toolTip = "Add New", children = "Add New", disabled = false}) => {
    return (
        <button type="button" className="btn btn-primary" onClick={onClick} title={toolTip} disabled={disabled}>
            <FontAwesomeIcon icon='plus'/>&nbsp;{children}
        </button>
    )
}

export const HelpButton = ({onClick, toolTip = "Help"}) => {
    return (
        <button type="button" className="btn-round btn btn-primary" onClick={onClick} title={toolTip}>
            <FontAwesomeIcon size="lg" icon='question-circle'/>
        </button>
    )
}

export const SearchButtonIcon = ({onClick, toolTip = "Search", extraClassName = ""}) => {
    return (
        <button type="button" className={'btn-round btn btn-primary ' + extraClassName} onClick={onClick} title={toolTip}>
            <FontAwesomeIcon size="lg" icon='search'/>
        </button>
    )
}

export const UploadButton = ({onClick, toolTip = "Upload"}) => {
    return (
        <button type="button" className="btn-round btn btn-primary" onClick={onClick} title={toolTip}>
            <FontAwesomeIcon size="lg" icon='upload'/>
        </button>
    )
}

export const UploadButtonText = ({onClick, toolTip = "Upload File", children = "Upload", disabled = false}) => {
    return (
        <button type="button" className="btn btn-primary" onClick={onClick} title={toolTip} disabled={disabled}>
            <FontAwesomeIcon icon='upload'/>&nbsp;{children}
        </button>
    )
}

export const EditButton = ({onClick, toolTip = "Edit"}) => {
    return (
        <button type="button" className="btn btn-primary btn-icon btn-sm" title={toolTip} onClick={onClick}>
            <FontAwesomeIcon icon="edit"/>
        </button>
    )
}

export const IconButtonAlt = ({onClick, toolTip, icon, children = undefined}) => {
    return (
        <button type="button" className="btn btn-secondary btn-sm" onClick={onClick}
                title={toolTip}>
            <FontAwesomeIcon icon={icon}/>
            {
                children &&
                <>
                    &nbsp;{children}
                </>
            }
        </button>
    )
}

export const IconButton = ({onClick, toolTip, icon, children = undefined}) => {
    return (
        <button type="button" className="btn btn-primary btn-sm" onClick={onClick}
                title={toolTip}>
            <FontAwesomeIcon icon={icon}/>
            {
                children &&
                <>
                    &nbsp;{children}
                </>
            }
        </button>
    )
}

export const CollapseButton = ({onClick, collapsed = false, toolTipOpen = "", toolTipCollapsed = "", icon = "", className}) => {
    return (
        <button type="button" className={'btn-round btn btn-primary btn-sm ' + className} onClick={onClick}
                title={collapsed ? toolTipCollapsed : toolTipOpen}>
            <FontAwesomeIcon icon={collapsed ? 'angle-down' : 'angle-up'} />
            &nbsp;
            <FontAwesomeIcon icon={icon}/>
        </button>
    )
}

export const CollapseSearchButton = ({onClick, collapsed = false, toolTip = null, className}) => {
    return (
        <CollapseButton onClick={onClick} collapsed={collapsed}  icon={"search"}
                        toolTipCollapsed={(toolTip ?? "Expand Search section")}
                        toolTipOpen={(toolTip ?? "Collapse Search section")} className={className} />
    )
}

export const CollapseDocumentationButton = ({onClick, collapsed = false, toolTip = null, className}) => {
    return (
        <CollapseButton onClick={onClick} collapsed={collapsed} icon={"question-circle"}
                        toolTipCollapsed={(toolTip ?? "Expand Documentation section")}
                        toolTipOpen={(toolTip ?? "Collapse Documentation section")} className={className} />
    )
}

/**
 * ToDo: Known issue: https://github.com/djorg83/react-bootstrap-sweetalert/issues/64
 */
export const DeleteButton = ({onDelete, entityName, 
                                 iconClassName = 'trash-sign', 
                                 buttonClassName = 'icon-button', 
                                 disabled = false, 
                                 disabledMessage = "Can't Delete"}) => {
    const [showConfirm, setShowConfirm] = useState(false);

    const onClick = (e) => {
        e.stopPropagation();
        setShowConfirm(true);
    }
    
    if (disabled) {
        return (
            <>
                <button type="button" title={disabledMessage} className={buttonClassName} disabled={true}>
                    <FontAwesomeIcon className={iconClassName} icon="trash" color={"#ccc"} aria-disabled={true}/>
                </button>
            </>
        )
    }
    
    return (
        <>
            <button type="button" title="Delete" className={buttonClassName}>
                <FontAwesomeIcon className={iconClassName} icon="trash" onClick={ onClick }/>
            </button>
            {showConfirm &&
            <SweetAlert
                btnSize="sm"
                style={{fontSize: '0.9em'}}
                // warning
                showCancel
                confirmBtnText="Delete"
                confirmBtnBsStyle="danger"
                title={`Delete${entityName ? " " + entityName : ""}?`}
                onConfirm={() => {
                    onDelete();
                    setShowConfirm(false);
                }}
                onCancel={() => setShowConfirm(false)}
                focusCancelBtn
                closeOnClickOutside={false}
            >
                This action cannot be undone.
            </SweetAlert>
            }
        </>
    )
}

export const DeleteButtonText = ({onDelete, confirmationMessageTitle, 
                                     children = undefined,
                                     tooltip = 'Delete',
                                     disabled = false,
                                     disabledMessage = "Can't Delete"}) => {
    const [showConfirm, setShowConfirm] = useState(false);

    const onClick = (e) => {
        e.stopPropagation();
        setShowConfirm(true);
    }

    if (disabled) {
        return (
            <>
                <button type="button" className="btn btn-danger" title={disabledMessage} disabled={true}>
                    {
                        children &&
                        <>
                            {children}
                        </>
                    }
                </button>
            </>
        )
    }

    return (
        <>
            <button type="button" className="btn btn-danger" onClick={onClick} title={tooltip}>
                {
                    children &&
                    <>
                        {children}
                    </>
                }
            </button>
            {showConfirm &&
                <SweetAlert
                    btnSize="sm"
                    style={{fontSize: '0.9em'}}
                    // warning
                    showCancel
                    confirmBtnText="Delete"
                    confirmBtnBsStyle="danger"
                    title={confirmationMessageTitle}
                    onConfirm={() => {
                        onDelete();
                        setShowConfirm(false);
                    }}
                    onCancel={() => setShowConfirm(false)}
                    focusCancelBtn
                    closeOnClickOutside={false}
                >
                    This action cannot be undone.
                </SweetAlert>
            }
        </>
    )
}