import {useState} from "react";
import {DateTime} from 'luxon';
import {mkPagedResult} from "./MKPagination";

export function dateStartOfWeek()   // ** temporal ** //
{
    return DateTime.utc().startOf('week').minus({days:2}).toISO(); 
}

export function isDirty(object) 
{
    for (let [ , value] of Object.entries(object)) {
        if (value != null && value !== '')
            return true;
      }
    return false;
}

export function jsonSecureParse(jsonString)
{
    try {
        return JSON.parse(jsonString);
    } catch (e) {
        return {
            Error: 'The string value is not a valid JSON object representation',
            string: jsonString ?? '[ null ]'
        };
    }
}

export function checkControlCharactersAndLeadingAndTrailingWhitespaces(text)
{
    let RegEx = /^\s+|([\cA-\cZ])|\s+$/;    //"[\cA-\cZ]" looks for control character like \t, \r, \n, \e, etc
    return RegEx.test(text);
}


/**
 * Creates a QueryString (location.search) based on an object properties/values
 * Example:
 *  queryValuesObject = { searchQuery: 'Hello world/', pageNumber: 1, pageSize: 50}
 * will return:
 *  'searchQuery=Hello%20world%2F&pageNumber=1&pageSize=50'
 *
 * @export
 * @param {string} queryValuesObject Object from which properties/values will be inspected to make the returned QueryString
 * @param {string} [locationSearch=null] (optional) Actual QueryString (location.search), only used to return the querystring parameters in the same order as actual
 * @returns {string} new concatenated QueryString (location.search) based on properties/values on [queryValuesObject] parameter
 */
export function composeQueryString(queryValuesObject, locationSearch = null)
{
    let newQueryValues = new URLSearchParams();
    if (queryValuesObject)
    {
        if (locationSearch !== null)
        {
            let actualQueryValues = new URLSearchParams(locationSearch);

            for(let key of actualQueryValues.keys()) { //To add the QueryString parameters in the actual order, avoiding modifying the order of parameters and adding unnecessary entries in history
                let kValue = queryValuesObject[key];
                if (kValue !== undefined && kValue !== null && kValue !== '') {
                    newQueryValues.append(key, queryValuesObject[key]);
                }
            }
        }
        
        let objectEntries = Object.entries(queryValuesObject);
        for (let [key, value] of objectEntries) {
            if (!newQueryValues.has(key) && value !== null && value !== '') {
                newQueryValues.append(key, value);
            }
        }
    }
    return newQueryValues.toString();
}

/**
 * Maps parameters in QueryString (location.search) to properties in [object] (only the actual properties of [object] will be mapped)
 *
 * @export
 * @param {object} object Object to which actual properties QueryString values will be mapped
 * @param {string} locationSearch Actual QueryString (location.search)
 * @returns {boolean} True if any property was mapped
 */
export function mapQueryStringValuesToObject(object, locationSearch) {
    let rtn = false;

    const queryString = new URLSearchParams(locationSearch);
    for (const [key, value] of queryString.entries()) 
        if (object.hasOwnProperty(key) && value !== null && value !== '')
        {
            let newValue = convertStringValue(value);

            // eslint-disable-next-line eqeqeq
            if (object[key] != newValue)
            {
                object[key] = newValue;
                rtn = true;
            } 
        }
    
    return rtn;
}

function convertStringValue(value)
{
    if (isNaN(value) === false)
    {
        return parseFloat(value);
    }
    
    if (value === 'False' || value === 'false')
    {
        return false;
    }
    
    if (value === 'True' || value === 'true')
    {
        return true;
    }
    
    return decodeURIComponent(value);
}

function useStateWithCustomSave(initialState, customSaveFunction) {
    
    const [value, setValue] = useState(initialState);

    const setStateWithCustom = newValue => {
        if (typeof newValue === 'function') { 
            setValue(prevValue => { 
                const nextValue = newValue(prevValue); 
                customSaveFunction(nextValue); 
                return nextValue; 
            });
        }
        else {
            setValue(newValue); 
            customSaveFunction(newValue);
        }
    };
    
    return [value, setStateWithCustom];
}

function setQueryString(value, history, locationSearch)
{
    let newQS = '?' + composeQueryString(value, locationSearch);
    if (newQS !== locationSearch)
    {
        history.push({ search: newQS });
    }
}

const useSessionStateExpireMinutes = 10;

function setSession(value, sessionKey, expireMinutes = useSessionStateExpireMinutes)
{
    setTimeout(() => { 
        sessionStorage[sessionKey] = JSON.stringify(value); 
        sessionStorage[sessionKey + '.Expire'] = (new Date()).getTime() + expireMinutes * 60 * 1000; // (new Date).getTime(): milliseconds since January 1, 1970
    }); 
}

function getSessionInitialState(initialState, sessionKey) 
{
    return () => {
        let sessionValue = sessionStorage[sessionKey];
        
        if (sessionValue !== undefined)
        { 
            if ((new Date()).getTime() < parseInt(sessionStorage[sessionKey + '.Expire'])) {
                return JSON.parse(sessionValue);
            } // If there is a value stored in session with Kye=sessionKey and it's not expired, use it as initialValue
            else {
                sessionStorage.removeItem(sessionKey);
            }
        }

        return typeof initialState === 'function' ? initialState() : initialState;
    };
}

/**
 * Track a state variable (React.useState) also in sessionStorage, to keep in for use in a next load of a component.
 * If it is already a value stored from before, uses it as initialState
 *
 * @export
 * @param {object/function} initialState
 * @param {string} sessionKey Key to maintain state in browser's sessionStorage (sessionStorage[sessionKey])
 * @param {number} expireMinutes Time for which the value will be valid
 * @returns {Array} a session-stateful value, and a function to update it.
 */
export function useSessionState(initialState, sessionKey, expireMinutes = useSessionStateExpireMinutes) 
{
    return useStateWithCustomSave(getSessionInitialState(initialState, sessionKey), x => setSession(x, sessionKey, expireMinutes));
}

export function usePagedResultSessionState(sessionKey)
{
    const [pagedResult, setPagedResult] = useSessionState(mkPagedResult, sessionKey);
    const refreshItem = x => {
        const newValue = { ...pagedResult };
        let index = newValue.items.findIndex(i => i.id == x.id);
        if (index >= 0)
            newValue.items[index] = x;
        else 
            newValue.items = [x, ...newValue.items];
        
        setPagedResult(newValue);
    };
    return [pagedResult, setPagedResult, refreshItem];
}

export function useQueryStringState(initialState, history, locationSearch) 
{    
    return useStateWithCustomSave(initialState, x => setQueryString(x, history, locationSearch));
}

export function useQueryStringAndSessionState(initialState, history, locationSearch, sessionKey, sessionExpireMinutes = useSessionStateExpireMinutes) 
{
    const saveFunction = x => {
        setSession(x, sessionKey, sessionExpireMinutes);
        setQueryString(x, history, locationSearch);
    };
    return useStateWithCustomSave(getSessionInitialState(initialState, sessionKey), saveFunction);
}

/**
 * Simplified version of useSessionState
 *
 * @export
 * @param {string} key Key with which the value will be stored in browser's sessionStorage
 * @param initialValue Value to be used if there is no value already stored in the cache
 * @param storage Browser storage to be used (sessionStorage by default)
 * @returns {Array} a session-stateful value, and a function to update it.
 */
export function useCache(key, initialValue = null, storage = sessionStorage)
{
    const [value, setValue] = useState(() => storage[key] !== undefined ? JSON.parse(storage[key]) : initialValue);

    const setCache = newValue =>
    {
        setValue(newValue);
        setTimeout(() => storage[key] = JSON.stringify(newValue));
    };

    return [value, setCache];
}

export function useCacheLocal(key, initialValue = null)
{
    return useCache(key, initialValue, localStorage);    
}

export function downloadStringAsFile(stringFileContent, fileName, contentType = 'text/plain')
{
    const element = document.createElement("a");
    const file = new Blob([stringFileContent], {
        type: contentType
    });
    element.href = URL.createObjectURL(file);
    element.download = fileName;
    document.body.appendChild(element);
    element.click();
}

/**
 * Converts a string path to a value that is existing in a json object.
 *
 * @param {Object} jsonData Json data to use for searching the value.
 * @param {Object} path the path to use to find the value.
 * @returns {valueOfThePath|null}
 */
export function jsonPathToValue(jsonData, path) {
    if (!(jsonData instanceof Object) || typeof (path) === "undefined") {
        throw "Not valid argument:jsonData:" + jsonData + ", path:" + path;
    }
    path = path.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    path = path.replace(/^\./, ''); // strip a leading dot
    var pathArray = path.split('.');
    for (var i = 0, n = pathArray.length; i < n; ++i) {
        var key = pathArray[i];
        if (key in jsonData) {
            if (jsonData[key] !== null) {
                jsonData = jsonData[key];
            } else {
                return null;
            }
        } else {
            return key;
        }
    }
    return jsonData;
}

export function jsonPathSetValue(jsonData, path, value)
{
    if (!(jsonData instanceof Object) || typeof (path) === "undefined") {
        throw "Not valid argument:jsonData:" + jsonData + ", path:" + path;
    }
    path = path.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    path = path.replace(/^\./, ''); // strip a leading dot
    
    let pathItems = path.split('.');
    let targetObject = jsonData;
    let propertyName = pathItems[0];
    for (let i = 1; i < pathItems.length; i++) {
        targetObject = targetObject[propertyName];
        propertyName =  pathItems[i];
    }
    targetObject[propertyName] = value;
}