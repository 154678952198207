import React, {useEffect, useRef, useState} from "react";
import {useHistory} from "react-router-dom";
import {AuthorizedPut} from "../../AuthorizedRequest";
import SelectAgents from "../../shared/SelectAgents";
import {useSupportTicketLocationState} from "./SupportTicketsNavigator";
import {useToasts} from "react-toast-notifications";
import authService from "../../api-authorization/AuthorizeService";
import {useCacheLocal} from "../../shared/Utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


export default function SupportTicketAssignedAgentEditor({ ticket, readOnly = false })
{
    const history = useHistory();
    const [ t, recentIdsList, setT ] = useSupportTicketLocationStateWithResentAssignedList(ticket);
    const [ assignedAgentId, setAssignedAgentId ] = useState(ticket.assignedAgentId);
    const [ updating, setUpdating ] = useState(null);
    const currentAgentId = useRef();
    const { addToast } = useToasts();

    useEffect(() => {
        (async () => currentAgentId.current = (await authService.getUser()).oid)();
    }, []);
    useEffect(() => setT(ticket),
    // eslint-disable-next-line react-hooks/exhaustive-deps
        [ticket]);
    useEffect(() => setAssignedAgentId(t.assignedAgentId), [t]);

    const onChange = (agentId, agentDisplayName) => {
        if (agentId !== t.assignedAgentId) {
            setUpdating(true);
            AuthorizedPut(null, `api/Support/UpdateAssignedAgent/${ticket.id}/${agentId}?ticketURL=${encodeURI(window.location.href)}`, history)
                .then(r => {
                    if (r) {
                        if (r.warningMessage) {
                            ticket.assignedAgentId = r.assignedAgentId;
                            setT(Object.assign({}, ticket));
                            addToast(r.warningMessage, { appearance: 'warning', autoDismiss: true });
                            console.warn(r.errorMessage);
                        }
                        else {
                            setT(r);
                            addToast(getToastMsg(agentId, agentDisplayName, currentAgentId.current), { appearance: 'info', autoDismiss: true });
                        }
                    }
                })
                .finally(() => setUpdating(false));
        }
    };
    
    return (
        <div className="row">
            <div className="col-md-11 pe-1">
                <SelectAgents selectedAgentId={assignedAgentId}
                              onChange={onChange}
                              recentAgentIdsList={recentIdsList}
                              processing={updating} readOnly={updating || readOnly}/>                
            </div>
            <div className="col-md-1 p-0 text-start">
                <button className="btn btn-sm no-margin shadow-none btn-link mt-1" title="Assign ticket to myself"
                        onClick={() => onChange(currentAgentId.current)} type="button"
                        disabled={currentAgentId.current === assignedAgentId}>
                    <FontAwesomeIcon className="green" icon='reply'/>
                </button>
            </div>
        </div>
    );
}

function getToastMsg(agentId, agentDisplayName, currentAgentId) {
    
    if (agentId === currentAgentId)
        return "Ticket assigned to yourself";
    
    let iSpace = agentDisplayName?.indexOf(' ');
    let name = iSpace > 3 ? agentDisplayName.substring(0, iSpace) : agentDisplayName;
    
    return (
        <>
            Ticket assigned to <b>{name}</b>.
            <br/>
            <i>An <b>email</b> notification was send.</i>
        </>        
    );
}

function useSupportTicketLocationStateWithResentAssignedList(ticket) {
    const [ t, setT ] = useSupportTicketLocationState(ticket);
    const [ recentIdsList, setRecentIdsList] = useCacheLocal('mkSupportTicketsRecentAssignedAgentIdsList', []);
    
    useEffect(() => {
        if (t.assignedAgentId)
        {
            let index = recentIdsList.findIndex(x => x === t.assignedAgentId);
            if (index === -1) {
                recentIdsList.unshift(t.assignedAgentId);
                if (recentIdsList.length > 5)
                    recentIdsList.pop();
            } else {
                let item = recentIdsList.splice(index,1)[0];
                recentIdsList.unshift(item);
            }
            setRecentIdsList([...recentIdsList]);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t]);
    
    return [ t, recentIdsList, setT];
}


//Test:

// <div className="row">
//     <div className={`col-md-4`}>
//         <div className="form-group">
//             <label>Spacer</label>
//             <input className="form-control" />
//         </div>
//     </div>
//     <div className={`col-md-4`}>
//         <div className="form-group">
//             <label>Assigned to</label>
//             <SelectAgents/>
//         </div>
//     </div>
//     <div className={`col-md-4`}>
//         <div className="form-group">
//             <label>Spacer</label>
//             <input className="form-control" />
//         </div>
//     </div>
// </div>