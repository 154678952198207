import React, { useState } from 'react'
import { MKPaginationWithTotal, mkPagedResult, setPagedResultPage } from '../shared/MKPagination'
import SizeBytes from '../shared/SizeBytes'
import { ShortYearDateTime } from '../shared/MKDateTime'

const bundlesPageSize = 25;

export default function AssetBundleDetailsBundles({ bundles })
{
    const [pagedResult, setPagedResult] = useState(() => getPagedResult(bundles, 1));

    function onBundlesPageClick(e, pageNumber)
    {
        setPagedResult(getPagedResult(bundles, pageNumber));
    }

    function getPagedResult(items, pageNumber)
    {
        return setPagedResultPage({ ...mkPagedResult, pageSize: bundlesPageSize }, items, pageNumber);
    }

    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <>
            <table className='table table-sm table-striped'>
                <thead>
                    <tr>
                        <th>Bundle</th>
                        <th>Build Date</th>
                        <th>Hash</th>
                        <th>CRC</th>
                        <th>Size</th>
                    </tr>
                </thead>
                <tbody>
                    {pagedResult.items.map((bundle, index) =>
                    <tr className="tr" key={index}>
                        <td>{bundle.bundle}</td>
                        <td><ShortYearDateTime>{bundle.buildTime}</ShortYearDateTime></td>
                        <td>{bundle.hash}</td>
                        <td>{bundle.crc}</td>
                        <td><SizeBytes>{bundle.byteLength}</SizeBytes></td>
                    </tr>
                    )}
                </tbody>
            </table>
            <MKPaginationWithTotal currentPage={pagedResult.pageNumber} total={pagedResult.pagesTotalCount} totalItems={pagedResult.itemsTotalCount} onClick={onBundlesPageClick} itemsName="Bundles included"/>
        </>
    );
}