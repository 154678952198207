import React, {useContext} from 'react'
import ActiveModulesContext from "../../contexts/ActiveModulesContext";

export const ModuleConditional = ({modules, children}) => {

    const { activeModules } = useContext(ActiveModulesContext);
    
    //////// render
    if (activeModules !== null && modules.every(x => activeModules.includes(x))) {
        return <>{children}</>;
    } else {
        return null;
    }
    
}