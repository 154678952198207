import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { AuthorizedGet, AuthorizedPut, AuthorizedPost } from '../AuthorizedRequest'
import { Card } from '../shared/Card'
import { AddButton } from '../shared/Buttons'
import { Loading } from '../shared/Loading'
import LeaderboardsList from '../Leaderboards/LeaderboardsList'
import TotalCount from '../shared/TotalCount'
import SoloEventDetails from "./SoloEventDetails";

export default function SoloEventEdit()
{
    const [event, setEvent] = useState(null);
    const [loading, setLoading] = useState(true);

    const history = useHistory();
    let {eventId} = useParams();
        
    // Fetch event
    useEffect(() => 
    {
        AuthorizedGet(`api/SoloEvent/${eventId}`, history)
            .then(result => {
                if (!result.pausedUntil) // null value causes unmanaged->managed field error on change
                {
                    result.pausedUntil = "";
                }
                setEvent(result);
                setLoading(false);
            });
    }, [eventId, history]);

    // Save changes
    const saveEvent = (values, actions) => 
    {
        AuthorizedPut(values, 'api/SoloEvent', history)
            .then(result => {
                actions.setSubmitting(false);
                if (result) {
                    actions.setTouched({});
                }
            });
    }

    // Create Leaderboard
    const createLeaderboard = () => 
    {
        AuthorizedPost(event, 'api/Leaderboard/CreateWithSoloEvent', history)
            .then(result => {
                history.push("/Leaderboards/" + result);
            });
    }

    if (loading)
    { return <Loading /> }
    
    if (!event)
    { return null }
    
    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <>
            
            <SoloEventDetails soloEvent={event} saveMethod={saveEvent} />

            <Card headerComponent={
                <div className="row">
                    <div className="col-md-10">
                        <h3>
                            Leaderboards
                        </h3>
                    </div>
                    <div className="col-md-2 text-end">
                        <AddButton onClick={createLeaderboard} title="Add New Leaderboard for the event"/>
                    </div>
                </div>
            }>

                <LeaderboardsList leaderboards={event.leaderboards} showEventColumn={false}/>

                <div className="row">
                    <div className="col-md-12 d-flex align-items-center justify-content-end">
                        <TotalCount itemsName="Leaderboards for the Event">{event.leaderboards.length}</TotalCount>
                    </div>
                </div>
            </Card>

        </>
    );
}