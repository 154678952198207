import React from 'react'

export const CronUI = ({ field, form: { touched, errors },  ...props }) =>
(   <div>
        <div className="human-readable">
            {props.description}
        </div>
        <div className="text-editor">
            <input {...field} type="text" className={props.error ? "error" : ""} onChange={props.onChange}></input>
        </div>
        <div className="part-explanation">
            <div className="cron-parts">
                <div><span>minute</span></div>
                <div><span>hour</span></div>
                <div><span>day</span><br></br>(month)</div>
                <div><span>month</span></div>
                <div><span>day</span><br></br>(week)</div>
            </div>
            <table>
                <tbody>
                <tr><th>*</th><td>any value</td></tr>
                <tr><th>,</th><td>value list separator</td></tr>
                <tr><th>-</th><td>range of values</td></tr>
                </tbody>
            </table>
        </div>
    </div>
)