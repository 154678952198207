import React from 'react'
import { useHistory } from "react-router-dom"
import { Loading } from '../../shared/Loading'
import { ShortYearDate } from '../../shared/MKDateTime'
import { ReceiptButton } from './ReceiptsButton'
import { AppStoreIcon } from '../../shared/FormElementsDropdowns'
import {UncontrolledTooltip} from "reactstrap";


export default function TransactionsList({ transactions, loading }) {
    
    const history = useHistory();

    const onRowClick = transactionId => e => {
        e.stopPropagation();
        const path = `/IAP/Transactions/${encodeURIComponent(transactionId)}`;
        history.push(path);
    }

    if (loading)
    {
        return <Loading loading={true} />
    }

    return (
        <table className='table table-full-width table-striped'>
            <thead>
                <tr>
                    <th>Id</th>
                    <th>Date</th>
                    <th>User Id</th>
                    {/* <th>Store Transaction Id</th> */}
                    <th>Store</th>
                    <th>Product Id</th>
                    <th>Amount</th>
                    <th>Currency</th>
                    {/* <th>Base Amount</th> */}
                    {/* <th>Base Currency</th> */}
                    <th/>
                </tr>
            </thead>
            <tbody>
                { transactions?.map((transaction, index) =>
                    <tr className="tr-link" onClick={ onRowClick(transaction.transactionId) } key={index}>
                        <TransactionIdTD storeTransactionId={transaction.storeTransactionId}
                                         indexKey={index}>{transaction.transactionId}</TransactionIdTD>
                        <td><ShortYearDate>{transaction.dateTime}</ShortYearDate></td>
                        <td>{transaction.userId}</td>
                        {/* <td>{transaction.storeTransactionId}</td> */}
                        <td className="text-center"><AppStoreIcon>{transaction.store}</AppStoreIcon></td>
                        <td>{transaction.productId}</td>
                        <td title={'Base Amount: ' + transaction.baseAmount} className="text-end">{transaction.amount}</td>
                        <td title={'Base Currency: ' + transaction.baseCurrency}>{transaction.currency}</td>
                        {/* <td>{transaction.baseAmount}</td> */}
                        {/* <td>{transaction.baseCurrency}</td> */}
                        <td className="text-center">
                            <ReceiptButton hash={transaction.receiptId} />
                        </td>
                    </tr>
                )}
            </tbody>
        </table>
        );
}


function TransactionIdTD({ children: tId, storeTransactionId, indexKey })
{
    let maxLenght = 32;

    if (tId?.length < maxLenght)
        return <td title={'StoreTransactionId: ' + storeTransactionId}>{tId}</td>;
    else
        return (
            <td title={'TransactionId: ' + tId + '\n\nStoreTransactionId: ' + storeTransactionId}>
                {tId.substr(0, maxLenght)}<span className="text-primary" id={'TransactionIdSpan' + indexKey}>...</span>
                <UncontrolledTooltip target={'TransactionIdSpan' + indexKey}><i>{tId}</i></UncontrolledTooltip>                            
            </td>
        );
}
