import React, {useState} from 'react'
import {CardStats} from '../../shared/CardStats'
import {Card} from '../../shared/Card'
import {Loading} from '../../shared/Loading'
import {AuthorizedGet} from '../../AuthorizedRequest'
import {useHistory} from 'react-router-dom'
import {PieChart, Pie, Legend, Tooltip, Cell, BarChart, Bar, XAxis, YAxis, CartesianGrid, Brush} from 'recharts'
import categories from "../elements/SupportTicketCategories.json";
import StatisticsDateRangesSelector from "./Details/StatisticsDateRangesSelector";
import {composeQueryString} from "../../shared/Utils";

export default function Statistics() {
    const [statsData, setStatsData] = useState(null)
    const [loading, setLoading] = useState(true)
    const history = useHistory()
        
    function onDateRangeChange(dateRange) {
        setLoading(true);
        AuthorizedGet('api/Support/Statistics?' + composeQueryString(dateRange), history)
            .then(result => {
                if (result) {
                    //console.log("Stats", result)
                    setStatsData(result);
                }
                setLoading(false);
            });
    }
    
    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <div>
            <div className="row">
                <div className="col-md-12 pl-0 pr-2 pb-1">
                    <Card>
                        <StatisticsDateRangesSelector onChange={onDateRangeChange} processing={loading} />
                    </Card>                    
                </div>
            </div>
            {loading ? <Loading /> : !statsData ? <p>Something went wrong</p> :
                <StatisticsCharts statsData={statsData}/>
            }            
        </div>
    );
}


/**
 * Statistics charts section
 * @param statsData
 * @returns {JSX.Element}
 */
function StatisticsCharts({statsData}) {
    
    const platformChartData =
        [
            {name: 'iOS', value: Number(statsData.ios)},
            {name: 'Android', value: Number(statsData.android)},
            {name: 'Other', value: Number(statsData.otherPlatform)}
        ]

    const severityChartData =
        [
            {name: 'Trivial', value: Number(statsData.trivial)},
            {name: 'Normal', value: Number(statsData.normal)},
            {name: 'Critical', value: Number(statsData.critical)}
        ]

    const categoryChartData = statsData.categories.map(x => {
        // eslint-disable-next-line eqeqeq
        return {name: categories.find(c => c.key == x.category).value, value: Number(x.count)}
    })

    const createdByMonth = Object.keys(statsData.createdByMonth).map(month => {
        return {month: month, tickets: statsData.createdByMonth[month]}
    })

    const closedByMonth = Object.keys(statsData.closedByMonth).map(month => {
        return {month: month, tickets: statsData.closedByMonth[month]}
    })
    //console.log(JSON.stringify(createdByMonth))
    const COLORS = ['#5a069c', '#85E8FF', '#F99DC7', '#2E28A6', '#2c9408', '#6a0894'];
    const pieChartWidth = 240;
    const barChartWidth = 856;

    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <>
            <div className="row">
                <div className="col-md-12 col-lg-4">
                    <CardStats statsTitle="Total Tickets" value={statsData.total} icon="nc-icon nc-world-2"/>
                </div>
                <div className="col-md-12 col-lg-4">
                    <CardStats statsTitle="Active/Unread" value={`${statsData.unread + statsData.read}/${statsData.unread}`}
                               icon="nc-icon nc-bulb-63"/>
                </div>
                <div className="col-md-12 col-lg-4">
                    <CardStats statsTitle="Archived Tickets" value={statsData.archived} icon="nc-icon nc-box"/>
                </div>
            </div>
            <div className="row">
                <div className="col-md-4 col-sm-12">
                    <Card title="Platform">
                        <PieChart width={pieChartWidth} height={pieChartWidth}>
                            <Pie dataKey="value" isAnimationActive={false} data={platformChartData} cx={pieChartWidth * 0.5} cy={pieChartWidth * 0.4}
                                 outerRadius={pieChartWidth * 0.35} fill="#8884d8" label>
                                {
                                    platformChartData.map((entry, index) =>
                                        <Cell key={index} fill={COLORS[index % COLORS.length]}/>)
                                }
                            </Pie>
                            <Tooltip/>
                            <Legend/>
                        </PieChart>
                    </Card>
                </div>
                <div className="col-md-4 col-sm-12">
                    <Card title="Severity">
                        <PieChart width={pieChartWidth} height={pieChartWidth}>
                            {COLORS.reverse()}
                            <Pie dataKey="value" isAnimationActive={false} data={severityChartData} cx={pieChartWidth * 0.5} cy={pieChartWidth * 0.4}
                                 outerRadius={pieChartWidth * 0.35} fill="#8884d8" label>
                                {
                                    platformChartData.map((entry, index) =>
                                        <Cell key={index} fill={COLORS[index % COLORS.length]}/>)
                                }
                            </Pie>
                            <Tooltip/>
                            <Legend/>
                        </PieChart>
                    </Card>
                </div>
                <div className="col-md-4 col-sm-12">
                    <Card title="Category">
                        <PieChart width={pieChartWidth} height={pieChartWidth}>
                            {COLORS.reverse()}
                            <Pie dataKey="value" isAnimationActive={false} data={categoryChartData} cx={pieChartWidth * 0.5} cy={pieChartWidth * 0.4}
                                 outerRadius={pieChartWidth * 0.35} fill="#8884d8" label>
                                {
                                    categoryChartData.map((entry, index) =>
                                        <Cell key={index} fill={COLORS[index % COLORS.length]}/>)
                                }
                            </Pie>
                            <Tooltip/>
                        </PieChart>
                    </Card>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <Card title="Open by Month">
                        <BarChart width={barChartWidth} height={300} data={createdByMonth}
                                  margin={{top: 5, right: 30, left: 20, bottom: 5}}>
                            {COLORS.reverse()}
                            <CartesianGrid strokeDasharray="3 3"/>
                            <XAxis dataKey="month"/>
                            <YAxis type="number" domain={[0, 20]}/>
                            <Tooltip/>
                            <Brush dataKey='month' height={30} stroke={COLORS[0]}/>
                            <Bar dataKey="tickets" fill={COLORS[0]}/>
                        </BarChart>
                    </Card>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <Card title="Closed by Month">
                        <BarChart width={barChartWidth} height={300} data={closedByMonth}
                                  margin={{top: 5, right: 30, left: 20, bottom: 5}}>
                            <CartesianGrid strokeDasharray="3 3"/>
                            <XAxis dataKey="month"/>
                            <YAxis type="number" domain={[0, 20]}/>
                            <Tooltip/>
                            <Brush dataKey='month' height={30} stroke={COLORS[3]}/>
                            <Bar dataKey="tickets" fill={COLORS[3]}/>
                        </BarChart>
                    </Card>
                </div>
            </div>
        </>
    );
}
