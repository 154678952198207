import React from 'react'
import { Field } from 'formik'
import { TextFieldInputColumn } from '../shared/FormElements'
import { UserIdFieldInputColumn } from '../Users/UserIdFieldInputColumn'
import { DateTimeInput } from '../shared/MKDateTime'
import { mkPaginationObject } from '../shared/MKPagination'

export const leaderboardEntriesSearchCriteriaObject = {...{
    leaderboardEntryId: '',
    leaderboardId: '',
    soloEventId: '',
    userId : '',
    updatedFrom : '',
    updatedTo : '',
    scoreFrom : '',
    scoreTo : '',
    scoreBaseFrom : '',
    scoreBaseTo : ''
}, ...mkPaginationObject, ...{pageSize: 25}};

export default function LeaderboardEntriesSearchCriteria({leaderboard})
{
    return (
        <>
            <div className="row">
                <Field name="leaderBoardEntryId" component={TextFieldInputColumn} type="number" labelText="Entry Id" toolTip="LeaderboardEntryId to filter" column={4}/>
                { leaderboard ?
                    <div className="col-md-3">
                        <div className="form-group">
                            <label title={'Id=' + leaderboard.id}>Leaderboard</label>
                            <input value={leaderboard.name} className="form-control" readOnly={true} title={'Id=' + leaderboard.id} />
                        </div>
                    </div>                    
                    :
                    <Field name="leaderboardId" component={TextFieldInputColumn} type="number" labelText="Leaderboard Id" column={4}/>
                }
                <Field name="soloEventId" component={TextFieldInputColumn} type="number" labelText="Event Id" toolTip="SoloEvent Id to filter" column={4}/>
            </div>
            <div className="row">
                <Field name="userId" component={UserIdFieldInputColumn} labelText="User Id" column={6}/>
                <Field name="updatedFrom" component={DateTimeInput} useTime={true} labelText="Updated From" column={3} />
                <Field name="updatedTo" component={DateTimeInput} useTime={true} labelText="Updated To" column={3} />
            </div>
            <div className="row">
                <Field name="scoreFrom" component={TextFieldInputColumn} type="number" labelText="Score From" toolTip="Minimum Score to filter" column={3}/>
                <Field name="scoreTo" component={TextFieldInputColumn} type="number" labelText="Score To" toolTip="Maximum Score to filter" column={3}/>
                <Field name="scoreBaseFrom" component={TextFieldInputColumn} type="number" labelText="ScoreBase From" toolTip="Minimum ScoreBase to filter (for exponential notation scores)" column={3}/>
                <Field name="scoreBaseTo" component={TextFieldInputColumn} type="number" labelText="ScoreBase To" toolTip="Maximum ScoreBase to filter (for exponential notation scores)" column={3}/>
            </div>
        </>
    );
}