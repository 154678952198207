import React from 'react'
import { Formik, Field, Form } from 'formik'
import { TextFieldInputColumn, ErrorDisplay, BoolToggleInput } from '../shared/FormElements'
import { UserIdFieldInputColumn } from '../Users/UserIdFieldInputColumn'
import { shortYearDateTime, getDateTimeToolTip } from '../shared/MKDateTime'
import { SubmitButton } from '../shared/Buttons'
import { Card } from '../shared/Card'
import { Loading } from '../shared/Loading'


export default function TeamDataEdit({ team, saveTeam, validate, loading, forceTouched = false, creating = false })
{
    const validateInternal = (values, props /* only available when using withFormik */ ) =>
    {
        let errors = validate ? validate(values, props) : {};
        if (!values.name)
        {
            errors.name = 'A non empty name is required';
        }
        return errors;
    };

    if (loading)
    {
        return <Loading />
    }
    
    if (!team)
    {
        return null
    }

    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <>
            <br/>
            <Formik
                    enableReinitialize
                    validate={validateInternal}
                    initialValues={team}
                    onSubmit={saveTeam}
                >
                {({ errors, status, touched, isSubmitting }) => (
                    <Form>
                        <Card title="Team Details">
                            { creating &&
                            <div className="row">
                                <Field name="leaderUserId" component={UserIdFieldInputColumn} searchCriteriaObject={{ isInATeam : false }} searchTitle="Select Leader User" labelText="Leader Id" column={6} autofocus={true} errors={touched.leaderUserId && errors.leaderUserId} /> 
                                <Field name="coLeaderUserId" component={UserIdFieldInputColumn} searchCriteriaObject={{ isInATeam : false }} searchTitle="Select Co-leader User" labelText="Co-leader Id" column={6} />
                            </div>
                            }
                            <div className="row">
                                <Field name="id" component={TextFieldInputColumn} labelText="Team Id" readOnly={true} column={2} /> 
                                <Field name="name" component={TextFieldInputColumn} labelText="Name" errors={touched.name && errors.name} column={4} />
                                <Field name="badge" component={TextFieldInputColumn} labelText="Badge" column={4} />
                                <Field name="localeCode" component={TextFieldInputColumn} maxLength={3} labelText="Locale Code" column={2} />
                            </div>
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Created Date</label>
                                        <input className="form-control" value={creating ? '' : shortYearDateTime(team.created)} title={getDateTimeToolTip(team.created)} readOnly={true} />
                                    </div>
                                </div>
                                <Field name="open" component={BoolToggleInput} labelText="Type" trueText="Open" falseText="Closed" column={3} />
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label>Status</label>
                                        <input className="form-control" value={(team.dead ? 'Dead' : 'Alive')} title={getDateTimeToolTip(team.created)} readOnly={true} />
                                    </div>
                                </div>
                                <Field name="minLevel" component={TextFieldInputColumn} labelText="Minimum Level" type="number" min={0} 
                                    tooltip="Minimum level required to join the team" column={2} />
                                <Field name="maxMembers" component={TextFieldInputColumn} labelText="Max Members" type="number" min={0} 
                                    tooltip="Maximum number of members allowed" column={2} />
                            </div>
                            
                            <div className="row">   
                                <div className="md-12 col text-center">
                                    <SubmitButton errors={errors} isSubmitting={isSubmitting} touched={touched} forceTouched={forceTouched} />
                                </div>
                            </div>
                        </Card>
                            
                        <ErrorDisplay errors={errors} touched={touched} />

                    </Form>
                )} 
            </Formik>

        </>
    )
}