import React from 'react'

export default function UserIdColumnReadOnly({ user })
{
    return (
        <div className="col-md-6">
            <div className="form-group">
                <label>User Id
                    { user.cheater && <span className="ps-2 cheater">(Flagged as a Cheater)</span> }</label>
                <input
                    className={'form-control' + (user.deleted ? ' text-decoration-line-through' : '')}
                    value={user.userId}
                    title={user.deleted ? 'User is DELETED' : ''} readOnly={true}/>
            </div>
        </div>
    );
}