import React, {useState, useEffect, useContext} from 'react'
import sideMenuItems from '../../assets/sideMenu.json'
import Toggle from 'react-toggle'
import { AuthorizedPut } from '../AuthorizedRequest'
import { useHistory } from 'react-router-dom'
import { Loading } from '../shared/Loading'
import { SubmitButton } from '../shared/Buttons'
import ActiveModulesContext from "../../contexts/ActiveModulesContext";


export default function ProjectModules() {
    
    const { activeModules, loadActiveModules, activeModulesLoading } = useContext(ActiveModulesContext);
    
    const [workingActiveModules, setWorkingActiveModules] = useState([]);
    const [touched, setTouched] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const history = useHistory();
    
    useEffect(() => {
        if (!activeModulesLoading)
            setWorkingActiveModules(activeModules);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeModulesLoading]);

    const toggleModule = route => () =>
    {
        if (workingActiveModules !== null)
        {
            const copy = [...workingActiveModules];
            
            let index = copy.indexOf(route);
            if (index >= 0)
                copy.splice(index, 1);
            else
                copy.push(route);

            setWorkingActiveModules(copy);
            setTouched(true);
        }
    };

    const saveActiveModules = e =>
    {
        e.preventDefault();
        setIsSubmitting(true);
        AuthorizedPut(workingActiveModules, 'api/KeyVault/UpdateModules', history)
            .then(result =>
            {
                if (result) {
                    setTouched(false);
                    loadActiveModules().catch(console.error);
                }
                setIsSubmitting(false);
            });
    };
    
    if (activeModulesLoading)
        return <Loading loading={true} />

    return (
        <form onSubmit={saveActiveModules}>
            <table className='table table-sm table-striped table-hover'>
                <thead>
                    <tr>
                        <th />
                        <th>Module Name</th>
                    </tr>
                </thead>
                <tbody>
                    {sideMenuItems.map((menuItem, index) =>
                    <tr key={index}>
                        <td>
                            <div className="form-group">
                                <input type="checkbox" checked={menuItem.coreModule || workingActiveModules.includes(menuItem.route)} className="form-control" hidden readOnly/>
                                <Toggle checked={menuItem.coreModule || workingActiveModules.includes(menuItem.route)} 
                                    icons={false} onChange={toggleModule(menuItem.route)} disabled={menuItem.coreModule} />
                            </div>
                        </td>
                        <td>{menuItem.displayName}</td>
                    </tr>)}
                </tbody>
            </table>
            <SubmitButton forceTouched={touched} isSubmitting={isSubmitting} />
            {/*<p>Refresh browser window to see changes in the side menu</p>*/}
        </form>
        );
}