import React, { useState, useEffect } from 'react'
import { AuthorizedGet } from '../AuthorizedRequest'
import { useHistory } from 'react-router-dom'
import {Card} from '../shared/Card'
import AuditLogList from './AuditLogList'
import AuditLogSearch from './AuditLogSearch'
import { auditLogSearchCriteriaObject } from './AuditLogSearchCriteria'
import MKPaginationUncounted, { mkPagedResultUncounted } from '../shared/MKPaginationUncounted';
import { composeQueryString, mapQueryStringValuesToObject, useSessionState, useQueryStringAndSessionState, useCache } from '../shared/Utils'
import { CollapseSearchButton } from '../shared/Buttons'
import {Collapse} from 'reactstrap'
import AuditLogDetails from "./AuditLogDetails";

export default function AuditLog(props)
{
    const initialEmptyValues = Object.assign({}, auditLogSearchCriteriaObject);

    let history = useHistory();
    const [loading, setLoading] = useState(true);
    const [initialValues, setInitialValues] = useQueryStringAndSessionState(() => Object.assign({}, initialEmptyValues), history, history.location.search, 'auditLogInitialValues');
    const [pagedResult, setPagedResult] = useSessionState(mkPagedResultUncounted, 'auditLogs');
    const [searchCollapsed, setSearchCollapsed] = useCache('auditLogSearchCollapsed', true);
    const [logDetails, setLogDetails] = useState({ isOpen: false, id: null });
    const [auditLogEnabled, setAuditLogEnabled] = useState(true);
    
    useEffect(() => {
        if (mapQueryStringValuesToObject(initialEmptyValues, history.location.search))   // There is allowed search parameters in QueryString
        {
            if (JSON.stringify(initialEmptyValues) !== JSON.stringify(initialValues))  // Those parameters means a different search tan the one saved in sessionStorage
                searchAuditLogs(initialEmptyValues);
            else
                setLoading(false);
        } else {
            if (pagedResult.itemsTotalCount == null)
                searchAuditLogs(initialValues);
            else
                setLoading(false);
        }
        AuthorizedGet('api/AuditLog/Enabled', history)
            .then(result => setAuditLogEnabled(result?.enabled ?? true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    
    const searchAuditLogs = (searchValues, formikBag = { setSubmitting: x => {} }, pageNumber = 1) => {
        searchValues.pageNumber = pageNumber;
        searchValues.eventType = searchValues.eventType === '-1' ? null : searchValues.eventType;
        searchValues.eventType = searchValues.entityType === '-1' ? null : searchValues.eventType;
        setLoading(true);
        setInitialValues(Object.assign({}, searchValues));
        AuthorizedGet('api/AuditLog/Search?' + composeQueryString(searchValues), history)
            .then(result => 
            {
                setPagedResult(result ?? mkPagedResultUncounted);
                formikBag.setSubmitting(false);
                setLoading(false);
            });
    };

    const onPageClick = (e, pageNumber) => searchAuditLogs(initialValues, undefined, pageNumber);
    
    const logDetailsToggle = () => setLogDetails({ ...logDetails, isOpen: !logDetails.isOpen });
    const openLogDetails = id => setLogDetails({ id, isOpen: true });
        
    const toggleSearch = e =>  {
        e.preventDefault();
        setSearchCollapsed(!searchCollapsed);
    };
    
    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////   
    return (
        <div>
            { auditLogEnabled ||
                <p className="alert alert-warning text-black" title="For enabling Audit Log the corresponding setting should be toggled (an application restart is required)">
                    Audit Log is <b>disabled</b>. Only logs from when it was enabled will be shown.
                </p>
            }
            
            <AuditLogDetails id={logDetails.id} isOpen={logDetails.isOpen} toggleModal={logDetailsToggle} />
            
            <div className="row align-items-center">
                <div className="md-8 col">
                    <h3>&nbsp;Audit Logs</h3>
                </div>
                <div className="md-4 col text-end">
                    <CollapseSearchButton collapsed={searchCollapsed} onClick={toggleSearch}/>
                </div>
            </div>

            <Collapse isOpen={!searchCollapsed}>
                <Card>
                    <AuditLogSearch handleSearch={searchAuditLogs} initialValues={initialValues}/>
                </Card>
            </Collapse>

            <Card>
                <AuditLogList onClick={openLogDetails} auditLogs={pagedResult.items} loading={loading}/>
                <MKPaginationUncounted currentPage={pagedResult.pageNumber} currentPageCount={pagedResult.currentPageCount} onClick={onPageClick} itemsName="Audit Logs"/>
            </Card>
        </div>
    );
}
