import React, { useRef, useState } from 'react'
import { Formik, Field, Form } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import JSONKeyValueEditor from "../shared/JSONKeyValueEditor";
import { Card } from '../shared/Card'
import { TextFieldInputColumn, ToggleInput, ErrorDisplay, TextAreaInput } from '../shared/FormElements'
import { DateTimeInput, shortYearDateTime, getDateTimeToolTip } from '../shared/MKDateTime'
import { SubmitButton } from '../shared/Buttons'
import { CronUI } from './elements/CronUI'
import { isValidCron } from 'cron-validator'
import cronstrue from 'cronstrue'
import { checkControlCharactersAndLeadingAndTrailingWhitespaces } from "../shared/Utils";
import useSoloEventDurationType from "./SoloEventDurationType";

export default function SoloEventDetails({soloEvent, saveMethod, showCreated = true, editPauseUntil = true, title="Solo Event Details"})
{
    const [event, setEvent] = useState(soloEvent);
    const [warnings, setWarnings] = useState(null);
    const [forceTouched, setForceTouched] = useState(false);
    let jsonFieldRef = useRef(null);
    let cronstrueOptions = { throwExceptionOnParseError: true };
    const durationType = useSoloEventDurationType();
    
    const saveInternal = (values, actions) => 
    {
        if (jsonFieldRef.current)
        {
            values.json = JSON.stringify(jsonFieldRef.current);
        }
        saveMethod(values, actions);
        setForceTouched(false);
    };

    const validate = (values, props) => 
    {
        let errors = {}
        let warnings = {}
        
        if (!values.name) {
            errors.name = "Name required."
        }
        else if (checkControlCharactersAndLeadingAndTrailingWhitespaces(values.name)) {
            warnings.name = "Placing control characters or leading/trailing whitespaces on the Name field could lead to unexpected behaviours"
        }
        if (!values.json && !jsonFieldRef.current) {
            errors.json = "Json required."
        }
        if (Number.isInteger(parseInt(values.duration)) === false || values.duration <= 0) {
            errors.duration = "Duration must me a positive integer."
        }

        if (!isValidCron(values.schedule)) {
            errors.schedule = "Cron string is not valid.";
        }

        setWarnings(warnings);
        return errors
    }

    const updateSchedule = (e) =>
    {
        let readable;
        if (isValidCron(e.target.value)) {
            readable = cronstrue.toString(e.target.value, cronstrueOptions);
        } else {
            readable = "Invalid Schedule - check the CRON syntax";
        }
        
        event.schedule = e.target.value;
        event.scheduleDescription = readable;
        setEvent({...event});
    }

    const togglePause = setFieldTouched => e =>
    {
        e.stopPropagation();
        e.preventDefault();
        
        const getPauseDate = () => {
            let testDate = new Date();
            testDate.setDate(testDate.getDate() + 7);
            return testDate.toISOString();
        };

        setFieldTouched('pausedUntil');
        event.pausedUntil = event.pausedUntil ? null : getPauseDate();
        setEvent({...event});
    }

    if (!event)
    { return null }
    
    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <Formik
            enableReinitialize
            validate={validate}
            initialValues={event}
            onSubmit={saveInternal}>
            {({handleSubmit, isSubmitting, errors, touched, setFieldTouched}) =>
                <Form onSubmit={handleSubmit}>
                    <Card title={title}>
                        <div className="row">
                            <Field name="id" component={TextFieldInputColumn} labelText="Event Id" readOnly={true}
                                   column={2}/>
                            <Field name="name" component={TextFieldInputColumn} labelText="Name*" readOnly={false} maxLength={100}
                                   onChange={e => {
                                       event.name = e.target.value;
                                       setEvent({...event});
                                   }} errors={errors.name} column={7}/>
                            {showCreated &&
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Created Date</label>
                                        <input className="form-control" value={event.created ? shortYearDateTime(event.created) : ''}
                                               title={getDateTimeToolTip(event.created)} readOnly={true}/>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="row">
                            <Field name="useLocalTime" component={ToggleInput} checked={event.useLocalTime} labelText="Use Local Time?"
                                   onToggle={() => {
                                       setFieldTouched('useLocalTime');
                                       event.useLocalTime = !event.useLocalTime;
                                       setEvent({...event});
                                   }}
                                   onLabel="Local time" offLabel="Server time"  column={2}/>
                            <Field name="duration" component={TextFieldInputColumn} labelText={"Duration*" + (durationType ? ` (${durationType})` : "")}
                                   onChange={e => {
                                       event.duration = e.target.value;
                                       setEvent({...event});
                                   }} errors={errors.duration} column={3}/>
                            <div className="col-md-1">
                            </div>
                            {editPauseUntil && 
                                <>
                                    {event.pausedUntil ?
                                        <Field name="pausedUntil" component={DateTimeInput} labelText="Pause Until"
                                               onChange={day => {
                                                   event.pausedUntil = day;
                                                   setEvent({...event});
                                               }}
                                               errors={errors.pausedUntil} column={3} />
                                        :
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Pause Until</label>
                                                <input className="form-control" value="[ Running ]" readOnly={true}/>
                                            </div>
                                        </div>
                                    }
                                    <div className="col-md-3">
                                        {event.pausedUntil ?
                                            <button className="btn btn-small btn-warning mt-3 w-100" onClick={togglePause(setFieldTouched)}>
                                                <FontAwesomeIcon icon="play"/>{` Unpause`}
                                            </button>
                                            :
                                            <button className="btn btn-warning mt-3 w-100" onClick={togglePause(setFieldTouched)}>
                                                <FontAwesomeIcon icon="pause"/>{` Pause`}
                                            </button>
                                        }
                                    </div>
                                </>                            
                            }
                        </div>
                        <div className="row">
                            <Field name="description" component={TextAreaInput} labelText="Description" readOnly={false} maxLength={255}
                                   onChange={e => {
                                       event.description = e.target.value;
                                       setEvent({...event});
                                   }} errors={errors.description} column={12}/>
                        </div>
                        <ErrorDisplay errors={errors} touched={touched} warnings={warnings}/>
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <SubmitButton errors={errors} isSubmitting={isSubmitting} touched={touched} forceTouched={forceTouched} />
                            </div>
                        </div>
                    </Card>

                    <span/>
                    <Card title="Simple (One-Off) Schedule">
                        <p>Use this to set a simple one-off start date and time for the event. If you're not using a start date for your game's event scheduling, you can leave this blank.</p>
                        <Field name="startDate" component={DateTimeInput} useTime={true} useUTC={true} labelText="Start Date and Time (UTC)"
                            onDayChange={day => {
                               event.startDate = day;
                               setEvent({...event});
                            }}
                            value={event.startDate}
                            errors={errors.startDate}>
                        </Field>
                        <br />
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <SubmitButton errors={errors} isSubmitting={isSubmitting} touched={touched} forceTouched={forceTouched} />
                            </div>
                        </div>
                    </Card>

                    <span/>
                    <Card title="CRON (Repeating) Schedule">
                        <p>You must provide a valid CRON string here even if your game is not using it - if you use the simple schedule, you can leave this at its default value.</p>
                        <Field name="schedule" component={CronUI} error={errors.schedule}
                               description={event.scheduleDescription}
                               onChange={updateSchedule}/>
                        <br/>
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <SubmitButton errors={errors} isSubmitting={isSubmitting} touched={touched} forceTouched={forceTouched} />
                            </div>
                        </div>
                    </Card>

                    <span/>
                    <Card title="Json">
                        <JSONKeyValueEditor
                            jsonString={event.json} editAsJson={true}
                            onChange={json => { jsonFieldRef.current = json; setForceTouched(true); }} />
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <SubmitButton errors={errors} isSubmitting={isSubmitting} touched={touched} forceTouched={forceTouched} />
                            </div>
                        </div>
                    </Card>
                </Form>
            }
        </Formik>
    );
}