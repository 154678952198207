import React from 'react'

export default function ExponentNotation({ children, base = null, exponent = null })
{
    if (base > 0)
    {
        if (exponent > 0)
        {
            return (
                <>
                    {base}×10<sup>{exponent}</sup>
                </>
            );           
        }
        else 
        {
            return base;
        }
    }        
    
    return children ?? null;
}