import React from 'react';
import {Field, FieldArray} from "formik";
import {AddButtonText, IconButtonAlt} from "../shared/Buttons";
import {azurePortTypes, defaultPortConfig} from "./PlayFabConstants";
import {Card} from "../shared/Card";
import {SelectField, TextFieldInputColumn} from "../shared/FormElements";

export default function PlayFabPortConfigurationSection({values, handleChange, errors}) {
    return(
        <FieldArray name="ports"
            render={arrayHelpers => (
                <>
                    <br />
                    <div className="row align-items-center">
                        <div className="col-md-8">
                            <h5>Port Configuration</h5>
                        </div>
                        <div className="col-md-4 text-end">
                            <AddButtonText onClick={() => arrayHelpers.push({
                                ...defaultPortConfig
                            })} />
                        </div>
                    </div>
                    {
                        values.ports.map((port, index) => (
                            <Card key={index}>
                                <div className="row align-items-center">
                                    <div className={`${(values.ports.length > 1 ? 'col-md-10' : 'col-md-12')}`}>
                                        <Field name={`ports.${index}.num`} component={TextFieldInputColumn} type="number" labelText="Port Number" />
                                        <Field value={values.ports[index].protocol} name={`ports.${index}.protocol`} component={SelectField} labelText="Port Protocol"
                                               onChange={(e) => {
                                                   // Patch the port name when the protocol changes then tell the form to handle the changes
                                                   values.ports[index].name = `${e.target.value.toLowerCase()}_port`;
                                                   handleChange(e);
                                               }}
                                        >
                                            {
                                                Object.keys(azurePortTypes).map((portType, index) =>
                                                    <option key={index} value={azurePortTypes[portType]}>{azurePortTypes[portType]}</option>
                                                )
                                            }
                                        </Field>
                                        <Field name={`ports.${index}.name`} 
                                               errors={errors[index]?.name}
                                               component={TextFieldInputColumn} labelText="Port Name" />
                                    </div>
                                    {
                                        values.ports.length > 1 &&
                                        <div className="col-md-2 text-center">
                                            <IconButtonAlt icon="trash" toolTip="Delete Port Configuration" onClick={() => arrayHelpers.remove(index)}>
                                                DELETE
                                            </IconButtonAlt>
                                        </div>
                                    }
                                </div>
                            </Card>
                        ))
                    }
                </>
            )}
        />
    );
}