import React from 'react'
import { useHistory } from "react-router-dom"
import { Loading } from '../shared/Loading'
import {AddButton, DeleteButton} from '../shared/Buttons'
import { AuditLogButtonConditional } from "../AuditLog/AuditLogButton";

export default function SoloEventTemplatesList({ soloEvents, loading, onDelete, onCreate }) {
    
    const history = useHistory();
    
    const onRowClick = itemId => e =>
    {
        e.stopPropagation();
        const path = `/SoloEvents/Templates/${encodeURIComponent(itemId)}`;
        history.push(path);
    }

    if (loading)
    {
        return <Loading loading={true} />
    }

    return (
        <table className='table table-full-width table-striped table-hover'>
            <thead>
                <tr>
                    <th>Id</th>
                    <th>Name</th>
                    <th title="Schedule times are shown in the UTC time zone">Schedule [UTC]</th>
                    <th>Duration</th>
                    <th></th>
                    <th></th>
                    { onDelete !== undefined &&
                    <th/>
                    }
                </tr>
            </thead>
            <tbody>
                {soloEvents?.map((event, index) =>
                <tr className="tr-link" onClick={onRowClick(event.id)} key={index}>
                    <td>{event.id}</td>
                    <td>{event.name}</td>
                    <td>{event.scheduleDescription}</td>
                    <td>{event.duration}</td>
                    <td>
                        <AuditLogButtonConditional searchObject={{entityId: event.id, entityType: 'SoloEventTemplate'}} />
                    </td>
                    <td>
                        <AddButton onClick={onCreate(event.id)} toolTip="Create Event based on this template"></AddButton>
                    </td>
                    { onDelete !== undefined &&
                    <td className="text-end">
                        <DeleteButton onDelete={onDelete(event.id)} entityName="Event"/>
                    </td>
                    }
                </tr>
                )}
            </tbody>
        </table>
        );
}