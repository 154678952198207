import React from 'react'
import { useHistory } from 'react-router-dom'
import { composeQueryString } from '../../shared/Utils'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function ReceiptsButton({ searchObject })
{    
    let history = useHistory();
    
    const onClick = e =>
    {
        e.stopPropagation();
        const path = '/IAP/Receipts/?' + composeQueryString(searchObject);
        history.push(path);
    };

    return (
        <ReceiptBaseButton title="Receipts" onClick={ onClick } />
    );
}

export function ReceiptButton({ hash })
{    
    let history = useHistory();
    
    const onClick = e =>
    {
        e.stopPropagation();
        const path = `/IAP/Receipts/${encodeURIComponent(hash)}`;
        history.push(path);
    };

    return (
        <ReceiptBaseButton title={ "View Receipt" } onClick={ onClick } />
    );
}

function ReceiptBaseButton({ title, onClick })
{
    return (
        <button title={title} className="icon-button">
            <FontAwesomeIcon className="dollar-sign" size="2x" icon="file-invoice-dollar" onClick={ onClick }/>
        </button>
    );
}