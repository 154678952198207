import React from 'react'
import { useHistory } from 'react-router-dom'
import {AuthorizedPost } from '../AuthorizedRequest'
import CurrencyForm from "./CurrencyForm";

export default function CreateCurrency()
{
    const history = useHistory()
    const currency = {
        name: 'A new currency'
    };
    
    const saveEntity = (currency) =>
        AuthorizedPost(currency, 'api/Currency', history)
            .then(result =>
            {
                history.push(`/Currencies/${result.id}`);
            });

    
    return (
        <div>
            <CurrencyForm
                currency={currency}
                onSubmit={saveEntity}/>
        </div>
    )
}