import React, {useState} from 'react'
import {useHistory} from "react-router-dom"
import SizeBytes from '../shared/SizeBytes'
import { ShortYearDateTime } from '../shared/MKDateTime'
import { PlatformIconText } from '../shared/FormElementsDropdowns'
import {DeleteButton} from '../shared/Buttons'
import AssetBundleIsActive from "./AssetBundleIsActive"
import { AuditLogButtonConditional } from "../AuditLog/AuditLogButton"
import {descriptionToolTip} from "./AssetBundleUploadNew"

export default function AssetBundlesListRow({ assetBundle, onDelete, deleteMode, selectedForDelete, toggleSelectedForDelete, onIsActiveChanged = _ => {} }) {

    const [isActive, setIsActive] = useState(assetBundle.isActive);
    const history = useHistory();

    function onRowClick() 
    {
        if (deleteMode) {
            toggleSelectedForDelete(assetBundle);
        } else {
            const path = `/AssetBundles/${encodeURIComponent(assetBundle.appVersion)}/${encodeURIComponent(assetBundle.bundleVersionInt)}/${encodeURIComponent(assetBundle.platform)}`;
            history.push(path);
        }
    }

    return (        
        <tr className={`tr-link ${selectedForDelete ? "table-secondary" : ""}`} onClick={onRowClick}>
            <td><PlatformIconText>{assetBundle.bundleVersion}</PlatformIconText></td>
            <td><PlatformIconText>{assetBundle.platform}</PlatformIconText></td>
            <td title={descriptionToolTip}>{assetBundle.description}</td>
            <td><ShortYearDateTime>{assetBundle.buildTime}</ShortYearDateTime></td>
            <td title="Number of Bundles included">{assetBundle.bundlesCount}</td>
            <td><SizeBytes>{assetBundle.byteLength}</SizeBytes></td>
            <td onClick={e => e.stopPropagation()}>
                <AssetBundleIsActive assetBundle={assetBundle} disabled={deleteMode} onUpdate={result => {
                    onIsActiveChanged(result);
                    setIsActive(result.isActive);
                }} />
            </td>
            <td>
                <AuditLogButtonConditional searchObject={{entityId: `${assetBundle.platform};${assetBundle.appVersion};${assetBundle.bundleVersionInt}`, entityType: 'BundleManifest'}} />
            </td>
            <td className="text-end">
                {
                    deleteMode ?
                        (
                            isActive ? <></> :
                                <input type={"checkbox"}
                                       className={"d-inline-block form-check-input me-1"}
                                       checked={selectedForDelete}
                                        readOnly={true}/>
                        )
                        :
                        <DeleteButton onDelete={onDelete(assetBundle)}
                                      entityName="Asset Bundle"
                                      disabled={isActive}
                                      disabledMessage={"Can't delete while active"}/>        
                }
            </td>
        </tr>
    );
}