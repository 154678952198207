import React from 'react'
import {Card, CardContrast} from "../../../shared/Card";
import {Loading} from "../../../shared/Loading";
import {ShortDateTime} from "../../../shared/MKDateTime";
import TranslateButton from "../../../shared/TranslateButton";

export default function SupportTicketMessages({messages, isSending, ticketLanguage})
{
    const isMessageBold = (message) => message.agentId && !message.viewedAt;
    
    if (!messages || messages.length === 0)
        return null;
    
    return (
        <div>
            <Card>
                <div className="row">
                    <h5>History</h5>
                    {isSending && <Loading margin={10} size={20}/>}
                </div>
                {messages.map((message, index) =>
                    <CardContrast key={index} isRight={message.agentId} isLeft={!message.agentId}>
                        <div className="row">
                            <small className="col-md-8">
                                <u>Sent</u>: <ShortDateTime>{message.createdAt}</ShortDateTime> {` by ${typeof (message.agentId) === "undefined" ? "User" : message.agentName}`}
                                {message.agentId &&
                                <>
                                    <br/>
                                    <u>Read</u>: {message.viewedAt ? 
                                                <ShortDateTime>{message.viewedAt}</ShortDateTime> : 
                                                <>[ <span className="font-italic" title="Not read">N/A</span> ]</>}
                                </>
                                }
                            </small>
                            <small className="col-md-2 text-end">
                                {`Id: ${message.id}`}
                            </small>
                            { message.language === 'English' || ticketLanguage === 'English' ||
                            <div className={"col-md-2 text-end"}>
                                <TranslateButton text={message.content}/>
                            </div>                            
                            }
                        </div>
                        <hr className="mt-1" />
                        <div className="support-message">
                            {isMessageBold(message) ? <b>{message.content}</b> : message.content}
                        </div>
                        <div className="text-end">
                            { message.supportContentCode &&
                            <ContentCodeDisplay>{message.supportContentCode}</ContentCodeDisplay>
                            }
                            { message.supportCodes && message.supportCodes.length > 0 &&
                            <ContentCodeDisplay>{message.supportCodes[0].contentCode}</ContentCodeDisplay>
                            }
                        </div>
                    </CardContrast>)
                }
            </Card>
        </div>
    );
}

function ContentCodeDisplay( { children: contentCode } ) {
    return (
        <span>            
            <small onClick={() => alert(contentCode.json)}>
                Content Code Id:&nbsp;
                <pre style={{display: 'inline', cursor: 'help'}} title={contentCode.json}>{contentCode.id}</pre>
                {contentCode.uses === 0 ? " redeemed" : " not redeemed"}
            </small>
        </span>
    );
}