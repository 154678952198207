import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom'
import { Container } from 'reactstrap'
import PerfectScrollbar from 'perfect-scrollbar'
import Sidebar from './Sidebar.js'
import sideMenuItems from '../../assets/sideMenu.json'
import Header from './Header'
import { useToasts } from 'react-toast-notifications'
import { setUseToastHook } from '../AuthorizedRequest'

var perfectScrollbar;


export default function Layout(props) {
  setUseToastHook(useToasts()); //To allow raise notifications from inside AuthorizedRequest.js

  const [backgroundColor, /* setBackgroundColor */] = useState("black")
  const [activeColor, /* setActiveColor */] = useState("info")
  const mainPanel = useRef(null)
  const history = useHistory()

  history.listen((location, action) => {
    if(mainPanel && mainPanel.current) {
      mainPanel.current.scrollTop = 0
    }
  })

  useEffect(() => {
    if(!mainPanel.current) {
      return;
    }
    
    if (navigator.platform.indexOf("Win") > -1) {
      perfectScrollbar = new PerfectScrollbar(mainPanel.current)
      document.body.classList.toggle("perfect-scrollbar-on")
    }

    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        perfectScrollbar.destroy()
        document.body.classList.toggle("perfect-scrollbar-on")
      }
    }
  }, [])

  return (
      <div className="wrapper">
        <Sidebar
            {...props}
            sideMenuItems={sideMenuItems}
            bgColor={backgroundColor}
            activeColor={activeColor}
        />
        <div className="main-panel" ref={mainPanel}>
          <Header/>
          <Container className="content">
            {props.children}
          </Container>
        </div>
      </div>
  )
}
