import React, { useState, useEffect } from 'react'
import { AuthorizedGet } from '../AuthorizedRequest'
import { useHistory } from 'react-router-dom'
import { Card } from '../shared/Card'
import TotalCount from '../shared/TotalCount'
import { mkPagedResult } from '../shared/MKPagination'
import UserInventoryItemList from "../Inventory/UserInventoryItemList";


export default function UserItems({ userId })
{
    const [loading, setLoading] = useState(true)
    const [itemsResult, setItemsResult] = useState(mkPagedResult)
    const history = useHistory()

    // Fetch items
    useEffect(() =>
    {
        AuthorizedGet('api/InventoryItem/PurchasedItems?pageSize=500&userId=' + userId, history)
            .then(result => 
            {
                setItemsResult({
                    ...result ?? mkPagedResult,
                    items: result.items.map(item => item)
                });
                setLoading(false);
            });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ userId ])

    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <Card title="Items">
            <UserInventoryItemList items={itemsResult.items} loading={loading}/>
            <div className="row">
                <div className="md-12 col d-flex align-items-center justify-content-end">
                    <TotalCount itemsName="Items for the User">{loading ? null : itemsResult.itemsTotalCount}</TotalCount>
                </div>
            </div>
        </Card>
    )
}