import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { AuthorizedGet, AuthorizedDelete } from '../AuthorizedRequest'
import { Card } from '../shared/Card'
import ConfigurationsList from './ConfigurationsList'
import { AddButtonText } from '../shared/Buttons'
import { MKPaginationWithTotal, mkPagedResult } from "../shared/MKPagination"
import ConfigurationDuplicate from './ConfigurationDuplicate'


export default function Configurations({ byKey = false })
{    
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [pagedResult, setPagedResult] = useState(mkPagedResult);
    const [configToDuplicate, setConfigToDuplicate] = useState(null);    
    
    useEffect(() =>
    {
        if (pagedResult.itemsTotalCount == null)
            loadConfigurations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    const loadConfigurations = (pageNumber = 1) => {
        setLoading(true);
        AuthorizedGet('api/Configuration/Search?pageNumber=' + pageNumber, history)
            .then(result => 
            {
                setPagedResult(result ?? mkPagedResult);
                setLoading(false);
            });
    };

    const onPageClick = (e, pageNumber) => loadConfigurations(pageNumber);
    
    const onCreateClick = () => {
        history.push(`/configurations${byKey ? 'ByKey' : ''}/create`);
    };
    
    const onDelete = (packedAppVersion, configKey) => () => {
        AuthorizedDelete(null, `api/Configuration/${packedAppVersion}/${configKey}`, history)
            .then(result => {
                pagedResult.items = pagedResult.items.filter(s => 
                    s.packedAppVersion !== result.packedAppVersion ||
                    s.configKey !== result.configKey);
                pagedResult.itemsTotalCount--;
                setPagedResult(Object.assign({}, pagedResult));
            });
    };

    const onDuplicated = cDuplicated => {        
        pagedResult.items.push(cDuplicated);
        pagedResult.itemsTotalCount++;
        setPagedResult(Object.assign({}, pagedResult));
        setConfigToDuplicate(null);
    };

    const onDuplicatingCancel = () => {        
        setConfigToDuplicate(null);
    };

    const onDuplicateClick = cToDuplicate => e => {
        e.stopPropagation();
        setConfigToDuplicate(cToDuplicate);
    };

    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <div>
            { configToDuplicate &&
                <ConfigurationDuplicate configurationToDuplicate={configToDuplicate} onDuplicated={onDuplicated}
                                        onCancel={onDuplicatingCancel} byKey={byKey}/> 
            }
            <div className="row align-items-center">
                <div className="md-8 col">
                    <h3>Configurations</h3>
                </div>
                <div className="md-4 col text-end">
                    <AddButtonText onClick={onCreateClick} toolTip="Add New Configuration">Add New</AddButtonText>
                </div>
            </div>

            <Card>
                <ConfigurationsList configurations={pagedResult.items} loading={loading} onDelete={onDelete}
                                    onDuplicateClick={onDuplicateClick} byKey={byKey}/>
                <MKPaginationWithTotal total={pagedResult.pagesTotalCount} currentPage={pagedResult.pageNumber}
                                       totalItems={pagedResult.itemsTotalCount} onClick={onPageClick}
                                       itemsName="Configurations"/>
            </Card>
        </div>
    );
}
