import React from 'react'

export const TabControl = (props) =>
{
    return (
        <ul className="nav nav-pills nav-pills-primary nav-pills-icons justify-content-center" role="tablist">
        {props.items.map((item, index) => 
            <li className="nav-item" key={index}>
                <a className={`nav-link ${item.isActive ? ' active' : ''}`} style={{'cursor': 'pointer'}}  data-toggle="tab" href={item.route} role="tablist" onClick={() => props.onChange(item.name, item.key, index)} >
                    <i className="now-ui-icons objects_umbrella-13"/> {item.name}
                </a>
            </li>
        )}
        </ul>
    )
}