import React from 'react'
import { useHistory } from "react-router-dom"
import { Loading } from '../shared/Loading'
import { DeleteButton } from '../shared/Buttons'

export default function CurrencyList({ items, loading, onDelete }) {

    const history = useHistory();

    function onRowClick(itemId)
    {
        const path = `/Currencies/${encodeURIComponent(itemId)}`;
        history.push(path);
    }
    
    if (loading)
    {
        return <Loading loading={true} />
    }
    
    return (
        <table className='table table-full-width table-striped table-hover'>
            <thead>
                <tr>
                    <th>Id</th>
                    <th>Name</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {items.map((item, index) =>
                <tr className="tr-link" onClick={() => onRowClick(item.id)} key={index}>
                    <td>{item.id}</td>
                    <td>{item.name}</td>
                    <td className="text-end">
                        <DeleteButton onDelete={ onDelete(item.id) } entityName="Currency" />
                    </td>
                </tr>
                )}
            </tbody>
        </table>
        );
}