import React from 'react'
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFlag} from "@fortawesome/free-solid-svg-icons";

export default function UserIdValue({ userId, deleted = false, cheater = false, children }) {

    let inner = userId ? <Link to={'/Users/' + userId}>{children}</Link> : children;

    return (
        <>
            {deleted ?
                <span className="text-decoration-line-through"
                      title="User is DELETED">{inner}</span>
                :
                inner
            }
            {cheater &&
                <FontAwesomeIcon className="ps-2 fs-5 cheater align-bottom" icon={faFlag}
                                 title="This user is flagged as a cheater"/>
            }
        </>
    );
}