import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { AuthorizedGet } from '../AuthorizedRequest'
import {MKPaginationWithTotal, mkPagedResult, setPagedResultPage} from '../shared/MKPagination'
import LeaderboardEntriesList from "./LeaderboardEntriesList";
import {Card} from "../shared/Card";
import {getDateTimeToolTip, shortYearDateTime} from "../shared/MKDateTime";


export default function LeaderboardEntryBucket({ leaderboardEntryId })
{
    const [bucketEntry, setBucketEntry] = useState(null);
    const [bucketEntriesPage, setBucketEntriesPage] = useState(mkPagedResult);
    const [loading, setLoading] = useState(true);
    const history = useHistory();

    // Fetch
    useEffect(() =>
    {
        if (leaderboardEntryId)
        {
            setLoading(true);
            AuthorizedGet(`api/Leaderboard/EntryBucket/${leaderboardEntryId}`, history)
                .then(result =>
                {
                    if (result)
                    {
                        setBucketEntry(result);
                        onPageClick(null, 1, result.bucketEntries);
                    }
                    setLoading(false);
                });            
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [leaderboardEntryId]);

    const onPageClick = (e, pageNumber, items = bucketEntry.bucketEntries) => {
        setPagedResultPage(bucketEntriesPage, items, pageNumber);
        setBucketEntriesPage({...bucketEntriesPage});
    };
    
    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <>
            <Card>
                <div className="row">
                    <div className="col-md-3">
                        <div className="form-group">
                            <label title="Entry's position in its Bucket">Position in Bucket</label>
                            <input value={bucketEntry ? (bucketEntry.entryPosition ?? 0) + 'º' : '[...]'}
                                   className="form-control" readOnly={true} title="Entry's position in its Bucket"/>
                        </div>
                    </div>
                    <div className="col-md-3">
                        { bucketEntry?.bucketOriginal &&
                            <div className="form-group" title="Original bucket to which the cheating entry belongs">
                                <label>Original Bucket</label>
                                <input value={bucketEntry.bucketOriginal.bucketName}
                                       className="form-control" readOnly={true}/>
                            </div>                            
                        }
                    </div>
                    <div className="col-md-3">
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <label title="Last date the score was updated">Last updated</label>
                            <input
                                value={bucketEntry?.entry?.updatedAt ? shortYearDateTime(bucketEntry.entry.updatedAt) : '[...]'}
                                className="form-control" readOnly={true}
                                title={bucketEntry?.entry?.updatedAt ? getDateTimeToolTip(bucketEntry.entry.updatedAt) : '[...]'}/>
                        </div>
                    </div>
                </div>
                
                <LeaderboardEntriesList entries={[bucketEntry?.entry]} loading={loading} showBucketRow={false} />    
            </Card>
            <span/>
            <Card headerComponent={<h5>{`Bucket: ${bucketEntry?.bucket?.bucketName ?? '[...]'}`}</h5>}>
                <div className="row">
                    <div className="col-md-2">
                        <div className="form-group">
                            <label>Leaderboard Id</label>
                            <input value={bucketEntry?.leaderboard?.id ?? '[...]'} className="form-control" readOnly={true} />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <label>Leaderboard Name</label>
                            <input value={bucketEntry?.leaderboard?.name ?? '[...]'} className="form-control" readOnly={true} />
                        </div>
                    </div>
                    { bucketEntry?.event &&
                        <>
                            <div className="col-md-2">
                                <div className="form-group">
                                    <label>Event Id</label>
                                    <input value={bucketEntry.event.id} className="form-control" readOnly={true} />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Event Name</label>
                                    <input value={bucketEntry.event.name} className="form-control" readOnly={true} />
                                </div>
                            </div>
                        </>
                    }
                </div>
                <br/>
                <LeaderboardEntriesList entries={bucketEntriesPage.items} loading={loading} showBucketRow={false}
                                        getPosition={index => (bucketEntriesPage.pageNumber - 1) * bucketEntriesPage.pageSize + index + 1}/>
                <MKPaginationWithTotal currentPage={bucketEntriesPage.pageNumber} total={bucketEntriesPage.pagesTotalCount}
                                       totalItems={bucketEntriesPage.itemsTotalCount} onClick={onPageClick}
                                       itemsName={'Results for the ' + (bucketEntry?.bucket?.bucketName ?? '[...]')}/>
            </Card>
        </>
    );
}