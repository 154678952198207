import React, { useState, Suspense, lazy } from 'react'
import { jsonSecureParse } from './Utils'
import Button from "react-bootstrap/Button"
import { ButtonToolbar } from "reactstrap"
import { CardContrast } from './Card'
import {Loading} from "./Loading";
const Editor = lazy(() => import('./JSONEditorLazy'))


/**
 * 
 * @export
 * @param {Object} props - Component properties
 * @param {Object} props.value - Initial JSON object to be edited
 * @param {string} props.valueString - Serialized initial JSON object to be edited
 * @param {JSONEditorOnChangeCallback} props.onChange - Callback function called after JSON is modified (only when it is valid). Parameter: The modified JSON object 
 */
export function JSONEditor({ value, valueString, onChange, maxLines, ...props })
{
    return (
        <Suspense fallback={<Loading/>}>
            <Editor
                value={value ?? jsonSecureParse(valueString)}
                onChange={onChange}
                maxLines={maxLines}
                {...props}
            >
            </Editor>            
        </Suspense>
    );
}

export function JSONEditorReadOnly({ value, valueString, maxLines, ...props })
{
    return (
        <Suspense fallback={<Loading/>}>
            <Editor
                readOnly={true}
                value={value ?? jsonSecureParse(valueString)}
                maxLines={maxLines}
                {...props}
            >
            </Editor>            
        </Suspense>
    );
}

export function JSONEditorAndText({ value, valueString, onChange, maxLines, editJson = false })
{    
    const [editAsJson, setEditAsJson] = useState(editJson);

    return (
        <> 
            { editAsJson ?
            <>
                { onChange ?
                <JSONEditor value={value} valueString={valueString} onChange={onChange} maxLines={maxLines} />
                :
                <JSONEditorReadOnly value={value} valueString={valueString} maxLines={maxLines} />
                }
                <Button size="sm" variant="info" onClick={e => setEditAsJson(false)}>View as Text</Button>
            </> 
            :
            <>
                <CardContrast>
                    <small><p style={{ fontFamily: 'monospace' }}>{valueString ?? JSON.stringify(value, null, 2)}</p></small>
                </CardContrast>
                <ButtonToolbar>
                    <ButtonToolbar>
                        <Button size="sm" variant="info" onClick={e => setEditAsJson(true)}>{onChange ? 'Edit' : 'View as JSON'}</Button>
                    </ButtonToolbar>
                </ButtonToolbar>
            </>
            }
        </>
    );
}