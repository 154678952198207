import React from 'react'
import { useHistory } from "react-router-dom"
import { DeleteButton } from '../../shared/Buttons'
import { FlexiDateTime } from '../../shared/MKDateTime'

export default function ContentCodesList({ contentCodes, onDelete }) {
    
    const history = useHistory();
    
    function onRowClick(contentCode)
    {
        const path = contentCode.supportMessageId ? `SupportContentCodes/${encodeURIComponent(contentCode.id)}` : `ContentCodes/${encodeURIComponent(contentCode.id)}`
        history.push(path, {redeemable: contentCode});
    }

    return (
        <>
            <table className='table table-full-width table-striped table-hover'>
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>User Id</th>
                        <th>Uses</th>
                        <th>Created At</th>
                        <th>Updated At</th>
                        <th>Is Active</th>
                        <th/>
                    </tr>
                </thead>
                <tbody>
                    {contentCodes.map((contentCode, index) =>
                    <tr className="tr-link" onClick={() => onRowClick(contentCode)} key={index}>
                        <td>{contentCode.id}</td>
                        <td>{contentCode.userId}</td>
                        <td>{contentCode.uses}</td>
                        <td><FlexiDateTime>{contentCode.createdAt}</FlexiDateTime></td>
                        <td><FlexiDateTime>{contentCode.updatedAt}</FlexiDateTime></td>
                        <td>{"" + contentCode.isActive}</td>
                        <td className="text-end">
                            <DeleteButton onDelete={onDelete(contentCode.id)} entityName="Content Code"/>
                        </td>
                    </tr>
                    )}
                </tbody>
            </table>
        </>
        );
}