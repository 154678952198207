import React from 'react'
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import LeaderboardEntryBucket from "./LeaderboardEntryBucket";


export default function LeaderboardEntryBucketPopUp({ leaderboardEntryId, clearLeaderboardEntryId })
{    
    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <Modal isOpen={leaderboardEntryId != null} toggle={clearLeaderboardEntryId} contentClassName="card" size="xl">
            <ModalHeader toggle={clearLeaderboardEntryId}>
                {'Bucket data for the score entry ' + leaderboardEntryId}
            </ModalHeader>
            <ModalBody>
                <LeaderboardEntryBucket leaderboardEntryId={leaderboardEntryId}/>
            </ModalBody>
        </Modal>
    );
}