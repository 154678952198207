import { HashLoader } from "react-spinners";
import { css } from "@emotion/core"
import React from "react"

export const Loading = ({ loading = true, margin = 20, size = 30, color = "#F99DC7", ...props }) =>
{
    const override = css`
    margin: ${margin}px;
    ${props.noBlock ? "" : "display: block;"}`

    return <HashLoader css={override} size={size} color={color} loading={loading}/>
}
