import React, {useState, useEffect, useRef} from 'react'
import {Prompt, useHistory, useParams} from 'react-router-dom'
import { Formik, Field, Form } from 'formik'
import { AuthorizedGet, AuthorizedPut} from '../AuthorizedRequest'
import { TextFieldInputColumn, ErrorDisplay } from '../shared/FormElements'
import { shortYearDateTime, getDateTimeToolTip } from '../shared/MKDateTime'
import { SubmitButton } from '../shared/Buttons'
import { Card } from '../shared/Card'
import { Loading } from '../shared/Loading'
import TransactionsButton from '../IAP/elements/TransactionsButton'
import ReceiptsButton from '../IAP/elements/ReceiptsButton'
import SupportTicketsButton from '../Support/SupportTicketsButton'
import UserTransactions from './UserTransactions'
import UserPrefs from './UserPrefs/UserPrefs'
import { InputGroup, InputGroupAddon } from "reactstrap"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import UserBalances from "./UserBalances";
import UserSoftCurrencyLog from "./UserSoftCurrencyLog";
import UserItems from "./UserItems";
import {ModuleConditional} from "../shared/ModuleConditional";
import LeaderboardEntriesButton from "../Leaderboards/LeaderboardEntriesButton";
import UserDelete from "./UserDelete";
import UserCheaterButton from "./UserCheaterButton";
import UserIdentityViewer from "./UserIdentityViewer";
import UserIdColumnReadOnly from "./UserIdColumnReadOnly";


export default function UserDetails()
{
    const [user, setUser] = useState(null);
    const [userPrefs, setUserPrefs] = useState(null);
    const [loading, setLoading] = useState(true);
    const [forceTouched, setForceTouched] = useState(false);
    const [userPrefsSaving, setUserPrefsSaving] = useState(false);
    const metadataKeysRef = useRef([]);
    const userPrefsSaveMethodRef = useRef();
    const savePending = useRef(false);
    const history = useHistory();
    let {userId} = useParams();

    // Fetch users
    useEffect(() =>
    {
        AuthorizedGet(`api/User/${userId}`, history)
            .then(result => 
            {
                if (result)
                {
                    LoadUser(result.user);
                    metadataKeysRef.current = result.metadataKeys;                    
                }
                setLoading(false);
            });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    useEffect(() => {
        if (savePending.current) {
            window.onbeforeunload = () => true;
        } else {
            window.onbeforeunload = undefined;
        }
    });

    const saveUser = (values, actions) => {
        AuthorizedPut(values, 'api/User', history)
            .then(result => {
                actions.setSubmitting(false)
                if (result) {
                    actions.setTouched({})
                }
            });
        userPrefsSaveMethodRef.current();
    };
    
    const LoadUser = user => {
        setUserPrefs(user.userPrefs);
        user.userPrefs = null;   //To avoid sending them back to server 
        setUser(user);
    }
    
    const UserCheaterValUpdated = isCheater => {
        setUser({...user, cheater: isCheater})
    }

    const validate = (values, props) =>
    {
      let errors = {}
      return errors
    }

    if (loading)
    {
        return <Loading />
    }
    
    if (!user)
    {
        return null
    }

    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <div>
            <br/>
            <Formik
                    enableReinitialize
                    validate={validate}
                    initialValues={user}
                    onSubmit={saveUser}
                >
                {({ errors, status, touched, isSubmitting }) => {
                    savePending.current = forceTouched || Object.entries(touched).length > 0;
                    return (
                        <Form>
                            <Prompt when={savePending.current} message={"There are unsaved changes. \nIf you leave the page changes will be lost. \nConfirm?"}/>

                            <Card headerComponent={<UserDetailsHeader user={user}
                                                                      onCheaterValueUpdated={UserCheaterValUpdated}
                                                                      onUserDeleted={LoadUser}/>}>
                                <div className="row">
                                    <UserIdColumnReadOnly user={user}/>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Created Date</label>
                                            <input className="form-control" value={shortYearDateTime(user.created)}
                                                   title={getDateTimeToolTip(user.created)} readOnly={true}/>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Last Login</label>
                                            <input className="form-control"
                                                   value={user.lastLogin && shortYearDateTime(user.lastLogin)}
                                                   title={getDateTimeToolTip(user.lastLogin)} readOnly={true}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    {user.accountReference &&
                                    <Field name={"accountReference.accountId"} component={TextFieldInputColumn}
                                           labelText="MK Account reference" readOnly={true} column={4}/>}
                                    {user.installations && user.installations.map((installation, index) =>
                                        <Field key={index} name={"installations[" + index + "].installationId"}
                                               component={TextFieldInputColumn} labelText="Installation Id" readOnly={true} column={3}/>
                                    )}
                                </div>
                                <div className="row">
                                    <UserNameEdit isSubmitting={isSubmitting} />
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Locale</label>
                                            <input className="form-control" value={user.locale?.localeCode} readOnly={true}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 text-center">
                                        <SubmitButton errors={errors} isSubmitting={isSubmitting || userPrefsSaving}
                                                      touched={touched} forceTouched={forceTouched} />
                                    </div>
                                </div>
                            </Card>
                            <span/>
                            
                            <ErrorDisplay errors={errors}/>

                            <UserIdentityViewer userId={userId} />
                            <span/>

                            <UserPrefs userId={user.userId} userPrefs={userPrefs}
                                       setSaveMethod={saveMethod => userPrefsSaveMethodRef.current = saveMethod}
                                       metadataKeys={metadataKeysRef.current} saving={isSubmitting || userPrefsSaving}
                                       setForceTouched={setForceTouched} setUserPrefsSaving={setUserPrefsSaving}
                                       submitButton={
                                           <SubmitButton errors={errors} isSubmitting={isSubmitting || userPrefsSaving}
                                                         touched={touched} forceTouched={forceTouched} />}
                            />

                        </Form>
                    );
                }} 
            </Formik>

            <ModuleConditional modules={["Currencies"]}>
                <span/>
                <UserBalances userId={userId}/>
            </ModuleConditional>

            <ModuleConditional modules={["Inventory"]}>
                <span/>
                <UserItems userId={userId}/>
            </ModuleConditional>

            <ModuleConditional modules={["Currencies"]}>
                <span/>
                <UserSoftCurrencyLog userId={userId}/>
            </ModuleConditional>

            <ModuleConditional modules={["IAP"]}>
                <span/>
                <UserTransactions userId={userId}/>
            </ModuleConditional>
            
        </div>
    )
}

function UserDetailsHeader({user, onCheaterValueUpdated, onUserDeleted})
{
    return (
        <div className="row">
            <div className="col-md-8">
                <h3>User Details</h3>
            </div>
            <div className="col-md-4 text-end">

                <UserCheaterButton user={user} onCheaterValueUpdated={onCheaterValueUpdated} />

                <ModuleConditional modules={["Leaderboards"]}>
                    <LeaderboardEntriesButton searchObject={{userId: user.userId}}/>
                </ModuleConditional>

                <ModuleConditional modules={["IAP"]}>
                    <TransactionsButton searchObject={{userId: user.userId}}/>&nbsp;
                    <ReceiptsButton searchObject={{userId: user.userId}}/>&nbsp;
                </ModuleConditional>

                <ModuleConditional modules={["Support"]}>
                    <SupportTicketsButton searchObject={{userId: user.userId}}/>
                </ModuleConditional>

                <UserDelete user={user} onUserDeleted={onUserDeleted}></UserDelete>
            </div>
        </div>        
    );
}

function UserNameEdit({isSubmitting}) {
    const [readOnly, setReadOnly] = useState(true)
    const ref = useRef();

    useEffect(() => {
        if (!readOnly)
            ref.current.focus();
    }, [readOnly] );

    const onClick = e => {
        e.preventDefault();
        setReadOnly(!readOnly);
    };
    
    return (
        <div className="col-md-9">
            <div className="form-group">
                <label title="User's name">Name</label>
                <InputGroup>
                    <Field name="name" className="form-control" readOnly={readOnly || isSubmitting} title="User's name" innerRef={ref}/>
                    <InputGroupAddon addonType="append">
                        <button className="btn btn-primary inline-button mt-0 pt-0 ps-3 pe-3" title="Edit user's name"
                                onClick={onClick} disabled={isSubmitting} style={{height: '30px'}}>
                            <FontAwesomeIcon icon="edit"/>
                        </button>
                    </InputGroupAddon>
                </InputGroup>
            </div>
        </div>
    );
}
