import React, { useState, useEffect } from 'react'
import { AuthorizedGet, AuthorizedDelete } from '../AuthorizedRequest'
import { useHistory } from 'react-router-dom'
import { Card } from '../shared/Card'
import ReplaysList from './ReplaysList';
import ReplaysSearch from './ReplaysSearch'
import { replaysSearchCriteriaObject } from './ReplaysSearchCriteria'
import { composeQueryString, mapQueryStringValuesToObject, useSessionState, useQueryStringAndSessionState, useCache } from '../shared/Utils'
import { CollapseSearchButton } from '../shared/Buttons'
import { Collapse } from 'reactstrap';
import MKPaginationUncounted, {mkPagedResultUncounted} from "../shared/MKPaginationUncounted";

export default function Replays()
{
    const initialEmptyValues = Object.assign({}, replaysSearchCriteriaObject);
    
    let history = useHistory();
    const [loading, setLoading] = useState(true);
    const [initialValues, setInitialValues] = useQueryStringAndSessionState(() => Object.assign({}, initialEmptyValues), history, history.location.search, "replaysInitialValues");
    const [pagedResult, setPagedResult] = useSessionState(mkPagedResultUncounted, 'replays');
    const [searchCollapsed, setSearchCollapsed] = useCache('replaysSearchCollapsed', false);
    
    useEffect(() => {
        setLoading(false)
        if (mapQueryStringValuesToObject(initialEmptyValues, history.location.search))   // There is allowed search parameters in QueryString
        {
            if (JSON.stringify(initialEmptyValues) !== JSON.stringify(initialValues))  // Those parameters means a different search tan the one saved in sessionStorage
            {
                searchReplays(initialEmptyValues);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const searchReplays = (searchValues, formikBag = { setSubmitting: x => {} }, pageNumber = 1) => {
        searchValues.pageNumber = pageNumber;
        setLoading(true);
        setInitialValues(Object.assign({}, searchValues));
        AuthorizedGet('api/Replay/Search?' + composeQueryString(searchValues), history)
            .then(result => 
            {
                setPagedResult(result ?? mkPagedResultUncounted);
                formikBag.setSubmitting(false);
                setLoading(false);
            });
    };

    const onPageClick = (e, pageNumber) => searchReplays(initialValues, undefined, pageNumber);
        
    const toggleSearch = e =>  {
        e.preventDefault();
        setSearchCollapsed(!searchCollapsed);
    };

    const onDelete = replay => () =>
    {
        AuthorizedDelete(replay, `api/Replay/${replay.userId}/${replay.name}/${replay.version}`, history)
            .then(result => 
            {
                pagedResult.items = pagedResult.items.filter(function (s) { return s.userId !== result.userId || s.name !== result.name || s.version !== result.version });
                pagedResult.itemsTotalCount--;
                setPagedResult(Object.assign({}, pagedResult));
            });
    };


    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const paginationComponent = !loading && <MKPaginationUncounted currentPageCount={pagedResult.currentPageCount} currentPage={pagedResult.pageNumber} onClick={onPageClick} itemsName="Replays"/>;

    return (
        <div>
            <div className="row align-items-center">
                <div className="md-8 col">
                    <h3>&nbsp;Replays</h3>
                </div>
                <div className="md-4 col text-end">
                    <CollapseSearchButton collapsed={searchCollapsed} onClick={toggleSearch}/>
                </div>
            </div>

            <Collapse isOpen={!searchCollapsed}>
                <Card>
                    <ReplaysSearch handleSearch={searchReplays} initialValues={initialValues}/>
                </Card>
            </Collapse>

            <Card>
                {paginationComponent}
                <ReplaysList replays={pagedResult.items} loading={loading} onDelete={onDelete}/>
                {paginationComponent}
            </Card>
        </div>
    );
}
