import React from "react";
import {Card} from "../shared/Card";
import PlayFabEditRegionConfigurationModal from "./PlayFabEditRegionConfigurationModal";

export default function PlayFabServerRegionCard({ regions, buildId, onClick, onRegionsEdited }) {
    
    return (
        <Card headerComponent={
            <div className="row align-items-center">
                <div className="col-md-8">
                    <h3>&nbsp;Regions</h3>
                </div>
                <div className="col-md-4 text-end">
                    <PlayFabEditRegionConfigurationModal buildId={buildId} onRegionsEdited={onRegionsEdited} />
                </div>
            </div>
        }>
            <table className='table table-full-width table-striped table-hover'>
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Status</th>
                    <th>Active</th>
                    <th>Standby</th>
                </tr>
                </thead>
                <tbody>
                { regions?.map((region, index) =>
                    <tr className="tr-link" key={index} onClick={onClick(region.name)}>
                        <td>{region.name}</td>
                        <td>{region.status}</td>
                        <td>{region.activeInstances}</td>
                        <td>{region.standbyInstances}</td>
                    </tr>
                )}
                </tbody>
            </table>
        </Card>
    )    
}