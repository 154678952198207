import React, {useState, useRef} from 'react'
import {useHistory, useLocation, Redirect} from 'react-router-dom'
import {AuthorizedPut} from '../../../AuthorizedRequest'
import {Card} from '../../../shared/Card'
import {Formik, Field, Form} from 'formik'
import {TextFieldInputColumn, ErrorDisplay} from '../../../shared/FormElements'
import {SubmitButton} from '../../../shared/Buttons'
import {LabeledDetail} from '../../../shared/LabeledDetail'
import MessageTemplateContent from './MessageTemplateContent'

export default function MessageTemplate({ saveMethod = AuthorizedPut, saveEndPoint = 'api/Support/UpdateMessageTemplate', editTemplate = null, ...props }) {
    const location = useLocation()
    const history = useHistory()
    const [forceTouched, setForceTouched] = useState(false)
    const contentJson = useRef(null)

    const [template, /* setTemplate */] = useState( editTemplate ?? location.state?.messageTemplate ?? null)

    const validate = (values, props) => {
        let errors = {}
        if (!values.name) {
            errors.name = "Name required"
        }

        if ((contentJson.current === null && !values.content) ||
            (contentJson.current !== null && Object.keys(contentJson.current).filter(k => contentJson.current[k]).length === 0)) {
            errors.content = "Content required"
        }
        return errors
    }

    const saveTemplate = (values, actions) => {
        if (contentJson.current !== null) {
            values['content'] = JSON.stringify(contentJson.current)
        }

        saveMethod(values, saveEndPoint, history)
            .then(result => {
                actions.setSubmitting(false)
                if (result) {
                    actions.setTouched({})
                    setForceTouched(false)
                    history.replace({pathname: location.pathname, state: {messageTemplate: result}})
                }
            })
    }


    if (template == null) {
        return <Redirect to="/Support/Templates"/>
    }

    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <>
            <Card title="Message Template">
                <Formik
                    enableReinitialize
                    validate={validate}
                    initialValues={template}
                    onSubmit={saveTemplate}>
                    {({handleSubmit, isSubmitting, errors, touched, setFieldTouched}) =>
                        <Form onSubmit={handleSubmit}>
                            <LabeledDetail label="Template Id" value={template.id ?? <>( <i>creating</i> )</>}/>
                            <Field name="name" component={TextFieldInputColumn} labelText="Name" column={9}
                                   errors={errors.name} autofocus={true}/>
                            <br/>
                            <h5>Contents</h5>
                            <MessageTemplateContent content={template.content} onChange={json => {
                                contentJson.current = json;
                                setFieldTouched('content');
                            }}/>

                            <ErrorDisplay errors={errors} touched={touched}/>
                            <SubmitButton errors={errors} isSubmitting={isSubmitting} touched={touched}
                                          forceTouched={forceTouched}/>
                        </Form>
                    }
                </Formik>
            </Card>
        </>
    )
}