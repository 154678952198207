import React, {useEffect, useState} from 'react'
import {Card} from "../../shared/Card";
import DiffViewer from "../../shared/DiffViewer";
import {AuthorizedGet} from "../../AuthorizedRequest";
import {useHistory, useParams} from "react-router-dom";
import {downloadStringAsFile} from "../../shared/Utils";
import UserIdColumnReadOnly from "../UserIdColumnReadOnly";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import MKNetSettingsStringArrayPopUp from "../../MKNetSettings/MKNetSettingsStringArrayPopUp";
import {getDateTimeToolTipUTC} from "../../shared/MKDateTime"; 

export default function UserPrefsBackup()
{
    const actualValuesLabel = '[ Actual values ]';
    const actualValuesFileName = 'UserPrefsActual.json';

    let history = useHistory();
    let {userId} = useParams();
    
    const [backupFiles, setBackupFiles] = useState([]);
    const [userPrefsActual, setUserPrefsActual] = useState('"[ Loading... ]"');
    const [userPrefsLeft, setUserPrefsLeft] = useState("");
    const [userPrefsRight, setUserPrefsRight] = useState("{}");
    const [loadingLeft, setLoadingLeft] = useState(true);
    const [loadingRight, setLoadingRight] = useState(true);
    const [selectedLeft, setSelectedLeft] = useState(null);
    const [selectedRight, setSelectedRight] = useState('');
    const [userPrefLastSaveDate, setUserPrefLastSaveDate] = useState('');

    useEffect(() => {
        AuthorizedGet('api/User/UserPrefsBackupFiles/' + userId, history)
            .then(result => {
                if (result)
                    setBackupFiles(result.map(function(x) { return { value: x, text: x }; }));
                else 
                    setBackupFiles([ { value: '', text: '[ Error loading backup files ]' }]);
            })
            .finally(() => {
                setLoadingLeft(false);
                setLoadingRight(false);
            });
        
        AuthorizedGet('api/User/UserPrefsActualAsBackup/' + userId, history)
            .then(result => { 
                if (result)
                {
                    setUserPrefsActual(result);
                    setContentSafe(result, actualValuesFileName, setUserPrefsLeft);
                    setSelectedLeft(actualValuesFileName);
                    let userPrefLastSave = JSON.parse(result).find(x => x['key'] == 'userPrefLastSaveDate')?.value
                    setUserPrefLastSaveDate(userPrefLastSave);
                }
                else
                {
                    setUserPrefsActual('"Error loading actual UserPrefs"');
                }
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
        
    const setContentSafe = (content, fileName, setContent) =>
    {
        if (content.length > 1024 * 256)
        {
            setContent('"Content too long to be show, downloaded instead"')
            downloadStringAsFile(content, fileName);
        }
        else
        {
            setContent(content);
        }
    }

    const getBackupFileContent = (fileName, setContent, setLoading, setSelected) => {
        if (fileName === '')
        {
            setContent('{}');
        }
        else if (fileName === actualValuesFileName)
        {
            setContentSafe(userPrefsActual, actualValuesFileName, setContent);
        }
        else
        {
            setLoading(true);
            AuthorizedGet(`api/User/UserPrefsBackupFileContent/${userId}/${fileName}`, history)
                .then(result => {
                    setContentSafe(result, fileName, setContent);
                })
                .finally(() => setLoading(false));            
        }
        setSelected(fileName);
    }

    return (
        <>
            <div className="row">
                <div className="col-md-10">
                    <h3>UserPrefs backup</h3>
                </div>
                <div className="col-md-2">
                    <MKNetSettingsStringArrayPopUp title="Selected Keys for Backups" subtitle="Backup keys list" MKNetSettingsKey="UserPrefBackupKeys" valueNamePattern="" />
                </div>
            </div>
            
            <Card>
                <div className="row">
                    <UserIdColumnReadOnly user={{userId}}/>
                    <div className="col-md-4">
                        <div className="form-group">
                            <label title="Date in the UTC timezone">UserPrefs last saved</label>
                            <input className="form-control" value={userPrefLastSaveDate} readOnly={true} title={getDateTimeToolTipUTC(userPrefLastSaveDate)}/>
                        </div>
                    </div>
                </div>  
            </Card>
            <span/>

            <Card>
                <div className="row align-items-center">
                    <div className="col-11 col-md-5 col-lg-5">
                        <BackupFilesSelect
                            items={[ { value: actualValuesFileName, text: actualValuesLabel }, ...backupFiles ]}
                            selectedValue={selectedLeft}
                            loading={loadingLeft}
                            onChange={event => getBackupFileContent(event.target.value, setUserPrefsLeft, setLoadingLeft, setSelectedLeft)} />
                    </div>
                    <div className="col-1 col-md-1 col-lg-1">
                        <DownloadButton content={userPrefsLeft} fileName={selectedLeft}/>                        
                    </div>
                    <div className="col-11 col-md-5 col-lg-5">
                        <BackupFilesSelect
                            items={[ { value: '', text: '[ Select a backup file ]' }, ...backupFiles ]}
                            selectedValue={selectedRight}
                            loading={loadingRight}
                            onChange={event => getBackupFileContent(event.target.value, setUserPrefsRight, setLoadingRight, setSelectedRight)} />
                    </div>
                    <div className="col-1 col-md-1 col-lg-1">
                        <DownloadButton content={userPrefsRight} fileName={selectedRight}/>
                    </div>
                </div>
            </Card>
            <span/>
            
            <Card>
                <DiffViewer valueOne={userPrefsLeft} valueTwo={userPrefsRight} />
            </Card>
        </>
    );
}


function BackupFilesSelect({items, selectedValue, loading, onChange}) {
    if (loading) {
        return (
            <select className={"form-select"} disabled={true}>
                <option key="0">[ Loading... ]</option>
            </select>
        )
    }
    return (
        <select className={"form-select"} value={selectedValue} onChange={onChange}>
            { items.map(item => (
                <option key={item.value} value={item.value}>{item.text}</option>
            )) }
        </select>
    )
}

function DownloadButton({ content, fileName})
{
    return (
        <button type="button" className="btn btn-primary btn-icon btn-sm" title={`Download ${fileName} content`} 
                onClick={e => downloadStringAsFile(content, fileName)}>
            <FontAwesomeIcon icon="file-download"/>
        </button>
    );
}