import React, {useState, useEffect} from 'react'
import {AuthorizedGet, AuthorizedDelete} from '../AuthorizedRequest'
import {useHistory} from 'react-router-dom'
import {Card} from '../shared/Card'
import VersionsList from './VersionsList';
import VersionsSearch from './VersionsSearch'
import {versionsSearchCriteriaObject} from './VersionsSearchCriteria'
import {
    composeQueryString,
    mapQueryStringValuesToObject,
    useSessionState,
    useQueryStringAndSessionState,
    useCache
} from '../shared/Utils'
import {MKPaginationWithTotal, mkPagedResult} from '../shared/MKPagination'
import {CollapseSearchButton} from '../shared/Buttons'
import {Collapse} from 'reactstrap'
import VersionAddNew from './VersionAddNew'

export default function Versions() {
    const initialEmptyValues = Object.assign({}, versionsSearchCriteriaObject);

    let history = useHistory();
    const [loading, setLoading] = useState(true);
    const [initialValues, setInitialValues] = useQueryStringAndSessionState(() => Object.assign({}, initialEmptyValues), history, history.location.search, "versionsInitialValues");
    const [pagedResult, setPagedResult] = useSessionState(mkPagedResult, 'versions');
    const [searchCollapsed, setSearchCollapsed] = useCache('versionsSearchCollapsed', true);

    useEffect(() => {
        if (mapQueryStringValuesToObject(initialEmptyValues, history.location.search))   // There is allowed search parameters in QueryString  [&&]
        {
            if (JSON.stringify(initialEmptyValues) !== JSON.stringify(initialValues))  // Those parameters means a different search tan the one saved in sessionStorage
                searchVersions(initialEmptyValues);
            else
                setLoading(false);
        } else {
            if (pagedResult.itemsTotalCount == null)
                searchVersions(initialValues);
            else
                setLoading(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const searchVersions = (searchValues, formikBag = {
        setSubmitting: x => {
        }
    }, pageNumber = 1) => {
        searchValues.pageNumber = pageNumber;
        setLoading(true);
        setInitialValues(Object.assign({}, searchValues));
        AuthorizedGet('api/Version/Search?' + composeQueryString(searchValues), history)
            .then(result => {
                setPagedResult(result ?? mkPagedResult);
                formikBag.setSubmitting(false);
                setLoading(false);
            });
    };

    const onPageClick = (e, pageNumber) => searchVersions(initialValues, undefined, pageNumber);

    const toggleSearch = e => {
        e.preventDefault();
        setSearchCollapsed(!searchCollapsed);
    };

    const versionAdded = newVersion => {
        // Refresh the list of versions to ensure the last version in the list is correct
        // and avoid issues with Force Update checkbox not updating correctly
        searchVersions(initialValues);
    };

    const onDelete = versionName => () => {
        AuthorizedDelete(versionName, `api/Version/${encodeURIComponent(versionName)}`, history)
            .then(result => {
                // Refresh the list of versions to ensure the last version in the list is correct
                // and avoid issues with Force Update checkbox not updating correctly
                searchVersions(initialValues);
            });
    };

    // Updates the array stored in sessionStorage
    const onUpdate = (updatedVersion, index) => {
        pagedResult.items[index] = updatedVersion;
        setPagedResult(pagedResult);    //There is no need to re-render
    };

    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <div>
            <div className="row align-items-center">
                <div className="md-8 col">
                    <h3>&nbsp;Versions</h3>
                </div>
                <div className="md-4 col text-end">
                    <VersionAddNew onVersionAdded={versionAdded}/>
                    &nbsp;
                    <CollapseSearchButton collapsed={searchCollapsed} onClick={toggleSearch}/>
                </div>
            </div>

            <Collapse isOpen={!searchCollapsed}>
                <Card>
                    <VersionsSearch handleSearch={searchVersions} initialValues={initialValues}/>
                </Card>
            </Collapse>

            <Card>
                <VersionsList versions={pagedResult.items} loading={loading} onDelete={onDelete} onUpdate={onUpdate}
                              isFirstPage={pagedResult.pageNumber === 1}/>
                <MKPaginationWithTotal currentPage={pagedResult.pageNumber} total={pagedResult.pagesTotalCount}
                                       totalItems={pagedResult.itemsTotalCount} onClick={onPageClick}
                                       itemsName="Versions"/>
            </Card>
        </div>
    );
}
