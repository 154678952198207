import React from 'react'
import { Field } from 'formik'
import { TextFieldInputColumn } from '../shared/FormElements'
import { UserIdFieldInputColumn } from '../Users/UserIdFieldInputColumn'
import { DateTimeInput } from '../shared/MKDateTime'
import { mkPaginationObject } from '../shared/MKPagination'

export const replaysSearchCriteriaObject = Object.assign({
    name : '', 
    userId : '',
    version : '',
    updatedFrom : '',
    updatedTo : ''
}, mkPaginationObject);

export default function ReplaysSearchCriteria(props)
{
    return (
        <>
            <div className="row">
                <Field name="name" component={TextFieldInputColumn} labelText="Name" column={4}/>
                <Field name="userId" component={UserIdFieldInputColumn} labelText="User Id" column={4}/>
                <Field name="version" component={TextFieldInputColumn} labelText="version" column={4}/>
            </div>
            <div className="row">
                <Field name="updatedFrom" component={DateTimeInput} useTime={true} labelText="Last Updated From" column={6}/>
                <Field name="updatedTo" component={DateTimeInput} useTime={true} labelText="Last Updated To" column={6}/>
            </div>
        </>
    );
}