import React, {useState, useEffect, useRef} from 'react'
import {IconButton} from "../shared/Buttons";
import {Card} from "../shared/Card";
import DiffViewer from "../shared/DiffViewer";
import {jsonSecureParse, useCache} from "../shared/Utils";
import {AuthorizedGet} from "../AuthorizedRequest";
import {useHistory} from "react-router-dom";
import {Loading} from "../shared/Loading";

export default function AssetBundleDiff({}) {

    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [loadingManifests, setLoadingManifests] = useState(false);
    // let bundleOneManifest = useRef('');
    // let bundleTwoManifest = useRef('');
    const [bundleOneManifest, setBundleOneManifest] = useState("");
    const [bundleTwoManifest, setBundleTwoManifest] = useState("");
    const [allAssetBundlesList, setAllAssetBundlesList] = useCache("assetBundlesList");
    
    useEffect(() => {
        console.log(allAssetBundlesList);
        if (allAssetBundlesList === null || allAssetBundlesList.length === 0) {
            getAllAssetBundles();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    const getAllAssetBundles = () => {
        setLoading(true);
        resetValues();
        AuthorizedGet('api/AssetBundle/Search?pageSize=9999', history)
            .then(result => {
                setAllAssetBundlesList(result.items);
                setLoading(false);
            });
    }
    
    const onBundleOneChange = event => {
        getManifestForBundle(allAssetBundlesList[event.target.value], setBundleOneManifest);
    }

    const onBundleTwoChange = event => {
        getManifestForBundle(allAssetBundlesList[event.target.value], setBundleTwoManifest);
    }
    
    const getManifestForBundle = (bundle, complete) => {
        setLoadingManifests(true);
        AuthorizedGet(`api/AssetBundle/${bundle.appVersion}/${bundle.bundleVersionInt}/${bundle.platform}`, history)
            .then(result => {
                complete(formatJsonForDiff(result.json));
            })
            .finally(() => setLoadingManifests(false));
    } 
    
    /* Asset Bundle manifests don't have any line breaks when we receive them from the server, which prevents effective
     * text comparison. To counteract this, we have to parse the JSON and then re-stringify it with whitespace and line
     * breaks. This isn't ideal for performance but is negligible next to the cost of single line string comparison.
    */ 
    const formatJsonForDiff = jsonString => JSON.stringify(jsonSecureParse(jsonString), null, 2);
    
    const resetValues = () => {
        setBundleOneManifest('');
        setBundleTwoManifest('');
    }
    
    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    if (loading)
    {
        return <Loading loading={true} />
    }
    
    return (
        <div>
            
            <h3>Asset Bundles Diff Tool</h3>
            
            <p>Select two asset bundles below to compare their manifests. Please note that this tool can take a short time to render as the manifests have to be loaded and compared each time.</p>
            
            <div className="row align-items-center">
                
                <div className="col-12 col-md-6 col-lg-5">
                    <AssetBundleSelect 
                        items={allAssetBundlesList} 
                        onChange={onBundleOneChange} />
                </div>
                <div className="col-12 col-md-6 col-lg-5">
                    <AssetBundleSelect 
                        items={allAssetBundlesList} 
                        onChange={onBundleTwoChange} />
                </div>
                <div className="col-12 col-md-12 col-lg-2">
                    <IconButton icon={"sync"} toolTip={"Refresh the list of Asset Bundles"} onClick={getAllAssetBundles} />
                </div>
                
            </div>

            <Loading loading={loadingManifests} />
            
            <Card>
                <DiffViewer valueOne={bundleOneManifest} valueTwo={bundleTwoManifest} />
            </Card>
            
        </div>
    )    
}

export function AssetBundleSelect({items, onChange}) {
    if (items === null || items === undefined) {
        return (
            <select className={"form-select"}>
                <option>No asset bundles to display.</option>
            </select>
        )
    }
    return (
        <select className={"form-select"} onChange={onChange}>
            <option value={""}>-- Select an Asset Bundle --</option> 
            { items.map((assetBundle, index) => (
                <option key={index} value={index}>{ `${assetBundle.version} - ${assetBundle.bundleVersion} - ${assetBundle.platform}` }</option>
            )) }
        </select>
    )
}