import React from 'react'
import { useHistory } from "react-router-dom"
import { Loading } from '../../shared/Loading'
import TransactionsButton from './TransactionsButton'

export default function ReceiptsList(props) {
    
    const history = useHistory();

    const onRowClick = hash => e => {
        e.stopPropagation();
        const path = `/IAP/Receipts/${encodeURIComponent(hash)}`;
        history.push(path);
    }

    if (props.loading)
    {
        return <Loading loading={true} />
    }

    return (
        <table className='table table-full-width table-striped'>
            <thead>
                <tr>
                    <th>Hash</th>
                    <th>Validation Result</th>
                    <th>Redeemed</th>
                    <th/>
                </tr>
            </thead>
            <tbody>
                {props.receipts?.map((receipt, index) =>
                    <tr className="tr-link" onClick={ onRowClick(receipt.hash) } key={index}>
                        <td>{receipt.hash}</td>
                        <td>{receipt.validationResult}</td>
                        <td>{'' + receipt.redeemed}</td>
                        <td className="text-center">
                            <TransactionsButton searchObject={{ receiptId: receipt.hash }} />
                        </td>
                    </tr>
                )}
            </tbody>
        </table>
        );
}