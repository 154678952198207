import React, {useState, useEffect, useRef} from 'react'
import { AuthorizedGet } from '../../AuthorizedRequest'
import {useHistory, useLocation} from 'react-router-dom'
import { Card } from '../../shared/Card'
import { Loading } from '../../shared/Loading'
import {EmailRow} from "../elements/EmailRow";
import {useCache} from "../../shared/Utils";
import {of} from "rxjs";
import {delay, repeat} from "rxjs/operators";
import MKPagination from "../../shared/MKPagination";

const groupBy = (items, key) => items.reduce(
    (result, item) => ({
        ...result,
        [item[key]]: [
            ...(result[item[key]] || []),
            item,
        ],
    }),
    {},
);

let pageSize = 30

export default function Emails(props)
{
    const history = useHistory()
    const location = useLocation()
    const abortControllerRef = useRef(new AbortController())

    const [cache, setCache] = useCache(location.pathname)
    const [entities, setEntities] = useState(cache ? cache.entities : null)
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(cache ? cache.page : 1)
    const [total, setTotal] = useState( cache ? cache.total : 0)

    useEffect(() =>
    {
        let abortCtrl = abortControllerRef.current;
        return(() =>
        {
            abortCtrl.abort()
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    useEffect(() =>
    {
        fetchData()

        // schedule data refresh every 60 seconds
        const refreshSubscription = of(page).pipe(delay(60 *1000)).pipe(repeat()).subscribe((next) =>
        {
            fetchData()
        })
        let abortCtrl = abortControllerRef.current;
        return(() =>
        {
            refreshSubscription.unsubscribe()
            abortCtrl.abort()
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])


    function fetchData()
    {
        setLoading(true)
        AuthorizedGet(`api/Support/Emails?pageNumber=${page}&pageSize=${pageSize}`, history, abortControllerRef.current.signal)
            .then(result =>
            {
                if (result)
                {
                    const grouped = groupBy(result.messages, "conversationId")
                    setEntities(grouped)
                    setTotal(result.totalPages)
                    setCache({entities: grouped, page: result.pageNumber, total: result.totalPages})
                }
                if (!abortControllerRef.current.signal.aborted)
                {
                    setLoading(false)
                }
            })
    }

    const onPageClick = (e) =>
    {
        if (e.target.value)
        {
            setPage(Number(e.target.value))
        }
    }
    
    function onConversationDeleted(conversationId)
    {
        delete entities[conversationId]
        setEntities(Object.assign({}, entities))
    }

    function onRowClick(conversation)
    {
        const path = `/Support/Emails/${encodeURIComponent(conversation[0].conversationId)}`
        history.push(path, {emailConversation: conversation})
    }
    
    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    if (loading)
    {
        return <Loading />
    }

    const paginationComponent = total > 1 && <MKPagination currentPage={page} total={total} onClick={onPageClick}/>;

    return (
        <Card>
            <div className="card-content table-responsive table-full-width">
                <div className="row">
                    <h2>Outlook Inbox</h2>
                    {loading && <Loading margin={10} size={20}/>}
                </div>
                {paginationComponent}
                <table className="table">
                    <tbody>
                    {entities && Object.keys(entities).map((conversationId, index) =>
                        <EmailRow key={index} index={index}
                                  conversation={entities[conversationId]}
                                  onRowClick={() => onRowClick(entities[conversationId])}
                                  onConversationDeleted={onConversationDeleted}/>)
                    }
                    </tbody>
                </table>
                {paginationComponent}
            </div>
        </Card>
    )
}
