import React from 'react'
import { Field } from 'formik'
import { TextFieldInputColumn, NullableToggleInput } from '../../shared/FormElements'
import { mkPaginationObject } from '../../shared/MKPagination'

export const receiptsSearchCriteriaObject = Object.assign({
    hash : '',
    redeemed : '',
    validationResult : '',
    multipleTransactions : ''
}, mkPaginationObject);

export default function ReceiptsSearchCriteria(props)
{
    let hideHash = props.showHash === false;

    return (
        <>
            <div className="row">
                { hideHash ||
                    <Field name="hash" component={TextFieldInputColumn} labelText="Hash (Receipt Id)" column={3}/>
                }
                <Field name="validationResult" component={TextFieldInputColumn} labelText="Validation Result" column={hideHash ? 4 : 3}/>
                <Field name="redeemed" component={NullableToggleInput} labelText="Redeemed" column={hideHash ? 4 : 3}/>
                <Field name="multipleTransactions" component={NullableToggleInput} trueText="Yes" falseText="No" labelText="Multiple Transactions" column={hideHash ? 4 : 3} toolTip="Receipts that have been used for multiple transactions" />
            </div>
        </>
    );
}