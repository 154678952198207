import React from 'react'
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap'
import TotalCount from './TotalCount'

const defaultPageSize = 50;

export const mkPaginationObject = {
    pageNumber: 1,
    pageSize: defaultPageSize
};

export const mkPagedResultUncounted = {
    currentPageCount: 0,
    pageNumber: 1,
    pageSize: defaultPageSize,
    items: null
};

export default function MKPaginationUncounted({ currentPage, onClick, currentPageCount, itemsName = '' })
{

    // eslint-disable-next-line eqeqeq
    if (currentPageCount == 0 && currentPage == 1)
    {
        return <div/>
    }

    const createPages = () =>
    {
        let pages = [];
        
        let lastButton = currentPage;
        let firstButton = currentPage;

        let visiblePages = Math.min(currentPage, 7);

        while (lastButton - firstButton < visiblePages) {
            firstButton --;
        }

        for (firstButton; firstButton < lastButton; firstButton++)
        {
            pages.push(
                // eslint-disable-next-line eqeqeq
                <PaginationItem key={firstButton} active={firstButton + 1 == currentPage} /*disabled={i == currentPage}*/>
                    <PaginationLink value={firstButton + 1} onClick={onPageClick(firstButton + 1)}>{firstButton + 1}</PaginationLink>
                </PaginationItem>
            );
        }
        return pages
    }

    const onPageClick = pageNumber => e =>
    {
        onClick(e, pageNumber)
    }

    const totalDisplay = currentPageCount < defaultPageSize ? `${((currentPage-1)*defaultPageSize) + currentPageCount}` : `${currentPage*defaultPageSize}+`

    return (
        <div className="row">
            <div className="md-10 col">
                <Pagination>
                    <PaginationItem>
                        <PaginationLink value={1} first onClick={onPageClick(1)} disabled={currentPage <= 1} />
                    </PaginationItem>
                    <PaginationItem >
                        <PaginationLink value={currentPage ? currentPage - 1 : 0} previous onClick={onPageClick(currentPage ? currentPage - 1 : 0)} disabled={currentPage <= 1}/>
                    </PaginationItem>
                    {createPages()}
                    <PaginationItem>
                        <PaginationLink value={currentPage + 1} next onClick={onPageClick(currentPage + 1)} disabled={currentPageCount < defaultPageSize}/>
                    </PaginationItem>
                </Pagination>
            </div>
            <div className="md-2 col d-flex align-items-center justify-content-end">
                <TotalCount itemsName={itemsName}>{totalDisplay}</TotalCount>
            </div>
        </div>
        );
}
