import React, {useState} from "react";
import {IconButton, IconButtonAlt, SubmitButton} from "../shared/Buttons";
import {Modal, ModalBody} from "reactstrap";
import {Form, Formik} from "formik";
import {defaultRegionConfig} from "./PlayFabConstants";
import {Loading} from "../shared/Loading";
import {AuthorizedGet, AuthorizedPost} from "../AuthorizedRequest";
import PlayFabRegionConfigurationSection from "./PlayFabRegionConfigurationSection";

export default function PlayFabEditRegionConfigurationModal({buildId, onRegionsEdited}) {
    
    const initialValues = {
        regions: [{
            ...defaultRegionConfig
        }]
    };
    
    const [modalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [formValues, setFormValues] = useState({...initialValues});
    
    const toggleModal = () => {
        const open = !modalOpen;
        
        setModalOpen(open);
        
        if (open) {
            setLoading(true);
            AuthorizedGet(`api/PlayFab/GetRegionsForBuild?BuildId=${buildId}`)
                .then(result => {
                    setFormValues({
                        regions: [...result]
                    });
                    setLoading(false);
                });
        }
    }
    
    const submitForm = (values, actions) => {
        AuthorizedPost(values.regions, `api/PlayFab/UpdateRegionsForBuild?BuildId=${buildId}`)
            .then(() => {
                setModalOpen(!modalOpen);
                onRegionsEdited();
            });
    }
    
    return (
        <>
            <IconButton onClick={toggleModal} toolTip="Add New Server Region" icon="edit">EDIT</IconButton>
            <Modal isOpen={modalOpen} toggle={toggleModal} contentClassName="card">
                <ModalBody>
                    <div className="row align-items-center">
                        <h3 className="col-8">Edit Region Configuration</h3>
                        <div className="col-4 text-end">
                            <IconButtonAlt icon="times" toolTip="Close Modal" onClick={toggleModal} />
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        {
                            loading && <Loading />
                        }
                        {
                            !loading &&
                            <Formik
                                initialValues={formValues}
                                enableReinitialize
                                onSubmit={submitForm}
                            >
                                {({ handleSubmit, handleChange, values, touched, isSubmitting}) => (
                                    <Form onSubmit={handleSubmit}>
                                        <PlayFabRegionConfigurationSection values={values} handleChange={handleChange} />
                                        <div className="row">
                                            <div className="col-md-12 text-end">
                                                <SubmitButton column={4} isSubmitting={isSubmitting} touched={touched} />
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>    
                        }
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
}