import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { AuthorizedGet, AuthorizedPut } from '../AuthorizedRequest'
import TeamDataEdit from './TeamDataEdit'
import TeamMembersEdit from '../TeamMembers/TeamMembersEdit'


export default function TeamDetails()
{
    const [team, setTeam] = useState(null);
    const [loading, setLoading] = useState(true);
    const history = useHistory();
    let { id } = useParams()

    // Fetch teams
    useEffect(() =>
    {
        AuthorizedGet(`api/Team/${id}`, history)
            .then(result => 
            {
                if (result) {
                    setTeam(result);                    
                }
                setLoading(false);
            });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const saveTeam = (values, actions) =>
    {
        AuthorizedPut(values, 'api/Team', history)
            .then(result =>
            {
                actions.setSubmitting(false);
                if (result)
                {
                    actions.setTouched({});
                }
            });
    };

    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <div>

            <TeamDataEdit {...{ team, saveTeam, loading }} />
            
            <TeamMembersEdit teamId={id} />

        </div>
    )
}