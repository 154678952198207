import React from 'react'
import { Field } from 'formik'
import { TextFieldInputColumn } from '../shared/FormElements'
import { UserIdFieldInputColumn } from '../Users/UserIdFieldInputColumn'
import { DateTimeInput } from '../shared/MKDateTime'
import { mkPaginationObject } from '../shared/MKPagination'

export const giftsSearchCriteriaObject = Object.assign({
    id : '',
    category : '',
    senderId : '',
    recipientId : '',
    sendTimeFrom : '',
    sendTimeTo : ''
}, mkPaginationObject);

export default function GiftsSearchCriteria(props)
{

    return (
        <>
            <div className="row">
                <Field name="id" component={TextFieldInputColumn} labelText="Id" column={6}/>
                <Field name="category" component={TextFieldInputColumn} labelText="Category" column={6}/>
            </div>
            <div className="row">
                <Field name="senderId" component={UserIdFieldInputColumn} searchTitle="Select sender User" labelText="Sender User Id" column={6}/>
                <Field name="recipientId" component={UserIdFieldInputColumn} searchTitle="Select recipent User" labelText="Recipient User Id" column={6}/>
            </div>
            <div className="row">
                <Field name="sendTimeFrom" component={DateTimeInput} useTime={true} labelText="Send date From" column={6}/>
                <Field name="sendTimeTo" component={DateTimeInput} useTime={true} labelText="Send date To" column={6}/>
            </div>
        </>
    );
}