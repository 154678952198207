import React, {useEffect, useState} from 'react';
import {Card} from "../../shared/Card";
import UserPrefsDashboardConfigurationPopUp from "./UserPrefsDashboardConfigurationPopUp";
import UserPrefsDashboardItem from "./UserPrefsDashboardItem";
import {Loading} from "../../shared/Loading";
import {AuthorizedGet} from "../../AuthorizedRequest";
import {useHistory} from "react-router-dom";


export default function UserPrefsDashboard({ userId, userPrefs, userPrefsSaved })
{
    const [items, setItems] = useState();
    const history = useHistory();

    useEffect(() =>
    {
        AuthorizedGet(`api/Settings/UserPrefDashboardSettings`, history)
            .then(result =>
            {
                result ? setItems(result) : setItems(false);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    const getItemValue = key => {
        if (!userPrefs)
            return null;
        else {
            return userPrefs.find(x => x.key == key)?.value;
        }
    };
    
    const UserPrefsDashboardHeader = () => 
        <div className="row">
            <div className="col-md-8 col">
                <h4 className="d-inline-block mt-0 mb-0">Dashboard</h4>
            </div>
            <div className="col-md-4 text-end">
                <UserPrefsDashboardConfigurationPopUp dashboardSettings={items} settingsSaved={setItems} />
            </div>
        </div>;    
    
    return (
        <>
            <Card headerComponent={<UserPrefsDashboardHeader/>}>
                { items ?
                    <table className='table table-sm'>
                        <tbody>
                        {items.map((x, i) =>
                            <UserPrefsDashboardItem key={`${x.key}_${i}_${x.modified}`} userId={userId} item={x}
                                                    itemValue={getItemValue(x.key)} valueSaved={userPrefsSaved}/>)
                        }
                        </tbody>
                    </table>
                    : items === false ?
                        <p className="alert alert-warning text-black">
                            Dashboard settings/configuration not set
                        </p>
                        :
                        <Loading/>
                }
            </Card>
            <hr/>
            <br/>
        </>

    );
}

