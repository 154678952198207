import React, {useRef} from 'react'
import { AuthorizedPost } from '../AuthorizedRequest'
import {useHistory, useParams} from 'react-router-dom'
import LeaderboardDetails from "./LeaderboardDetails";
import leaderboardsTypes from "./LeaderboardsTypes.json";

export default function LeaderboardCreate(props)
{
    let newLeaderboard = useRef({ name: 'New Leaderboard', bucketSize: 20 });
    const history = useHistory();
    
    let leaderboardType = useParams().type ?? props.type ?? leaderboardsTypes[0].Type;

    const save = (values, actions) =>
    {
        AuthorizedPost(values, `api/Leaderboard/${leaderboardType}Create`, history)
            .then(result =>
            {
                actions.setSubmitting(false)
                if (result)
                {
                    actions.setTouched({})
                    history.push('/leaderboards/' + result);
                }
            })
    }

    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <div>
            <LeaderboardDetails leaderboard={newLeaderboard.current} saveMethod={save} editSoloEventId={leaderboardType === 'Solo'}/>
        </div>
    );
}