import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import UserSelectModal from "../Users/UserSelectModal"
import { AuthorizedPost } from "../AuthorizedRequest"


export const TeamMemberAdd = ({ teamId, onMemberAdded }) =>
    <TeamMemberAddOrInvite teamId={teamId} onMemberProcessed={onMemberAdded} 
                           action="Add" buttonTitle="Add Team Member" buttonIcon="user-plus" 
                           userSearchTitle="Select User to add" successMsj="User successfully added as a member of the Team" />

                           
export const TeamMemberInvite = ({ teamId, onMemberInvited }) =>
    <TeamMemberAddOrInvite teamId={teamId} onMemberProcessed={onMemberInvited}
                           action="Invite" buttonTitle="Invite user to Team" buttonIcon="user-tag" 
                           userSearchTitle="Select User to invite" successMsj="User successfully invited to the Team" />

                           
function TeamMemberAddOrInvite({ teamId, onMemberProcessed, action, buttonTitle, buttonIcon, userSearchTitle, successMsj })
{
    const [isOpen, setIsOpen] = useState(false);
    const history = useHistory();
    
    const onUserIdSelected = userId =>
    {
        setIsOpen(false);
        
        if (userId)
        {
            AuthorizedPost(userId, `api/TeamMember/${action}/${teamId}`, history)
                .then(result =>
                {
                    if (onMemberProcessed && result)
                    {
                        onMemberProcessed(result, successMsj);
                    }
                });
        }      
    };

    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <>
            <button title={buttonTitle} className="icon-button">
                <FontAwesomeIcon size="2x" icon={buttonIcon} onClick={() => setIsOpen(!isOpen)} />
            </button>

            <UserSelectModal isOpen={isOpen} onUserIdSelected={onUserIdSelected} searchTitle={userSearchTitle} searchCriteriaObject={{ isInATeam: false }} />
        </>
    )
}