import React, {useRef} from 'react'
import { Formik, Field, Form } from 'formik'
import { Card } from '../shared/Card'
import {
    TextFieldInputColumn,
    ErrorDisplay,
    ToggleInputManaged
} from '../shared/FormElements'
import { DateTimeInput } from '../shared/MKDateTime'
import { JSONEditor } from '../shared/JSONEditor'
import { SubmitButton } from '../shared/Buttons'
import { Loading } from '../shared/Loading'
import UserSegmentsSelectList from '../UserSegments/UserSegmentsSelectList'
import {useToasts} from "react-toast-notifications";
import {useNewsAlertsListCache} from "./NewsAlerts";

export default function NewsAlertForm({ newsAlert, saveMethod, loading })
{
    const jsonEdit = useRef()
    const userSegmentsEdit = useRef()
    const { addToast } = useToasts()
    const [,,refreshItem] = useNewsAlertsListCache()

    // Save changes
    const saveEntity = (values, actions) => {
        let newsAlertEdited = {
            ...values,
            json: jsonEdit.current ? JSON.stringify(jsonEdit.current) : newsAlert.json,
            userSegments: userSegmentsEdit.current ?? newsAlert.userSegments
        };
        saveMethod(newsAlertEdited)
            .then(result => {
                actions.setSubmitting(false);
                if (result) {
                    refreshItem(result);
                    actions.setTouched({});
                    addToast(<>New/Alert <span className="font-monospace">Id={result.id}</span> saved correctly</>,
                        {appearance: 'success', autoDismiss: true, autoDismissTimeout: 10000});
                }
            });
    }

    const validate = (values, props) =>
    {
        let errors = {}
        if (!values.name)
        {
          errors.name = "Name required";
        }
        if (!values.json)
        {
          errors.json = "Json required";
        }
        if (!values.endDate)
        {
          errors.endDate = "Active Till date required";
        }
        
        return errors
    }

    const toggleSegment = segmentId =>
    {
        let uss = userSegmentsEdit.current ?? newsAlert.userSegments;

        let index = uss.findIndex(x => x.id == segmentId);
        if (index >= 0)
            uss.splice(index, 1);
        else
            uss.push({ id: segmentId });
        
        userSegmentsEdit.current = uss;        
    }

    if (loading)
      return <Loading />

    if (!newsAlert)
      return null
    
    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <div>
            <br/>
            <Formik
                enableReinitialize
                validate={validate}
                initialValues={newsAlert}
                onSubmit={saveEntity}>
                {({handleSubmit, isSubmitting, errors, touched, setFieldTouched}) =>
                    <Form onSubmit={handleSubmit}>
                        <Card title="New/Alert Details">
                            <div className="row">
                                <Field name="id" component={TextFieldInputColumn} labelText="New/Alert Id"
                                       placeholder="( Creating... )" readOnly={true} column={2}/>
                                <Field name="name" component={TextFieldInputColumn} labelText="Name*" autofocus={!newsAlert.id}
                                       errors={errors.name} maxLength={64} column={3}/>
                                <Field name="startDate" component={DateTimeInput} useTime={true} useUTC={true} labelText="Active From (UTC)"
                                       errors={errors.startDate} value={newsAlert.startDate} column={3}/>
                                <Field name="endDate" component={DateTimeInput} useTime={true} useUTC={true} labelText="Active Till (UTC)"
                                       errors={errors.endDate} value={newsAlert.endDate} column={3}/>
                                <Field name="enabled" component={ToggleInputManaged} labelText="Enabled" column={1}/>
                            </div>
                            <ErrorDisplay errors={errors}/>
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <SubmitButton errors={errors} isSubmitting={isSubmitting} touched={touched} />
                                </div>
                            </div>
                        </Card>

                        <span/>
                        <Card title="Json">
                            <JSONEditor valueString={newsAlert.json} maxLines={40}
                                        onChange={json => { jsonEdit.current = json; setFieldTouched('json'); }}/>
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <SubmitButton errors={errors} isSubmitting={isSubmitting} touched={touched} />
                                </div>
                            </div>
                        </Card>
                        
                        <span/>
                        <Card title="User Segments">
                            <UserSegmentsSelectList selectedSegments={newsAlert.userSegments} 
                                                    onToggleSegment={sId => {
                                toggleSegment(sId);
                                setFieldTouched('userSegments');
                            }}/>
                        </Card>
                    </Form>}
            </Formik>
        </div>
    )
}