import React, { useState, useEffect } from 'react'
import { AuthorizedGet } from '../AuthorizedRequest'
import { useHistory } from 'react-router-dom'
import { Card } from '../shared/Card'
import { mkPagedResult } from '../shared/MKPagination'
import BalancesList from "../Inventory/BalancesList";


export default function UserBalances({ userId })
{
    const [loading, setLoading] = useState(true)
    const [balances, setBalances] = useState(mkPagedResult)
    const history = useHistory()

    // Fetch users
    useEffect(() =>
    {
        AuthorizedGet(`api/User/${userId}/Balance`, history)
            .then(result => 
            {
                setBalances(result ?? mkPagedResult);
                setLoading(false);
            });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ userId ])

    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <Card title="Balances">
            <BalancesList balances={balances} loading={loading}/>
        </Card>
    )
}