import React, {useEffect, useState} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {AuthorizedPut, AuthorizedGet } from '../AuthorizedRequest'
import {Loading} from "../shared/Loading";
import {useToasts} from "react-toast-notifications";
import ApiKeyForm from "./ApiKeyForm";

export default function EditApiKey()
{
    const history = useHistory()
    const [apiKey, setApiKey] = useState();
    const toast = useToasts()
    const {apiKeyId} = useParams();
    
    useEffect(() => {
        AuthorizedGet(`api/ApiKey/${apiKeyId}`, history)
            .then(result =>
            {
                setApiKey({
                    ...result
                });
            });
    }, [apiKeyId, history, setApiKey]);
    
    const saveEntity = (catalogueType) =>
        AuthorizedPut(catalogueType, `api/ApiKey/${apiKeyId}`, history)
            .then(() => {
                toast.addToast("Saved", {
                    autoDismiss: true
                });
            });

    if (!apiKey) {
        return <Loading />;
    }

    return (
        <div>
            <ApiKeyForm
                apiKey={apiKey}
                onSubmit={saveEntity}/>
        </div>
    )
}