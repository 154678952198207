import React, { useState, useEffect } from 'react'
import { AuthorizedGet, AuthorizedDelete } from '../AuthorizedRequest'
import { useHistory } from 'react-router-dom'
import { Card } from '../shared/Card'
import NewsAlertsList from './NewsAlertsList'
import NewsAlertsSearch from './NewsAlertsSearch'
import { NewsAlertsSearchCriteriaObject } from './NewsAlertsSearchCriteria'
import { MKPaginationWithTotal, mkPagedResult } from '../shared/MKPagination';
import { composeQueryString, mapQueryStringValuesToObject, usePagedResultSessionState, useQueryStringAndSessionState, useCache } from '../shared/Utils'
import { CollapseSearchButton, AddButtonText } from '../shared/Buttons'
import { Collapse } from 'reactstrap'


export function useNewsAlertsListCache()
{
    return usePagedResultSessionState('newsAlerts');
}


export default function NewsAlerts(props)
{
    const initialEmptyValues = Object.assign({}, NewsAlertsSearchCriteriaObject);

    let history = useHistory();
    const [loading, setLoading] = useState(true);
    const [initialValues, setInitialValues] = useQueryStringAndSessionState(() => Object.assign({}, initialEmptyValues), history, history.location.search, 'NewsAlertsInitialValues');
    const [pagedResult, setPagedResult] = useNewsAlertsListCache();
    const [searchCollapsed, setSearchCollapsed] = useCache('NewsAlertsSearchCollapsed', true);
    
    useEffect(() => {
        if (mapQueryStringValuesToObject(initialEmptyValues, history.location.search))   // There is allowed search parameters in QueryString
        {
            if (JSON.stringify(initialEmptyValues) !== JSON.stringify(initialValues))  // Those parameters means a different search tan the one saved in sessionStorage
                searchNewsAlerts(initialEmptyValues);
            else
                setLoading(false);
        } else {
            if (pagedResult.itemsTotalCount == null)
                searchNewsAlerts(initialValues);
            else
                setLoading(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    const searchNewsAlerts = (searchValues, formikBag = { setSubmitting: x => {} }, pageNumber = 1) => {
        searchValues.pageNumber = pageNumber;
        setLoading(true);
        setInitialValues(Object.assign({}, searchValues));
        AuthorizedGet('api/NewsAlert/Search?' + composeQueryString(searchValues), history)
            .then(result => 
            {
                setPagedResult(result ?? mkPagedResult);
                formikBag.setSubmitting(false);
                setLoading(false);
            });
    };

    const onPageClick = (e, pageNumber) => searchNewsAlerts(initialValues, undefined, pageNumber);
        
    const toggleSearch = e =>  {
        e.preventDefault();
        setSearchCollapsed(!searchCollapsed);
    };

    const deleteEntity = newsAlertId => () =>
    {
        AuthorizedDelete(null, `api/NewsAlert/${newsAlertId}`, history)
            .then(data =>
            {
                if (data)
                {
                    pagedResult.items = pagedResult.items.filter(function(s) { return s.id !== data.id });
                    pagedResult.itemsTotalCount--;
                    setPagedResult(Object.assign({}, pagedResult));
                }
                setLoading(false);
            });
    };
 
    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////   
    return (
        <div>
            <div className="row align-items-center">
                <div className="md-8 col">
                    <h3>&nbsp;News/Alerts</h3>
                </div>
                <div className="md-4 col text-end">
                    <AddButtonText onClick={_ => history.push('/newsalerts/Create')} toolTip="Add New New/Alert">Add New</AddButtonText>
                    &nbsp;
                    <CollapseSearchButton collapsed={searchCollapsed} onClick={toggleSearch}/>
                </div>
            </div>

            <Collapse isOpen={!searchCollapsed}>
                <Card>
                    <NewsAlertsSearch handleSearch={searchNewsAlerts} initialValues={initialValues}/>
                </Card>
            </Collapse>

            <Card>
                <NewsAlertsList newsAlerts={pagedResult.items} loading={loading} onDelete={deleteEntity}/>
                <MKPaginationWithTotal currentPage={pagedResult.pageNumber} total={pagedResult.pagesTotalCount} totalItems={pagedResult.itemsTotalCount} onClick={onPageClick} itemsName="A/B Tests"/>
            </Card>
        </div>
    );
}
