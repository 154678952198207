import React, {useEffect, useState} from 'react'
import Toggle from 'react-toggle';
import {AuthorizedGet} from "../AuthorizedRequest";
import {useHistory} from "react-router-dom";

export default function UserSegmentsSelectList({ selectedSegments, onToggleSegment }) 
{
    const [userSegments, setUserSegments] = useState();
    let history = useHistory();
    
    useEffect(() => {
        AuthorizedGet('api/UserSegment?pageSize=1000', history)
            .then(result => setUserSegments(result.items));
    }, []);    
    
    return (
        <table className='table table-sm table-striped table-hover'>
            <thead>
                <tr>
                    <th></th>
                    <th>Id</th>
                    <th>Name</th>
                    <th>Filters</th>
                </tr>
            </thead>
            <tbody>
                {userSegments?.map((userSegment, index) =>
                <tr key={userSegment.id}>
                    <td>
                        <Toggle icons={false}
                                value={String(userSegment.id)}
                                defaultChecked={selectedSegments?.find(x => x.id == userSegment.id) !== undefined}
                                onChange={e => onToggleSegment(e.target.value)} />
                    </td>
                    <td>{userSegment.id}</td>
                    <td>{userSegment.name}</td>
                    <td>{userSegment.prettyFilterString}</td>
                </tr>
                )}
            </tbody>
        </table>
    );
}