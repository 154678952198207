import React from 'react'
import { Formik, Form } from 'formik'
import { SearchButton } from '../shared/Buttons'
import GiftsSearchCriteria from './GiftsSearchCriteria'

export default function GiftsSearch({ initialValues, handleSearch })
{

    return (
        <Formik
            enableReinitialize
            //validate={validate}
            initialValues={initialValues}
            onSubmit={handleSearch} 
        >
            {({ errors, status, touched, isSubmitting }) => (
            <Form>
                <GiftsSearchCriteria />
                <div className="row">
                    <div className="md-12 col text-center">
                        <SearchButton isSubmitting={isSubmitting} touched={touched} forceTouched={true} column={2} />
                    </div>
                </div>
            </Form>
            )} 
        </Formik>
    );
}