import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AuthorizedPut } from '../../AuthorizedRequest'
import { useHistory } from 'react-router-dom'
import { Subject } from 'rxjs';
import {debounceTime} from 'rxjs/operators';


export const Severity = ({supportTicketId, severity, ...props}) =>
{
    const history = useHistory()

    //Severity updated on server with delay
    const severitySubject = (new Subject()).pipe(debounceTime(2000))
    severitySubject.subscribe(newSeverity =>
    {
        // console.log(severityValue)
        AuthorizedPut(null, `api/Support/UpdateSeverity/${supportTicketId}/${newSeverity}`, history)
        .then(result =>
        {
            if (!result)
            {
                console.log("Error updating severity")
            }
        })
    });

    const updateSeverity = (e) =>
    {
        e.stopPropagation()
        const newValue = severity + 1 > 2 ? 0 : severity + 1
        severitySubject.next(newValue)
        props.updateSeverity(newValue)
    }

    const severityIcon = severity === 0 ? "meh" : severity === 1 ? "angry" : "tired"
    const severityColor = severity === 0 ? "icon-trivial" : severity === 1 ? "icon-normal" : "icon-critical"
    const severityText = severity === 0 ? "Trivial" : severity === 1 ? "Normal" : "Critical"

    return (
        <button title={"Severity: " + severityText} className="icon-button" onClick={updateSeverity}>
            <FontAwesomeIcon size="2x" className={severityColor} icon={severityIcon} />
        </button>
    )
}