import React, { useState} from 'react'
import { Redirect } from 'react-router-dom'
import { Card } from '../../../shared/Card'
import { Formik, Field, Form } from 'formik'
import { TextFieldInputColumn, ErrorDisplay, TextAreaInput } from '../../../shared/FormElements'
import { PlatformInputColumn } from '../../../shared/FormElementsDropdowns'
import { UserIdFieldInputColumn } from '../../../Users/UserIdFieldInputColumn'
import { SubmitButton } from '../../../shared/Buttons'
import { LabeledDetail } from "../../../shared/LabeledDetail";
import LanguageDropdownButton from "../../../shared/LanguageDropdownButton";

export default function SupportTicketEdit({ title = 'Edit Support Ticket', saveTicket, supportTicket})
{
    const [language, setLanguage] = useState(supportTicket.language)
    const [forceTouched, setForceTouched] = useState(false)
    
    const validate = (values, props) =>
    {
        let errors = {}
        if (!values.message)
        {
            errors.message = "Message required"
        }
        return errors
    }
    
    const onSubmit = (values, actions) => { 
        saveTicket({ ...values, language: language }, actions) 
        setForceTouched(false)
    }
    
    const onLanguageChanged = lang => {
        setLanguage(lang)
        setForceTouched(true)
    }

    if (supportTicket == null) 
        return <Redirect to="/Support/Inbox"/>

    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (<>
            <Card title={title}>
                <div className="row">
                    <LabeledDetail label="Support Ticket Id" value={supportTicket.id ?? <>( <i>creating</i> )</>}/>
                    <div className="col-md-4">
                        <label className="mt-3 float-end">Language:</label>
                    </div>
                    <div className="col-md-2">
                        <div className="form-group float-end">
                            <LanguageDropdownButton language={language ?? <>[ <i>Unknown</i> ]</>} onChange={onLanguageChanged}/>
                        </div>
                    </div>
                </div>
                <Formik
                    enableReinitialize
                    validate={validate}
                    initialValues={supportTicket}
                    onSubmit={onSubmit}>
                    {({handleSubmit, isSubmitting, errors, touched, setFieldTouched}) =>
                        <Form onSubmit={handleSubmit}>
                            <Field name="message" component={TextAreaInput} labelText="Message" column={9} errors={touched.message && errors.message}/>
                            <Field name="platform" component={PlatformInputColumn} labelText="Platform" column={9} errors={touched.platform && errors.platform}/>
                            <Field name="appVersion" component={TextFieldInputColumn} labelText="App Version" column={9} errors={touched.appVersion && errors.appVersion}/>
                            <Field name="userId" component={UserIdFieldInputColumn} labelText="User ID" column={9} errors={touched.userId && errors.userId}/>
                            <ErrorDisplay errors={errors} touched={touched} />
                            <SubmitButton errors={errors} isSubmitting={isSubmitting} touched={touched} forceTouched={forceTouched} />
                        </Form>
                    }
                </Formik>
            </Card>
        </>
    )
}