import React, {useEffect, useState} from 'react'
import { Card } from '../shared/Card'
import ProjectModules from './ProjectModules'
import {Link} from "react-router-dom";
import {AuthorizedGet} from "../AuthorizedRequest";
import authService from '../api-authorization/AuthorizeService';
import ExternalConfiguration from "./ExternalConfiguration/ExternalConfiguration";

export default function Developer()
{

    const [apiVersion, setApiVersion] = useState("");

    useEffect(() => {
        (async () => {
            const apiVersionURL = await authService.getApiUrl() + '/ServerStatus/ApiVersion';
            AuthorizedGet(apiVersionURL)
                .then(result => {
                    if (result === null) {
                        setApiVersion(null);
                    } else {
                        setApiVersion('v' + result.version);
                    }
                })
                .catch(e => {
                    console.error(e);
                    setApiVersion(null);
                });
        })();
    });

    let gitLink, releaseLink;

    if (apiVersion === null) {
        gitLink = <p>Couldn't load API version. If your If your project uses the Unity Package Manager, you can add the matching version of the SDK from this Git URL: <br /><i>https://github.com/mightykingdom/MKNetUnitySDK.git#VERSION</i> (replace VERSION with the version of the API deployed to the project)</p>
        releaseLink = <p>If your project adds the SDK files manually, you can find and download the correct version from the list of SDK releases here: <a href="https://github.com/mightykingdom/MKNetUnitySDK/releases" target="_blank" rel="noreferrer">SDK Releases</a></p>
    } else {
        gitLink = <p>If your project uses the Unity Package Manager, you can add the matching version of the SDK from this Git URL: <br /><i>https://github.com/mightykingdom/MKNetUnitySDK.git#{apiVersion}</i></p>
        releaseLink = <p>If your project adds the SDK files manually, you can find and download the correct version from the list of SDK releases here: <a href={"https://github.com/mightykingdom/MKNetUnitySDK/releases/" + apiVersion} target="_blank" rel="noreferrer">SDK Releases</a></p>
    }

    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <div>
            <div className="md-8 col">
                <h3>Information for Game Developers</h3>
            </div>
            <br/>

            <Card title="Latest SDK">
                <p>The SDK is a set of model classes and predefined requests to simplify work with MKNet. It's updated every time a new version of MKNet is published.</p>
                <p>Read <Link to="/OpenApi">API Documentation</Link> for more details</p>
                {gitLink}
                {releaseLink}
            </Card>
            
            <Card title="Modules for this Project">
                <ProjectModules/>
            </Card>
            <span/>
            
            <ExternalConfiguration/>
        </div>
    )
}
