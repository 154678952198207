import React, { useState } from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import { useToasts } from 'react-toast-notifications'
import UserSelectModal from "./../UserSelectModal"
import {AuthorizedPut} from "../../AuthorizedRequest"
import {useHistory} from "react-router-dom";

export default function UserPrefsClone({ userId, onUserPrefsChanged })
{
    const [isOpen, setIsOpen] = useState(false);
    const toasts = useToasts();
    const history = useHistory();

    const doClone = userIdToClone =>
    {
        let cloningToastId;
        toasts.addToast(`Cloning UserPrefs from user ${userIdToClone} to user ${userId}...`, {
            appearance: 'info',
            autoDismiss: false
        }, id => cloningToastId = id);
        
        AuthorizedPut(null, `api/User/CloneUserPrefs/${userId}/${userIdToClone}`, history)
            .then(result =>
            {
                if (result)
                {
                    let modified = (new Date()).getTime().toString();
                    result.forEach(x => x.modified = modified); //To get a different key for the array's map() and be able to refresh the ui
                    onUserPrefsChanged(result);
                    toasts.updateToast(cloningToastId, { 
                        content: `UserPrefs cloned successfully from user ${userIdToClone} to user ${userId}`, 
                        appearance: 'success', autoDismiss: true 
                    });
                }
                else
                {
                    toasts.removeToast(cloningToastId);
                }
            });
    };

    const onUserIdSelected = userIdToClone =>
    {
        if (userIdToClone && window.confirm(
            `The UserPrefs of user ${userId} will be OVERWRITTEN by the UserPrefs of user ${userIdToClone}. \n` +
            'It is strongly recommended to first make a BACKUP of user\'s UserPrefs. \n' + 
            '⚠ This action can not be undone. \n' +
            'Proceed?'))
        {
            doClone(userIdToClone);
        }
        toggleModal();
    }

    const toggleModal = () => setIsOpen(!isOpen);

    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <>
            <button type="button" className="btn btn-sm shadow-none btn-link m-0"
                    onClick={toggleModal} title="Clone UserPrefs from another user">
                <FontAwesomeIcon className="blue" icon="clone" size="2x" />
            </button>

            <UserSelectModal isOpen={isOpen} onUserIdSelected={onUserIdSelected} searchTitle="Search user to clone" />
        </>
    )
}