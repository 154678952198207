import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Loading } from '../shared/Loading'
import { AuthorizedGet, AuthorizedPut } from '../AuthorizedRequest'
import ConfigurationEdit from "./ConfigurationEdit";


export default function ConfigurationDetails({byKey = false})
{
    const [configuration, setConfiguration] = useState();
    const [loading, setLoading] = useState(true);
    let { packedAppVersion } = useParams();
    let { configKey } = useParams();
    const history = useHistory();
    
    // Fetch configurations
    useEffect(() =>
    {
        AuthorizedGet(`api/Configuration/${packedAppVersion}/${configKey ?? ''}`, history)
            .then(result => 
            {
                setConfiguration(result);
                setLoading(false);                    
            });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (loading)
        return <Loading />

    if (!configuration)
        return null

    return (
        <ConfigurationEdit configuration={configuration} saveAction={AuthorizedPut} creating={false} byKey={byKey}/>
    );
}