import { useEffect } from 'react';
import {useCache} from "../shared/Utils";
import {AuthorizedGet} from "../AuthorizedRequest";

export default function useSoloEventDurationType() {

    const [durationType, setDurationType] = useCache('soloEventsDurationType', null);
    const getDurationType = () => {
        AuthorizedGet('api/SoloEvent/DurationType')
            .then(result => setDurationType(result));
    }

    useEffect(() => {
        if (durationType == null) {
            getDurationType();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return durationType;
}