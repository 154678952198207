import React from "react";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import UserSelect from "./UserSelect";


export default function UserSelectModal({ isOpen, onUserIdSelected, searchCriteriaObject, searchTitle = 'Select User' })
{
    const toggleModal = () => onUserIdSelected(null);
    
    return (
        <Modal isOpen={isOpen} toggle={toggleModal} contentClassName="card" size="xl">
            <ModalHeader toggle={toggleModal}>
                {searchTitle}
            </ModalHeader>
            <ModalBody>
                <UserSelect onUserIdSelected={onUserIdSelected} searchCriteriaObject={searchCriteriaObject} />
            </ModalBody>
        </Modal>
    );
}