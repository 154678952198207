import React from 'react'
import { Field } from 'formik'
import { TextFieldInputColumn, NullableToggleInput } from '../shared/FormElements'
import { mkPaginationObject } from '../shared/MKPagination';

export const versionsSearchCriteriaObject = Object.assign({    
    name : '', 
    forceUpdate : ''
}, mkPaginationObject);

export default function VersionsSearchCriteria(props)
{
    return (
        <>
            <div className="row">
                <Field name="name" component={TextFieldInputColumn} labelText="Name" column={6}/>
                <Field name="forceUpdate" component={NullableToggleInput} trueText="Yes" falseText="No" labelText="Force Update" column={6}/>
            </div>
        </>
    );
}