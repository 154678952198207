import React from 'react'
import { Loading } from '../shared/Loading'

export default function BalancesList({ balances, loading }) {
    if (loading)
    {
        return <Loading loading={true} />
    }

    return (
        <table className='table table-full-width table-striped'>
            <thead>
                <tr>
                    <th>Amount</th>
                    <th>Currency</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {balances.map(({balance, currency}, index) =>
                <tr key={index}>
                    <td>{balance}</td>
                    <td>{currency.name}</td>
                </tr>
                )}
            </tbody>
        </table>
        );
}