import React, {useEffect, useState} from 'react'
import { Field } from 'formik'
import { DateTimeInput } from '../shared/MKDateTime'
import { mkPaginationObject } from '../shared/MKPagination'
import {AuditLogEventTypes} from "./AuditLogTypeMap";
import {SelectField, TextFieldInputColumn} from "../shared/FormElements";
import {useHistory} from "react-router-dom";
import {AuthorizedGet} from "../AuthorizedRequest";
import {SelectAgentsInput} from "../shared/SelectAgentsInput";

export const auditLogSearchCriteriaObject = Object.assign({
    entityId : '',
    entityType : '',
    eventType : '-1',
    adUserId: '',
    dateFrom : '',
    dateTo : ''
}, mkPaginationObject);

const eventTypeValuesList = Object.keys(AuditLogEventTypes).map(key => ({key: key, value: AuditLogEventTypes[key]}));

export default function AuditLogSearchCriteria(props)
{
    const [entityTypes, setEntityTypes] = useState(null);
    const history = useHistory();

    useEffect(() => {
        AuthorizedGet('api/AuditLog/EntityTypes', history)
            .then(result => setEntityTypes(result.sort((a, b) => a > b ? 1 : -1)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return <>
        <div className='row'>
            <Field name="entityId" component={TextFieldInputColumn} labelText="Entity Id" column={4}/>
            <Field name="entityType" component={SelectField} labelText="Entity Type" column={4}>
                <option key="-1" value=""></option>
                {entityTypes?.map(entityType =>
                    <option key={entityType} value={entityType}>{entityType}</option>)
                }
            </Field>
            <Field name="eventType" component={SelectField} labelText="Action" column={4}>
                <option key="-1" value=""></option>
                {eventTypeValuesList.map(eventType =>
                    <option key={eventType.key} value={eventType.key}>{eventType.value}</option>)
                }
            </Field>
        </div>
        <div className="row">
            <Field name="adUserId" component={SelectAgentsInput} labelText="User" placeholder="User involved..."
                   showCleaner1stItem={true} toolTip="User who made the change" column={4}/>
            <Field name="dateFrom" component={DateTimeInput} labelText="Date From" column={4}/>
            <Field name="dateTo" component={DateTimeInput} labelText="Date To" column={4}/>
        </div>
    </>;
}