import React, {useEffect, useState} from 'react';
import {Card} from "../shared/Card";
import {AuthorizedGet, AuthorizedPost} from "../AuthorizedRequest";
import MKPaginationUncounted, {mkPagedResultUncounted} from "../shared/MKPaginationUncounted";
import {composeQueryString, useQueryStringAndSessionState, useSessionState} from "../shared/Utils";
import PlayFabList from "./PlayFabList";
import {useHistory} from "react-router-dom";
import {mkPaginationObject} from "../shared/MKPagination";
import {AddButtonText} from "../shared/Buttons";

export default function PlayFab() {

    const initialPaginationValues = Object.assign({}, mkPaginationObject);

    let history = useHistory();
    const [loading, setLoading] = useState(true);
    const [paginationValues, setPaginationValues] = useQueryStringAndSessionState(() => Object.assign({}, initialPaginationValues), history, history.location.search, 'playFabPaginationValues');
    const [pagedResult, setPagedResult] = useSessionState(mkPagedResultUncounted, 'users');

    useEffect(() => {
        // We could check the url bar here to see if we need to run the request or just use state (see Administration/ClientApp/src/components/Users/Users.js)
        // However these should always be unique (you can't enter other search terms for example) so I think it's safe to just request every time the url changes.
        searchForServers(paginationValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    const searchForServers = (pagination, pageNumber = 1) => {
        pagination.pageNumber = pageNumber;
        setPaginationValues(pagination);
        
        // Make a new request and populate the URL with the relevant search terms
        AuthorizedGet('api/PlayFab/Search?' + composeQueryString(pagination), history)
            .then(result => {
                // Set the paginated result and display
                setPagedResult(result ?? mkPagedResultUncounted);
                setLoading(false);
            });
    };
    
    // Fetch data for relevant page
    const onPageClick = (e, pageNumber) => searchForServers(paginationValues, pageNumber);
    
    const onNewClick = () => {
        history.push("PlayFab/Create");
    }
    
    // Delete the record
    const onDeleteBuild = buildId => () => {
        setLoading(true);
        AuthorizedPost(buildId, 'api/PlayFab/DeleteBuild')
            .then(() => {
                // We can't really delete something that would shift all the values off the page so just
                // re-fetch in case we can push an older value into the list (and remove the one we just deleted)
                searchForServers(paginationValues, pagedResult.pageNumber);
            });
    }
    
    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <div>
            <div className="row align-items-center">
                <div className="col-md-8">
                    <h3>&nbsp;PlayFab Servers</h3>
                </div>
                <div className="col-md-4 text-end">
                    <AddButtonText onClick={onNewClick} toolTip="Add New Server">Add New</AddButtonText>
                </div>
            </div>
            
            <Card>
                <PlayFabList servers={pagedResult.items} loading={loading} onDelete={onDeleteBuild} />
                {
                    !loading && <MKPaginationUncounted currentPageCount={pagedResult.currentPageCount} currentPage={pagedResult.pageNumber} onClick={onPageClick} itemsName="Servers"/>
                }
            </Card>
        </div>
    );
}
