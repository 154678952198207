import React from 'react'
import { Formik, Form } from 'formik'
import { SearchButton } from '../../shared/Buttons'
import TransactionsSearchCriteria from './TransactionsSearchCriteria'
import ReceiptsSearchCriteria from './ReceiptsSearchCriteria'
import TransactionsButton from './TransactionsButton';

export default function ReceiptsSearch({ initialValues, handleSearch })
{
    let transactionsButtonSearchObject = Object.assign({}, initialValues);
    transactionsButtonSearchObject.receiptId = initialValues.hash;
    transactionsButtonSearchObject.hash = "";

    return (
        <Formik
            enableReinitialize
            //validate={validate}
            initialValues={initialValues}
            onSubmit={handleSearch} 
        >
            {({ errors, status, touched, isSubmitting }) => (
            <Form>
                <ReceiptsSearchCriteria />
                <TransactionsSearchCriteria showReceiptId={false} />
                <div className="row">
                    <div className="md-2 col"></div>
                    <div className="md-8 col text-center">
                        <SearchButton isSubmitting={isSubmitting} touched={touched} forceTouched={true} column={7} />
                    </div>
                    <div className="md-2 col text-end">
                        <div className="mt-3">
                            <TransactionsButton searchObject={transactionsButtonSearchObject} />
                        </div>
                    </div>
                </div>
            </Form>
            )} 
        </Formik>
    );
}