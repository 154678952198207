import React, { useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { AuthorizedPost } from '../AuthorizedRequest'
import SoloEventDetails from "./SoloEventDetails";

export default function SoloEventCreate({
                                            postEndpoint = 'api/SoloEvent',
                                            pushUrl = '/SoloEvents/',
                                            showCreated = true,
                                            editPauseUntil = true,
                                            initialData = {
                                                json: '{}',
                                                schedule: '0 12 * * 1',
                                                duration: 24,
                                                useLocalTime: false
                                            }
                                        })
{
    const eventRef = useRef(initialData);
    const history = useHistory();
       
    // Save changes
    const saveEvent = (values, actions) => 
    {
        AuthorizedPost(values, postEndpoint, history)
            .then(result => {
                actions.setSubmitting(false);
                if (result) {
                    history.push(pushUrl + result);
                }
            });
    }

    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <SoloEventDetails soloEvent={eventRef.current} saveMethod={saveEvent} showCreated={showCreated} editPauseUntil={editPauseUntil} />
    );
}