import React, { useState, useEffect, useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { AuthorizedGet, AuthorizedPut } from '../AuthorizedRequest'
import { Formik, Field, Form } from 'formik'
import SegmentFilter from './elements/SegmentFilter'
import PrefKeysSuggestions from './elements/PrefKeysSuggestions'
import { TextFieldInputColumn, ErrorDisplay } from '../shared/FormElements'
import { SubmitButton, AddButton } from '../shared/Buttons'
import { Card } from '../shared/Card'
import { Loading } from '../shared/Loading'

export default function UserSegmentDetails(props)
{
  
    const [userSegment, setUserSegment] = useState(null)
    const [userPrefKeys, setUserPrefKeys] = useState(null)
    const [forceTouched, setForceTouched] = useState(false)
    const userPrefKeysRef = useRef(null)
    const filterOperatorsRef = useRef(null)
    const history = useHistory()
    
    let {id} = useParams()
    useEffect(() =>
    {
        AuthorizedGet(`api/UserSegment/EditViewModel/${id}`, history)
        .then(result => 
        {
          filterOperatorsRef.current = result.filterOperators
          setUserSegment(result.userSegment)
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() =>
    {
        AuthorizedGet('api/UserSegment/PrefKeySuggestions', props.history)
            .then(result =>
            {
                setUserPrefKeys(result)
                userPrefKeysRef.current = result
            });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const saveSegment = (values, actions) =>
    {
      AuthorizedPut(values, 'api/UserSegment', history)
      .then(result =>
      {
        actions.setSubmitting(false)
        if (result)
        {
          actions.setTouched({})
          setForceTouched(false)
        }
      })
    }

    const addFilter = (userPrefKey) => e =>
    {
      if (userSegment == null)
      {
        return
      }
      const copy = Object.assign({}, userSegment)
      const newFilter =
      {
        userPrefKey: userPrefKey ?? "",
        type: 2,
        filterValue: "",
        filterOperator: 0,
        asOr: false
      }

      copy.filters == null ? copy.filters = [newFilter] : copy.filters.push(newFilter)
      setUserSegment(copy)
      setForceTouched(true)
    }

    const removeFilter = (index) =>
    {
      if (userSegment == null || userSegment.filters == null || userSegment.filters.count < index)
      {
        console.log("error removing filter " + userSegment)
        return
      }
      console.log("will remove filter " + index)
      const copy = Object.assign({}, userSegment)

      const copyFilters = [...userSegment.filters]
      copyFilters.splice(index, 1)
      copy.filters = copyFilters
      setUserSegment(copy)
      setForceTouched(true)
    }

    const updateFilter = (index, newVersion) =>
    {
      const copySegment = Object.assign({}, userSegment);
      const copy = userSegment.filters.map(function(f, i) 
      { 
        if (i !== index)
        {
          return f;
        }
        return newVersion;
       })
       copySegment.filters = copy;
       setUserSegment(copySegment);
       setForceTouched(true);
    }

    const validate = (values, props /* only available when using withFormik */) =>
    {
      let errors = {}
      if (!values.name)
      {
        errors.name = "Segment name is required"
      }
      return errors
    }


    const validateUserPrefKey = (key) =>
    {
        if (!userPrefKeysRef.current)
        {
            return null
        }
        var fiteredResult = userPrefKeysRef.current.filter((pref) => pref.key === key)

        console.log(JSON.stringify(fiteredResult))
        if (fiteredResult.length > 0)
        {
            return fiteredResult[0].type
        }
        return null
    }


    // // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    if (userSegment === null)
    {
        return <Loading />
    }

    return (
        <div>
            <Formik
                enableReinitialize
                validate={validate}
                initialValues={userSegment}
                onSubmit={(values, actions) => saveSegment(values, actions)}
                render={({errors, touched, isSubmitting, setFieldValue}) => 
                    <Form>
                        <Card title="User Segment">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Id</label>
                                        <input className="form-control" value={userSegment.id} readOnly={true}/>
                                    </div>
                                </div>
                                <Field name="name" component={TextFieldInputColumn} labelText="Name" readOnly={false}
                                       errors={errors.name} column={9}/>
                            </div>
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <SubmitButton errors={errors} isSubmitting={isSubmitting} touched={touched}
                                                  forceTouched={forceTouched}/>
                                </div>
                            </div>
                        </Card>
                        <div>
                            <Card headerComponent={
                                <div className="row">
                                    <div className="col-md-10">
                                        <h3>
                                            Filters
                                        </h3>
                                    </div>
                                    <div className="col-md-2 text-end">
                                        <AddButton onClick={addFilter()} title="Add new filter"/>
                                    </div>
                                </div>
                            }>
                                {userSegment.filters && userSegment.filters.map((filter, index) =>
                                    <SegmentFilter key={index} index={index} prefix={"filters[" + index + "]."}
                                                   filter={filter}
                                                   removeFilter={removeFilter} updateFilter={updateFilter}
                                                   filterOperators={filterOperatorsRef.current}
                                                   errors={errors}
                                                   setFieldValue={setFieldValue}
                                                   validateUserPrefKey={validateUserPrefKey}/>
                                )}
                                <div className="row">
                                    <div className="col-md-12 text-center">
                                        <SubmitButton errors={errors} isSubmitting={isSubmitting} touched={touched}
                                                      forceTouched={forceTouched}/>
                                    </div>
                                </div>
                            </Card>
                        </div>
                        <ErrorDisplay errors={errors}/>
                    </Form>
                }
            />
            <br/>

            <Card title="Pref Key Samples">
                <PrefKeysSuggestions history={history} onAdd={addFilter} keys={userPrefKeys}/>
            </Card>
        </div>)
}
