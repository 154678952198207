import {useHistory} from "react-router-dom";
import NewsAlertForm from "./NewsAlertForm";
import {AuthorizedPost} from "../AuthorizedRequest";
import {useMemo} from "react";


export default function NewsAlertCreate()
{
    const newsAlert = useMemo(() => {
        let date = new Date();
        let dateUTC_MilliSeconds = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 0, 0, 0);
        let milliSecondsInADay = 1000 * 60 * 60 * 24;
        let endDate = (new Date(dateUTC_MilliSeconds + 7 * milliSecondsInADay)).toISOString(); //Default ending in a week

        return { endDate, json: '{}', enabled: true, userSegments: [] }}, []);
    
    const history = useHistory();
    
    const saveMethod = values => {
        return AuthorizedPost(values, 'api/NewsAlert', history)
            .then(result =>
            {
                if (result?.id)
                {
                    history.replace('/NewsAlerts/' + result.id);
                }
                return result;
            });
    };
    
    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <NewsAlertForm newsAlert={newsAlert} saveMethod={saveMethod} loading={false} />
    );
}