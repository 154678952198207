import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { AuthorizedGet } from '../AuthorizedRequest'
import { composeQueryString } from '../shared/Utils'
import { Card } from '../shared/Card'
import UsersList from './UsersList'
import UsersSearch from './UsersSearch'
import { usersSearchCriteriaObject } from './UsersSearchCriteria'
import MKPaginationUncounted, { mkPagedResultUncounted } from "../shared/MKPaginationUncounted"


export default function UserSelect({ onUserIdSelected, searchCriteriaObject })
{
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [initialValues, setInitialValues] = useState({ ...usersSearchCriteriaObject, ...{isDeleted: false}, ...searchCriteriaObject});
    const [pagedResult, setPagedResult] = useState(mkPagedResultUncounted);
    
    const searchUsers = (searchValues, formikBag = { setSubmitting: x => {} }, pageNumber = 1) => {
        searchValues.pageNumber = pageNumber;
        setLoading(true);
        setInitialValues(Object.assign({}, searchValues));
        AuthorizedGet('api/User/Search?' + composeQueryString(searchValues), history)
            .then(result => 
            {
                setPagedResult(result ?? mkPagedResultUncounted);
                formikBag.setSubmitting(false);
                setLoading(false);
            });
    };
    
    const onPageClick = (e, pageNumber) => searchUsers(initialValues, undefined, pageNumber);

    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const paginationComponent = !loading && <MKPaginationUncounted currentPageCount={pagedResult.currentPageCount} currentPage={pagedResult.pageNumber} onClick={onPageClick} itemsName="Users" />;

    return (
        <div>
            <Card>
                <UsersSearch handleSearch={searchUsers} initialValues={initialValues} />
            </Card>

            <Card>
                {paginationComponent}
                <UsersList users={pagedResult.items} onRowClick={onUserIdSelected} loading={loading} showButtons={false} />
                {paginationComponent}
            </Card>
        </div>
    )
}
