import React, {useRef, useState} from 'react'
import { useHistory } from 'react-router-dom'
import {AuthorizedGet, AuthorizedPostRaw} from '../AuthorizedRequest'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { UploadButton, UploadButtonText } from '../shared/Buttons'
import { Loading } from '../shared/Loading'
import {useToasts} from 'react-toast-notifications'

const fileLabelText = 'Choose Asset Bundles Zip File';
export const descriptionToolTip = 'Descriptive information to help understand bundles’ contents';

export default function AssetBundleUploadNew({onFileUploaded})
{
    const [uploading, setUploading] = useState(false);
    const [modal, setModal] = useState(false);
    const fileRef = useRef();
    const descriptionRef = useRef();
    const fileLabelRef = useRef();
    const history = useHistory();
    const toastsHook = useToasts();

    const toggleModal = () => setModal(!modal);

    const onFileChanged = e => {
        fileLabelRef.current.innerHTML = fileRef.current.value ?
            `<span class="font-weight-bold text-monospace">${fileRef.current.value.replace('C:\\fakepath\\', '')}</span>` :
            fileLabelText;
    };
    
    const uploadFileClick = e =>
    {
        e.preventDefault();
        if (!fileRef.current || fileRef.current.files.length === 0)
        {
            alert('Please select a file');
        }
        else
        {
            let formData = new FormData();
            formData.append('file', fileRef.current.files[0]);
            setUploading(true);
            AuthorizedPostRaw(formData,'api/AssetBundle/FileUpload?description=' + encodeURI(descriptionRef.current.value), history, undefined, null)  //'multipart/form-data')
                .then(result =>
                {
                    if (result)
                    {
                        var toastId;
                        toastsHook.addToast('Starting cloud upload process...', { appearance: 'info' }, x => toastId = x);
                        setTimeout(() => checkFileUploadStatus(result.processId, toastId, history, toastsHook), 1000);
                        onFileUploaded(result.bundleViews);
                        toggleModal();
                    }
                })
                .finally(() => setUploading(false));
        }
    };

    return (
        <>
            <UploadButton onClick={toggleModal} toolTip="Upload new Asset Bundles file (.zip format)"/>

            <Modal isOpen={modal} toggle={toggleModal} contentClassName="card">
                <ModalHeader toggle={toggleModal}>Upload new Asset Bundles file</ModalHeader>
                <ModalBody>
                    <div className="container ">
                        <div className="row">
                            <div className="col-md-12">
                                {uploading ?
                                    <>
                                        <label>[<i> Uploading file... </i>]</label>
                                        <Loading/>
                                    </>
                                    :
                                    <>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label title={descriptionToolTip}>Description</label>
                                                    <input ref={descriptionRef} className="form-control"
                                                           title={descriptionToolTip}></input>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="custom-file">
                                            <input type="file" className="custom-file-input" id="inputGroupFile"
                                                   accept=".zip, application/zip" ref={fileRef} onChange={onFileChanged}
                                                   style={{cursor: 'pointer', width: '100%'}}/>
                                            <label className="custom-file-label" htmlFor="inputGroupFile"
                                                   ref={fileLabelRef}>{fileLabelText}</label>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>                     
                </ModalBody>
                <ModalFooter style={{justifyContent: 'center'}}>
                    <UploadButtonText onClick={uploadFileClick} toolTip="Upload new Asset Bundles file (.zip format)" disabled={uploading}>
                        { uploading ? <>[ <i>Uploading...</i> ]</> : "Upload file"}
                    </UploadButtonText>
                </ModalFooter>
            </Modal>
        </>
    );
}


function checkFileUploadStatus(processId, toastId, history, toastsHook)
{
    AuthorizedGet('api/AssetBundle/FileUploadStatus/' + processId, history)
        .then(result =>
        {
            if (result)
            {
                if (result.finished)
                {
                    toastsHook.updateToast(toastId, { content: result.message, appearance: result.finishedOk ? 'success' : 'warning' });
                }
                else
                {
                    toastsHook.updateToast(toastId, { content: result.message });
                    setTimeout(() => checkFileUploadStatus(processId, toastId, history, toastsHook), 3000);    //Check again in 3 seconds
                }
            }
            else
            {
                toastsHook.updateToast(toastId, { content: 'Failed to get process status', appearance: 'error' });
            }
        });
}