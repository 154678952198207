import React from "react";
import {Card} from "../shared/Card";
import {Loading} from "../shared/Loading";
import {Field, Form, Formik} from "formik";
import {TextFieldInputColumn} from "../shared/FormElements";
import {AddButtonText, DeleteButton} from "../shared/Buttons";

export default function PlayFabServerInstanceCard({ instanceDetails, loading, onAddInstance, onDeleteInstance }) {
    
    const titleSuffix = instanceDetails?.regionName ? ` (${instanceDetails.regionName})` : "";
    
    return (
        <div>
            <div className="row align-items-center">
                <div className="col-md-9">
                    <h3>{`Instances${titleSuffix}`}</h3>
                </div>
                <div className="col-md-3 text-end">
                    {
                        instanceDetails &&
                        <AddButtonText disabled={!instanceDetails.canAddInstance} onClick={() => onAddInstance(instanceDetails.regionName)} />
                    }
                </div>
            </div>
            {
                loading &&
                <Loading />
            }
            {
                !loading &&
                <div className="row">
                    {
                        (instanceDetails && instanceDetails.instances.length > 0) &&
                        <>
                            {
                                instanceDetails.instances.map((instance, index) =>
                                    <Card key={index}>
                                        <div className="col-md-12 text-end">
                                            {
                                                instance.status === "Active" &&
                                                <DeleteButton onDelete={() => onDeleteInstance(instance.sessionId, instanceDetails.regionName)} />
                                            }
                                        </div>
                                        <Formik
                                            initialValues={instance}
                                            onSubmit={() => { /* Do nothing this is a readonly form */ }}
                                            enableReinitialize
                                        >
                                            <Form>
                                                <Field name="status" component={TextFieldInputColumn} labelText="Status" readOnly={true} column={12}/>
                                                <Field name="ipaddr" component={TextFieldInputColumn} labelText="IP Address" readOnly={true} column={12}/>
                                                <Field name="players" component={TextFieldInputColumn} labelText="Player Count" readOnly={true} column={12}/>
                                            </Form>
                                        </Formik>
                                    </Card>
                                )
                            }
                        </>
                    }
                    {
                        (instanceDetails && instanceDetails.instances.length === 0) &&
                        <div className="col-md-12 text-center">
                            <i>There are no Active Instances for this region.</i>
                        </div>
                    }
                    {
                        !instanceDetails &&
                        <div className="col-md-12 text-center">
                            <i>No Region selected, select (or create) one from the regions list.</i>
                        </div>
                    }
                </div>
            }
        </div>
    )
}