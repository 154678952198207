import React, {useState, useEffect} from 'react'
import {AuthorizedGet, AuthorizedPut} from '../../AuthorizedRequest'
import {useHistory} from 'react-router-dom'
import { Loading } from '../../shared/Loading'
import {Card} from "../../shared/Card";
import Toggle from "react-toggle";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useToasts} from "react-toast-notifications";


export default function AutoRefresh() 
{
    const [autoRefresh, setAutoRefresh] = useState(null);
    const history = useHistory();
    const { addToast } = useToasts()
    
    const errorMsj = '[ Error loading AutoRefresh configuration value ]';

    useEffect(() => {
        AuthorizedGet('api/ExternalConfiguration/AutoRefresh', history)
            .then(result => {
                setAutoRefresh(result ?? errorMsj);
            })
            .catch(e => {
                console.error(e);
                setAutoRefresh(errorMsj);
            });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    const TriggerRefresh_Click = e => {
        AuthorizedPut(null, 'api/ExternalConfiguration/TriggerRefresh')
            .then(result => {
                if (result)
                {
                    autoRefresh.autoRefreshSentinel = result.autoRefreshSentinel;
                    setAutoRefresh(Object.assign({}, autoRefresh));
                    let msg = <>Configuration refresh successfully scheduled.<br/>
                        The refresh will be triggered across all instances of API and Admin Panel between the next {autoRefresh.autoRefreshCacheExpirationMinutes} minutes.</>;
                    addToast(msg, {appearance: 'info', autoDismiss: true, autoDismissTimeout: 10000});
                }
            });
    };

    return (
        <Card headerComponent={<h5>Auto refresh</h5>}>            
            { autoRefresh ?
                <div className="row">
                    <div className="col-md-2">
                        <div className="form-group">
                            <label>Enabled</label>
                            <Toggle checked={autoRefresh.autoRefreshEnabled} icons={true} disabled={true} />
                            {/*<input className="form-control" value={autoRefresh.autoRefreshEnabled} readOnly={true}></input>*/}
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <label>Cache Expiration (Minutes)</label>
                            <input className="form-control" value={autoRefresh.autoRefreshCacheExpirationMinutes} readOnly={true}></input>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <label>Sentinel value</label>
                            <input className="form-control" value={autoRefresh.autoRefreshSentinel} readOnly={true}></input>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <label>&nbsp;</label>
                            <button type="button" style={{marginTop: '1px'}} className="btn btn-primary btn-sm" onClick={TriggerRefresh_Click}
                                    title="Trigger a configuration refresh">
                                <FontAwesomeIcon icon="sync"/>&nbsp;Trigger refresh
                            </button>
                        </div>
                    </div>
                </div>
                :
                <Loading />                
            }
        </Card>
        );
}
