import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {NavLink} from "react-router-dom"
import ContentCodes from "./Submenus/ContentCodes";
import RedeemableDetails from "./Submenus/RedeemableDetails";
import ContentCodeTemplates from "./Submenus/ContentCodeTemplates";
import SupportContentCodes from "./Submenus/SupportContentCodes";

const menuItems = [
    { route: "ContentCodes", name: "Content Codes" }, 
    { route: "SupportContentCodes", name: "Support Content Codes" }, 
    { route: "Templates", name: "Templates" }
]

export default function RedeemablesSubmenu(props) {
  

    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <div>

            <div className="row align-items-center">
                <div className="md-8 col">
                    <h3>&nbsp;Redeemables</h3>
                </div>
            </div>
            <br/>

            <div className="row">
                <div className="col-lg-2 col-md-12">
                    <div className="card card-plain">
                        <div className="card-content">
                            <ul className="nav nav-stacked" role="tablist">
                                {menuItems.map((item, index) => 
                                    <li key={index}>
                                        <NavLink to={"/Redeemables/" + item.route} className="nav-link"
                                                 activeClassName="active">
                                            <p>{item.name}</p>
                                        </NavLink>
                                    </li>)
                                }
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-lg-10 col-md-12">
                    <Switch>
                        <Route exact path='/Redeemables' component={() => <Redirect to="/Redeemables/ContentCodes"/>}/>
                        <Route exact path='/Redeemables/ContentCodes' component={ContentCodes}/>
                        <Route exact path='/Redeemables/ContentCodes/:contentCodeId'
                               component={() => <RedeemableDetails redirectPath={"/Redeemables/ContentCodes"}
                                                                   type={"contentCode"}/>}/>
                        <Route exact path='/Redeemables/SupportContentCodes' component={SupportContentCodes}/>
                        <Route exact path='/Redeemables/SupportContentCodes/:contentCodeId'
                               component={() => <RedeemableDetails redirectPath={"/Redeemables/SupportContentCodes"}
                                                                   type={"supportContentCode"}/>}/>
                        <Route exact path='/Redeemables/Templates/:id'
                               component={() => <RedeemableDetails redirectPath={"/Redeemables/Templates"}
                                                                   type={"template"}/>}/>
                        <Route exact path='/Redeemables/Templates' component={ContentCodeTemplates}/>
                    </Switch>
                </div>
            </div>
        </div>
    )
}
