import React, { useState } from 'react'
import { useHistory} from "react-router-dom"
import { AuthorizedPut } from '../AuthorizedRequest'
import { Loading } from '../shared/Loading'
import Toggle from 'react-toggle'
import SweetAlert from "react-bootstrap-sweetalert";

export default function VersionForceUpdate({ version, onUpdate, forceUpdateRequiresConfirmation })
{
    const [forceUpdate, setForceUpdate] = useState(version.forceUpdate);
    const [loading, setLoading] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const history = useHistory();
    
    const onForceUpdateChange = () => {
        if (forceUpdate || !forceUpdateRequiresConfirmation) {
            forceUpdateChange();
        } else {
            setShowConfirm(true);
        }
    }

    // Save ForceUpdate change
    const forceUpdateChange = () =>
    {
        setLoading(true);
        AuthorizedPut({ name: version.name, forceUpdate: !forceUpdate }, 'api/Version/ForceUpdateChange', history)
            .then(result =>
            {
                if (result != null)
                {
                    setForceUpdate(result.forceUpdate);
                    onUpdate(result);
                }
                setLoading(false);
            });
    };

    if (loading)
        return <Loading size="20" margin="0" loading={true} />

    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <>
            <Toggle checked={forceUpdate} icons={false} onChange={onForceUpdateChange} />
            {showConfirm &&
                <SweetAlert
                    btnSize="sm"
                    style={{fontSize: '1em'}}
                    // warning
                    showCancel
                    confirmBtnText="Force Update"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="secondary"
                    title={`Are you sure?`}
                    onConfirm={() => {
                        forceUpdateChange();
                        setShowConfirm(false);
                    }}
                    onCancel={() => setShowConfirm(false)}
                    focusCancelBtn
                    closeOnClickOutside={false}
                >
                    If this is the most recent build, turning on Force Update might cause the game to become unplayable. Are you sure?
                </SweetAlert>
            }    
        </>
    );
}