/*!
* Paper Dashboard React - v1.1.0
* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)
* Coded by Creative Tim
*/
import React, {useState, useEffect, useContext} from "react";
import { NavLink } from "react-router-dom";
import { Nav } from "reactstrap";
import { useLocation } from 'react-router-dom';
import authService from '../api-authorization/AuthorizeService';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Loading } from '../shared/Loading'
import ActiveModulesContext from "../../contexts/ActiveModulesContext";
import MKNetVersions from "./MKNetVersions";

export default function Sidebar(props)
{
    const { activeModules } = useContext(ActiveModulesContext);
    const location = useLocation();
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    function isActiveRoute(routeName)
    {
        return location.pathname.split('/').indexOf(routeName) === 1 ? "active" : "";
    }

    useEffect(() =>
    {
        (async () => {
            const auth = await authService.isAuthenticated();
            setIsAuthenticated(auth);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="sidebar" data-color={props.bgColor} data-active-color={props.activeColor}>
            <div className="logo">
                <NavLink to="/" className="simple-text logo-normal">MKNet</NavLink>
                <MKNetVersions/>
            </div>
            <div className="sidebar-wrapper">
                <PerfectScrollbar options={{ suppressScrollX: true,  suppressScrollY: false }}>
                    <Nav>
                        {isAuthenticated && activeModules ?
                            props.sideMenuItems.filter(x => x.coreModule || activeModules.includes(x.route)).map((item, key) =>
                                <li className={isActiveRoute(item.route) + (item.pro ? " active-pro" : "")} key={key}>
                                    <NavLink to={"/" + item.route} className="nav-link" activeClassName="active">
                                        <i className={item.icon}/>
                                        <p>{item.displayName}</p>
                                    </NavLink>
                                </li>)
                            :
                            <Loading/>
                        }
                    </Nav>
                </PerfectScrollbar>
            </div>
        </div>
    );
}
