import React from 'react'
import { Formik, Form } from 'formik'
import { SearchButton } from '../shared/Buttons'
import ReplaysSearchCriteria from './ReplaysSearchCriteria'

export default function ReplaysSearch({ initialValues, handleSearch })
{
    return (
        <Formik
            enableReinitialize
            //validate={validate}
            initialValues={initialValues}
            onSubmit={handleSearch} 
        >
            {({ errors, status, touched, isSubmitting }) => (
            <Form>
                <ReplaysSearchCriteria />
                <div className="row">
                    <div className="md-2 col"></div>
                    <div className="md-8 col text-center">
                        <SearchButton isSubmitting={isSubmitting} touched={touched} forceTouched={true} column={6} />
                    </div>
                    <div className="md-2 col"></div>
                </div>
            </Form>
            )} 
        </Formik>
    );
}