import React, {useState, useEffect, useRef} from 'react'
import {useHistory} from 'react-router-dom'
import {AuthorizedGet} from '../../AuthorizedRequest'
import {Card} from '../../shared/Card'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Button from 'react-bootstrap/Button'
import Dropdown from 'react-bootstrap/Dropdown'
import {Formik, Field, Form} from 'formik'
import {ErrorDisplay, TextAreaInput} from '../../shared/FormElements'
import {SendButton} from '../../shared/Buttons'
import {ButtonToolbar} from 'reactstrap'
import JSONKeyValueEditor from '../../shared/JSONKeyValueEditor'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import LanguageDropdownButton from "../../shared/LanguageDropdownButton";

export const SupportMessageEdit = (props) => {
    const [forceTouched, setForceTouched] = useState(false)
    const [contentCodeTemplates, setContentCodeTemplates] = useState(null)
    const [messageTemplates, setMessageTemplates] = useState(null)
    const [message, setMessage] = useState({message: ""});
    const [contentCodeJson, setContentCodeJson] = useState("");
    const [contentCodePrefix, setContentCodePrefix] = useState("");
    const [language, setLanguage] = useState(props.language);
    const contentCodeJsonRef = useRef(null)
    const history = useHistory()

    useEffect(() => {
        AuthorizedGet(`api/Support/Templates`, history)
            .then(result => {
                if (result) {
                    setContentCodeTemplates(result.contentCodeTemplates)
                    setMessageTemplates(result.messageTemplates.map(template => {
                        template.content = JSON.parse(template.content);
                        return template;
                    }))
                }
            })
    }, [history])

    const validate = (values, props) => {
        let errors = {}
        if (!values.message) {
            errors.message = "Message is required"
        }

        return errors
    }

    const onSubmitInternal = (values, actions) => {
        let cCodeJson = contentCodeJsonRef.current != null ? JSON.stringify(contentCodeJsonRef.current) : contentCodeJson
        const onSendOK = () => { actions.setSubmitting(false); onClear(actions.resetForm)(); }
        props.sendResponse(values.message, cCodeJson, contentCodePrefix, language, onSendOK)
    }

    const addContentCode = (template) => (e) => {
        setContentCodeJson(template.json)
        setContentCodePrefix(template.prefix)
        contentCodeJsonRef.current = null
        setForceTouched(true)
    }

    const removeContentCode = (e) => addContentCode({json: "", prefix: ""})()

    const addMessageTemplate = (template) => (e) => {
        const templateText = template.content[language] ? template.content[language] : template.content["English"]
        const newLineChar = message.message.length === 0 ? "" : "\n"
        const updatedMessage = `${message.message}${newLineChar}${templateText}`
        setMessage({message: updatedMessage})
        setForceTouched(true)
    }

    const contentCodeJsonOnChange = json => {
        contentCodeJsonRef.current = json
        setForceTouched(true)
    }

    const onClear = resetForm => (e) => {
        resetForm();
        setMessage({message: ""});
        removeContentCode();
        setForceTouched(false);
    }
    
    const onDiscard = resetForm => (e) => {
        onClear(resetForm)();
        props.toggleEdit();
    }

    return (
        <Card>
            <h5>New Response </h5>
            <ButtonToolbar>
                <LanguageDropdownButton language={language ? language : "English"} onChange={setLanguage}/>
                {contentCodeJson === "" ?
                <DropdownButton title="Add Content Code" size="sm" variant="secondary" drop="down" id="ddbContentCodeAdd">
                    {contentCodeTemplates && contentCodeTemplates.map((template, index) =>
                        <Dropdown.Item as="button" type="button" eventKey={index} onClick={addContentCode(template)}
                                       key={index}>{template.name}</Dropdown.Item>)
                    }
                </DropdownButton>
                :
                <Button size="sm" variant="secondary" onClick={removeContentCode}>Remove Content Code</Button>
                }
                <DropdownButton title="Add Message Template" size="sm" variant="secondary" drop="down" id="ddpMessageTemplateAdd">
                    {messageTemplates && messageTemplates.map((template, index) =>
                        <Dropdown.Item as="button" type="button" eventKey={index} 
                                       onClick={addMessageTemplate(template)}
                                       key={index}>{template.name}</Dropdown.Item>)
                    }
                </DropdownButton>
            </ButtonToolbar>

            <Formik
                enableReinitialize
                validate={validate}
                initialValues={message}
                onSubmit={onSubmitInternal}>
                {({handleSubmit, isSubmitting, errors, touched, setFieldTouched, resetForm}) =>
                    <Form onSubmit={handleSubmit}>
                        <Field name="message" component={TextAreaInput} labelText="Message"
                               errors={touched.message && errors.message}/>

                        {contentCodeJson &&
                        <>
                            <br/>
                            <h5>Content Code</h5>
                            <JSONKeyValueEditor jsonString={contentCodeJson} onChange={contentCodeJsonOnChange}/>
                        </>
                        }

                        <SendButton errors={errors} isSubmitting={isSubmitting} touched={touched} forceTouched={forceTouched}/>
                        <button type="button" className="btn btn-warning" onClick={onClear(resetForm)}>
                            <FontAwesomeIcon icon='i-cursor'/>
                            &nbsp;Clear
                        </button>
                        <button type="button" className="btn btn-warning" onClick={onDiscard(resetForm)}>
                            <FontAwesomeIcon icon='trash'/>
                            &nbsp;Discard
                        </button>
                        
                        <ErrorDisplay errors={errors} touched={touched}/>
                    </Form>}
            </Formik>
        </Card>
    )
}