import React, { useState } from 'react'
import { Formik, Field, Form } from 'formik'
import { Card } from '../shared/Card'
import { TextFieldInputColumn, ErrorDisplay } from '../shared/FormElements'
import { SubmitButton } from '../shared/Buttons'

export default function LeaderboardDetails({ saveMethod, leaderboard, editSoloEventId = false, title='Leaderboard Details', idLabel='Leaderboard Id' })
{
    const [forceTouched, /* setForceTouched */] = useState(false);

    const validate = (values, props /* only available when using withFormik */) =>
    {
        let errors = {}
        if (!values.name)
        {
          errors.name = "Name required"
        }
        if (Number.isInteger(parseInt(values.bucketSize)) === false || values.bucketSize <= 0)
        {
          errors.bucketSize = "Bucket must me a positive integer"
        }
        return errors
    }
    
    if (!leaderboard)
        return null
    
    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <div>
            <br/>
            <Formik
                enableReinitialize
                validate={validate}
                initialValues={leaderboard}
                onSubmit={saveMethod}>
                {({handleSubmit, isSubmitting, errors, touched, setFieldTouched}) =>
                    <Form onSubmit={handleSubmit}>
                        <Card title={title}>
                            <div className="row">
                                <Field name="id" component={TextFieldInputColumn} labelText={idLabel}
                                       readOnly={true} column={2}/>
                                <Field name="name" component={TextFieldInputColumn} labelText="Name*" 
                                       errors={errors.name} column={editSoloEventId || leaderboard.soloEventTemplateId ? 6 : 8}/>
                                { editSoloEventId &&
                                    <Field name="soloEventId" component={TextFieldInputColumn} type="number" labelText="Event Id"
                                           errors={errors.name} column={2}/>
                                }
                                { leaderboard.soloEventTemplateId &&
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label>Event Template Id</label>
                                            <input defaultValue={leaderboard.soloEventTemplateId} className="form-control" readOnly={true} />
                                        </div>
                                    </div>
                                }
                                <Field name="bucketSize" component={TextFieldInputColumn} labelText="Bucket Size"
                                       errors={errors.bucketSize} column={2}/>
                            </div>
                            <div className="row">
                                <h5 className="mt-3 mb-2" title="Maximum score to be considered “legitimate”, more than that will be considered as cheating">Cheating threshold</h5>
                                <Field name="scoreMaxThreshold" component={TextFieldInputColumn} labelText="Max Score"
                                       errors={errors.scoreMaxThreshold} type="number" column={2}/>
                                <Field name="scoreBaseMaxThreshold" component={TextFieldInputColumn} labelText="Max Score Base" toolTip="For Exponential based scores"
                                       errors={errors.scoreMaxThreshold} type="number" column={2}/>
                                <Field name="scoreExponentMaxThreshold" component={TextFieldInputColumn} labelText="Max Exponent" toolTip="For Exponential based scores"
                                       errors={errors.scoreExponentMaxThreshold} type="number" column={2}/>                                
                            </div>
                            <ErrorDisplay errors={errors}/>
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <SubmitButton errors={errors} isSubmitting={isSubmitting} touched={touched}
                                                  forceTouched={forceTouched}/>
                                </div>
                            </div>
                        </Card>
                    </Form>}
            </Formik>
        </div>
    )
}