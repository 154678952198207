import React, { useState, useEffect } from 'react'
import { AuthorizedGet, AuthorizedPut } from '../AuthorizedRequest'
import { useHistory } from 'react-router-dom'
import { Card } from '../shared/Card'
import TeamMembersList from './TeamMembersList'
import TotalCount from '../shared/TotalCount'
import teamMembershipStatus from './TeamMembershipStatus.json'
import TeamMembershipStatusEditor from "./TeamMembershipStatusEditor"
import { useToasts } from 'react-toast-notifications'
import { TeamMemberAdd, TeamMemberInvite } from "./TeamMemberAdd"

const specialItems = [
    {
        "key": -1,
        "value": "Active"
    },
    {
        "key": null,
        "value": "All"
    }];


export default function TeamMembersEdit({ teamId })
{
    const [loading, setLoading] = useState(true);
    const [membersList, setMembersList] = useState([]);
    const [currentKey, setCurrentKey] = useState(specialItems[0].key);
    const [title, setTitle] = useState('');
    const history = useHistory();
    const { addToast } = useToasts()

    // Fetch teams
    useEffect(() =>
    {
        fetchMembers(currentKey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ teamId ]);
    
    function fetchMembers(statusKey, statusValue, initialLoading = true)
    {
        setLoading(initialLoading);
        setCurrentKey(statusKey);
        setTitle((statusValue ?? specialItems.find(x => x.key === statusKey)?.value ?? teamMembershipStatus.find(x => x.key === statusKey).value) + ' members');
        
        let url = 'api/TeamMember/';
        
        switch(statusKey) {
            case -1:
                url += 'GetActives/' + teamId; 
                break;
            case null:
                url += 'search?teamId=' + teamId;
                break;
            default:
                url += `search?teamId=${teamId}&membershipStatus=${statusKey}`;
        }
        
        AuthorizedGet(url, history)
            .then(result =>
            {
                setMembersList(result ?? []);
                setLoading(false);
            });
    }
    
    const onMemberStatusChange = (index, newStatusKey) =>
    {
        AuthorizedPut(newStatusKey,`api/TeamMember/ChangeStatus/${teamId}/${membersList[index].userId}`, history)
            .then(result =>
            {
                if (result)
                {
                    let msg = <>Status successfully changed.<br />&nbsp;&nbsp;Previous status: {membersList[index].statusString}<br />&nbsp;&nbsp;New status: {result.statusString}</>;
                    addToast(msg, { appearance: 'success', autoDismiss: true, autoDismissTimeout: 10000 });
                    fetchMembers(currentKey, null, false);                    
                }
            });        
    };
    
    const onMemberInvitedOrAdded = (member, msj) =>
    {
        addToast(msj, { appearance: 'success', autoDismiss: true, autoDismissTimeout: 10000 });
        fetchMembers(currentKey, null, false);
    };

    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <Card headerComponent={
            <div className="row">
                <div className="col-md-8 col">
                    <h3>{title}</h3>
                </div>
                <div className="col-md-2 text-end">
                    <TeamMemberAdd teamId={teamId} onMemberAdded={onMemberInvitedOrAdded} />
                    <TeamMemberInvite teamId={teamId} onMemberInvited={onMemberInvitedOrAdded} />
                </div>
                <div className="col-md-2 text-end" title="List members with status...">
                    <div style={{ paddingRight: '10px' }}>
                        <TeamMembershipStatusEditor selectedKey={currentKey} specialItems={specialItems} onChange={fetchMembers} />
                    </div>
                </div>
            </div>
        }>
            <TeamMembersList teamMembers={membersList} loading={loading} onStatusChange={onMemberStatusChange} />
            { loading ||
            <div className="row">
                <div className="md-12 col d-flex align-items-center justify-content-end">
                    <TotalCount itemsName="Members">{membersList.length}</TotalCount>
                </div>
            </div>
            }
        </Card>
    )
}