import React, {useState, useEffect} from 'react'
import {AuthorizedGet, AuthorizedDelete} from '../AuthorizedRequest'
import {useHistory} from 'react-router-dom'
// import {TabControl} from '../shared/TabControl'
import {Card} from '../shared/Card'
import {MKPaginationWithTotal, mkPagedResult} from '../shared/MKPagination'
import LeaderboardsList from './LeaderboardsList'
import LeaderboarsSearch from './LeaderboarsSearch'
import {leaderboarsSearchCriteriaObject} from './LeaderboarsSearchCriteria'
import {
    composeQueryString,
    mapQueryStringValuesToObject,
    //useQueryStringAndSessionState,
    useCache
} from '../shared/Utils'
import {CollapseSearchButton, AddButtonText} from '../shared/Buttons'
import {Collapse} from 'reactstrap'
import leaderboardsTypes from './LeaderboardsTypes.json'

let pageSize = 20
const initialEmptyValues = Object.assign(leaderboarsSearchCriteriaObject, {pageSize: pageSize});

export default function Leaderboards(props) 
{
    let leaderboardType = props.type ?? leaderboardsTypes[0].Type;
    let title = leaderboardsTypes.find(x => x.Type === leaderboardType)?.Name ?? 'Leaderboards';
 
    let history = useHistory();
    const [loading, setLoading] = useState(true);
    const [initialValues, setInitialValues] = useCache('leaderboardsInitialValues_' + leaderboardType, initialEmptyValues); //useQueryStringAndSessionState(() => Object.assign({}, initialEmptyValues), history, history.location.search, "leaderboardsInitialValues_" + leaderboardType);
    const [pagedResult, setPagedResult] = useState(mkPagedResult);
    const [searchCollapsed, setSearchCollapsed] = useCache('leaderboardsSearchCollapsed', true);
    //const [leaderboardType, /*setLeaderboardType*/] = useCache('leaderboards.leaderboardType', leaderboardsTypes[0].Type);

    useEffect(() => {
        mapQueryStringValuesToObject(initialValues, history.location.search);
        searchLeaderboards(initialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const searchLeaderboards = (searchValues, formikBag = { setSubmitting: x => { }}, pageNumber = 1, type = leaderboardType) => {
        searchValues.pageNumber = pageNumber;
        setLoading(true);
        setInitialValues(Object.assign({}, searchValues));

        AuthorizedGet(`api/Leaderboard/Search/${type}?` + composeQueryString(searchValues), history)
            .then(result => {
                setPagedResult(result ?? mkPagedResult);
                formikBag.setSubmitting(false);
                setLoading(false);
            });
    };

    const onPageClick = (e, pageNumber) => searchLeaderboards(initialValues, undefined, pageNumber);

    const toggleSearch = e => {
        e.preventDefault();
        setSearchCollapsed(!searchCollapsed);
    };

    const createLeaderboard = () => {
        history.push('/Leaderboards/create/' + leaderboardType);
    };

    const onDelete = leaderboardId => () => {
        AuthorizedDelete(null, 'api/Leaderboard/' + leaderboardId, history)
            .then(data => {
                if (data) {
                    pagedResult.items = pagedResult.items.filter(s => s.id !== data.id);
                    pagedResult.itemsTotalCount--;
                    setPagedResult(Object.assign({}, pagedResult));
                }
            });
    };

    // const onTabChange = (name, key, index) => {
    //     setLeaderboardType(key);
    //     searchLeaderboards(initialValues, undefined, undefined, key);
    // }

    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <div>
            <div className="row align-items-center">
                <div className="md-8 col">
                    <h3>&nbsp;{title}</h3>
                </div>
                <div className="md-4 col text-end">
                    <AddButtonText onClick={createLeaderboard} toolTip="Create New Leaderboard">Create</AddButtonText>
                    &nbsp;
                    <CollapseSearchButton collapsed={searchCollapsed} onClick={toggleSearch}/>
                </div>
            </div>

            {/*<TabControl onChange={onTabChange} items={leaderboardsTypes.map(type => {*/}
            {/*    return {name: type.Name, key: type.Type, isActive: type.Type == leaderboardType};*/}
            {/*})}/>*/}
            {/*<br/>*/}

            <Collapse isOpen={!searchCollapsed}>
                <Card>
                    <LeaderboarsSearch initialValues={initialValues} handleSearch={searchLeaderboards}/>
                </Card>
            </Collapse>

            <Card>
                <LeaderboardsList leaderboards={pagedResult.items} loading={loading}
                                  showEventColumn={leaderboardType === 'Solo'}
                                  entityType={leaderboardType === 'Solo' ? 'SoloLeaderboard' : 'Leaderboard'}
                                  onDelete={onDelete} onRowClickURL={props.onRowClickURL}/>
                <MKPaginationWithTotal currentPage={pagedResult.pageNumber} total={pagedResult.pagesTotalCount}
                                       totalItems={pagedResult.itemsTotalCount} onClick={onPageClick}
                                       itemsName="Leaderboards"/>
            </Card>
        </div>
    )
}
