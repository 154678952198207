import React, { useState } from 'react'
import { useHistory} from "react-router-dom"
import { AuthorizedPut } from '../AuthorizedRequest'
import { Loading } from '../shared/Loading'
import Toggle from 'react-toggle'
//import {useToasts} from "react-toast-notifications";

export default function AssetBundleIsActive({ assetBundle, disabled, onUpdate = _ => {} })
{
    const [isActive, setIsActive] = useState(assetBundle.isActive);
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    //const toastsHook = useToasts();

    const isActiveChange = () =>
    {
        setLoading(true);
        AuthorizedPut(!isActive, `api/AssetBundle/IsActiveChange/${assetBundle.appVersion}/${assetBundle.bundleVersionInt}/${assetBundle.platform}`, history)
            .then(result =>
            {
                if (result != null)
                {
                    setIsActive(result.isActive);
                    //toast
                    onUpdate(result);
                }
                setLoading(false);
            });
    };

    if (loading)
        return <Loading size="20px" margin="0" loading={true} />

    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <Toggle disabled={disabled} checked={isActive} icons={false} onChange={isActiveChange} />
    );
}