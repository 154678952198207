import React from 'react'
import {Loading} from '../shared/Loading'
import {DeleteButton} from '../shared/Buttons'
import VersionForceUpdate from './VersionForceUpdate'

export default function VersionsList({versions, loading, onDelete, onUpdate, isFirstPage}) {

    if (loading)
        return <Loading/>

    return (
        <table className='table table-full-width table-striped table-hover'>
            <thead>
            <tr>
                <th>Name</th>
                <th>Force Update</th>
                <th/>
            </tr>
            </thead>
            <tbody>
            {versions.map((version, index) =>
                <tr key={index}>
                    <td><span className="text-monospace">{version.name}</span></td>
                    <td>
                        <VersionForceUpdate version={version}
                                            onUpdate={updatedVersion => onUpdate(updatedVersion, index)}
                                            forceUpdateRequiresConfirmation={isFirstPage && index === (0)}/>
                    </td>
                    <td className="text-end">
                        <DeleteButton onDelete={onDelete(version.name)} entityName="Version"/>
                    </td>
                </tr>
            )}
            </tbody>
        </table>
    );
}
