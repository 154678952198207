import React, { useState } from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {AuthorizedDownload} from "../../AuthorizedRequest"
import {useHistory} from "react-router-dom";

export default function UserPrefsDownload({ userId, savePending })
{
    const history = useHistory();
    const [ downloading, setDownloading ] = useState(false);
    
    const onFileDownloadClick = e => {
        e.preventDefault();
        if (!savePending || window.confirm('There are pending changes to User Prefs. \nIf you download now, those changes will not be reflected in the file. \nProceed anyway?'))
        {
            setDownloading(true);
            AuthorizedDownload(`api/User/GetUserPrefsPlistFile/${userId}`, userId + '.ppo', history)
                .finally(() => setDownloading(false));            
        }
    };

    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <button type="button" title="Download UserPrefs as a Property List (plist) XML file"
                className="btn btn-sm shadow-none btn-link m-0" disabled={downloading}
                style={downloading ? {cursor: 'wait'} : null}>
            <FontAwesomeIcon className="blue" icon="file-download" size="2x" onClick={onFileDownloadClick}/>
        </button>
    );
}