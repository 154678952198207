import React from 'react'
import { useHistory } from 'react-router-dom'
import { composeQueryString } from '../shared/Utils'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function SupportTicketsButton({searchObject})
{    
    let history = useHistory();
    
    const onClick = e =>
    {
        e.stopPropagation();
        const path = '/Support/Search?' + composeQueryString(searchObject);
        history.push(path);
    };

    return (
        <button title={"Support Tickets"} className="icon-button">
            <FontAwesomeIcon className="archived" size="2x" icon="comments" onClick={ onClick }/>
        </button>
    );
}