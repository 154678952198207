import React, {useEffect, useState} from "react";
import {Card} from "../shared/Card";
import {Link, useHistory} from "react-router-dom";
import {AuthorizedGet} from "../AuthorizedRequest";

export default function LeaderboardsCheating() {
    const [cheatingConfig, setCheatingConfig] = useState(null);
    const history = useHistory();

    useEffect(() => {
        AuthorizedGet('api/Leaderboard/CheatingConfiguration', history)
            .then(result => {
                setCheatingConfig(result);
            });
    }, []);

    return (
        <div>
            <div className="mb-4 row">
                <div className="md-12 col">
                    <h3>&nbsp;Leaderboards cheating</h3>
                </div>
            </div>

            <Card headerComponent={<h4 className="mt-1 mb-1">Users marked as cheaters</h4>}>
                <p>
                    Once a user is marked as a cheater, they will always be placed in the cheaters' leaderboard.<br/>
                    This option reaches all the leaderboards (actual, past and future).<br/>
                    At the moment of marking/unmaking a user as cheater, the <span
                    className="text-monospace">IsCheatingEntry</span> field of all the score entries for the user in
                    the <span className="text-monospace">LeaderboardEntry</span> table are marked accordingly.
                </p>
                <p>
                    <Link to={'/Users?isCheater=true'}>Check users marked as <i>cheaters</i></Link>
                </p>
            </Card>
            <span/>

            <Card headerComponent={<h4 className="mt-1 mb-1">Cheating thresholds</h4>}>
                <p>
                    Thresholds scores are set for each leaderboard.<br/>
                    Users with scores above the threshold will be excluded from the leaderboard's normal results and
                    they will be grouped in a separate leaderboard result only for cheaters.
                </p>
                <p>
                    <Link to={'/Leaderboards/General?hasScoreThreshold=true'}>Check Leaderboards with cheating
                        threshold</Link>
                </p>

                <Card headerComponent={<h4 className="mt-1 mb-1">Threshold reliefs</h4>}>
                    <p>These exceptions allow certain users to avoid being grouped as cheaters through the cheating thresholds.</p>

                    <Card headerComponent={<h5 className="mt-1 mb-1"
                                               title="Configuration Key: LeaderboardCheatingThresholdSpendersReliefHours">
                        Spenders relief</h5>}>
                        <p>This prevents users who have any IAP transactions within a certain period of time from being grouped as cheaters.</p>

                        <u>Current value</u>: <SpendersRelief
                        value={cheatingConfig?.leaderboardCheatingThresholdSpendersReliefHours}/>
                    </Card>
                    <span/>

                    <Card headerComponent={<h5 className="mt-1 mb-1"
                                               title="Configuration Key: LeaderboardCheatingThresholdOldUsersReliefHours">
                            Old users relief</h5>}>
                        <p>This prevents users whose accounts were created before a certain time from being grouped as cheaters.</p>

                        <u>Current value</u>: <OldUsersRelief
                        value={cheatingConfig?.leaderboardCheatingThresholdOldUsersReliefHours}/>
                    </Card>
                </Card>

            </Card>
        </div>
    );
}

function SpendersRelief({value})
{
    if (value === undefined)
        return <>(<i>loading</i>)</>

    if (value === -1)
        return <><span title="Value: -1"><b>DISABLED</b></span> (<i>functionality off</i>)</>
    
    if (value === 0)
        return <><span title="Value: 0"><b>ANY</b></span> (<i>users with transactions at any time will not be caught as cheaters</i>)</>
    
    return <><b>{value}</b> hours (<i>users with transactions in the last {value / 24} days will not be caught as cheaters</i>)</>
}

function OldUsersRelief({value})
{
    if (value === undefined)
        return <>(<i>loading</i>)</>

    if (value === 0)
        return <><span title="Value: -1"><b>DISABLED</b></span> (<i>functionality off</i>)</>

    return <><b>{value}</b> hours (<i>users who registered more than {value / 24} days ago will not be caught as cheaters</i>)</>
}