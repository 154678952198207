import React, { useState, useEffect } from 'react'
import { AuthorizedGet } from '../../AuthorizedRequest'
import { useHistory, useParams } from 'react-router-dom'
import { Card } from '../../shared/Card'
import { Loading } from '../../shared/Loading'
import { JSONEditorReadOnly } from '../../shared/JSONEditor'
import TransactionsButton from '../elements/TransactionsButton'
import SupportTicketsButton from "../../Support/SupportTicketsButton";

export default function ReceiptDetails(props)
{
    const [receipt, setReceipt] = useState(null)
    const [loading, setLoading] = useState(true)
    const history = useHistory()
    let hash = useParams().hash ?? props.hash

    // Fetch 
    useEffect(() =>
    {
        AuthorizedGet(`api/Receipt/${hash}`, history)
            .then(result => 
            {
                setReceipt(result)
                setLoading(false)
            });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    if (loading)
        return <Loading />
    
    if (!receipt)
        return null
    
    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <div>
            <br/>
            <Card headerComponent={
                <div className="row">
                    <div className="col-md-10">
                        <h3>
                            Receipt Details
                        </h3>
                    </div>
                    <div className="col-md-2 text-end">
                        { props.showSupportButton === false || 
                        <SupportTicketsButton searchObject={{ receiptId: receipt.hash }}/>}
                        { props.showTransactions === false ||
                        <>&nbsp;<TransactionsButton searchObject={{ receiptId: receipt.hash }} /></>}
                    </div>
                </div>
            }>
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group">
                            <label>Hash</label>
                            <input className="form-control" value={receipt.hash} readOnly={true}/>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Validation Result</label>
                            <input className="form-control" value={receipt.validationResult} readOnly={true}/>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Redeemed</label>
                            <input className="form-control" value={receipt.redeemed} readOnly={true}/>
                        </div>
                    </div>
                </div>
            </Card>
            <br/>
            <Card title="Receipt Data">
                <JSONEditorReadOnly valueString={receipt.data} /> 
                <br/>
            </Card>
        </div>
    )
}

