import React, { useState, useEffect } from 'react'
import { AuthorizedGet, AuthorizedDelete } from '../AuthorizedRequest'
import { useHistory } from 'react-router-dom'
import { Card } from '../shared/Card'
import ABTestsList from './ABTestsList'
import ABTestsSearch from './ABTestsSearch'
import { aBTestsSearchCriteriaObject } from './ABTestsSearchCriteria'
import { MKPaginationWithTotal, mkPagedResult } from '../shared/MKPagination';
import { composeQueryString, mapQueryStringValuesToObject, usePagedResultSessionState, useQueryStringAndSessionState, useCache } from '../shared/Utils'
import { CollapseSearchButton, AddButtonText } from '../shared/Buttons'
import { Collapse } from 'reactstrap'


export function useABTestsListCache()
{
    return usePagedResultSessionState('aBTests');
}


export default function ABTests(props)
{
    let history = useHistory();
    const initialEmptyValues = Object.assign({}, aBTestsSearchCriteriaObject);
    const [loading, setLoading] = useState(true);
    const [initialValues, setInitialValues] = useQueryStringAndSessionState(() => Object.assign({}, initialEmptyValues), history, history.location.search, 'aBTestsInitialValues');
    const [pagedResult, setPagedResult] = useABTestsListCache();
    const [searchCollapsed, setSearchCollapsed] = useCache('aBTestsSearchCollapsed', true);
    
    useEffect(() => {
        if (mapQueryStringValuesToObject(initialEmptyValues, history.location.search))   // There is allowed search parameters in QueryString
        {
            if (JSON.stringify(initialEmptyValues) !== JSON.stringify(initialValues))  // Those parameters means a different search tan the one saved in sessionStorage
                searchABTests(initialEmptyValues);
            else
                setLoading(false);
        } else {
            if (pagedResult.itemsTotalCount == null)
                searchABTests(initialValues);
            else
                setLoading(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    const searchABTests = (searchValues, formikBag = { setSubmitting: x => {} }, pageNumber = 1) => {
        searchValues.pageNumber = pageNumber;
        setLoading(true);
        setInitialValues(Object.assign({}, searchValues));
        AuthorizedGet('api/ABTest/Search?' + composeQueryString(searchValues), history)
            .then(result => 
            {
                setPagedResult(result ?? mkPagedResult);
                formikBag.setSubmitting(false);
                setLoading(false);
            });
    };

    const onPageClick = (e, pageNumber) => searchABTests(initialValues, undefined, pageNumber);
        
    const toggleSearch = e =>  {
        e.preventDefault();
        setSearchCollapsed(!searchCollapsed);
    };

    const deleteEntity = abTestId => () =>
    {
        AuthorizedDelete(null, `api/ABTest/${abTestId}`, history)
            .then(data =>
            {
                if (data)
                {
                    pagedResult.items = pagedResult.items.filter(function(s) { return s.id !== data.id });
                    pagedResult.itemsTotalCount--;
                    setPagedResult(Object.assign({}, pagedResult));
                }
                setLoading(false);
            });
    };
 
    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////   
    return (
        <div>
            <div className="row align-items-center">
                <div className="md-8 col">
                    <h3>&nbsp;A/B Tests</h3>
                </div>
                <div className="md-4 col text-end">
                    <AddButtonText onClick={_ => history.push('/ABTests/Create')} toolTip="Add New A/B Test">Add New</AddButtonText>
                    &nbsp;
                    <CollapseSearchButton collapsed={searchCollapsed} onClick={toggleSearch}/>
                </div>
            </div>

            <Collapse isOpen={!searchCollapsed}>
                <Card>
                    <ABTestsSearch handleSearch={searchABTests} initialValues={initialValues}/>
                </Card>
            </Collapse>

            <Card>
                <ABTestsList abTests={pagedResult.items} loading={loading} onDelete={deleteEntity}/>
                <MKPaginationWithTotal currentPage={pagedResult.pageNumber} total={pagedResult.pagesTotalCount} totalItems={pagedResult.itemsTotalCount} onClick={onPageClick} itemsName="A/B Tests"/>
            </Card>
        </div>
    );
}
