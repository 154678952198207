import React, {useRef} from 'react'
import { Formik, Field, Form } from 'formik'
import { Card } from '../shared/Card'
import {TextFieldInputColumn, ErrorDisplay, ToggleInput, ToggleInputManaged} from '../shared/FormElements'
import { DateTimeInput } from '../shared/MKDateTime'
import { JSONEditor } from '../shared/JSONEditor'
import { SubmitButton } from '../shared/Buttons'
import { Loading } from '../shared/Loading'
import UserSegmentsSelectList from './../UserSegments/UserSegmentsSelectList'
import {useToasts} from "react-toast-notifications";
import {useABTestsListCache} from "./ABTests";

export default function ABTestForm({abTest, saveMethod, loading})
{
    const jsonEdit = useRef()
    const userSegmentsEdit = useRef()
    const { addToast } = useToasts()
    const [,,refreshItem] = useABTestsListCache()

    // Save changes
    const saveEntity = (values, actions) => {

        let entityEdited = {
            ...values,
            json: jsonEdit.current ? JSON.stringify(jsonEdit.current) : abTest.json,
            userSegments: userSegmentsEdit.current ?? abTest.userSegments
        };
        
        saveMethod(entityEdited)
            .then(result => {
                actions.setSubmitting(false);
                if (result) {
                    refreshItem(result);
                    actions.setTouched({});
                    addToast(<>A/B Test <span className="font-monospace">Id={result.id}</span> saved correctly</>,
                        {appearance: 'success', autoDismiss: true, autoDismissTimeout: 10000});
                }
            });
    }

    const validate = (values, props) =>
    {
        let errors = {}
        if (!values.name)
        {
          errors.name = "Name required";
        }
        if (!values.json)
        {
          errors.json = "Json required";
        }
        if (!values.startDate)
        {
          errors.startDate = "Active From date required";
        }
        if (!values.endDate)
        {
          errors.endDate = "Active Till date required";
        }
        if (Number.isInteger(parseInt(values.groups)) === false || values.groups <= 0)
        {
          errors.groups = "Number of groups must be a positive integer";
        }
        
        return errors
    }

    const toggleSegment = segmentId =>
    {
        let uss = userSegmentsEdit.current ?? abTest.userSegments;

        let index = uss.findIndex(x => x.id == segmentId);
        if (index >= 0)
            uss.splice(index, 1);
        else
            uss.push({ id: segmentId });

        userSegmentsEdit.current = uss;
    }
    

    if (loading)
      return <Loading />

    if (!abTest)
      return null
    
    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <div>
            <br/>
            <Formik
                enableReinitialize
                validate={validate}
                initialValues={abTest}
                onSubmit={saveEntity}>
                {({handleSubmit, isSubmitting, errors, touched, setFieldTouched}) =>
                    <Form onSubmit={handleSubmit}>
                        <Card title="A/B Test Details">
                            <div className="row">
                                <Field name="id" component={TextFieldInputColumn} labelText="AB Test Id"
                                       placeholder="( Creating... )" readOnly={true} column={2}/>
                                <Field name="name" component={TextFieldInputColumn} labelText="Name*" autofocus={!abTest.id}
                                       maxLength={32} errors={errors.name} column={6}/>
                                <Field name="groups" component={TextFieldInputColumn} labelText="Number of Groups*"
                                       errors={errors.groups} type="number" column={2}/>
                                <Field name="enabled" component={ToggleInputManaged} labelText="Enabled" column={1}/>
                            </div>
                            <div className="row">
                                <Field name="maxUsers" component={TextFieldInputColumn} labelText="Maximum Users"
                                       errors={errors.maxUsers} type="number" column={3}/>
                                <Field name="assignedUsers" component={TextFieldInputColumn} readOnly={true} labelText="Assigned Users"
                                       type="number" column={3}/>
                                <Field name="startDate" component={DateTimeInput} useTime={true} labelText="Active From"
                                       errors={errors.startDate} column={3}/>
                                <Field name="endDate" component={DateTimeInput} useTime={true} labelText="Active Till"
                                       errors={errors.endDate} column={3}/>
                            </div>
                            <ErrorDisplay errors={errors}/>
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <SubmitButton errors={errors} isSubmitting={isSubmitting} touched={touched} />
                                </div>
                            </div>
                        </Card>
                        
                        <span />
                        <Card title="Json">
                            {abTest &&
                            <>
                                <JSONEditor valueString={abTest.json} maxLines={40}
                                            onChange={json => { jsonEdit.current = json; setFieldTouched('json'); }} />
                                <div className="row">
                                    <div className="col-md-12 text-center">
                                        <SubmitButton errors={errors} isSubmitting={isSubmitting} touched={touched} />
                                    </div>
                                </div>
                            </>
                            }
                        </Card>

                        <span />
                        <Card title="User Segments">
                            <UserSegmentsSelectList selectedSegments={abTest.userSegments}
                                                    onToggleSegment={sId => {
                                                        toggleSegment(sId);
                                                        setFieldTouched('userSegments');
                                                    }}/>
                        </Card>
                    </Form>
                }
            </Formik>
        </div>
    )
}