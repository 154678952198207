import React from 'react'
import {AddButton} from "../../shared/Buttons";
import userPrefsTypes from "./UserPrefsTypes.json"

/**
 * ToDo: use a custom component instead of prompt() for the Add functionality
 *       => a beta version is commented at the bottom of the file
 */
export default function UserPrefsAdd({ userPrefs, onUserPrefModified, onUserPrefAdded, metadataKeys, saving })
{
    const promptNewType = (msj = 'Enter new UserPref type (' + userPrefsTypes.map(x => `${x.key}=${x.value}`).join('; ') + ')',
                           defaultType = String(userPrefsTypes[0].key)) => {
        let newType = prompt(msj, defaultType);
        // eslint-disable-next-line eqeqeq
        if (newType && !userPrefsTypes.find(x => x.key == newType))
            newType = promptNewType(`The value “${newType}” is not valid.\n` + msj, defaultType);
        return newType;
    };

    const onAddClick = e => {
        e.preventDefault();
        let newKey = prompt('Enter new UserPref key');
        if (newKey)
        {
            if (metadataKeys.includes(newKey))
            {
                alert(`“${newKey}” is a MetaData reserved key and cannot be modified`);
            }
            else
            {
                let existingIndex = userPrefs.findIndex(x => x.key === newKey);
                if (existingIndex >= 0)
                {
                    let newValue = prompt(`A User Pref with key=“${newKey}” already exists; the value can be modified instead`, userPrefs[existingIndex].value);
                    if (newValue)
                    {
                        let newType = promptNewType(undefined, userPrefs[existingIndex].type);
                        if (newType)
                        {
                            userPrefs[existingIndex].value = newValue;
                            userPrefs[existingIndex].type = newType;
                            onUserPrefModified(existingIndex, true);
                        }
                    }
                }
                else
                {
                    let newValue = prompt(`Enter new UserPref value for “${newKey}”`);
                    if (newValue)
                    {
                        let newType = promptNewType();
                        if (newType)
                            onUserPrefAdded({key: newKey, value: newValue, type: newType});
                    }
                }
            }
        }
    };

    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <AddButton onClick={onAddClick} disabled={saving} toolTip="Add new User Pref" />
    );
}




// ================================================================================================================
//  Version using a custom popup (beta)
// ================================================================================================================


// import React, { useState } from 'react'
// import { useHistory } from 'react-router-dom'
// import { Formik, Field, Form } from 'formik'
// import { AuthorizedPost } from '../AuthorizedRequest'
// import { TextFieldInputColumn, ErrorDisplay } from '../shared/FormElements'
// import { DropdownInputColumn } from '../shared/FormElementsDropdowns'
// import { Button, Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap"
// import { BaseSubmitButton, AddButton } from '../shared/Buttons'
// import userPrefsTypes from "./UserPrefsTypes.json"
//
//
// export default function UserPrefsAddNew({ userId, onUserPrefChanged })
// {
//     const [isOpen, setIsOpen] = useState(false);
//     let history = useHistory();
//
//     const save = (values, actions) =>
//     {
//         AuthorizedPost(values, 'api/User/UserPref', history)
//             .then(result =>
//             {
//                 if (result)
//                 {
//                     onUserPrefChanged(result);
//                     toggleModal();
//                     actions.setSubmitting(false);
//                     actions.setTouched({});
//                     actions.resetForm();
//                 }
//             });
//     };
//
//     const validate = (values, props) =>
//     {
//         let errors = {}
//         if (!values.key)
//         {
//           errors.key = "Key is required";
//         }        
//         return errors
//     }
//
//     const toggleModal = () => setIsOpen(!isOpen);
//
//     // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//     return (
//         <>
//             <AddButton toolTip="Add new User Pref" onClick={toggleModal} />
//            
//             <Modal isOpen={isOpen} toggle={toggleModal} contentClassName="card" size="xl">
//                 <Formik
//                     enableReinitialize
//                     validate={validate}
//                     initialValues={{ userId: userId, type: 2, key: '', value: '' }}
//                     onSubmit={save}>
//                     {({ handleSubmit, isSubmitting, errors }) =>  
//                     <Form className="d-inline" onSubmit={handleSubmit}>
//                         <ModalHeader toggle={toggleModal}>Add new User Pref</ModalHeader>
//                         <ModalBody>
//                             <div className="row">
//                                 Adding a new preference to this users data. If user already has data for the provided key, existing data will be replaced.
//                             </div>
//                             <div className="row">
//                                 <Field name="type" component={DropdownInputColumn} valuesList={userPrefsTypes} labelText="Type" readOnly={false} column={4}/>
//                                 <Field name="key" autofocus={true} component={TextFieldInputColumn} labelText="Key" readOnly={false} errors={errors.key} column={4}/>
//                                 <Field name="value" component={TextFieldInputColumn} labelText="Value" readOnly={false} errors={errors.value} column={4}/>
//                             </div>
//                             <ErrorDisplay errors={errors} />
//                         </ModalBody>
//                         <ModalFooter>
//                             <Button color="secondary" onClick={() => setTimeout(toggleModal)} className="col-md-4">Cancel</Button>&nbsp;
//                             <BaseSubmitButton errors={errors} isSubmitting={isSubmitting} column="4" icon="save" text=" Add or Update" isSubmittingText=" Saving..." />
//                         </ModalFooter>
//                     </Form> }
//                 </Formik>
//             </Modal>
//         </>
//     )
// }