import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { AuthorizedGet } from '../AuthorizedRequest'
import { MKPaginationWithTotal, mkPagedResult } from '../shared/MKPagination'
import LeaderboardEntriesList from "./LeaderboardEntriesList";

export default function LeaderboardBucketEntries({ leaderboardId, resultType = "SoloGrouped", bucketNumber = 0, bucketName = "Leaderboard", entriesLoaded = null })
{
    const [pagedResult, setPagedResult] = useState(mkPagedResult);
    const [loading, setLoading] = useState(true);
    const history = useHistory()

    // Fetch
    useEffect(() =>
    {
        fetchEntries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [leaderboardId, bucketNumber]);

    const fetchEntries = (pageNumber = 1) =>
    {
        setLoading(true);
        AuthorizedGet(`api/Leaderboard/Entries/${leaderboardId}?type=${resultType}&bucketNumber=${bucketNumber}&pageNumber=${pageNumber}`, history)
            .then(result => 
            {
                //console.log(JSON.stringify(result))
                setPagedResult(result ?? mkPagedResult);
                if (entriesLoaded)
                    entriesLoaded(result.itemsTotalCount);
                setLoading(false);
            });
    };

    const onPageClick = (e, pageNumber) => fetchEntries(pageNumber);
    
    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <>
            <LeaderboardEntriesList entries={pagedResult.items} loading={loading}
                                    getPosition={index => (pagedResult.pageNumber - 1) * pagedResult.pageSize + index + 1}
                                    showBucketRow={false}/>
            <MKPaginationWithTotal currentPage={pagedResult.pageNumber} total={pagedResult.pagesTotalCount}
                                   totalItems={pagedResult.itemsTotalCount} onClick={onPageClick}
                                   itemsName={'Results for the ' + bucketName}/>
        </>
    );
}