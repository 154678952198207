import React, {useState} from "react";
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupButtonDropdown
} from "reactstrap";


export default function DropdownMenuInput({ selectedKey, selectedValue, itemsList, onChange = (key, value) => {}, readOnly = false })
{
    const [ dropdownOpen, setDropdownOpen ] = useState(false);

    const toggleDropDown = () => setDropdownOpen(!dropdownOpen);

    // eslint-disable-next-line eqeqeq
    const sValue = selectedValue ?? itemsList?.find(x => x.key == selectedKey)?.value;
    
    return (
        <InputGroup className="m-0">
            <Input value={sValue} readOnly={true} title={`${selectedKey}: ${sValue}`} />
            <InputGroupAddon addonType="append">
                <InputGroupButtonDropdown addonType="append" className="m-0" isOpen={dropdownOpen} toggle={toggleDropDown} disabled={readOnly}>
                    <DropdownToggle caret className="mt-0 ps-3 pe-3 inline-button" style={{height: '30px'}}/>
                    <DropdownMenu>
                        { itemsList?.map(x =>
                            <DropdownItem key={x.key} onClick={() => onChange(x.key, x.value)} title={`${x.key}: ${x.value}`}>
                                {x.value}
                            </DropdownItem>) ?? <></>
                        }
                    </DropdownMenu>
                </InputGroupButtonDropdown>
            </InputGroupAddon>
        </InputGroup>
    );
}