import React, { useState, useEffect } from 'react'
import { JSONEditorReadOnly } from '../shared/JSONEditor'
import { useHistory, useParams} from "react-router-dom"
import { AuthorizedGet } from '../AuthorizedRequest'
import { Card } from '../shared/Card'
import { Loading } from '../shared/Loading'
import { shortYearDateTime, getDateTimeToolTip } from '../shared/MKDateTime'

export default function GiftDetails(props)
{
    const [gift, setGift] = useState(null)
    const [loading, setLoading] = useState(true)
    let { id } = useParams()
    const history = useHistory()
    
    useEffect(() => 
    {
        AuthorizedGet(`api/Gift/${id}`, history)
            .then(result => 
            {
                setGift(result)
                setLoading(false)
            })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (loading)
        return <Loading />

    if (!gift)
        return null
    
    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <div>
            <br></br>
            <Card title="Gift Details"> 
                <div className="row">
                    <div className="col-md-4">
                        <div className="form-group">
                            <label>Id</label>
                            <input className="form-control" value={gift.id} readOnly={true}></input>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <label>Send time</label>
                            <input className="form-control" value={shortYearDateTime(gift.sendTime)} title={getDateTimeToolTip(gift.sendTime)} readOnly={true}></input>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <label>Category</label>
                            <input className="form-control" value={gift.category} readOnly={true}></input>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Receipt Id</label>
                            <input className="form-control" value={gift.recipientId} readOnly={true}></input>
                        </div>
                    </div>
                    <div className="col-md-6 well pull-right">
                        <div className="form-group">
                            <label>Sender Id</label>
                            <input className="form-control" value={gift.senderId} readOnly={true}></input>
                        </div>
                    </div>
                </div>
            </Card>
            <br></br>
            <Card title="JSON">
                <JSONEditorReadOnly valueString={gift.json} /> 
                <br></br>
            </Card>
        </div>
    )
}