import React, {useState} from 'react'
import {Formik, Field, Form} from 'formik'
import {Card} from '../shared/Card'
import {TextFieldInputColumn, ErrorDisplay} from '../shared/FormElements'
import {SubmitButton} from '../shared/Buttons'
import {DateTimeInput} from "../shared/MKDateTime";

export default function ApiKeyForm({apiKey, onSubmit}) {
    const [forceTouched, setForceTouched] = useState(false);
    const [keyValue, setKeyValue] = useState();
    const isCreate = !apiKey.id;
    
    const saveEntity = (values, actions) => {
        onSubmit(values)
            .then((result) => {
                actions.setSubmitting(false);
                actions.setTouched({});
                setForceTouched(false);
                if(result && result.key) {
                    setKeyValue(result.key);
                }
            })
            .catch(console.error);
    };

    const validate = ({name}, props) => {
        let errors = {};
        if (!name) {
            errors.name = "Name required";
        }
        
        return errors
    };
    
    return (
        <div>
            <br/>
            <Formik
                enableReinitialize
                validateOnChange
                validate={validate}
                initialValues={apiKey}
                onSubmit={saveEntity}>
                {({handleSubmit, isSubmitting, errors, touched, setFieldTouched}) =>
                    <Form onSubmit={handleSubmit}>
                        <Card title={isCreate ? "Create an API Key" : "API Key details"}>
                            <div className="row">
                                <Field name="id" component={TextFieldInputColumn} labelText="API Key Id"
                                       readOnly={true} column={4}/>
                                <Field name="name" component={TextFieldInputColumn} labelText="Name*" readOnly={false}
                                       errors={errors.name}
                                       column={4}/>
                                <Field name="expiresUTC" errors={errors.expiresUTC} component={DateTimeInput} useTime={true} labelText="Expires" column={4} />
                                <div>
                                    <Field name="key" errors={errors.key}>
                                        {({field, form}) => <TextFieldInputColumn form={form} field={{...field, value: keyValue || '***'}} labelText="Api Key" readOnly={true} column={4} toolTip="Copy this before navigating away from the page" />}
                                    </Field>
                                    <p className='text-black-50' style={{'fontSize': '12px'}}>Copy the API Key before navigating away from the page, it will only be displayed once.</p>    
                                </div>
                                
                            </div>
                            <ErrorDisplay errors={errors}/>
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <SubmitButton errors={errors} isSubmitting={isSubmitting} touched={touched}
                                                  forceTouched={forceTouched}/>
                                </div>
                            </div>
                        </Card>
                    </Form>
                }
            </Formik>
        </div>
    )
}