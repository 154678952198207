import React from 'react'
import {Link} from 'react-router-dom'

export const LabeledDetail = ({label, value, toolTip, columns = 3, ...props}) =>
{
    if (typeof props.path !== "undefined")
        value = <Link to={props.path} title={toolTip}>{value}</Link>;

    return ( 
        <div className={`col-md-${columns} col-md-offset-1`}>
            <p title={toolTip}><small><strong><label>{label}</label></strong>{value}</small></p>
        </div>
    );
}