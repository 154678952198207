import React, {useEffect, useState} from 'react'
import { Loading } from '../shared/Loading'
import ExponentNotation from "../shared/ExponentNotation";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import LeaderboardEntryBucketPopUp from "./LeaderboardEntryBucketPopUp";
import UserIdValue from "../Users/UserIdValue";
import {AuthorizedGet} from "../AuthorizedRequest";
import {useHistory} from 'react-router-dom'

export default function LeaderboardEntriesList({
                                                   entries,
                                                   loading,
                                                   showLeaderboardId = false,
                                                   showBucketRow = true,
                                                   showBucketButton = false,
                                                   getPosition = null
                                               }) 
{
    const [bucketDataEntryId, setBucketDataEntryId] = useState(null);
    const [showDynamicBucketNumber, setShowDynamicBucketNumber] = useState(false);
    
    const OpenBucketPopUp = entryId => e => {
        e.preventDefault();
        e.stopPropagation();
        setBucketDataEntryId(entryId);
    };
    
    if (loading)
    {
        return <Loading />
    }

    return (
        <>
            <LeaderboardEntryBucketPopUp leaderboardEntryId={bucketDataEntryId} clearLeaderboardEntryId={() => setBucketDataEntryId(null)} />

            <table className='table table-full-width table-striped table-hover'>
                <thead>
                    <tr>
                        { getPosition &&
                            <th>Position</th>
                        }
                        <th>Entry Id</th>
                        { showLeaderboardId &&
                            <th colSpan="2" title="Id / Name">Leaderboard</th>
                        }
                        <th>User Id</th>
                        { (showBucketRow || showBucketButton) &&
                            <th colSpan={showBucketButton && showBucketRow ? 2 : 1}>
                                <input type="checkbox" style={{cursor: 'pointer', display: 'inline'}}
                                       title="Display dynamic bucket number" checked={showDynamicBucketNumber}
                                       onChange={e => setShowDynamicBucketNumber(!showDynamicBucketNumber)}/>&nbsp;Bucket
                            </th>                            
                        }
                        <th>Score</th>
                        <th>Data</th>
                    </tr>
                </thead>
                <tbody>
                {entries.map((entry, index) =>
                    <tr key={entry.id}>
                        { getPosition &&
                            <td>{getPosition(index) + 'º'}</td>
                        }
                        <td>{entry.id}</td>
                        { showLeaderboardId &&
                            <>
                                <td title="Id">{entry.leaderboardId}</td>
                                <td title="Name">{entry.leaderboard?.name}</td>
                            </>
                        }                        
                        <td>
                            <UserIdValue userId={entry.userId} cheater={entry.isCheatingEntry}
                                         deleted={entry.user?.deleted}>{entry.userId}</UserIdValue>    
                        </td>
                        <BucketCell entry={entry} showBucketRow={showBucketRow} showBucketButton={showBucketButton}
                                    showDynamicBucketNumber={showDynamicBucketNumber}
                                    OpenBucketPopUp={OpenBucketPopUp}/>
                        <td>
                            <span style={{fontFamily: 'monospace'}}>
                                <ExponentNotation base={entry.scoreBase}
                                                  exponent={entry.scoreExponent}>{entry.score}</ExponentNotation>
                            </span>
                        </td>
                        <td>
                            <small><span style={{fontFamily: 'monospace'}}>{entry.data}</span></small>
                        </td>
                    </tr>)}
                </tbody>
            </table>
        </>
        );
}

function BucketCell({entry, showBucketRow, showBucketButton, showDynamicBucketNumber, OpenBucketPopUp})
{
    const [bucketNumberDisplay, setBucketNumberDisplay] = useState();
    const [bucketOriginal, setBucketOriginal] = useState();
    let history = useHistory();
    
    useEffect(() =>
    {
        if (showDynamicBucketNumber)
        {
            AuthorizedGet(`api/Leaderboard/EntryBucketNumber/${entry.id}`, history)
                .then(result =>
                {
                    if (result)
                    {
                        setBucketNumberDisplay(result.bucket.bucketName);
                        setBucketOriginal(result.bucketOriginal);
                    }
                });
        }
        else
        {
            setBucketNumberDisplay(entry.bucketType ? entry.bucketType : entry.bucketNumber);
            setBucketOriginal(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showDynamicBucketNumber]);
    
    return (
        <>    
            { showBucketRow &&
                <td title={bucketOriginal && ('Original bucket: ' + bucketOriginal.bucketName)}>
                    {bucketNumberDisplay}
                    {bucketOriginal && <sup>(#{bucketOriginal.bucketNumber})</sup>}
                </td>
            }
            { showBucketButton &&
                <td>
                    <button type="button" title="View Bucket data for the entry" className="icon-button">
                        <FontAwesomeIcon className="tasks" size="2x" icon="tasks" onClick={OpenBucketPopUp(entry.id)}/>
                    </button>
                </td>
            }
        </>
    );

    
}

