import React from 'react'
import { Container } from 'reactstrap'

export const Card = ({ title, children, headerComponent}) =>
(
    <div className="card">
        { (title || headerComponent) && 
        <div className="card-header">
            { title ? 
            <h3>{title}</h3>
            :
            headerComponent
            }
        </div>
        }
        <div className="card-body">
            <Container>
            {children}
            </Container>
        </div>
    </div>
)

export const CardContrast = (props) =>
(
    <div className="row">
        {props.isLeft && <div className="card bg-light col-md-10">
            {props.title && <div className="card-header"><h3>{props.title}</h3></div>}
            <div className="card-body">
                <Container>
                {props.children}
                </Container>
            </div>
        </div>}

        {props.isRight && <>
        <div className="col-md-2" />
        <div className="card bg-light col-md-10">
            {props.title && <div className="card-header"><h3>{props.title}</h3></div>}
            <div className="card-body">
                <Container>
                {props.children}
                </Container>
            </div>
        </div></>}

        {!props.isRight && !props.isLeft &&
        <div className="card bg-light col-md-12">
            {props.title && <div className="card-header"><h3>{props.title}</h3></div>}
            <div className="card-body">
                <Container>
                {props.children}
                </Container>
            </div>
        </div>}
    </div>
)