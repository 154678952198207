import React, {useEffect, useRef} from "react";


export default function UserPrefsFilter({uPrefs, setFilteredPrefs, disabled}) 
{
    const filterFields = useRef({});
    const filterAction = useRef(null);
    
    useEffect(() => filterActionReset, []);

    function filterActionReset() {
        if (filterAction.current)
        {
            clearTimeout(filterAction.current);
            filterAction.current = null;
        }
    }

    const filter = fieldName => e => {
        filterActionReset();
        filterFields.current[fieldName] = e.target.value;
        filterAction.current = setTimeout(filterSearch, 700);
    }

    const filterSearch = () => {
        filterAction.current = null;
        let entries = Object.entries(filterFields.current).filter(x => x[1]);
        if (entries.length > 0) {
            let filters = entries.map(entry => {
                const [fieldName, filterValue] = entry;
                let searchExp = filterValue.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); //Escaping RegEx special characters
                let regex = new RegExp(searchExp, "i");
                return uPref => regex.test(uPref[fieldName]);
            });
            setFilteredPrefs(uPrefs.filter(uPref => filters.every(f => f(uPref))));
        } else {
            setFilteredPrefs(null);
        }
    };

    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <div className="row mb-4">
            <div className="col-md-6">
                <div className="form-group">
                    <label>Search by Key</label>
                    <input className="form-control" onChange={filter('key')} placeholder="Type to search by Key" disabled={disabled}/>
                </div>
            </div>
            <div className="col-md-6">
                <div className="form-group">
                    <label>Search by Value</label>
                    <input className="form-control" onChange={filter('value')} placeholder="Type to search by Value" disabled={disabled}/>
                </div>
            </div>
        </div>
    );
}