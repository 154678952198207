import React from 'react'
import { Loading } from '../shared/Loading'
import {mapType} from './AuditLogTypeMap';
import {ShortYearDateTime} from "../shared/MKDateTime";

export default function AuditLogList({ auditLogs, loading, onClick }) {
    
    if (loading)
    {
        return <Loading loading={true} />
    }

    return (
        <table className='table table-full-width table-striped table-hover'>
            <thead>
                <tr>
                    <th>Date Time</th>
                    <th>Action</th>
                    <th>Entity Type</th>
                    <th>Entity Id</th>
                    <th>User involved</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {auditLogs.map((log, index) =>
                <tr className="tr-link" onClick={() => onClick(log.id)} key={index}>
                    <td><ShortYearDateTime>{log.timestamp}</ShortYearDateTime></td>
                    <td>{mapType(log.type)}</td>
                    <td>{log.entityName}</td>
                    <td>{log.entityId}</td>
                    <td>{log.adDisplayName}</td>
                </tr>
                )}
            </tbody>
        </table>
        );
}