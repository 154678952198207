import React from 'react'

export function formatSizeBytes(bytes, decimals = 2) 
{
    if (isNaN(bytes) || bytes < 0)
        return "N/A";

    if (bytes === 0) 
        return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = [' Bytes', ' KB', ' MB', ' GB', ' TB', ' PB', ' EB', ' ZB', ' YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return (bytes / Math.pow(k, i)).toFixed(dm) + sizes[i];
}

export default function SizeBytes({ children, decimals })
{
    return (
        <span id="SizeBytes" title={ children + ' Bytes'}>{formatSizeBytes(children, decimals)}</span>
    );
}