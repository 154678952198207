import React, {useRef, useState} from 'react'
import { useHistory } from 'react-router-dom'
import { AuthorizedPost } from '../../AuthorizedRequest'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { AddButtonText } from '../../shared/Buttons'
import { Loading } from '../../shared/Loading'
import { ErrorDisplay } from '../../shared/FormElements'
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";

export default function ContentCodeAddBulk({contentCodeTemplates, props})
{
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [template, setTemplate] = useState(null);
    const [errors, setErrors] = useState(null);
    const [codesGenerated, setCodesGenerated] = useState(null);
    const bulkNumber = useRef(null);
    const history = useHistory();

    const toggleModal = () => setModal(!modal);

    const addButtonClick = e =>
    {
        e.preventDefault();
        toggleModal();
    };

    
    const generateBulkContentCode = e => {
        e.preventDefault();
        setLoading(true);
        setCodesGenerated(null);
        let qty = bulkNumber.current.value;
        
        AuthorizedPost({qty, template}, 'api/Redeemable/GenerateBulk', history)
            .then(result => 
            {
                if (!result)
                {
                    setErrors({Error: "No response"});
                }
                else if (result && result.errorMessage)
                {
                    setErrors({Error: result.errorMessage});
                }
                else if (result)
                {
                    setCodesGenerated(result);
                }
            })
            .catch(error =>
            {
                setErrors({Error: error});
            })
            .finally(() => setLoading(false));
    };    


    return (
        <>
            <AddButtonText onClick={addButtonClick} toolTip="Bulk Generation">Add Bulk</AddButtonText>
            <Modal isOpen={modal} toggle={toggleModal} contentClassName="card">
                <ModalHeader toggle={toggleModal}>Add New Content Code</ModalHeader>
                <ModalBody>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <DropdownButton title={template ? template.name : "Use Template"} variant="primary" drop="down">
                                    {contentCodeTemplates && contentCodeTemplates.map((template, index) => <Dropdown.Item as="button" type="button" eventKey={index} onClick={() => setTemplate(template)} key={index}>{template.name}</Dropdown.Item>)}
                                </DropdownButton>
                                <label>Number of codes to generate</label>
                                <input type="number" className="form-control" name="bulkNumber" ref={bulkNumber} defaultValue={5} autoFocus disabled={loading}></input>
                            </div>
                        </div>
                    </div>
                    { codesGenerated &&
                    <>
                        <span className="text-success">Codes generated ({codesGenerated.length}): </span>
                        {codesGenerated.map(x =>
                            <><span className="text-secondary font-monospace">{x}</span><span className="text-primary">; </span></>
                        )}
                    </>}
                    <ErrorDisplay errors={errors}/>
                </ModalBody>
                <ModalFooter>
                    <Loading loading={loading}></Loading>
                    <Button color="secondary" onClick={() => { setCodesGenerated(null); toggleModal()}}>Cancel</Button>&nbsp;
                    <Button color="primary" onClick={generateBulkContentCode} disabled={loading}>Create</Button>
                </ModalFooter>
            </Modal>
        </>
    );
}