import React, { useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { Formik, Field, Form } from 'formik'
import { TextFieldInputColumn, ErrorDisplay } from '../shared/FormElements'
import { Card } from '../shared/Card'
import JSONKeyValueEditor from '../shared/JSONKeyValueEditor'
import { SubmitButton } from '../shared/Buttons'

export function configurationValidate(values, props)
{
    let errors = {};
    let appVersionRegEx = /(\d+)\.(\d+)\.(\d+)/;
    if (!appVersionRegEx.test(values.appVersion))
        errors.appVersion = "App Version invalid";
    return errors
}

export default function ConfigurationEdit({ configuration, saveAction, creating, byKey = false })
{
    const [configLocal, setConfigLocal] = useState(configuration);
    const configJsonObject = useRef(null);
    const [forceTouched, setForceTouched] = useState(false);
    const history = useHistory();


    const saveConfiguration = (values, actions) =>
    {
        if (configJsonObject.current != null)
            values.config = JSON.stringify(configJsonObject.current);

        saveAction(values, 'api/Configuration', history)
            .then(result =>
            {
                actions.setSubmitting(false);
                if (result)
                {
                    if (creating)
                    {
                        history.replace(`/configurations${byKey ? 'ByKey' : ''}/${result.packedAppVersion}/${result.configKey}`);
                    }
                    else
                    {                        
                        actions.setTouched({});
                        setConfigLocal(result); //To update autogenerated configVersion value
                    }
                }
            });
    };

    const onChangeConfigObject = jObj =>
    {
        configJsonObject.current = jObj
        setForceTouched(true)
    };

    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <>
            <br/>
            <Formik
                    enableReinitialize
                    validate={configurationValidate}
                    initialValues={configLocal}
                    onSubmit={saveConfiguration}
                >
                {({ errors, status, touched, isSubmitting }) => (
                    <Form>
                        <Card title="Configuration Details">
                            <div className="row">
                                { byKey ||
                                    <Field name="appVersion" component={TextFieldInputColumn} labelText="App version" column={3} autofocus={creating} readOnly={!creating} errors={touched.appVersion && errors.appVersion} />                                    
                                }
                                <Field name="configKey" component={TextFieldInputColumn} labelText="Config Key" maxLength={50} column={3} readOnly={!creating} errors={touched.configKey && errors.configKey} />
                                <Field name="configVersion" component={TextFieldInputColumn} labelText="Config Version" type="number" toolTip="(Autogenerated)" column={3} readOnly={!creating} />
                            </div>
                        </Card>
                        <span/>

                        <Card title="Configuration Values">
                            <JSONKeyValueEditor jsonString={configuration.config} onChange={onChangeConfigObject} />
                        </Card>
                        <span/>

                        <Card>                            
                            <div className="row">   
                                <div className="md-12 col text-center">
                                    <SubmitButton errors={errors} isSubmitting={isSubmitting} touched={touched} forceTouched={forceTouched} />
                                </div>
                            </div>
                        </Card>

                        <ErrorDisplay errors={errors} touched={touched} forceTouched={forceTouched} />

                    </Form>
                )} 
            </Formik>
        </>
    )
}
