import React, { useState } from 'react'
import {Redirect, Route, Switch, NavLink} from 'react-router-dom'
import Transactions from "./Submenus/Transactions";
import Receipts from "./Submenus/Receipts";
import TransactionDetails from "./Submenus/TransactionDetails";
import ReceiptDetails from "./Submenus/ReceiptDetails";

export default function IAPSubmenu(props)
{
    const [menuItems] = useState(["Transactions", "Receipts"])

    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <div>

            <div className="row align-items-center">
                <div className="md-8 col">
                    <h3>In-App Purchases</h3>
                </div>
            </div>
            <br/>
            
            <div className="row">
                <div className="col-lg-2 col-md-12">
                    <div className="card card-plain">
                        <div className="card-content">
                            <ul className="nav nav-stacked" role="tablist">
                                {menuItems.map((item, index) =>
                                    <li key={index}>
                                        <NavLink to={"/IAP/" + item} className="nav-link" activeClassName="active" >
                                            <p>{item}</p>
                                        </NavLink>
                                    </li>)
                                }
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-lg-10 col-md-12">
                    <Switch>
                        <Route exact path='/IAP' component={() => <Redirect to="/IAP/Transactions"/>} />
                        <Route exact path='/IAP/Transactions' component={Transactions} />
                        <Route exact path='/IAP/Transactions/:transactionId' component={TransactionDetails} />
                        <Route exact path='/IAP/Receipts/:hash' component={ReceiptDetails} />
                        <Route exact path='/IAP/Receipts' component={Receipts} />
                    </Switch>
                </div>
            </div>
        </div>
    )
}
