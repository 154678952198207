import React from 'react'
import { useHistory } from "react-router-dom"
import { Loading } from '../shared/Loading'
import { DeleteButton } from '../shared/Buttons'

export default function UserSegmentsList({ userSegments, loading, onDelete }) {
    
    const history = useHistory();

    const onRowClick = itemId => e => {
        e.stopPropagation();
        const path = `UserSegments/${encodeURIComponent(itemId)}`;
        history.push(path);
    };

    if (loading)
    {
        return <Loading loading={true} />
    }

    return (
        <table className='table table-full-width table-striped table-hover'>
            <thead>
                <tr>
                    <th>Id</th>
                    <th>Name</th>
                    <th>Filter Json</th>
                    <th/>
                </tr>
            </thead>
            <tbody>
                {userSegments.map((segment, index) =>
                <tr className="tr-link" onClick={onRowClick(segment.id)} key={index}>
                    <td>{segment.id}</td><td>{segment.name}</td><td>{segment.prettyFilterString}</td>
                    <td className="text-end">
                        <DeleteButton onDelete={onDelete(segment.id)} entityName="User Segment"/>
                    </td>
                </tr>
                )}
            </tbody>
        </table>
        );
}