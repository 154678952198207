import React from 'react'
import { useHistory } from "react-router-dom"
import { Loading } from '../shared/Loading'
import { DeleteButton } from '../shared/Buttons'
import { AuditLogButtonConditional } from "../AuditLog/AuditLogButton";
import {shortDateTimeUTC, ShortYearDateTimeUTC} from "../shared/MKDateTime";
import useSoloEventDurationType from "./SoloEventDurationType";

export default function SoloEventsList({ soloEvents, loading, onDelete }) {
    
    const history = useHistory();
    const durationType = useSoloEventDurationType();
    
    const onRowClick = itemId => e =>
    {
        e.stopPropagation();
        const path = `/SoloEvents/${encodeURIComponent(itemId)}`;
        history.push(path);
    }

    if (loading)
    {
        return <Loading loading={true} />
    }

    return (
        <table className='table table-full-width table-striped table-hover'>
            <thead>
                <tr>
                    <th>Id</th>
                    <th>Name</th>
                    <th title="Start Date and Time are shown in the UTC time zone">Start Date [UTC]</th>
                    <th title="CRON Schedule times are shown in the UTC time zone">CRON Schedule [UTC]</th>
                    <th title="UTC TimeZone">Last occurrence</th>
                    <th>Duration { durationType != null ? `(${durationType})` : "" }</th>
                    <th title="End Date and Time are shown in the UTC time zone">End Date [UTC]</th>
                    <th>Status</th>
                    <th></th>
                    { onDelete !== undefined &&
                    <th/>
                    }
                </tr>
            </thead>
            <tbody>
                {soloEvents?.map((event, index) =>
                <tr className="tr-link" onClick={onRowClick(event.id)} key={index}>
                    <td>{event.id}</td>
                    <td>{event.name}</td>
                    <td>{event.startDate ? 
                        shortDateTimeUTC(event.startDate) :
                        <span class="text-muted" title="Using Cron Schedule">[ N/A ]</span>}
                    </td>
                    <td>{event.startDate ?
                        <span class="text-muted" title="Using Start Date">[ N/A ]</span> : 
                        event.scheduleDescription}
                    </td>
                    <td><ShortYearDateTimeUTC>{event.lastOccurrence}</ShortYearDateTimeUTC></td>
                    <td>{event.duration}</td>
                    <td><ShortYearDateTimeUTC>{event.lastOccurrenceEnd}</ShortYearDateTimeUTC></td>
                    <td>{event.status}</td>
                    <td>
                        <AuditLogButtonConditional searchObject={{entityId: event.id, entityType: 'SoloEvent'}} />
                    </td>
                    { onDelete !== undefined &&
                    <td className="text-end">
                        <DeleteButton onDelete={onDelete(event.id)} entityName="Event"/>
                    </td>
                    }
                </tr>
                )}
            </tbody>
        </table>
        );
}