import React, { useRef } from 'react'
import { Formik, Field, Form } from 'formik'
import { JSONEditor } from '../shared/JSONEditor'
import { Card } from '../shared/Card'
import {TextFieldInputColumn, ErrorDisplay, ToggleInputManaged} from '../shared/FormElements'
import { SubmitButton } from '../shared/Buttons'
import { Loading } from '../shared/Loading'
import UserSegmentsSelectList from './../UserSegments/UserSegmentsSelectList'
import {useToasts} from "react-toast-notifications";
import {useTargetedOffersListCache} from "./TargetedOffers";


export default function TargetedOfferForm({targetedOffer, saveMethod, loading})
{
    const jsonEdit = useRef()
    const userSegmentsEdit = useRef()
    const { addToast } = useToasts()
    const [,,refreshItem] = useTargetedOffersListCache()
        
    // Save changes
    const saveEntity = (values, actions) => 
    {
        let entityEdited = {
            ...values,
            data: jsonEdit.current ? JSON.stringify(jsonEdit.current) : targetedOffer.data,
            userSegments: userSegmentsEdit.current ?? targetedOffer.userSegments
        };
        
        saveMethod(entityEdited)
            .then(result => {
                refreshItem(result);
                actions.setSubmitting(false);
                if (result) {
                    actions.setTouched({});
                    addToast(<>Targeted Offer <span className="font-monospace">Id={result.id}</span> saved correctly</>,
                        {appearance: 'success', autoDismiss: true, autoDismissTimeout: 10000});
                }
            });
    };

    const validate = (values, props) =>
    {
        let errors = {};
        if (!values.name)
        {
          errors.name = "Name required";
        }
        if (!values.data)
        {
          errors.data = "Data required";
        }
        return errors
    };

    const toggleSegment = segmentId =>
    {
        let uss = userSegmentsEdit.current ?? targetedOffer.userSegments;

        let index = uss.findIndex(x => x.id == segmentId);
        if (index >= 0)
            uss.splice(index, 1);
        else
            uss.push({ id: segmentId });

        userSegmentsEdit.current = uss;
    }
    

    if (loading)
      return <Loading />
    
    if (!targetedOffer)
      return null

    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <div>
            <br/>
            <Formik
                enableReinitialize
                validate={validate}
                initialValues={targetedOffer}
                onSubmit={saveEntity}>
                {({handleSubmit, isSubmitting, errors, touched, setFieldTouched}) =>
                    <Form onSubmit={handleSubmit}>
                        <Card title="Targeted Offers Details">
                            <div className="row">
                                <Field name="id" component={TextFieldInputColumn} labelText="Targeted Offer Id"
                                       placeholder="( Creating... )" readOnly={true} column={3}/>
                                <Field name="name" component={TextFieldInputColumn} labelText="Name*" readOnly={false}
                                       autofocus={!targetedOffer.id}  errors={errors.name} column={8}/>
                                <Field name="enabled" component={ToggleInputManaged} labelText="Enabled" column={1}/>
                            </div>
                            <ErrorDisplay errors={errors}/>
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <SubmitButton errors={errors} isSubmitting={isSubmitting} touched={touched} />
                                </div>
                            </div>
                        </Card>

                        <span/>
                        <Card title="Data">
                            {targetedOffer &&
                            <JSONEditor
                                valueString={targetedOffer.data}
                                onChange={json => { jsonEdit.current = json; setFieldTouched('data'); }}/>}
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <SubmitButton errors={errors} isSubmitting={isSubmitting} touched={touched} />
                                </div>
                            </div>
                        </Card>

                        <span/>
                        <Card title="User Segments">
                            <UserSegmentsSelectList selectedSegments={targetedOffer.userSegments}
                                                    onToggleSegment={sId => {
                                                        toggleSegment(sId);
                                                        setFieldTouched('userSegments');
                                                    }} />
                        </Card>
                    </Form>
                }
            </Formik>
        </div>
    )
}