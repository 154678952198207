import React, {useState, useEffect} from 'react'
import {AuthorizedGet} from '../../AuthorizedRequest'
import {useHistory} from 'react-router-dom'
import { Loading } from '../../shared/Loading'
import {Card} from "../../shared/Card";
import {JSONEditorReadOnly} from "../../shared/JSONEditor";


export default function RateLimiting() 
{
    const [rateLiming, setRateLimiting] = useState(null);
    const history = useHistory();
    
    const errorMsj = '[ Error loading RateLimiting configuration value ]';

    useEffect(() => {
        AuthorizedGet(`api/ExternalConfiguration/ClientRateLimiting`, history)
            .then(result => {
                setRateLimiting(result ?? errorMsj);
            })
            .catch(e => {
                console.error(e);
                setRateLimiting(errorMsj);
            });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Card headerComponent={<h5>Rate Limiting</h5>}>
            { rateLiming ?
                <JSONEditorReadOnly maxLines={40} value={rateLiming} navigationBar={false} />
                :
                <Loading />                
            }
        </Card>
        );
}
