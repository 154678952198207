import React from 'react'
import { Field } from 'formik'
import { TextFieldInputColumn } from '../shared/FormElements'
import { mkPaginationObject } from '../shared/MKPagination'

export const userSegmentsSearchCriteriaObject = Object.assign({
    id : '',
    name : '',
    targetedOfferId : '', 
    targetedOfferName : '',
    aBTestId : '',
    aBTestName : '',
    newsAlertId : '',
    newsAlertName : ''
}, mkPaginationObject);

export default function UserSegmentsSearchCriteria(props)
{
    return (
        <>
            <div className="row">
                <Field name="id" component={TextFieldInputColumn} labelText="Id" type="number" column={4}/>
                <Field name="name" component={TextFieldInputColumn} labelText="Name" column={8}/>
            </div>
            <div className="row">
                <Field name="targetedOfferId" component={TextFieldInputColumn} labelText="Targeted Offer Id" type="number" column={2}/>
                <Field name="targetedOfferName" component={TextFieldInputColumn} labelText="Targeted Offer Name" column={2}/>
                <Field name="aBTestId" component={TextFieldInputColumn} labelText="ABTest Id" type="number" column={2}/>
                <Field name="aBTestName" component={TextFieldInputColumn} labelText="ABTest Name" column={2}/>
                <Field name="newsAlertId" component={TextFieldInputColumn} labelText="News Alert Id" type="number" column={2}/>
                <Field name="newsAlertName" component={TextFieldInputColumn} labelText="News Alert Name" column={2}/>
            </div>
        </>
    );
}