import React, { useState } from 'react'
import { SearchButtonIcon } from '../shared/Buttons'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import LeaderboardEntries from "./LeaderboardEntries";

export default function LeaderboardEntriesSearchPopUp({leaderboard})
{
    const [modal, setModal] = useState(false);

    const toggleModal = e => {
        e.stopPropagation();
        setModal(!modal);
    };

    return (
        <>
            <SearchButtonIcon onClick={toggleModal} toolTip="Search in leaderboard's entries" extraClassName="mt-0"/>
            <Modal isOpen={modal} toggle={toggleModal} contentClassName="card" size="xl">
                <ModalHeader toggle={toggleModal}>
                    Search for Leaderboard entries
                </ModalHeader>
                <ModalBody>
                    <LeaderboardEntries leaderboard={leaderboard} showHeader={false} />
                </ModalBody>
            </Modal>
        </>
    );
}