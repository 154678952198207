import React, { useEffect } from 'react'
import { FlexiDateTime } from '../../shared/MKDateTime'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AuthorizedPut } from '../../AuthorizedRequest'
import { useHistory } from 'react-router-dom'
import { Severity } from "./Severity";
import {useSupportTicketLocationState} from "./SupportTicketsNavigator";


export const SupportTicketRow = ({index, ...props}) =>
{
    const [supportTicket, setSupportTicket] = useSupportTicketLocationState(props.supportTicket)
    const history = useHistory()

    useEffect(() =>
    {
        setSupportTicket(props.supportTicket);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.supportTicket])

    const markAs = (e, endPoint) => {
        e.stopPropagation()
        AuthorizedPut(null, `api/Support/${endPoint}/${supportTicket.id}`, history)
            .then(result =>
            {
                if (result)
                {
                    setSupportTicket(result)
                }
            })
    }
    
    const markAsRead = (e) =>
    {
        markAs(e, 'MarkAsRead')
    }

    const markAsUnread = (e) =>
    {
        markAs(e, 'MarkAsUnread')
    }
    
    const onRowClick = (e) =>
    {
        props.onRowClick(supportTicket)
    }
    
    function updateSeverity(newValue)
    {
        const copy = Object.assign({}, supportTicket)
        copy.severity = newValue
        setSupportTicket(copy)
    }

    const toggleArchived = (e) =>
    {
        e.stopPropagation()
        props.toggleArchived(supportTicket)
    }

    let rowClassName = 'tr-link'
    if (supportTicket.status === "Open" || supportTicket.status === "Reopened")
        rowClassName += " unread"
    else if (supportTicket.status === "Deleted")
        rowClassName += " deleted"
    else if (supportTicket.status === "Closed")
        rowClassName += " archived"
    
    return (
        <>
            <tr key={index+"_1"} className={rowClassName} onClick={onRowClick}>
                <td className="text-center">
                    <Severity supportTicketId={supportTicket.id} severity={supportTicket.severity} updateSeverity={updateSeverity}/>
                </td>
                <td title={'Status: ' + supportTicket.status}>
                    {supportTicket.id}
                </td>
                <td>
                    {supportTicket.message}
                </td>
                <td className="text-end" style={{minWidth:'100px'}}>
                    <FlexiDateTime>{supportTicket.timeStamp}</FlexiDateTime>
                </td>
                <td className="td-actions text-end">
                    {(supportTicket.status === "Open" || supportTicket.status === "Reopened") && 
                    <>
                        <button className='btn no-margin shadow-none btn-link' title="Mark as Read" onClick={markAsRead}>
                            <FontAwesomeIcon icon='envelope-open' />
                        </button>
                        <button className='btn no-margin shadow-none btn-link' title="Archive" onClick={toggleArchived}>
                            <FontAwesomeIcon icon='archive' />
                        </button>
                    </>
                    }        
                    {supportTicket.status === "InProgress" && 
                    <>
                        <button className='btn no-margin shadow-none btn-link' title="Mark as Unread" onClick={markAsUnread}>
                            <FontAwesomeIcon icon='envelope' />
                        </button>
                        <button className='btn no-margin shadow-none btn-link' title="Archive" onClick={toggleArchived}>
                            <FontAwesomeIcon icon='archive' />
                        </button>
                    </>
                    }        
                    {(supportTicket.status === "Closed" || supportTicket.status === "Deleted") &&
                    <button className='btn no-margin shadow-none btn-link' title="Send to Inbox" onClick={toggleArchived}>
                        <FontAwesomeIcon icon='inbox' />
                    </button>
                    }        
                </td>
            </tr>
            {supportTicket.messages && supportTicket.messages.length > 0 &&
            <tr key={index+"_2"} className={rowClassName} onClick={(e) => props.onRowClick(supportTicket)}>
                <td className="re-message" colSpan="2"/>
                <td className="re-message">{supportTicket.messages[supportTicket.messages.length - 1].content}</td>
                <td className="re-message text-end">
                    <FlexiDateTime>{supportTicket.messages[supportTicket.messages.length - 1].createdAt}</FlexiDateTime>
                </td>
                <td className="re-message"/>
            </tr>}
        </>
    )
}