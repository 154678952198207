import React, { Component, Suspense, lazy } from 'react'
import { ToastProvider } from 'react-toast-notifications'
import { Route, Switch } from 'react-router-dom'
import Layout from './components/Layout/Layout'
import Home from './components/Home'
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute'
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes'
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants'
import Analytics from './components/Analytics/Analytics'
import Replays from './components/Replays/Replays'
import ReplayDetails from './components/Replays/ReplayDetails'
import UserSegments from './components/UserSegments/UserSegments'
import UserSegmentDetails from './components/UserSegments/UserSegmentDetails'
import Users from './components/Users/Users'
import UserDetails from './components/Users/UserDetails'
import UserPrefsBackup from "./components/Users/UserPrefs/UserPrefsBackup";
import Teams from './components/Teams/Teams'
import TeamCreate from './components/Teams/TeamCreate'
import TeamDetails from './components/Teams/TeamDetails'
import Versions from './components/Versions/Versions'
import Configurations from './components/Configurations/Configurations'
import ConfigurationCreate from './components/Configurations/ConfigurationCreate'
import ConfigurationDetails from './components/Configurations/ConfigurationDetails'
import ConfigurationsByKey from "./components/Configurations/ConfigurationsByKey";
import ConfigurationByKeyCreate from "./components/Configurations/ConfigurationByKeyCreate";
import ConfigurationByKeyDetails from "./components/Configurations/ConfigurationByKeyDetails";
import AssetBundles from './components/AssetBundles/AssetBundles'
import AssetBundleDetails from './components/AssetBundles/AssetBundleDetails'
import SoloEventsSubmenu from "./components/SoloEvents/SoloEventsSubmenu";
import Gifts from './components/Gifts/Gifts'
import GiftDetails from './components/Gifts/GiftDetails'
import Developer from './components/Developer/Developer'
import LeaderboardsSubmenu from "./components/Leaderboards/LeaderboardsSubmenu";
import ABTests from './components/ABTests/ABTests'
import ABTestCreate from './components/ABTests/ABTestCreate'
import ABTestEdit from './components/ABTests/ABTestEdit'
import TargetedOffers from './components/TargetedOffers/TargetedOffers'
import TargetedOfferCreate from './components/TargetedOffers/TargetedOfferCreate'
import TargetedOfferEdit from './components/TargetedOffers/TargetedOfferEdit'
import NewsAlerts from "./components/NewsAlerts/NewsAlerts";
import NewsAlertCreate from "./components/NewsAlerts/NewsAlertCreate";
import NewsAlertEdit from "./components/NewsAlerts/NewsAlertEdit";
import Dashboard from './components/Dashboard/Dashboard'
import SupportSubmenu from './components/Support/SupportSubmenu'
import IAPSubmenu from "./components/IAP/IAPSubmenu"
import RedeemablesSubmenu from "./components/ContentCodes/RedeemablesSubmenu"
import PlayFab from "./components/PlayFab/PlayFab";
import PlayFabDetails from "./components/PlayFab/PlayFabDetails";
import PlayFabCreate from "./components/PlayFab/PlayFabCreate";
import Currencies from "./components/Inventory/Currencies";
import CreateCurrency from "./components/Inventory/CreateCurrency";
import EditCurrency from "./components/Inventory/EditCurrency";
import ApiKeys from "./components/ApiKeys/ApiKeys";
import CreateApiKey from "./components/ApiKeys/CreateApiKey";
import EditApiKey from "./components/ApiKeys/EditApiKey";
import AnalyticsStream from "./components/AnalyticsStream/AnalyticsStream"
import AuditLog from "./components/AuditLog/AuditLog";
import AssetBundleDiff from "./components/AssetBundles/AssetBundleDiff";

import {Loading} from "./components/shared/Loading";
import {ActiveModulesProvider} from "./contexts/ActiveModulesContext";

//font awesome library
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faHome, faFlask, faUsers, faUserPlus, faUserTag, faGift, faListOl, faThList, faCalendar, faInfoCircle,
  faShieldAlt, faStar, faAngleUp, faAngleDown, faEdit, faAngleDoubleRight, faAngleRight,
  faSave, faPlus, faTrash, faSpinner, faCopy, faCaretDown, faPause, faPlay, faBackward, faForward,
  faEnvelopeOpen, faEnvelope, faArchive, faInbox, faMeh, faAngry, faTired, faSearch, faConciergeBell, faAt, faReply,
  faComment, faComments, faDollarSign, faFileInvoiceDollar, faQuestionCircle, faAppleAlt,
  faUpload, faFile, faFileDownload, faFileUpload, faSync, faICursor,
  faUserEdit, faClone, faTimes, faTasks, faChartBar, faPenSquare, faCog, faRetweet
} from '@fortawesome/free-solid-svg-icons'

import { faAppStore, faGooglePlay, faAndroid, faWindows, faApple } from '@fortawesome/free-brands-svg-icons'

library.add(faHome, faFlask, faUsers, faUserPlus, faUserTag, faGift, faListOl, faThList, faCalendar, faInfoCircle,
  faShieldAlt, faStar, faAngleUp, faAngleDown, faEdit, faAngleDoubleRight, faAngleRight, 
  faSave, faPlus, faTrash, faSpinner, faCopy, faCaretDown, faPause, faPlay, faBackward, faForward, 
  faEnvelopeOpen, faEnvelope, faArchive, faInbox, faMeh, faAngry, faTired, faSearch, faConciergeBell, faAt, faReply,
  faComment, faComments, faDollarSign, faFileInvoiceDollar, faQuestionCircle, faAppleAlt, 
  faUpload, faFile, faFileDownload, faFileUpload, faSync, faICursor, faUserEdit, faClone,
  faAppStore, faGooglePlay, faAndroid, faWindows, faApple, faFile, faTimes, faTasks, faChartBar, faPenSquare, faCog, faRetweet)


const OpenApi = lazy(() => import('./components/OpenApi/OpenApi'))
const Inventory = lazy(() => import('./components/Inventory/Inventory'))


export default class App extends Component {
  static displayName = App.name;
  
  render () {
    return (
      <ToastProvider autoDismissTimeout={10000}>
        <ActiveModulesProvider>
          <Layout>
            <Suspense fallback={<Loading/>}>
              <Switch>
                <AuthorizeRoute exact path='/' component={Home} />
                <AuthorizeRoute exact path='/openapi/:localhost' component={OpenApi} />
                <AuthorizeRoute exact path='/openapi' component={OpenApi} />
                <AuthorizeRoute exact path='/Analytics' component={Analytics} />
                <AuthorizeRoute exact path='/replays/:name/:version/:userId' component={ReplayDetails}/>
                <AuthorizeRoute exact path='/replays' component={Replays} />
                <AuthorizeRoute exact path='/usersegments/:id' component={UserSegmentDetails}/>
                <AuthorizeRoute exact path='/usersegments' component={UserSegments} />
                <AuthorizeRoute exact path='/users' component={Users} />
                <AuthorizeRoute exact path='/users/:userId' component={UserDetails} />
                <AuthorizeRoute exact path='/users/:userId/UserPrefsBackup' component={UserPrefsBackup} />
                <AuthorizeRoute exact path='/teams' component={Teams} />
                <AuthorizeRoute exact path='/teams/create' component={TeamCreate} />
                <AuthorizeRoute exact path='/teams/:id' component={TeamDetails} />
                <AuthorizeRoute exact path='/Versions' component={Versions} />
                <AuthorizeRoute exact path='/configurations' component={Configurations} />
                <AuthorizeRoute exact path='/configurations/create' component={ConfigurationCreate} />
                <AuthorizeRoute exact path='/configurations/:packedAppVersion/:configKey' component={ConfigurationDetails} />
                <AuthorizeRoute exact path='/configurations/:packedAppVersion' component={ConfigurationDetails} />
                <AuthorizeRoute exact path='/configurationsByKey' component={ConfigurationsByKey} />
                <AuthorizeRoute exact path='/configurationsByKey/create' component={ConfigurationByKeyCreate} />
                <AuthorizeRoute exact path='/configurationsByKey/:packedAppVersion/:configKey' component={ConfigurationByKeyDetails} />
                <AuthorizeRoute exact path='/configurationsByKey/:packedAppVersion' component={ConfigurationByKeyDetails} />
                <AuthorizeRoute exact path='/AssetBundles' component={AssetBundles} />
                <AuthorizeRoute exact path='/AssetBundles/:appVersion/:bundleVersion/:platform' component={AssetBundleDetails} />
                <AuthorizeRoute exact path='/AssetBundles/DiffTool' component={AssetBundleDiff} />
                <AuthorizeRoute path='/SoloEvents' component={SoloEventsSubmenu} />
                <AuthorizeRoute exact path='/Gifts' component={Gifts} />
                <AuthorizeRoute exact path='/Gifts/:id' component={GiftDetails} />
                <AuthorizeRoute path='/leaderboards' component={LeaderboardsSubmenu} />
                <AuthorizeRoute exact path='/abtests/Create' component={ABTestCreate} />
                <AuthorizeRoute exact path='/abtests/:abTestId' component={ABTestEdit} />
                <AuthorizeRoute exact path='/abtests' component={ABTests} />
                <AuthorizeRoute exact path='/auditlogs' component={AuditLog} />
                <AuthorizeRoute exact path='/targetedoffers/Create' component={TargetedOfferCreate} />
                <AuthorizeRoute exact path='/targetedoffers/:targetedOfferId' component={TargetedOfferEdit} />
                <AuthorizeRoute exact path='/targetedoffers' component={TargetedOffers} />
                <AuthorizeRoute exact path='/newsalerts/Create' component={NewsAlertCreate} />
                <AuthorizeRoute exact path='/newsalerts/:newsAlertId' component={NewsAlertEdit} />
                <AuthorizeRoute exact path='/newsalerts' component={NewsAlerts} />
                <AuthorizeRoute exact path='/dashboard' component={Dashboard} />
                <AuthorizeRoute exact path='/developer' component={Developer} />
                <AuthorizeRoute path='/support' component={SupportSubmenu} />
                <AuthorizeRoute path='/IAP' component={IAPSubmenu} />
                <AuthorizeRoute path='/Redeemables' component={RedeemablesSubmenu} />
                <AuthorizeRoute path='/Inventory' component={Inventory} />
                <AuthorizeRoute exact path='/Currencies' component={Currencies} />
                <AuthorizeRoute exact path='/Currencies/Create' component={CreateCurrency} />
                <AuthorizeRoute exact path='/Currencies/:currencyId' component={EditCurrency} />
                <AuthorizeRoute exact path='/ApiKeys' component={ApiKeys} />
                <AuthorizeRoute exact path='/ApiKeys/Create' component={CreateApiKey} />
                <AuthorizeRoute exact path='/ApiKeys/:apiKeyId' component={EditApiKey} />
                <AuthorizeRoute exact path='/PlayFab' component={PlayFab} />
                <AuthorizeRoute exact path='/PlayFab/Create' component={PlayFabCreate} />
                <AuthorizeRoute exact path='/PlayFab/:buildId' component={PlayFabDetails} />
                <AuthorizeRoute exact path='/AnalyticsStream/' component={AnalyticsStream} />
              </Switch>            
            </Suspense>
            <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
          </Layout>
        </ActiveModulesProvider>
      </ToastProvider>
    )
  }
}
