import React from 'react'
import { useHistory } from "react-router-dom"
import { ShortYearDateTime } from '../shared/MKDateTime'
import { Loading } from '../shared/Loading'
import {DeleteButton} from "../shared/Buttons";

export default function PlayFabList({ servers, loading, onDelete }) {

    const history = useHistory();

    const onRowClickInternal = buildId => () =>
    {
        const path = `PlayFab/${encodeURIComponent(buildId)}`;
        history.push(path);
    };

    if (loading)
        return <Loading />

    return (
        <table className='table table-full-width table-striped table-hover'>
            <thead>
            <tr>
                <th>Server Name</th>
                <th>Region(s)</th>
                <th>Active Instance(s)</th>
                <th>Standby Instance(s)</th>
                <th>Created</th>
                <th colSpan="1"/>
            </tr>
            </thead>
            <tbody>
            { servers?.map((server, index) =>
                <tr className="tr-link" onClick={onRowClickInternal(server.buildId)} key={index}>
                    <td>{server.buildName}</td>
                    <td>{server.activeRegions}</td>
                    <td>{server.activeInstances}</td>
                    <td>{server.standbyInstances}</td>
                    <td><ShortYearDateTime>{server.created}</ShortYearDateTime></td>
                    <td className="text-center">
                        <DeleteButton onDelete={onDelete(server.buildId)} entityName="PlayFab Server"/>
                    </td>
                </tr>
            )}
            </tbody>
        </table>
    );
}