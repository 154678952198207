import React, {useState, useEffect} from 'react'
import {AuthorizedGet, AuthorizedPut} from '../AuthorizedRequest'
import {useHistory} from 'react-router-dom'
import {Loading} from '../shared/Loading'
import {ErrorMessage, Field, FieldArray, Form, Formik} from "formik";
import {AddButtonText, SubmitButton} from "../shared/Buttons";
import {Card} from '../shared/Card'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ErrorDisplay} from "../shared/FormElements";

const eventNamePattern = /[a-z_]+/

export default function AnalyticsSettings() {
    const [submitting, setSubmitting] = useState(false);
    const [form, setForm] = useState(null);
    const history = useHistory();

    useEffect(() => {
        AuthorizedGet('api/Settings/analyticsBlacklist', history)
            .then(result => {
                setForm({
                    blacklist: result
                });
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const saveEntity = (values, actions) => {
        setSubmitting(true);
        AuthorizedPut(values.blacklist, 'api/Settings/analyticsBlacklist', history)
            .then(() => {
                setForm({
                    blacklist: values.blacklist
                });
                setSubmitting(false);
            });
    };
    //
    // const addBlackListItem = () => {
    //     setBlacklist(value => [...value, '']);
    // }
    //
    // const updateValue = (index, newValue) => {
    //     setBlacklist(v => v.map((item, i) => i === index ? newValue : v));
    // }

    const validate = ({blacklist}) => {
        let errors = {};

        const blacklistErrors = [];
        blacklist.forEach((item, index) => {
            if (!eventNamePattern.test(item)) {
                blacklistErrors[index] = 'Invalid event name';
            }
        });

        if (blacklistErrors.length !== 0) {
            errors.blacklist = blacklistErrors;
        }

        return errors;
    }

    if (!form)
        return <Loading loading={true}/>

    return (
        <Formik
            enableReinitialize
            validateOnChange
            validate={validate}
            initialValues={form}
            onSubmit={saveEntity}
        >
            {
                (formikProps) => (
                    <div>
                        <Form>
                            <Card title='Event Blacklist' style={{padding: 8}}>
                                <FieldArray
                                    name="blacklist"
                                    render={arrayHelpers => (
                                        <div>
                                            <AddButtonText onClick={() => arrayHelpers.push('')}>
                                                Add New Event
                                            </AddButtonText>
                                            {formikProps.values.blacklist.map((blacklistItem, index) => (
                                                <div key={index} style={{display: 'flex', marginBottom: 8}}>
                                                    <Field name={`blacklist[${index}]`}/>
                                                    <ErrorMessage name={`blacklist[${index}]`}/>
                                                    <button className="btn btn-danger"
                                                            style={{marginBottom: 0, marginTop: 0}}
                                                            onClick={() => arrayHelpers.remove(index)}>
                                                        <FontAwesomeIcon icon='trash'/>
                                                    </button>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                />
                            </Card>
                            <ErrorDisplay errors={formikProps.errors}/>
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <SubmitButton errors={formikProps.errors} isSubmitting={submitting}
                                                  touched={formikProps.touched} forceTouched={formikProps.values.blacklist.length !== form.blacklist.length ? true : undefined}
                                    />
                                </div>
                            </div>
                        </Form>
                    </div>
                )
            }
        </Formik>
    );
}