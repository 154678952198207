import React from "react";
import {Field, FieldArray} from "formik";
import {AddButtonText, IconButtonAlt} from "../shared/Buttons";
import {Card} from "../shared/Card";
import {SelectField, TextFieldInputColumn} from "../shared/FormElements";
import {azureRegions, defaultRegionConfig} from "./PlayFabConstants";

export default function PlayFabRegionConfigurationSection({values, handleChange}) {
    return (
        <FieldArray name="regions"
            render={arrayHelpers =>
                (
                    <>
                        <br />
                        <div className="row align-items-center">
                            <div className="col-md-8">
                                <h5>Region Configuration</h5>
                            </div>
                            <div className="col-md-4 text-end">
                                <AddButtonText onClick={() => {
                                    // Adding an item doesn't seem to dirty the form so do that here
                                    arrayHelpers.push({...defaultRegionConfig})
                                    arrayHelpers.form.setFieldTouched(`regions.${values.regions.length - 1}`, true);
                                }}/>
                            </div>
                        </div>
                        {
                            values.regions.map((region, index) => (
                                <Card key={index}>
                                    <div className="row align-items-center">
                                        <div className={`${(values.regions.length > 1 ? 'col-md-10' : 'col-md-12')}`}>
                                            <Field name={`regions.${index}.region`} component={SelectField} labelText="Region"
                                                onChange={(e) => {
                                                    // Make sure the form knows this field was touched so we can handle validation properly etc.
                                                    arrayHelpers.form.setFieldTouched(`regions.${index}.region`, true);
                                                    handleChange(e);
                                                }}
                                            >
                                                {
                                                    Object.keys(azureRegions).map((region, index) =>
                                                        <option key={index} value={region}>{azureRegions[region]}</option>
                                                    )
                                                }
                                            </Field>
                                            <Field name={`regions.${index}.maxServers`} component={TextFieldInputColumn} type="number" min="1" step="1" labelText="Maximum Servers" />
                                            <Field name={`regions.${index}.standbyServers`} component={TextFieldInputColumn} type="number" min="1" step="1" labelText="Standby Servers" />
                                        </div>
                                        {
                                            values.regions.length > 1 &&
                                            <div className="row">
                                                <div className="text-end">
                                                    <IconButtonAlt icon="trash" toolTip="Delete Region Configuration" onClick={() => {
                                                        // Removing an item doesn't seem to dirty the form so do it here instead
                                                        arrayHelpers.remove(index)
                                                        arrayHelpers.form.setFieldTouched(`regions.${index}`, true);
                                                    }}>
                                                        DELETE
                                                    </IconButtonAlt>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </Card>
                            ))
                        }
                    </>
                )
            }
        />
    );
}