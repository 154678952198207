import React from 'react'
import { Field } from 'formik'
import { TextFieldInputColumn } from '../../shared/FormElements'
import { UserIdFieldInputColumn } from '../../Users/UserIdFieldInputColumn'
import { DateTimeInput } from '../../shared/MKDateTime';
import { mkPaginationObject } from '../../shared/MKPagination'
import { AppStoreInputColumn } from '../../shared/FormElementsDropdowns'

export const transactionsSearchCriteriaObject = Object.assign({
    transactionId : '',
    userId : '',
    receiptId : '',
    productId : '',
    store : '',
    storeReceiptId : '',
    currency : '',
    amountFrom : '',
    amountTo : '', 
    dateTimeFrom : '', 
    dateTimeTo : ''
}, mkPaginationObject);

export default function TransactionsSearchCriteria(props)
{
    let hideRId = props.showReceiptId === false;

    return (
        <>
            <div className="row">
                <Field name="transactionId" component={TextFieldInputColumn} labelText="Transaction Id" column={hideRId ? 6 : 4}/>
                <Field name="userId" component={UserIdFieldInputColumn} labelText="User Id" column={hideRId ? 6 : 4}/>
                {  hideRId ||
                    <Field name="receiptId" component={TextFieldInputColumn} labelText="Receipt Id (Hash)" column={4}/>
                }
            </div>
            <div className="row">
                <Field name="productId" component={TextFieldInputColumn} labelText="Product Id" column={4}/>
                <Field name="store" component={AppStoreInputColumn} column={4}/>
                <Field name="storeTransactionId" component={TextFieldInputColumn} labelText="Store Transaction Id" column={4}/>
            </div>
            <div className="row">
                <Field name="currency" component={TextFieldInputColumn} labelText="Currency" column={4}/>
                <Field name="amountFrom" component={TextFieldInputColumn} labelText="Amount From" type="number" column={4}/>
                <Field name="amountTo" component={TextFieldInputColumn} labelText="Amount To" type="number" column={4}/>
            </div>
            <div className="row">
                <Field name="dateTimeFrom" component={DateTimeInput} useTime={true} labelText="Date From" column={6}/>
                <Field name="dateTimeTo" component={DateTimeInput} useTime={true} labelText="Date To" column={6}/>
            </div>
        </>
    );
}