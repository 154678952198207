import React from 'react'
import { Field } from 'formik'
import {NullableToggleInput, TextFieldInputColumn} from '../shared/FormElements'
import { UserIdFieldInputColumn } from '../Users/UserIdFieldInputColumn'
import { mkPaginationObject } from '../shared/MKPagination';

export const leaderboarsSearchCriteriaObject = Object.assign({
    id : '', 
    name : '', 
    bucketSizeFrom : '', 
    bucketSizeTo : '',
    soloEventId : '', 
    soloEventName : '',
    userId : '', 
    scoreFrom : '', 
    scoreTo : '',
    hasScoreThreshold: null
}, mkPaginationObject);

export default function LeaderboarsSearchCriteria(props)
{
    return (
        <>
            <div className="row">
                <Field name="id" component={TextFieldInputColumn} labelText="Id" column={3}/>
                <Field name="name" component={TextFieldInputColumn} labelText="Name" column={6}/>
                <Field name="hasScoreThreshold" component={NullableToggleInput} labelText="Has Score Threshold" trueText="Yes" falseText="No" column={3}/>
            </div>
            <div className="row">
                <Field name="soloEventId" component={TextFieldInputColumn} labelText="Event Id" column={3}/>
                <Field name="soloEventName" component={TextFieldInputColumn} labelText="Event Name" column={3}/>
                <Field name="bucketSizeFrom" component={TextFieldInputColumn} type="number" labelText="Bucket Size From" column={3}/>
                <Field name="bucketSizeTo" component={TextFieldInputColumn} type="number" labelText="Bucket Size To" column={3}/>
            </div>
            <div className="row">
                <Field name="userId" component={UserIdFieldInputColumn} labelText="User Id" column={4}/>
                <Field name="scoreFrom" component={TextFieldInputColumn} type="number" labelText="Score From" column={4}/>
                <Field name="scoreTo" component={TextFieldInputColumn} type="number" labelText="Score To" column={4}/>
            </div>
        </>
    );
}