import React, { useState, useEffect } from 'react'
import { AuthorizedGet, AuthorizedPost } from '../AuthorizedRequest'
import { useHistory } from 'react-router-dom'
import { Card } from '../shared/Card'
import AssetBundlesList from './AssetBundlesList';
import AssetBundlesSearch from './AssetBundlesSearch'
import { assetBundlesSearchCriteriaObject } from './AssetBundlesSearchCriteria'
import { composeQueryString, mapQueryStringValuesToObject, useQueryStringAndSessionState, useCache } from '../shared/Utils'
import { MKPaginationWithTotal, mkPagedResult } from '../shared/MKPagination'
import {CollapseSearchButton, IconButton} from '../shared/Buttons'
import { Collapse } from 'reactstrap'
import AssetBundleUploadNew from './AssetBundleUploadNew'
import {useToasts} from "react-toast-notifications";

export default function AssetBundles(props)
{
    let history = useHistory();
    const initialEmptyValues = Object.assign({}, assetBundlesSearchCriteriaObject);    
    const [loading, setLoading] = useState(true);
    const [initialValues, setInitialValues] = useQueryStringAndSessionState(() => Object.assign({}, initialEmptyValues), history, history.location.search, "assetBundlesInitialValues");
    const [pagedResult, setPagedResult] = useState(mkPagedResult);
    const [searchCollapsed, setSearchCollapsed] = useCache('assetBundlesSearchCollapsed', true);
    const useToastsHook = useToasts();
    
    useEffect(() => {
        if (mapQueryStringValuesToObject(initialEmptyValues, history.location.search))   // There is allowed search parameters in QueryString  [&&]
        {
            if (JSON.stringify(initialEmptyValues) !== JSON.stringify(initialValues))  // Those parameters means a different search tan the one saved in sessionStorage
                searchAssetBundles(initialEmptyValues);
            else
                setLoading(false);
        } else {
            if (pagedResult.itemsTotalCount == null)
                searchAssetBundles(initialValues);
            else
                setLoading(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const searchAssetBundles = (searchValues, formikBag = { setSubmitting: x => {} }, pageNumber = 1) => {
        searchValues.pageNumber = pageNumber;
        setLoading(true);
        setInitialValues(Object.assign({}, searchValues));
        AuthorizedGet('api/AssetBundle/Search?' + composeQueryString(searchValues), history)
            .then(result => 
            {
                setPagedResult(result ?? mkPagedResult);
                formikBag.setSubmitting(false);
                setLoading(false);
            });
    };

    const onPageClick = (e, pageNumber) => searchAssetBundles(initialValues, undefined, pageNumber);
        
    const toggleSearch = e => {
        e.preventDefault();
        setSearchCollapsed(!searchCollapsed);
    };
    
    const onFileBundleUploaded = bundleViews => 
    {   
        bundleViews.forEach(x => {
                let iVersion = 0;
                for (let i = 0; i < pagedResult.items.length; i++)
                {
                    if (pagedResult.items[i].appVersion === x.appVersion)
                    {
                        iVersion = i;
                        if (pagedResult.items[i].platform === x.platform)
                        {
                            pagedResult.items[i] = x;
                            return;
                        }
                    }
                }
                pagedResult.items.splice(iVersion, 0, x);
            });
        
        pagedResult.items = pagedResult.items.filter(x => !x.rowSpan && !x.rowSeparator); 
        
        setPagedResult(Object.assign({}, pagedResult));
    };
    
    const onIsActiveChanged = (index, result) => 
    {
        pagedResult.items[index].isActive = result.isActive;
        setPagedResult(pagedResult); //re-render not needed, only for update the session value
    };

    const onDelete = (bundle) => () =>
        AuthorizedPost({}, `api/AssetBundle/${bundle.appVersion}/${bundle.bundleVersionInt}/${encodeURIComponent(bundle.platform)}/delete`, history)
            .then(result => 
            {
                const itemIndex = pagedResult.items.indexOf(bundle);
                pagedResult.items.splice(itemIndex, 1); 
                pagedResult.itemsTotalCount--;
                pagedResult.items = pagedResult.items.filter(x => !x.rowSpan && !x.rowSeparator);
                setPagedResult(Object.assign({}, pagedResult));
            });
    
    const onDeleteMany = (bundlesToDelete) => {
        setLoading(true);
        AuthorizedPost(bundlesToDelete, `api/AssetBundle/DeleteMany`, history)
            .then(result => {
                if (result) {
                    useToastsHook.addToast(`Deleted ${result.deletedCount} Asset Bundles`, {
                        autoDismiss: true
                    });
                }
            })
            .catch(e => {
               console.error(e);
                useToastsHook.addToast(<span title="Error while deleting asset bundles">Error while deleting asset bundles: {e}</span>, {
                    appearance: 'warning',
                    autoDismiss: false,
                });
            })
            .finally(() => searchAssetBundles(initialValues));
    }
    
    const goToDiffTool = () => {
        const path = `/AssetBundles/DiffTool`;
        history.push(path);
    }


    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <div>
            <p className="alert alert-warning text-black">
                Asset bundles is in active development. Before attempting to upload bundles with an existing bundle version, please delete the existing bundle version first.
            </p>
            
            <div className="row align-items-center">
                <div className="md-8 col">
                    <h3>&nbsp;Asset Bundles Manifests</h3>
                </div>
                <div className="md-4 col text-end">
                    <AssetBundleUploadNew onFileUploaded={onFileBundleUploaded}/>
                    &nbsp;
                    <IconButton icon={"copy"} toolTip={"Compare Asset Bundles"} onClick={goToDiffTool} />
                    &nbsp;
                    <CollapseSearchButton collapsed={searchCollapsed} onClick={toggleSearch}/>
                </div>
            </div>

            <Collapse isOpen={!searchCollapsed}>
                <Card>
                    <AssetBundlesSearch handleSearch={searchAssetBundles} initialValues={initialValues}/>
                </Card>
            </Collapse>

            <Card>
                <AssetBundlesList assetBundles={pagedResult.items} loading={loading} onDeleteMany={onDeleteMany}
                                  onDelete={onDelete} onIsActiveChanged={onIsActiveChanged}/>
                <MKPaginationWithTotal currentPage={pagedResult.pageNumber} total={pagedResult.pagesTotalCount} totalItems={pagedResult.itemsTotalCount} 
                                       onClick={onPageClick} itemsName="Bundle Manifests"/>
            </Card>
        </div>
    );
}
