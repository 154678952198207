import React from 'react';
import {Card} from "../shared/Card";
import {CollapseButton} from "../shared/Buttons";
import {useCache} from "../shared/Utils";
import {Collapse} from "reactstrap";
import {Field, FieldArray, Form, Formik} from "formik";
import {TextFieldInputColumn} from "../shared/FormElements";

export default function PlayFabServerDetailCard({ server }) {
    
    const [serverDetailsCollapsed, setServerDetailsCollapsed] = useCache('playFabServerDetailsCollapsed', false);

    // Toggle the card collapsing
    const onToggleDetails = () => {
        setServerDetailsCollapsed(!serverDetailsCollapsed);
    }

    return (
        <Formik
            initialValues={server}
            onSubmit={(values) => { /* do nothing this is a read only form */ }}
            enableReinitialize
        >
            <Card>
                <div className="row align-items-center">
                    <div className="col-md-8">
                        <h3>&nbsp;Server Details</h3>
                    </div>
                    <div className="col-md-4 text-end">
                        <CollapseButton collapsed={serverDetailsCollapsed} onClick={onToggleDetails} toolTipCollapsed={"Expand Server Details"} toolTipOpen={"Collapse Server Details"} icon={"file"} />
                    </div>
                </div>
                <Collapse isOpen={!serverDetailsCollapsed}>
                    <Form>
                        <Field name="buildName" component={TextFieldInputColumn} labelText="Server Name" readOnly={true} column={12}/>
                        <Field name="buildId" component={TextFieldInputColumn} labelText="Server Id" readOnly={true} column={12}/>
                        <Field name="startCommand" component={TextFieldInputColumn} labelText="Start Command" readOnly={true} column={12}/>
                        <Field name="vmSize" component={TextFieldInputColumn} labelText="VM Size" readOnly={true} column={12}/>
                        <br />
                        <h5>Artifact(s)</h5>
                        <FieldArray
                            name="artifacts"
                            render={arrayHelpers =>                                     
                                <>
                                    {server.artifacts.map((artifact, index) => (
                                        <div className="row" key={index}>
                                            <Field name={`artifacts.${index}`}  component={TextFieldInputColumn} labelText="File Name" readOnly={true} column={12}/>
                                        </div>
                                    ))}
                                </>
                            }
                        />
                        <br />
                        <h5>Meta Data</h5>
                        <FieldArray
                            name="metadata"
                            render={arrayHelpers => (
                                <>
                                    {server.metadata?.map((kvp, index) => (
                                        <div className="row" key={index}>
                                            <Field name={`metadata.${index}.item1`} component={TextFieldInputColumn} labelText="Key" readOnly={true} column={6}/>
                                            <Field name={`metadata.${index}.item2`} component={TextFieldInputColumn} labelText="Value" readOnly={true} column={6}/>
                                        </div>
                                    ))}
                                </>
                            )}
                        />
                    </Form>
                </Collapse>
            </Card>
        </Formik>
    );
}
