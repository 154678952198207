import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { AuthorizedPost } from '../../AuthorizedRequest'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { AddButtonText } from '../../shared/Buttons'
import { Loading } from '../../shared/Loading'
import { ErrorDisplay } from '../../shared/FormElements'
// import DropdownButton from "react-bootstrap/DropdownButton";
// import Dropdown from "react-bootstrap/Dropdown";

export default function ContentCodeInsertCSV()
{
    let fileReader
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [content, setConent] = useState("");
    const [errors, setErrors] = useState(null)
    const history = useHistory();

    const toggleModal = () => setModal(!modal);

    const importButtonClick = e =>
    {
        e.preventDefault()
        setConent("")
        setLoading(false)
        setErrors(null)
        toggleModal()
    };

    const onFileSelected = e =>
    {
        fileReader = new FileReader()
        fileReader.onloadend = onFileRead
        fileReader.readAsText(e.target.files[0])
    }

    const onFileRead = e =>
    {
        setConent(fileReader.result)
    }

    const onUploadPressed = e =>
    {
        setLoading(true);
        AuthorizedPost(content, "api/Redeemable/ImportCSV", history)
            .then(result =>
            {
                setLoading(false);
                if (!result)
                {
                    setErrors({Error: "No response"});
                }
                else
                {
                    setModal(false)
                }
            })
            .catch(error =>
            {
                setLoading(true)
                setErrors({Error: error});
            })
    }

    return (
        <>
            <AddButtonText onClick={importButtonClick} toolTip="Import CSV">Import CSV</AddButtonText>
            
            <Modal isOpen={modal} toggle={toggleModal} contentClassName="card">
                <ModalHeader toggle={toggleModal}>Import Codes from CSV file</ModalHeader>
                <ModalBody>
                    <p>Expected format: comma-separated unique content code IDs. MKNet will generate single-use content codes with empty content body</p>
                    { loading && <Loading loading={true}/>}
                    <input type="file" onChange={onFileSelected} />
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label>List of codes to add</label>
                                <textarea value={content} readOnly={true}/>
                            </div>
                        </div>
                    </div>
                    <ErrorDisplay errors={errors}/>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggleModal}>Cancel</Button>&nbsp;
                    <Button color="primary" onClick={onUploadPressed} disabled={!content}>Upload</Button>
                </ModalFooter>
            </Modal>
        </>
    );
}