import React from 'react'
import { useHistory } from "react-router-dom"
import { Loading } from '../shared/Loading'
import { DeleteButton } from '../shared/Buttons'

export default function ReplaysList({ replays, loading, onDelete }) {
    
    const history = useHistory();
    
    function onRowClick(replay)
    {
        const path = `Replays/${encodeURIComponent(replay.name)}/${encodeURIComponent(replay.version)}/${encodeURIComponent(replay.userId)}`;
        history.push(path);
    }

    if (loading)
    {
        return <Loading loading={true} />
    }

    return (
        <table className='table table-full-width table-striped table-hover'>
            <thead>
                <tr>
                    <th>Track Id</th>
                    <th>Version</th>
                    <th>User Id</th>
                    <th/>
                </tr>
            </thead>
            <tbody>
                {replays?.map((replay, index) =>
                <tr className="tr-link" onClick={() => onRowClick(replay)} key={index}>
                    <td>{replay.name}</td><td>{replay.version}</td><td>{replay.userId}</td>
                    <td className="text-end">
                        <DeleteButton onDelete={onDelete(replay)} entityName="Replay"/>
                    </td>
                </tr>
                )}
            </tbody>
        </table>
        );
}