import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { AuthorizedGet, AuthorizedDelete } from '../AuthorizedRequest'
import { Card } from '../shared/Card'
import { MKPaginationWithTotal, mkPagedResult } from '../shared/MKPagination'
import { AddButtonText } from '../shared/Buttons'
import SoloEventTemplatesList from "./SoloEventTemplatesList";


export default function SoloEventTemplates(props)
{
    
    let history = useHistory();
    const [loading, setLoading] = useState(true);
    const [pagedResult, setPagedResult] = useState(mkPagedResult);
    
    useEffect(() => {
        onPageClick(null, 1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onPageClick = (e, pageNumber) => 
    {
        AuthorizedGet(`api/SoloEvent/GetTemplates?pageNumber${pageNumber}=&pageSize=${pagedResult.pageSize}`, history)
            .then(result =>
            {
                setPagedResult(result ?? mkPagedResult);
                setLoading(false);
            });        
    };

    const createEventTemplate = () =>
    {
        history.push("/SoloEvents/Templates/Create");
    };
    
    const onCreate = eventTemplateId => e =>
    {
        e.stopPropagation();
        history.push(`/SoloEvents/CreateFromTemplate/${eventTemplateId}`);
    };

    const onDelete = eventId => () =>
    {
        AuthorizedDelete(null, `api/SoloEvent/DeleteTemplate/${eventId}`, history)
            .then(data =>
            {
                if (data)
                {
                    pagedResult.items = pagedResult.items.filter(function(s) { return s.id !== data.id });
                    pagedResult.itemsTotalCount--;
                    setPagedResult(Object.assign({}, pagedResult));
                }
            });
    };

    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////    
    return (
        <div>
            <div className="row align-items-center">
                <div className="md-8 col">
                    <h3>&nbsp;Event Templates</h3>
                </div>
                <div className="md-4 col text-end">
                    <AddButtonText onClick={createEventTemplate} toolTip="Create New Event Template">New Template</AddButtonText>                    
                </div>
            </div>

            <Card>
                <SoloEventTemplatesList soloEvents={pagedResult.items} onDelete={onDelete} onCreate={onCreate} loading={loading}/>
                <MKPaginationWithTotal currentPage={pagedResult.pageNumber} total={pagedResult.pagesTotalCount} totalItems={pagedResult.itemsTotalCount} onClick={onPageClick} itemsName="Events"/>
            </Card>
        </div>
    )
}
