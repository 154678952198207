import React from 'react'
import { Loading } from '../shared/Loading'

export default function UserInventoryItemList({ items, loading }) {
    
    if (loading)
    {
        return <Loading loading={true} />
    }

    return (
        <table className='table table-full-width table-striped table-hover'>
            <thead>
                <tr>
                    <th>Id</th>
                    <th>Name</th>
                    <th>Rarity</th>
                    <th>Type</th>
                    <th>Slot Equipped</th>
                    <th>Acquired as Starter Item</th>
                </tr>
            </thead>
            <tbody>
                {items.map((item, index) =>
                <tr className="tr-link"  key={index}>
                    <td>{item.inventoryItem.id}</td>
                    <td>{item.inventoryItem.name}</td>
                    <td>{item.inventoryItem.rarity.name}</td>
                    <td>{item.inventoryItem.inventoryItemType.name}</td>
                    <td>{item.equippedItem && item.equippedItem.slot.name}</td>
                    <td>{item.acquiredAsStarterItem ? <span className='nc-icon nc-check-2'></span> : null}</td>
                </tr>
                )}
            </tbody>
        </table>
        );
}