import React, {useRef, useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DropdownMenuInput from "./DropdownMenuInput";
import Toggle from "react-toggle";

export const KeyValueEditorUnmanaged = ({ aKey, aValue, onChangeValue, onChangeKey, valueTypes = ['string', 'number', 'boolean'], editKey = true, ...props }) =>
{
    const [valueType, setValueType] = useState(typeof aValue);
    const cValue = useRef(aValue);
    
    let showType = valueTypes.length > 1;
    
    const onChangeValueInternal = (e, value) => {
        cValue.current = value;
        onChangeValue(e, value);
    };
    
    const onTypeChange = newType => {
        let newValue;
        switch (newType) {
            case 'boolean':
                newValue = Boolean(cValue.current);
                break;
            case 'number':
                newValue = isNaN(Number(cValue.current)) ? 0 : Number(cValue.current);
                break;
            case 'string':
                newValue = String(cValue.current);
                break;
            default:
                newValue = cValue.current;
        }
        onChangeValueInternal({ target: {value: newValue}}, newValue);
        setValueType(newType);
    };
    
    const getInputComponent = () => {
        switch (valueType) {
            case 'string':
                return (
                    <input defaultValue={cValue.current} placeholder={props.placeholder}
                           className={props.errors ? "form-control error" : "form-control"} readOnly={props.readOnly}
                           onChange={e => onChangeValueInternal(e, e.target.value)}/>
                );
            case 'number':
                return (
                    <input defaultValue={cValue.current} placeholder={props.placeholder}
                           type="number" step="any"
                           className={props.errors ? "form-control error" : "form-control"} readOnly={props.readOnly}
                           onChange={e => onChangeValueInternal(e, Number(e.target.value))}/>
                );
            case 'boolean':
                return (
                    <Toggle defaultChecked={cValue.current} icons={false}
                            onChange={e => onChangeValueInternal(e, e.target.checked)}/>
                );
            default:
                return (
                    <input defaultValue={JSON.stringify(cValue.current)} className="form-control" readOnly={true}/>
                );
        }
    };
    
    return (
        <div className="row">
            { editKey ?
            <>
                <div className="col-md-4">
                    <div className="form-group">
                        <label>Key</label>
                        <input defaultValue={aKey} placeholder={props.placeholder} type="text" className={props.errors ? "form-control error": "form-control"} 
                            readOnly={props.readOnly} onChange={e => onChangeKey(e, e.target.value)}/>
                    </div>
                </div>
                { showType &&
                <div className="col-md-3">
                    <div className="form-group">
                        <label title="Data type of the value">Type</label>
                        <DropdownMenuInput selectedKey={valueType} selectedValue={valueType} onChange={onTypeChange} 
                                           itemsList={valueTypes.map(x => { return { key: x, value: x}})} readOnly={props.readOnly || !valueTypes.includes(valueType)} />
                    </div>
                </div>
                }
                <div className={`col-md-${showType ? 4 : 7}`}>
                    <div className="form-group">
                        <label>Value</label>
                        { getInputComponent()}                        
                    </div>
                </div>
            </>
            :
            <div className="col-md-11">
                <div className="form-group">
                    <label>{aKey}</label>
                    <textarea defaultValue={aValue} placeholder={props.placeholder} className={props.errors ? "form-control error support-message": "form-control support-message"} 
                        readOnly={props.readOnly} onChange={props.onChange}/>
                </div>
            </div>
            }
            <div className="col-md-1 p-0 mt-2">
                <button className='btn no-margin shadow-none btn-link' title="Remove" onClick={props.removeKey} type="button">
                    <FontAwesomeIcon className='red' icon='trash' />
                </button>
            </div>
        </div>
    )
} 