import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { AuthorizedPost } from '../../../AuthorizedRequest'
import SupportTicketEdit from "./SupportTicketEdit";

export default function SupportTicketCreate()
{
    const location = useLocation()
    const history = useHistory()

    const saveTicket = (values, actions) =>
    {
        AuthorizedPost(values, "api/Support/CreateTicket", history)
            .then(result =>
            {
                actions.setSubmitting(false)
                if (result)
                {
                    actions.setTouched({})

                    const path = `/Support/SupportTickets/${encodeURIComponent(result.id)}`
                    history.push(path, { supportTicket: result })
                }
            })
    }

    return (
        <SupportTicketEdit supportTicket={location?.state?.supportTicket ?? {source:"Manual"}} saveTicket={saveTicket} title="Create Support Ticket"/>
    );
}

