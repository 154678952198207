import React from 'react'
import { useHistory } from "react-router-dom"
import { ShortDateTimeUTC} from '../shared/MKDateTime'
import { Loading } from '../shared/Loading'
import { DeleteButton } from '../shared/Buttons'
import {Input} from "reactstrap";

export default function NewsAlertsList({ newsAlerts, loading, onDelete }) {
    
    const history = useHistory();
    
    function onRowClick(itemId)
    {
        const path = `NewsAlerts/${encodeURIComponent(itemId)}`;
        history.push(path);
    }

    if (loading)
    {
        return <Loading loading={true} />
    }

    return (
        <table className='table table-full-width table-striped table-hover'>
            <thead>
                <tr>
                    <th>Id</th>
                    <th>Name</th>
                    <th>From (UTC)</th>
                    <th>Till (UTC)</th>
                    <th>Enabled</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {newsAlerts.map((newsAlert, index) =>
                <tr className="tr-link" onClick={() => onRowClick(newsAlert.id)} key={index}>
                    <td>{newsAlert.id}</td>
                    <td>{newsAlert.name}</td>
                    <td><ShortDateTimeUTC>{newsAlert.startDate}</ShortDateTimeUTC></td>
                    <td><ShortDateTimeUTC>{newsAlert.endDate}</ShortDateTimeUTC></td>
                    <td>
                        <Input type="checkbox" disabled={true} checked={newsAlert.enabled}
                               title={newsAlert.enabled ? 'Enabled' : 'Disabled'} />
                    </td>
                    <td className="text-end">
                        <DeleteButton onDelete={onDelete(newsAlert.id)} entityName="News / Alert"/>
                    </td>
                </tr>
                )}
            </tbody>
        </table>
        );
}