import React from 'react'
import { useHistory } from "react-router-dom"
import { Loading } from '../shared/Loading'
import { DeleteButton } from '../shared/Buttons'
import {Input} from "reactstrap";

export default function TargetedOffersList({ abTests: targetedOffers, loading, onDelete }) {
    
    const history = useHistory();
    
    function onRowClick(itemId)
    {
        const path = `TargetedOffers/${encodeURIComponent(itemId)}`;
        history.push(path);
    }

    if (loading)
    {
        return <Loading loading={true} />
    }

    return (
        <table className='table table-full-width table-striped table-hover'>
            <thead>
                <tr>
                    <th>Id</th>
                    <th>Name</th>
                    <th>User Segments</th>
                    <th>Enabled</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {targetedOffers.map((targetedOffer, index) =>
                <tr className="tr-link" onClick={() => onRowClick(targetedOffer.id)} key={index}>
                    <td>{targetedOffer.id}</td>
                    <td>{targetedOffer.name}</td>
                    <td>{targetedOffer.userSegmentsNames?.join(", ")}</td>
                    <td>
                        <Input type="checkbox" disabled={true} checked={targetedOffer.enabled}
                               title={targetedOffer.enabled ? 'Enabled' : 'Disabled'} />
                    </td>
                    <td className="text-end">
                        <DeleteButton onDelete={ onDelete(targetedOffer.id) } entityName="Targeted Offer"></DeleteButton>
                    </td>
                </tr>
                )}
            </tbody>
        </table>
        );
}