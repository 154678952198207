import React from 'react'
import { useHistory } from "react-router-dom"
import {Loading} from "../shared/Loading";
import {ShortYearDate} from "../shared/MKDateTime";


export default function SoftCurrencyTransactionList({ transactions, loading }) {
    
    const history = useHistory();

    const onRowClick = id => e => {
        e.stopPropagation();
        const path = `/Currencies/Log/${encodeURIComponent(id)}`;
        history.push(path);
    }

    if (loading)
    {
        return <Loading loading={true} />
    }

    return (
        <table className='table table-full-width table-striped'>
            <thead>
                <tr>
                    <th>Id</th>
                    <th>Date</th>
                    <th>User Id</th>
                    <th>Source</th>
                    <th>Amount</th>
                    <th>Currency</th>
                    <th>Description</th>
                    <th/>
                </tr>
            </thead>
            <tbody>
                { transactions?.map((transaction, index) =>
                    <tr className="tr-link" onClick={ onRowClick(transaction.id) } key={index}>
                        <td>{transaction.id}</td>
                        <td><ShortYearDate>{transaction.timestamp}</ShortYearDate></td>
                        <td>{transaction.userCurrency.userId}</td>
                        <td>{transaction.source}</td>
                        <td>{transaction.amount}</td>
                        <td>{transaction.userCurrency.currency.name}</td>
                        <td>{transaction.description}</td>
                        
                        {/*<td className="text-center">*/}
                        {/*    <ReceiptButton hash={transaction.receiptId} />*/}
                        {/*</td>*/}
                    </tr>
                )}
            </tbody>
        </table>
        );
}