import React from 'react'
import { useHistory } from "react-router-dom"
import { Loading } from '../shared/Loading'
import { DeleteButton } from '../shared/Buttons'
import ExponentNotation from "../shared/ExponentNotation";
import { AuditLogButtonConditional } from "../AuditLog/AuditLogButton";

export default function LeaderboardsList({ leaderboards, loading, onDelete, showEventColumn = true, entityType = 'SoloLeaderboard', onRowClickURL = '/Leaderboards' }) {
    
    const history = useHistory();

    const onRowClick = itemId => e => {
        e.stopPropagation();
        const path = `${onRowClickURL}/${encodeURIComponent(itemId)}`
        history.push(path)
    }

    if (loading)
    {
        return <Loading loading={true} />
    }

    return (
        <table className='table table-full-width table-striped table-hover'>
            <thead>
                <tr>
                    <th>Leaderboard Id</th>
                    <th>Name</th>
                    <th>Bucket Size</th>
                    <th>Max Threshold</th>
                    { showEventColumn &&
                    <th>Event Id</th>
                    }
                    <th></th>
                    { onDelete !== undefined &&
                    <th></th>
                    }
                </tr>
            </thead>
            <tbody>
                {leaderboards.map((leaderboard, index) =>
                <tr className="tr-link" onClick={onRowClick(leaderboard.id)} key={index}>
                    <td>{leaderboard.id}</td>
                    <td>{leaderboard.name}</td>
                    <td>{leaderboard.bucketSize}</td>
                    <td>
                        <ExponentNotation base={leaderboard.scoreBaseMaxThreshold}
                                          exponent={leaderboard.scoreExponentMaxThreshold}>
                            {leaderboard.scoreMaxThreshold}
                        </ExponentNotation>
                    </td>
                    { showEventColumn &&
                    <td>{leaderboard.soloEventId ?? leaderboard.soloEventTemplateId}</td>
                    }
                    <td>
                        <AuditLogButtonConditional searchObject={{entityId: leaderboard.id, entityType: entityType}}/>
                    </td>
                    { onDelete !== undefined &&
                    <td className="text-end">
                        <div className="btn-group" role="group">
                            <DeleteButton onDelete={onDelete(leaderboard.id)} entityName="Leaderboard"/>
                        </div>
                    </td>
                    }
                </tr>
                )}
            </tbody>
        </table>
        );
}