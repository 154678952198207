import React, {useState, useEffect} from "react";
import authService from '../api-authorization/AuthorizeService';
import {AuthorizedGet} from "../AuthorizedRequest";

const githubReleaseBaseURL = "https://github.com/mightykingdom/MKNetV2/releases/tag/"

export default function MKNetVersions(props)
{
    const [adminVersion, setAdminVersion] = useState(<>(<i>Loading</i>)</>);
    const [apiVersion, setApiVersion] = useState(<>(<i>Loading</i>)</>);

    useEffect(() => {
        (async () => {
            const apiVersionURL = await authService.getApiUrl() + '/ServerStatus/ApiVersion';
            AuthorizedGet(apiVersionURL)
                .then(result => {
                    if (result === null) {
                        setApiVersion(null);
                    } else {
                        setApiVersion('v' + result.version);
                    }
                })
                .catch(e => {
                    console.error(e);
                    setApiVersion(null);
                });
        })();

        AuthorizedGet('/api/AppInfo/AdminVersion')
            .then(result => {
                if (result === null) {
                    setAdminVersion(null);
                } else {
                    setAdminVersion('v' + result.version);
                }
            })
            .catch(e => {
                console.error(e);
                setAdminVersion(null);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //// Render
    return (
        <>            
            <small className="d-block text-white">
                { adminVersion === null ?
                    <>Couldn't load Admin version</>
                    :
                    <>Admin version: <a className="text-white text-decoration-none" href={githubReleaseBaseURL + adminVersion} target="_blank" rel="noreferrer">{adminVersion}</a></>
                }
            </small>
            <small className="d-block text-white">
                { apiVersion === null ?
                    <>Couldn't load API version</>
                    :
                    <>API version: <a className="text-white text-decoration-none" href={githubReleaseBaseURL + apiVersion} target="_blank" rel="noreferrer">{apiVersion}</a></>
                }
            </small>
        </>
    );
}