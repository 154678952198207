import React, {useEffect, useState} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {AuthorizedGet} from '../AuthorizedRequest'
import {Loading} from "../shared/Loading";
import SoloEventCreate from "./SoloEventCreate";

export default function SoloEventCreateFromTemplate()
{
    const [event, setEvent] = useState(null);
    const [loading, setLoading] = useState(true);
    let {eventTemplateId} = useParams();
    const history = useHistory();
       
    useEffect(() => 
    {
        AuthorizedGet(`api/SoloEvent/GetNewFromTemplate/${eventTemplateId}`, history)
            .then(result => {
                if (result)
                    setEvent(result);
                setLoading(false);
            });
    }, [eventTemplateId]);

    if (loading)
    { return <Loading /> }

    if (!event)
    { return null }
    
    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <SoloEventCreate postEndpoint={`api/SoloEvent/SaveFromTemplate/${eventTemplateId}`} initialData={event} showCreated={false} editPauseUntil={false}/>
    );
}