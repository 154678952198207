import React from 'react'
import { useHistory } from "react-router-dom"
import { ShortYearDateTime } from '../shared/MKDateTime'
import { Loading } from '../shared/Loading'

export default function TeamsList({ teams, loading }) {
    
    const history = useHistory();

    function onRowClick(itemId)
    {
        const path = 'Teams/' + itemId;
        history.push(path);
    }

    if (loading)
    {
        return <Loading />
    }

    return (
        <table className='table table-full-width table-striped table-hover'>
            <thead>
                <tr>
                    <th>Id</th>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Created</th>
                    <th>Min Level</th>
                    <th>Members</th>
                    <th>Badge</th>
                    <th>Locale</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                { teams?.map((team, index) =>
                <tr className="tr-link" onClick={() => onRowClick(team.id)} key={index}>
                    <td>{team.id}</td>
                    <td>{team.name}</td>
                    <td>{team.open ? 'Open' : 'Closed'}</td>
                    <td><ShortYearDateTime>{team.created}</ShortYearDateTime></td>
                    <td>{team.minLevel}</td>
                    <td>{team.memberCount + '/' + team.maxMembers}</td>
                    <td>{team.badge}</td>
                    <td>{team.localeCode}</td>
                    <td>{team.dead ? 'Dead' : 'Alive'}</td>
                </tr>
                )}
            </tbody>
        </table>
        );
}