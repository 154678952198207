import {useMemo} from "react";
import {useHistory} from "react-router-dom";
import {AuthorizedPost} from "../AuthorizedRequest";
import ABTestForm from "./ABTestForm";


export default function ABTestCreate() 
{
    const abTest = useMemo(() => {
        let date = new Date();
        let dateUTC_MilliSeconds = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 0, 0, 0);
        let startDate = (new Date(dateUTC_MilliSeconds)).toISOString(); //Today 
        let milliSecondsInADay = 1000 * 60 * 60 * 24;
        let endDate = (new Date(dateUTC_MilliSeconds + 30 * milliSecondsInADay)).toISOString(); //Default ending in 30 days

        return { groups: 2, startDate, endDate, json: '{}', enabled: true, userSegments: [] };
    }, [])
    const history = useHistory()

    const saveMethod = values => {
        return AuthorizedPost(values, 'api/ABTest', history)
            .then(result =>
            {
                if (result)
                {
                    history.replace('/ABTests/' + result.id);
                }
                return result;
            });
    };

    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <ABTestForm abTest={abTest} saveMethod={saveMethod} loading={false}/>
    );
}
