import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { AuthorizedGet, AuthorizedPost, AuthorizedDelete } from '../../AuthorizedRequest'
import { Card } from '../../shared/Card'
import { Loading } from '../../shared/Loading'
import { DeleteButton } from '../../shared/Buttons'

export default function Templates(props)
{
    const [contentCodeTemplates, setContentCodeTemplates] = useState(null)
    const [messageTemplates, setMessageTemplates] = useState(null)
    const [loading, setLoading] = useState(true)
   
    const history = useHistory()

    useEffect(() =>
    {
        AuthorizedGet(`api/Support/Templates`, history)
        .then(result => 
        {
            if (result)
            { 
                setContentCodeTemplates(result.contentCodeTemplates)
                setMessageTemplates(result.messageTemplates)
            }
            setLoading(false)
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    function onRowClickContentCode(contentCodeTemplate)
    {
        const path = `/Support/Templates/ContentCode/${encodeURIComponent(contentCodeTemplate.id)}`
        history.push(path, { redeemable: contentCodeTemplate })
    }

    function onRowClickMessage(messageTemplate)
    {
        const path = `/Support/Templates/Message/${encodeURIComponent(messageTemplate.id)}`
        history.push(path, { messageTemplate: messageTemplate })
    }

    function createContentCodeTemplate()
    {
        AuthorizedPost(null, `api/Redeemable/CreateTemplate`, history)
            .then(result => 
            {
                if (result)
                { 
                    onRowClickContentCode(result)
                }
            })
    }

    const createMessageTemplate = e =>
    {
        history.push('/Support/Templates/Message/Create')
    }

    const deleteContentCodeTemplate = template => () =>
    {
        AuthorizedDelete(template, `api/Redeemable/DeleteTemplate`, history)
            .then(result => 
            {
                if (result)
                { 
                    setContentCodeTemplates(contentCodeTemplates.filter(t => t.id !== result.id))
                }
            })
    }

    const deleteMessageTemplate = template => () =>
    {
        AuthorizedDelete(template, `api/Support/DeleteMessageTemplate`, history)
            .then(result => 
            {
                if (result)
                { 
                    setMessageTemplates(messageTemplates.filter(t => t.id !== result.id))
                }
            })
    }

    if (loading)
    {
        return <Loading />
    }

    return (
    <>
        <Card headerComponent={<HeaderComponent title="Content Code Templates" onCreateClick={createContentCodeTemplate}/>}>
            <table className="table table-full-width">
                <tbody>
                    {contentCodeTemplates && contentCodeTemplates.map((template, index) => 
                    <tr key={index} onClick={(e) => onRowClickContentCode(template)} className="tr-link">
                        <td>{template.name}</td>
                        <td>{template.prefix}</td>
                        <td className="td-actions text-end">
                            <DeleteButton className='btn no-margin shadow-none btn-link' onDelete={deleteContentCodeTemplate(template)} entityName="Content Code Template"/>
                        </td>
                    </tr>)}
                </tbody>
            </table> 
        </Card>
        <Card headerComponent={<HeaderComponent title="Message Templates" onCreateClick={createMessageTemplate}/>}>
            <table className="table table-full-width">
                <tbody>
                    {messageTemplates && messageTemplates.map((template, index) => 
                    <tr key={index} onClick={(e) => onRowClickMessage(template)} className="tr-link">
                        <td>{template.name}</td>
                        <td className="td-actions text-end">
                            <DeleteButton className='btn no-margin shadow-none btn-link' onDelete={deleteMessageTemplate(template)} entityName="Message Template"/>
                        </td>
                    </tr>)}
                </tbody>
            </table> 
        </Card>
    </>
    )
}

function HeaderComponent({title, onCreateClick})
{
    return (
        <div className="row">
            <div className="col-md-10">
                <h5 className="mt-2 mb-0">
                    {title}
                </h5>
            </div>
            <div className="col-md-2 text-end">
                <button className="btn btn-primary" onClick={onCreateClick}>Create New</button>
            </div>
        </div>
    );
}