import React from 'react'
import { useHistory } from "react-router-dom"
import { ShortYearDateTime } from '../shared/MKDateTime'
import { Loading } from '../shared/Loading'
import TransactionsButton from '../IAP/elements/TransactionsButton'
import ReceiptsButton from '../IAP/elements/ReceiptsButton'
import SupportTicketsButton from '../Support/SupportTicketsButton'
import {ModuleConditional} from "../shared/ModuleConditional";
import LeaderboardEntriesButton from "../Leaderboards/LeaderboardEntriesButton";
import UserIdValue from "./UserIdValue";
import {faFlag} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function UsersList({ users, loading, onRowClick, showButtons = true }) {
    
    const history = useHistory();

    const onRowClickInternal = userId => () => 
    {
        if (onRowClick)
        {
            onRowClick(userId);
        }
        else
        {
            const path = `Users/${encodeURIComponent(userId)}`;
            history.push(path);
        }
    };

    if (loading)
        return <Loading />

    return (
        <table className='table table-full-width table-striped table-hover'>
            <thead>
                <tr>
                    <th>User Id</th>
                    <th>Name</th>
                    <th>Last Login</th>
                    <th>Created</th>
                    { showButtons &&
                        <th colSpan="4"/>
                    }
                </tr>
            </thead>
            <tbody>
                { users?.map((user, index) =>
                <tr className="tr-link" onClick={onRowClickInternal(user.userId)} key={index}>
                    <td onClick={e => e.stopPropagation()} style={{cursor: "default"}}>
                        <UserIdValue userId={user.userId} deleted={user.deleted}
                                     cheater={user.cheater}>{user.userId}</UserIdValue>
                    </td>
                    <td>{user.name}</td>
                    <td><ShortYearDateTime>{user.lastLogin}</ShortYearDateTime></td>
                    <td><ShortYearDateTime>{user.created}</ShortYearDateTime></td>
                    { showButtons &&
                    <>
                        <td className="text-center">
                            <ModuleConditional modules={["Leaderboards"]}>
                                <LeaderboardEntriesButton searchObject={{userId: user.userId}}/>
                            </ModuleConditional>
                        </td>
                        <td className="text-center">
                            <ModuleConditional modules={["IAP"]}>
                                <TransactionsButton searchObject={{ userId: user.userId }} />
                            </ModuleConditional>
                        </td>
                        <td className="text-center">
                            <ModuleConditional modules={["IAP"]}>
                                <ReceiptsButton searchObject={{ userId: user.userId }} />
                            </ModuleConditional>
                        </td>
                        <td className="text-center">
                            <ModuleConditional modules={["Support"]}>
                                <SupportTicketsButton searchObject={{ userId: user.userId }} />
                            </ModuleConditional>
                        </td>
                    </>  
                    }
                </tr>
                )}
            </tbody>
        </table>
        );
}