import React from 'react'
import { ShortYearDateTime } from '../shared/MKDateTime'
import { Loading } from '../shared/Loading'
import TeamMembershipStatusEditor from "./TeamMembershipStatusEditor";

export default function TeamMembersList({ teamMembers, loading, onStatusChange }) {
    
    if (loading)
        return <Loading />

    return (
        <table className='table table-full-width table-striped table-hover'>
            <thead>
                <tr>
                    <th>User Id</th>
                    <th>Name</th>
                    <th>Status</th>
                    <th>Joined</th>
                    <th>Modified</th>
                    <th>Kick Count</th>
                </tr>
            </thead>
            <tbody>
                { teamMembers.map((teamMember, index) =>
                <tr key={index}>
                    <td>{teamMember.userId}</td>
                    <td>{teamMember.user.name}</td>
                    <td>
                        { onStatusChange ?
                        <TeamMembershipStatusEditor selectedKey={teamMember.status} selectedValue={teamMember.statusString} onChange={newKey => onStatusChange(index, newKey)} />    
                        : 
                        teamMember.statusString
                        }
                    </td>
                    <td><ShortYearDateTime>{teamMember.joined}</ShortYearDateTime></td>
                    <td><ShortYearDateTime>{teamMember.lastModified}</ShortYearDateTime></td>
                    <td>{teamMember.kickCount}</td>
                </tr>
                )}
            </tbody>
        </table>
    );
}