import React, { useState, useEffect } from 'react'
import { AuthorizedGet } from '../../AuthorizedRequest'
import { useHistory, useParams } from 'react-router-dom'
import { Card } from '../../shared/Card'
import { Loading } from '../../shared/Loading'
import { shortYearDateTime, getDateTimeToolTip } from '../../shared/MKDateTime';
import ReceiptDetails from './ReceiptDetails'
import SupportTicketsButton from "../../Support/SupportTicketsButton";

export default function TransactionDetails()
{
    const [transaction, setTransaction] = useState(null)
    const [loading, setLoading] = useState(true)
    const history = useHistory()
    let { transactionId } = useParams()

    // Fetch 
    useEffect(() =>
    {
        AuthorizedGet(`api/Transaction/${transactionId}`, history)
        .then(result => 
        {
            setTransaction(result)
            setLoading(false)
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    if (loading)
        return <Loading />
    
    if (!transaction)
        return null

    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <div>
            <br/>
            <Card headerComponent={
                <div className="row">
                    <div className="col-md-10">
                        <h3>
                            Transaction Details
                        </h3>
                    </div>
                    <div className="col-md-2 text-end">
                        <SupportTicketsButton searchObject={{ userId: transaction.userId }}/>
                    </div>
                </div>
            }>
                <div className="row">
                    <div className="col-md-4">
                        <div className="form-group">
                            <label>Transaction Id</label>
                            <input className="form-control" value={transaction.transactionId} readOnly={true}/>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <label>User Id</label>
                            <input className="form-control" value={transaction.userId} readOnly={true}/>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <label>Date</label>
                            <input className="form-control" value={shortYearDateTime(transaction.dateTime)} readOnly={true} title={getDateTimeToolTip(transaction.dateTime)}/>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Store</label>
                            <input className="form-control" value={transaction.store} readOnly={true}/>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Store Transaction Id</label>
                            <input className="form-control" value={transaction.storeTransactionId} readOnly={true}/>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Receipt Id</label>
                            <input className="form-control" value={transaction.receiptId} readOnly={true}/>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Product Id</label>
                            <input className="form-control" value={transaction.productId} readOnly={true}/>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <div className="form-group">
                            <label>Amount</label>
                            <input className="form-control" value={transaction.amount} readOnly={true}/>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <label>Currency</label>
                            <input className="form-control" value={transaction.currency} readOnly={true}/>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <label>Base Amount</label>
                            <input className="form-control" value={transaction.baseAmount} readOnly={true}/>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <label>Base Currency</label>
                            <input className="form-control" value={transaction.baseCurrency} readOnly={true}/>
                        </div>
                    </div>
                </div>
            </Card>
            <ReceiptDetails hash={transaction.receiptId} showTransactions={false} showSupportButton={false} />
        </div>
    )
}