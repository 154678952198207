import React, { useState, useEffect, lazy, Suspense } from 'react'
import { useHistory } from 'react-router-dom'
import { AuthorizedGet, AuthorizedDelete } from '../AuthorizedRequest'
import { Card } from '../shared/Card'
import SoloEventsList from './SoloEventsList'
import SoloEventsSearch from './SoloEventsSearch'
import { soloEventsSearchCriteriaObject } from './SoloEventsSearchCriteria'
import { MKPaginationWithTotal, mkPagedResult } from '../shared/MKPagination'
import { composeQueryString, mapQueryStringValuesToObject, useQueryStringAndSessionState, useCache } from '../shared/Utils'
import { CollapseSearchButton, AddButtonText } from '../shared/Buttons'
import { Collapse } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {Loading} from "../shared/Loading";
const SoloEventsCalendar = lazy(() => import('./SoloEventsCalendar')) 


export default function SoloEvents()
{
    const initialEmptyValues = Object.assign({}, soloEventsSearchCriteriaObject);
    
    let history = useHistory();
    const [loading, setLoading] = useState(true);
    const [initialValues, setInitialValues] = useQueryStringAndSessionState(() => Object.assign({}, initialEmptyValues), history, history.location.search, 'soloEventsInitialValues');
    const [pagedResult, setPagedResult] = useState(mkPagedResult);
    const [searchCollapsed, setSearchCollapsed] = useCache('soloEventsSearchCollapsed', true);
    const [calendarCollapsed, setCalendarCollapsed] = useCache('soloEventsCalendarCollapsed', true);
    
    
    useEffect(() => {
        mapQueryStringValuesToObject(initialValues, history.location.search);
        searchSoloEvents(initialValues);        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const searchSoloEvents = (searchValues, formikBag = { setSubmitting: x => {} }, pageNumber = 1) => {
        searchValues.pageNumber = pageNumber;
        setLoading(true);
        setInitialValues(Object.assign({}, searchValues));
        
        AuthorizedGet('api/SoloEvent/Search?' + composeQueryString(searchValues), history)
            .then(result => 
            {
                console.log("SoloEvent results");
                console.log(result);
                setPagedResult(result ?? mkPagedResult);
                formikBag.setSubmitting(false);
                setLoading(false);
            });
    };

    const onPageClick = (e, pageNumber) => searchSoloEvents(initialValues, undefined, pageNumber);    

    const toggleSearch = e =>  {
        e.preventDefault();
        setSearchCollapsed(!searchCollapsed);
    };

    const toggleCalendar = e =>  {
        e.preventDefault();
        setCalendarCollapsed(!calendarCollapsed);
    };

    //============================================================
    // ToDo: should fetch all the pages to create de Calendar (?)
    //============================================================

    const createEvent = () =>
    {
        history.push("/SoloEvents/Create");
    };

    const onDelete = eventId => () =>
    {
        AuthorizedDelete(null, `api/SoloEvent/${eventId}`, history)
            .then(data =>
            {
                if (data)
                {
                    pagedResult.items = pagedResult.items.filter(function(s) { return s.id !== data.id });
                    pagedResult.itemsTotalCount--;
                    setPagedResult(Object.assign({}, pagedResult));
                }
            });
    };

    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////    
    return (
        <div>
            <div className="row align-items-center">
                <div className="md-8 col">
                    <h3>&nbsp;Events</h3>
                </div>
                <div className="md-4 col text-end">
                    <AddButtonText onClick={createEvent} toolTip="Create New Event">New Event</AddButtonText>
                    &nbsp;
                    <button className="btn-round btn btn-primary" onClick={toggleCalendar} title={calendarCollapsed ? 'Show Calendar' : 'Hide Calendar' }>
                        <FontAwesomeIcon size="lg" icon={calendarCollapsed ? 'angle-down' : 'angle-up' }/>
                        &nbsp;
                        <FontAwesomeIcon size="lg" icon="calendar" />
                    </button>
                    &nbsp;
                    <CollapseSearchButton collapsed={searchCollapsed} onClick={toggleSearch}/>
                </div>
            </div>
            
            <Collapse isOpen={!calendarCollapsed}>
                <Card>
                    { calendarCollapsed || pagedResult.itemsTotalCount == null ||
                    <Suspense fallback={<Loading/>}>
                        <SoloEventsCalendar events={pagedResult.items}/>                        
                    </Suspense>
                    }
                    {/* ToDo: If SoloEventsCalendar is shown by default, it will show nothing the first time => find a better approach than using { calendarCollapsed || } */}
                </Card>
            </Collapse>

            <Collapse isOpen={!searchCollapsed}>
                <Card>
                    <SoloEventsSearch initialValues={initialValues} handleSearch={searchSoloEvents}/>
                </Card>
            </Collapse>
           
            <Card>
                <SoloEventsList soloEvents={pagedResult.items} onDelete={onDelete} loading={loading}/>
                <MKPaginationWithTotal currentPage={pagedResult.pageNumber} total={pagedResult.pagesTotalCount} totalItems={pagedResult.itemsTotalCount} onClick={onPageClick} itemsName="Events"/>
            </Card>
        </div>
    )
}
