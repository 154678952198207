import React, {useEffect, useState} from 'react';
import {Loading} from "../shared/Loading";
import PlayFabServerDetailCard from "./PlayFabServerDetailCard";
import {AuthorizedGet, AuthorizedPost} from "../AuthorizedRequest";
import PlayFabServerRegionCard from "./PlayFabServerRegionCard";
import PlayFabServerInstanceCard from "./PlayFabServerInstanceCard";

export const playFabServerDetailObject = {
    buildId: "",
    buildName: "",
    startCommand: "",
    vmSize: "",
    artifacts: [],
    metadata: [],
    regions: []
};

export default function PlayFabDetails(props) {
   
    const [loading, setLoading] = useState(true);
    const [server, setServer] = useState({...playFabServerDetailObject});
    const [instanceDetails, setInstanceDetails] = useState(undefined);
    const [loadingInstances, setLoadingInstances] = useState(false);
    
    useEffect(() => {
        getServerDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    const onRegionClicked = regionName => () => {
        // Don't request data if we are already on this card
        if (regionName === instanceDetails?.regionName)
            return;
        
        loadRegion(regionName);
    }
    
    const loadRegion = (regionName) => {

        // Clear instance details (in part to remove the instance suffix) and set to laoding
        setInstanceDetails(undefined);
        setLoadingInstances(true);

        // Fetch data
        AuthorizedPost({
            buildId: server.buildId,
            region: regionName
        }, "api/PlayFab/GetInstancesForRegion")
            .then(result => {
                // Just patch in a calculated disabled state on the front end to save doing this on the API
                // it's just a QoL thing to help users getting errors if they spin up too many servers...
                // Which given how async PlayFab is isn't perfect but it's better than not having it imo.
                const regionSummary = server?.regions?.find(r => r.name === result.regionName);

                result = {
                    ...result,
                    canAddInstance: !regionSummary ? false : regionSummary.standbyInstances > 0
                };

                // Update state
                setInstanceDetails(result);
                setLoadingInstances(false);
            });
    };
    
    // Once we add a region refresh the instances list.
    // This may or may not show the desired result depending how long PlayFab takes to spin stuff up.
    const onAddInstance = (regionName) => {
        setLoadingInstances(true);
        AuthorizedPost(regionName, `api/PlayFab/AddInstance?BuildId=${server.buildId}`)
          .then(() => {
             loadRegion(regionName); 
          });
    };

    // Once we delete a region refresh the instances list.
    // This may or may not show the desired result depending how long PlayFab takes to spin stuff up.
    const onDeleteInstance = (sessionId, regionName) => {
        setLoadingInstances(true);
        AuthorizedPost(sessionId, 'api/PlayFab/RemoveInstance')
            .then(() => {
                loadRegion(regionName);
            });
    };
    
    const getServerDetails = () => {
        setLoading(true);
        // Get the build id for the server from the url
        const path = props.location.pathname;
        const buildId = path.substr(path.lastIndexOf('/') + 1);

        // Make a new request and populate the URL with the relevant search terms
        AuthorizedGet("api/PlayFab/" + buildId)
            .then(result => {
                setServer(result);
                setLoading(false);
            });
    }
    
    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    if (loading)
        return <Loading />
    
    return (
        <div>
            <PlayFabServerDetailCard server={server} />
            <div className="row">
                <div className="col-md-12">
                    <h5>A note on region and instance management.</h5>
                    <p>The PlayFab API is very asynchronous (takes a long time to do things). You might find changes to regions or instances do not show on this page immediately. Please be patient with these changes and refresh the page as required.</p>
                    <br />
                </div>
                <div className="col-md-6">
                    <PlayFabServerRegionCard regions={server.regions} buildId={server.buildId} onClick={onRegionClicked} onRegionsEdited={() => {getServerDetails();}} />
                </div>
                <div className="col-md-6">
                    <PlayFabServerInstanceCard instanceDetails={instanceDetails} loading={loadingInstances} onAddInstance={onAddInstance} onDeleteInstance={onDeleteInstance} />
                </div>
            </div>
        </div>
    );
}
