import React, {useEffect, useRef, useState} from 'react'
import {AuthorizedGet, AuthorizedPut} from '../../AuthorizedRequest'
import {useHistory, useLocation} from 'react-router-dom'
import {Card} from '../../shared/Card'
import {SupportTicketRow} from '../elements/SupportTicketRow'
import {Loading} from '../../shared/Loading'
import {of} from "rxjs";
import {delay, repeat} from "rxjs/operators";
import MKPaginationUncounted from "../../shared/MKPaginationUncounted";
import {useSupportTicketsListLocationState} from "../elements/SupportTicketsNavigator";


export default function SupportTickets({folder, ...props})
{
    const history = useHistory();
    const location = useLocation();
    const abortControllerRef = useRef(new AbortController());
    // schedule data refresh every 1 minute:
    const observableRef = useRef(of(1).pipe(delay(60000)).pipe(repeat()));
    const [loading, setLoading] = useState(false);
    const [pagedResult, setPagedResult] = useSupportTicketsListLocationState();


    useEffect(() =>
    {
        if (pagedResult.items == null)
            fetchData();
        
        const refreshSubscription = observableRef.current.subscribe(next => fetchData());
        let abortCtr = abortControllerRef.current;
        
        return () =>
        {
            abortCtr?.abort();
            refreshSubscription.unsubscribe();
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function fetchData(pageNumber)
    {
        setLoading(true);
        
        let searchParams = new URLSearchParams(location.search);
        searchParams.set('pageNumber', pageNumber ?? searchParams.get('pageNumber') ?? pagedResult.pageNumber);
        if (!searchParams.has('pageSize'))
            searchParams.set('pageSize', pagedResult.pageSize)
        let queryString = '?' + searchParams.toString();
        
        AuthorizedGet(`api/Support/${folder}${queryString}`, history, abortControllerRef.current.signal)
            .then(result =>
            {
                if (result)
                {
                    setPagedResult(result)
                    setLoading(false)
                    //history.replace({pathname: location.pathname + queryString, state: Object.assign(location.state ?? {}, {supportTicketsList: result})})
                }
            });
    }

    const onPageClick = (e, pageNumber) => fetchData(pageNumber);

    function onRowClick(supportTicket)
    {
        const path = `/Support/SupportTickets/${encodeURIComponent(supportTicket.id)}`  //Navigate to: Details/SupportTicketDetails.js
        history.push(path, {supportTicket: supportTicket, supportTicketsList: pagedResult, supportTicketsListHome: location.pathname})
    }

    function toggleArchived(supportTicket)
    {
        let path = supportTicket.status === "Closed" || supportTicket.status === "Deleted" ? "api/Support/MarkAsUnarchived/" : "api/Support/MarkAsArchived/"
        // eslint-disable-next-line no-unused-vars
        let promise = AuthorizedPut(null, `${path}${supportTicket.id}`, history);

        if (folder !== "Search")
        {
            pagedResult.items = pagedResult.items.filter((item) => item.id !== supportTicket.id);
        }
        else
        {
            supportTicket.status = supportTicket.status === "Closed" || supportTicket.status === "Deleted" ? "InProgress" : "Closed";
        }
        setPagedResult(Object.assign({}, pagedResult))
    }
    
    function getSearchParams() {
        let searchParams = new URLSearchParams(location.search);
        searchParams.delete("pageNumber");
        searchParams.delete("pageSize");
        return searchParams.toString();
    }

    let title = folder === "Search" ? <><h2>Search:&nbsp;</h2><h3 className="mt-1">{getSearchParams()}</h3></> : <h2>{folder}</h2>;

    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const paginationComponent = <MKPaginationUncounted currentPage={pagedResult.pageNumber} currentPageCount={pagedResult.currentPageCount} onClick={onPageClick}/>;

    return (
        <Card>
            <div className="card-content table-responsive table-full-width">
                <div className="row">
                    {title}
                    {loading && <Loading margin={10} size={20}/>}
                </div>
                {paginationComponent}
                <table className="table">
                    <thead>
                        <tr><th/></tr>
                    </thead>
                    <tbody>
                        {pagedResult.items?.map((ticket, index) =>
                        <SupportTicketRow supportTicket={ticket} key={index} index={index} onRowClick={onRowClick} toggleArchived={toggleArchived}/>)}
                    </tbody>
                </table>
                {paginationComponent}
            </div>

        </Card>
    )
}
