import React from 'react'
import { useHistory } from "react-router-dom"
import { DeleteButton } from '../shared/Buttons'
import { Loading } from '../shared/Loading'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { jsonSecureParse } from '../shared/Utils';

export default function ConfigurationsList({ configurations, onDelete, onDuplicateClick, loading, byKey = false }) {
    
    const history = useHistory();

    function onRowClick(packedAppVersion, configKey)
    {
        const path = `Configurations${byKey ? 'ByKey' : ''}/${packedAppVersion}/${configKey}`;
        history.push(path);
    }

    if (loading)
    {
        return <Loading />
    }

    return (
        <table className='table table-full-width table-striped table-hover'>
            <thead>
                <tr>
                    { byKey ||
                        <th>App Version</th>                        
                    }
                    <th>Config Key</th>
                    <th>Config Version</th>
                    <th>Config values</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                { configurations.map((configuration, index) =>
                <tr className="tr-link" onClick={() => onRowClick(configuration.packedAppVersion, configuration.configKey)} key={index}>
                    { byKey ||
                        <td><span className="text-monospace">{configuration.appVersion}</span></td>                        
                    }
                    <td><span className="text-monospace">{configuration.configKey}</span></td>
                    <td>{configuration.configVersion}</td>
                    <td><RawJSONViewer>{JSON.stringify(jsonSecureParse(configuration.config),null,2)}</RawJSONViewer></td>
                    <td className="text-end">                        
                        <button title="Duplicate" className="icon-button">
                            <FontAwesomeIcon icon="clone" onClick={onDuplicateClick(configuration)}/>
                        </button>
                        <DeleteButton onDelete={onDelete(configuration.packedAppVersion, configuration.configKey)} entityName="Configuration"/>
                    </td>
                </tr>
                )}
            </tbody>
        </table>
    );
}

export function RawJSONViewer({ children, maxHeight = 400 }) {
    return (
        <pre style={{ maxHeight: maxHeight.toString() + "px", overflowY: "auto"}}>
            {children}
        </pre>
    )
}