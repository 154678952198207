import React, {useRef, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Collapse, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {Card} from "../../shared/Card";
import {CollapseDocumentationButton} from "../../shared/Buttons";
import {JSONEditor} from "../../shared/JSONEditor";
import {AuthorizedPut} from "../../AuthorizedRequest";
import {useCache} from "../../shared/Utils";
import {useHistory} from "react-router-dom";

let startingExample = [
    {
        Key: 'UserPrefs_Key',
        DisplayName: 'Descriptive name to show',
        Editable: true,
        TreatAs: 0,
        JsonPath: 'InnerObject.Prop'
    }
];

let cacheKey = 'UserPrefsDashboardConfiguration_EditingValue';

export default function UserPrefsDashboardConfigurationPopUp({ dashboardSettings, settingsSaved })
{
    const [isOpen, setIsOpen] = useState(false);
    const [settings, setSettings] = useCache(cacheKey, dashboardSettings ?? startingExample, sessionStorage);
    const [documentationCollapsed, setDocumentationCollapsed] = useState(true);
    let content = useRef(dashboardSettings ?? startingExample);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const history = useHistory();
    
    let toggleModal = () => setIsOpen(!isOpen);

    const toggleDocumentation = e =>  {
        e.preventDefault();
        setDocumentationCollapsed(!documentationCollapsed);
    };

    const onChangeJSONEditor = json =>
    {
        content.current = json;
        sessionStorage[cacheKey] = JSON.stringify(json);
    };

    const saveSettings = e =>
    {
        e.preventDefault();

        setIsSubmitting(true);
        AuthorizedPut(content.current, 'api/Settings/UserPrefDashboardSettings', history)
            .then(result =>
            {
                sessionStorage.removeItem(cacheKey);
                settingsSaved(content.current);
                setIsSubmitting(false);
            });

        e.stopPropagation();
    };
    
    return (
        <>
            <button title="Configure dashboard" className="icon-button" onClick={e => { e.preventDefault(); toggleModal(); }}>
                <FontAwesomeIcon size="2x" icon="cog" />
            </button>
        
            <Modal isOpen={isOpen} toggle={toggleModal} contentClassName="card" size="xl">
                <ModalHeader toggle={toggleModal} tag="table">
                    <tr>
                        <td><h5 className="m-0">UserPrefs Dashboard configuration</h5></td>
                        <td className="text-end" width="20%">
                            <CollapseDocumentationButton collapsed={documentationCollapsed} onClick={toggleDocumentation} className="m-0"/>
                        </td>
                    </tr>
                </ModalHeader>
                <ModalBody>
                    <Collapse isOpen={!documentationCollapsed} onClick={toggleDocumentation}>
                        <Documentation/>
                    </Collapse>
                    <JSONEditor maxLines={40} value={settings} onChange={onChangeJSONEditor}/>
                </ModalBody>
                <ModalFooter style={{justifyContent: 'center'}}>
                    <button type="button" className="btn btn-primary col-md-2"
                            disabled={isSubmitting} onClick={saveSettings}>
                        <FontAwesomeIcon icon={isSubmitting ? "spinner" : "save"} spin={isSubmitting}/>
                        &nbsp;Save
                    </button>
                </ModalFooter>
            </Modal>
        </>
    );
}


function Documentation()
{
    return (
        <>
            <Card>
                A dashboard item is configured by the following fields:
                <ul>
                    <li><span class="font-monospace">key</span>: the UserPrefs target key</li>
                    <li><span class="font-monospace">displayName</span>: the friendly descriptive name to show in
                        the dasboard
                    </li>
                    <li><span class="font-monospace">editable</span>: indicates whether the value can be edited from
                        the dashboard
                        <ul><span class="font-monospace">true</span>: editable</ul>
                        <ul><span class="font-monospace">flase</span>: not editable</ul>
                    </li>
                    <li><span class="font-monospace">treatAs</span>: How the value of the item will be treated
                        <ul><span class="font-monospace">0</span>: <span class="fst-italic">string</span>; it will
                            be considered as free text</ul>
                        <ul><span class="font-monospace">1</span>: <span class="fst-italic">string multiline</span>;
                            free text shown in a textarea field
                        </ul>
                        <ul><span class="font-monospace">2</span>: <span class="fst-italic">number</span>; numeric
                            value
                        </ul>
                    </li>
                    <li><span class="font-monospace">jsonPath</span>: the field/patch to get the relevant value when
                        the UserPref is a JSON object; eg: <span class="fst-italic">_amount</span>, <span
                            class="fst-italic">nestedObject.value</span></li>
                </ul>
            </Card>
            <span/>
        </>
    );
}