import React from 'react'
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap'
import TotalCount from './TotalCount'

const defaultPageSize = 50;

export const mkPaginationObject = {
    pageNumber: 1,
    pageSize: defaultPageSize
};

export const mkPagedResult = {
    itemsTotalCount: null,
    pageNumber: 1,
    pageSize: defaultPageSize,
    pagesTotalCount: 0,
    items: []
};

export function setPagedResultPage(pagedResult, allItems, pageNumber)
{
    pagedResult.itemsTotalCount = allItems.length;
    pagedResult.pagesTotalCount = Math.ceil(allItems.length / pagedResult.pageSize);
    pagedResult.items = allItems.slice((pageNumber - 1) * pagedResult.pageSize, pageNumber * pagedResult.pageSize);
    pagedResult.pageNumber = pageNumber;
    return pagedResult;
}

export default function MKPagination({ total, currentPage, onClick })
{
  
    if (total <= 1)
    {
        return <div/>
    }

    const createPages = () =>
    {
        let pages = [];
        
        let i = currentPage;
        let j = currentPage + 1;
        let visiblePages = Math.min(total, 7);
        
        while (j - i < visiblePages) {
            if (i > 1) i--;
            if (j <= total) j++;
        }
        //console.log(`Pagination control: i = ${i} j = ${j} total = ${total} current = ${currentPage}`);

        for (i; i < j; i++)
        {
            pages.push(
                // eslint-disable-next-line eqeqeq
                <PaginationItem key={i} active={i == currentPage} disabled={i == currentPage}>
                    <PaginationLink value={i} onClick={onPageClick(i)}>{i}</PaginationLink>
                </PaginationItem>
            );
        }
        return pages
    }

    const onPageClick = pageNumber => e => onClick(e, pageNumber);

    // eslint-disable-next-line eqeqeq
    const lastPage = currentPage == total;

    return (
        <Pagination>
            <PaginationItem>
                <PaginationLink value={1} first onClick={onPageClick(1)} disabled={currentPage <= 1} />
            </PaginationItem>
            <PaginationItem >
                <PaginationLink value={currentPage ? currentPage - 1 : 0} previous onClick={onPageClick(currentPage ? currentPage - 1 : 0)} disabled={currentPage <= 1}/>
            </PaginationItem>
            {createPages()}
            <PaginationItem>
                <PaginationLink value={currentPage + 1} next onClick={onPageClick(currentPage + 1)} disabled={lastPage} />
            </PaginationItem>
            <PaginationItem >
                <PaginationLink value={total} last onClick={onPageClick(total)} disabled={lastPage} />
            </PaginationItem>
        </Pagination>
        );
}

export function MKPaginationWithTotal({ total, currentPage, totalItems, onClick, itemsName })
{
    return (
        <div className="row">
            <div className="md-10 col">
                <MKPagination currentPage={currentPage} total={total} onClick={onClick}/>
            </div>
            <div className="md-2 col d-flex align-items-center justify-content-end">
                <TotalCount itemsName={itemsName}>{totalItems}</TotalCount>
            </div>
        </div>
    );
}