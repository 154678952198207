import React, { useState, useRef } from 'react'
import { KeyValueEditorUnmanaged } from './KeyValueEditor'
import { ButtonToolbar } from 'reactstrap'
import Button from 'react-bootstrap/Button'
import { jsonSecureParse } from './Utils'
import { JSONEditor } from './JSONEditor';

/**
 * Edit a JSON object made up of plain key/value pairs [unmanaged].
 * It has two edit modes: edit as JSON and edit as key/value pairs.
 * The component must receive the initial value of the JSON object to be edited in the [jsonString] parameter.
 * When the JSON object is modified, the callback method in the [onChange] parameter is called with the JSON object (as a JavaScript object, not serialized) as the parameter.
 * => It is important that the containing component does not serialize and pass back the JSON object as [jsonString], to avoid making a loop which is against the unmanaged nature of this component.
 *    The parent code should serialize back the object only when it is finally needed (eg: just before saving).
 *
 * @export
 * @param {Object} props - Component properties
 * @param {string} props.jsonString - Initial value of the serialized JSON objet to edit
 * @param {JSONEditorOnChangeCallback} props.onChange - Callback function called after JSON is modified (only when it is valid). Parameter: The modified JSON object 
 * @param {string} props.valueTypes - The possible types of the values. If ['text'] is specified, there will be no restriction
 * @returns
 */
export default function JSONKeyValueEditor({ jsonString, onChange, valueTypes , editAsJson = false})
{
    const [editJson, setEditJson] = useState(editAsJson);
    let content = useRef(jsonSecureParse(jsonString ?? "{}"));
    const [contentArray, setContentArray] = useState(() => getNewContentArray(content.current ?? [])); //Must be an array, otherwise (if it is an object) when changing key's names the keys' order is randomly modified resulting in the keys/values rendering in new order
    
    const switchToJSONEditor = () =>
    {
        content.current = getNewContent(contentArray);
        setEditJson(true);
    };

    const switchToKeyValueEditor = () =>
    {
        setContentArray(getNewContentArray(content.current));
        setEditJson(false);
    };

    const addCode = codeKey => e =>
    {
        let codeKey1 = codeKey;
        let i = 0;
        // eslint-disable-next-line no-loop-func
        while (contentArray.find(item =>
        // eslint-disable-next-line eqeqeq    
            item[0] == codeKey1))
        {   //already exists
            i++;
            codeKey1 = codeKey + i; 
        }        
        contentArray.push([codeKey1, 0]);
        setContentArray([...contentArray]); //To re-render
        onChangeContentArray();
    };

    const updateCodeValue = index => (e, value) =>
    {
        contentArray[index][1] = value;
        onChangeContentArray();
    };

    const updateCodeKey = index => (e, keyValue) =>
    {
        contentArray[index][0] = keyValue;
        onChangeContentArray();
    };

    const removeCode = index => (e, value) =>
    {
        contentArray.splice(index, 1);
        setContentArray([...contentArray]); //To re-render
        onChangeContentArray();
    };

    const onChangeContentArray = () =>
    {        
        onChange(getNewContent(contentArray));        
    };

    const onChangeJSONEditor = json =>
    {        
        content.current = json; 
        onChange(json);
    };

    function getNewContent(cArray)
    {
        let newC = {};
        cArray.map(item => newC[item[0]] = item[1]);
        return newC;
    }

    function getNewContentArray(c)
    {
        let newCA = [];
        Object.keys(c).map(codeKey => newCA.push([codeKey, c[codeKey]]));
        return newCA;
    }
    

    return (
        <>
            { editJson ?
            <>
                <JSONEditor maxLines={40} value={content.current} onChange={onChangeJSONEditor} />
                <div>
                    <Button size="sm" variant="secondary" onClick={switchToKeyValueEditor}>Edit as Keys/Values</Button>                    
                </div>
                <br/>
            </>
            :
            <>
                { contentArray.map((item, index) =>
                    <KeyValueEditorUnmanaged key={`${index}_${item[0]}`} editKey={true} aKey={item[0]} aValue={item[1]} index={index} valueTypes={valueTypes}
                                    onChangeValue={updateCodeValue(index)} 
                                    onChangeKey={updateCodeKey(index)} 
                                    removeKey={removeCode(index)} />) 
                }
                <ButtonToolbar>
                    <Button size="sm" variant="secondary" onClick={addCode("key")}>Add Key/Value</Button>
                    <Button size="sm" variant="secondary" onClick={switchToJSONEditor}>Edit as JSON</Button>
                </ButtonToolbar>
            </>
            }
        </>
    );
}