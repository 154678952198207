import React from 'react'
import {Redirect, Route, Switch, NavLink} from 'react-router-dom'
import LeaderboardCreate from "./LeaderboardCreate";
import LeaderboardEdit from "./LeaderboardEdit";
import Leaderboards from "./Leaderboards";
import LeaderboardEntries from "./LeaderboardEntries";
import LeaderboardsCheating from "./LeaderboardsCheating";

const menuItems = [
    {key: 'Solo', name: 'For Events', title: 'Leaderboards associated to an event'},
    {key: 'General', name: 'General', title: 'General leaderboards'},
    {key: 'Entries', name: 'Score Entries', title: 'Score entries for leaderboards'},
    {key: 'Cheating', name: 'Cheating', title: 'Cheating configuration for leaderboards'}
];

export default function LeaderboardsSubmenu(props)
{
    let lastType = localStorage['LeaderboardsLastType'] ?? menuItems[0].key;
    
    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <div>

            <div className="row align-items-center">
                <div className="md-8 col">
                    <h3>Leaderboards</h3>
                </div>
            </div>
            <br/>
            
            <div className="row">
                <div className="col-lg-2 col-md-12">
                    <div className="card card-plain">
                        <div className="card-content">
                            <ul className="nav nav-stacked" role="tablist">
                                {menuItems.map(x =>
                                    <li key={x.key} className="w-100">
                                        <NavLink to={'/Leaderboards/' + x.key} className="nav-link" activeClassName="active" >
                                            <p title={x.title}>{x.name}</p>
                                        </NavLink>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-lg-10 col-md-12">
                    <Switch>
                        <Route exact path='/Leaderboards/' component={() => <Redirect to={'/Leaderboards/' + lastType}/>} />
                        <Route exact path='/Leaderboards/Solo' component={props => <LeaderboardsLastTypeCache type="Solo" {...props}/>} />
                        <Route exact path='/Leaderboards/General' component={props => <LeaderboardsLastTypeCache type="General" {...props}/>} />
                        <Route exact path='/Leaderboards/Entries' component={LeaderboardEntries} />
                        <Route exact path='/leaderboards/Cheating' component={LeaderboardsCheating} />
                        <Route exact path='/leaderboards/create' component={LeaderboardCreate} />
                        <Route exact path='/leaderboards/create/:type' component={LeaderboardCreate} />
                        <Route exact path='/leaderboards/:leaderboardId' component={LeaderboardEdit} />
                    </Switch>
                </div>
            </div>
        </div>
    )
}

function LeaderboardsLastTypeCache(props)
{
    localStorage['LeaderboardsLastType'] = props.type;
    
    return (
        <Leaderboards {...props}/>
    );
}
