import React, { useState, useEffect } from 'react'
import { JSONEditorAndText } from '../shared/JSONEditor'
import { useHistory, useParams} from "react-router-dom"
import { AuthorizedGet } from '../AuthorizedRequest'
import { Card } from '../shared/Card'
import { Loading } from '../shared/Loading'
import { shortYearDateTime, getDateTimeToolTip } from '../shared/MKDateTime'

export default function ReplayDetails(props)
{
    const [replay, setReplay] = useState(null)
    const [loading, setLoading] = useState(true)
    let {name, version, userId} = useParams()
    const history = useHistory()

    // Fetch 
    useEffect(() => 
    {
        AuthorizedGet(`api/Replay/${userId}/${name}/${version}`, history)
            .then(result => 
            {
                setReplay(result);
                setLoading(false);
            });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    if (loading)
        return <Loading />
    
    if (!replay)
        return null
    
    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <div>
            <br/>
            <Card title="Replay Details"> 
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Name (Identifier)</label>
                            <input className="form-control" value={replay.name} readOnly={true}/>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Version</label>
                            <input className="form-control" value={replay.version} readOnly={true}/>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>User Id</label>
                            <input className="form-control" value={replay.userId} readOnly={true}/>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Last Updated</label>
                            <input className="form-control" value={shortYearDateTime(replay.updated)} readOnly={true} title={getDateTimeToolTip(replay.updated)}/>
                        </div>
                    </div>
                </div>
            </Card>
            <br/>
            <Card title="JSON">
                { replay.json && 
                <JSONEditorAndText valueString={replay.json} />
                }
            </Card>
        </div>
    )
}