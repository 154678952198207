import React, {useRef, useState} from 'react'
import { useHistory } from 'react-router-dom'
import { AuthorizedPostRaw} from '../../AuthorizedRequest'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { UploadButtonText } from '../../shared/Buttons'
import { Loading } from '../../shared/Loading'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function UserPrefsUpload({userId, onUserPrefsChanged})
{
    const [uploading, setUploading] = useState(false);
    const [modal, setModal] = useState(false);
    const fileRef = useRef();
    const fileLabelRef = useRef();
    const history = useHistory();
    
    const fileLabelText = 'Choose file (.ppo extension)'; 

    const toggleModal = () => setModal(!modal);

    const onFileChanged = e => {
        fileLabelRef.current.innerHTML = fileRef.current.value ? 
            `<span class="font-weight-bold text-monospace">${fileRef.current.value.replace('C:\\fakepath\\', '')}</span>` :
            fileLabelText;
    };

    const uploadFileClick = e =>
    {
        e.preventDefault();
        if (!fileRef.current || fileRef.current.files.length === 0)
        {
            alert('Please select a file');
        }
        else
        {
            let formData = new FormData();
            formData.append('file', fileRef.current.files[0]);
            setUploading(true);
            AuthorizedPostRaw(formData,`api/User/UploadUserPrefsPlistFile/${userId}`, history, undefined, null)  //'multipart/form-data')
                .then(result =>
                {
                    if (result)
                    {
                        let modified = (new Date()).getTime().toString();   
                        result.forEach(x => x.modified = modified); //To get a different key for the array's map() and be able to refresh the ui
                        onUserPrefsChanged(result);
                        toggleModal();
                    }
                })
                .finally(() => setUploading(false));
        }
    };

    return (
        <>
            <button type="button" title="Upload a Property List (plist) XML file (.ppo extension) to overwrite current user prefs"
                    className="btn btn-sm shadow-none btn-link m-0" disabled={uploading}>
                <FontAwesomeIcon className="blue" icon="file-upload" size="2x" onClick={toggleModal}/>
            </button>

            <Modal isOpen={modal} toggle={toggleModal} contentClassName="card">
                <ModalHeader toggle={toggleModal}>Upload User Prefs file</ModalHeader>
                <ModalBody>
                    <div className="container ">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                Upload new User Prefs as a Property List (plist) XML file
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <hr/>
                                {uploading ?
                                    <>
                                        <label>[<i> Uploading file... </i>]</label>
                                        <Loading/>
                                    </>
                                    :
                                    <>
                                        <div className="custom-file">
                                            <input type="file" className="custom-file-input" id="inputGroupFile" accept=".ppo" ref={fileRef} onChange={onFileChanged} style={{cursor: "hand"}}/>
                                            <label className="custom-file-label" htmlFor="inputGroupFile" style={{cursor: "hand"}} ref={fileLabelRef}>{fileLabelText}</label>
                                        </div>
                                    </>
                                }
                                <hr/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <span className="text-info font-italic">
                                    The currents User Prefs will be <b>overwritten</b> with the ones contained in the file to upload. 
                                </span>
                                <br />
                                <span className="text-warning">
                                    <b>This action cannot be undone</b>.
                                </span>
                                <br />
                                <span className="text-info">
                                    It is suggested to <b>make a backup</b> beforehand (<i>download the current User Prefs as a .ppo file</i>).
                                </span>
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter style={{justifyContent: 'center'}}>
                    <UploadButtonText onClick={uploadFileClick} toolTip="Upload Property List (plist) XML file to overwrite current user prefs" disabled={uploading}>
                        { uploading ? <>[ <i>Uploading...</i> ]</> : "Upload new User Prefs"}
                    </UploadButtonText>
                </ModalFooter>
            </Modal>
        </>
    );
}
