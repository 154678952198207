import React, {useEffect, useState} from 'react'
import {AuthorizedGet, AuthorizedPost, AuthorizedPut} from "../AuthorizedRequest";
import {useHistory} from "react-router-dom";
import {Field, Form, Formik} from "formik";
import {Card, CardContrast} from "../shared/Card";
import {ErrorDisplay, TextFieldInputColumn, ToggleInputManaged} from "../shared/FormElements";
import {SubmitButton} from "../shared/Buttons";
import {Loading} from "../shared/Loading";
import useInterval from "../../hooks/useInterval";
import {UserIdFieldInputColumn} from "../Users/UserIdFieldInputColumn";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import {JSONEditorReadOnly} from "../shared/JSONEditor";

const SettingsForm = ({initialValues, onSubmit}) => {
    const validate = () => {}
    return <div>
        <Formik
            enableReinitialize
            validateOnChange
            validate={validate}
            initialValues={initialValues}
            onSubmit={(values) => {
                onSubmit({
                    ...values,
                    eventNames: (values.eventName && [values.eventName]) || [],
                    userIds: (values.userId && [values.userId]) || []
                })
            }}>
            {({handleSubmit, isSubmitting, errors, touched, setFieldTouched}) =>
                <Form onSubmit={handleSubmit}>
                    <Card title='Stream settings'>
                        <div className="row">
                            <Field name="enabled" 
                                   component={ToggleInputManaged} 
                                   labelText="Enabled" 
                                   readOnly={false}
                                   errors={errors.enabled}
                                   column={4}/>
                            <Field name="allUsers" 
                                   component={ToggleInputManaged} 
                                   labelText="All Users" 
                                   readOnly={false}
                                   errors={errors.allUsers}
                                   column={4}/>
                            <Field name="allEvents" 
                                   component={ToggleInputManaged} 
                                   labelText="All Events" 
                                   readOnly={false}
                                   errors={errors.allEvents}
                                   column={4}/>

                            {/** Only a single User Id is supported at the moment **/}
                            <Field name="userId"
                                   component={UserIdFieldInputColumn} 
                                   labelText="User Id" 
                                   column={4}/>

                            {/** Only a single Event Name is supported at the moment **/}
                            <Field name="eventName" 
                                   errors={errors.userId} 
                                   component={TextFieldInputColumn} 
                                   labelText="Event Name" 
                                   readOnly={false} 
                                   column={4}/>
                          
                        </div>
                        <ErrorDisplay errors={errors}/>
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <SubmitButton errors={errors} isSubmitting={isSubmitting} touched={touched}/>
                            </div>
                        </div>
                    </Card>
                </Form>
            }
        </Formik>
    </div>
}

const extractTimestampFromStreamId = (streamId) => {
    return Number(streamId.split('-')[0]);
}

const prettyPrintJson = (data) => {
    let parsedJson = null;
    try {
        parsedJson = JSON.parse(data);
        return JSONEditorReadOnly({
            value: parsedJson,
            valueString: data
        });
    }
    catch(e)
    {
        return <CardContrast>
            <small><p style={{ fontFamily: 'monospace' }}>{data}</p></small>
        </CardContrast>
    }
}

// Sort so items appear newest to oldest
function compareItems(a, b) {
    if (a.id < b.id) {
        return 1;
    }
    if (a.id > b.id) {
        return -1;
    }
    return 0;
}

export default function AnalyticsStream (){ 

    const [items, setItems] = useState([]);
    const history = useHistory();
    const [settings, setSettings] = useState(null);
    const [payloadModal, setPayloadModal]  = useState(null);
    const isDataModelOpen = payloadModal != null;
    
    const openDataModal = (timestampId, eventName, userId, data) => {
        setPayloadModal({
            data,
            timestampId,
            eventName,
            userId
        })
    }

    const closeDataModal = () => {
        setPayloadModal(null);
    }
    
    const queryAnalytics = () => {
        const lastId = items[0]?.id;
        let min = "-";
        if(lastId)
        {
            const timestamp = extractTimestampFromStreamId(lastId);
            min = (timestamp + 1).toString();
        }

        return AuthorizedPost({all: true, min, max: Date.now().toString()}, 'api/Analytics/Query', history)
            .then(result =>
            {
                setItems((prevState) => [...prevState, ...result].sort(compareItems).splice(-1000));
            });
    }
    
    useEffect(() => {
        AuthorizedGet('api/Analytics/Settings', history)
            .then(result =>
            {
                setSettings({
                    ...result,
                    userId: result.userIds[0] || '',
                    eventName: result.eventNames[0] || '',
                });
            });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    useInterval(queryAnalytics, 5000);
    
    const onSettingsSubmit = (values) => {
        setSettings(values);
        return AuthorizedPut(values, 'api/Analytics/Settings', history)
            .then();
    }

    const toggleModal = () => {
        isDataModelOpen ? closeDataModal() : openDataModal();
    }
    
    return (
        <div>
            <Modal isOpen={isDataModelOpen} toggle={toggleModal} contentClassName="card" size="xl">
                <ModalHeader toggle={toggleModal}>
                    {payloadModal && `${payloadModal.timestampId} | ${payloadModal.eventName} | ${payloadModal.userId}`}
                </ModalHeader>
                <ModalBody>
                    <div style={{width: '400px', height: '100%'}}>
                        {payloadModal && prettyPrintJson(payloadModal.data)}
                    </div>
                </ModalBody>
            </Modal>
            <div>
                { settings == null ? <Loading/> : <SettingsForm initialValues={settings} onSubmit={onSettingsSubmit} />}
            </div>
            <div>
                <h1>Stream:</h1>
                <table className='table table-full-width table-striped table-hover'>
                    <thead>
                    <tr>
                        <th>Date Time (DD/MM/YY, HH:MM:SS)</th>
                        <th>User Id</th>
                        <th>Event Name</th>
                        <th>Data</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {items.map((item, index) =>
                        <tr className="tr-link" key={index}>
                            <td>{new Date(extractTimestampFromStreamId(item.id)).toLocaleString()}</td>
                            <td>{item.data.userId}</td>
                            <td>{item.data.event.name}</td>
                            <td>
                                <button title='Inspect data' className="icon-button">
                                    <FontAwesomeIcon className="open" size="2x" icon="angle-right" onClick={ () => openDataModal(item.id, item.data.event.name, item.data.userId, item.data.event.data) }/>
                                </button>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
        </div>
    );
}