/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useRef} from 'react'
import {useField} from 'formik'
import Toggle from 'react-toggle'
import MultiToggle from 'react-multi-toggle'
import {useToasts} from 'react-toast-notifications'

export const TextFieldInputColumn = ({
                                         field,
                                         form: {touched, errors},
                                         type = "text",
                                         labelText,
                                         placeholder,
                                         readOnly,
                                         toolTip,
                                         column = 12,
                                         ...props
                                     }) => {

    const ref = useRef();

    useEffect(() => {
        if (props.autofocus)  // autoFocus attribute not working with Formik
            ref.current.focus();
    }, [props.autofocus]);

    return (
        <div className={`col-md-${column}`}>
            <div className="form-group">
                <label title={toolTip}>{labelText}</label>
                {props.onChange ?
                    <input {...field} placeholder={placeholder} type={type}
                           className={props.errors ? "form-control error" : "form-control"}
                           onChange={props.onChange} readOnly={readOnly} title={toolTip} maxLength={props.maxLength}
                           min={props.min} max={props.max} ref={ref}/>
                    :
                    <input {...field} placeholder={placeholder} type={type}
                           className={props.errors ? "form-control error" : "form-control"}
                           readOnly={readOnly} title={toolTip} maxLength={props.maxLength} min={props.min}
                           max={props.max} ref={ref}/>
                }
            </div>
        </div>
    );
}

export const SelectField = ({field, toolTip, labelText, column = 12, ...props}) => {
    return (
        <div className={`col-md-${column}`}>
            <div className="form-group">
                <label title={toolTip}>{labelText}</label>
                {
                    props.onChange ?
                        <select {...field} className={`form-control form-select ${(props.errors ? " error" : "")}`} onChange={props.onChange}>
                            {props.children}
                        </select>
                        :
                        <select {...field} className={`form-control form-select ${(props.errors ? " error" : "")}`}>
                            {props.children}
                        </select>
                }
            </div>
        </div>
    );
};


export const TextFieldInput = ({field, form: {touched, errors}, labelText, readOnly, ...props}) =>
    (
        <div className="input-group mb-3">
            <div className="input-group-prepend">
                {props.onChange ?
                    <input {...field} className={props.errors ? "form-control error" : "form-control"}
                           readOnly={readOnly} onChange={props.onChange}/>
                    :
                    <span className={props.errors ? "input-group-text error" : "input-group-text"}>{labelText}</span>
                }
            </div>
            <input {...field} className={props.errors ? "form-control error" : "form-control"} readOnly={readOnly}/>
        </div>
    )


export const TextAreaInput = ({field, errors, labelText, column = 10, ...props}) => {
    const className = `support-message form-control${errors ? " error" : ""}`
    return (
        <div className="form-group">
            <div className={'col-sm-' + column}>
                <label>
                    {labelText}
                    {props.onChange ?
                        <textarea {...field} className={className} readOnly={props.readOnly} onChange={props.onChange}
                                  minLength={props.minLength} maxLength={props.maxLength} />
                        :
                        <textarea {...field} className={className} readOnly={props.readOnly}
                                  minLength={props.minLength} maxLength={props.maxLength} />
                    }
                </label>
            </div>
        </div>)
}


export const TextFieldInputTable = ({field, form: {touched, errors}, labelText, readOnly, ...props}) =>
    (
        <tr>
            <td>{labelText}</td>
            <td style={{width: "40%"}}><input {...field} className="form-control" readOnly={readOnly}/></td>
        </tr>
    )


export const ToggleInput = ({field, form: {touched, errors}, labelText, column = 12, ...props}) =>
    (
        <div className={`col-md-${column}`}>
            <div className="form-group">
                <label>{labelText}</label>
                <input type="checkbox" {...field} className="form-control" hidden/>
                <Toggle checked={props.checked} icons={false} onChange={props.onToggle}/>
                <p>{props.checked ? props.onLabel : props.offLabel}</p>
            </div>
        </div>
    )


export const ToggleInputManaged = ({field, labelText, column = 12, onLabel, offLabel, ...props}) => {

    const [/*input*/, meta, helpers] = useField(field.name);
    const {value} = meta;
    const {setValue, setTouched} = helpers;
    return (
        <div className={`col-md-${column}`}>
            <div className="form-group">
                <label>{labelText}</label>
                <input type="checkbox" {...field} checked={value} className="form-control" hidden/>
                <Toggle checked={value} icons={false} onChange={() => {
                    setValue(!value)
                    setTouched(true)
                }}/>
                { (onLabel || offLabel) &&
                    <p>{value ? onLabel : offLabel}</p>
                }
            </div>
        </div>
    )
}


const MultiToggleInput = ({groupOptions, field, labelText, toolTip, column = 12, ...props}) => {

    const [/* input */, meta, helpers] = useField(field.name);
    const {value} = meta;
    const {setValue, setTouched} = helpers;

    const convertIn = x => {
        switch (x) {
            case true:
            case 'true':
            case 'True':
                return 1;
            case false:
            case 'false':
            case 'False':
                return -1;
            default:
                return 0;
        }
    };

    const convertOut = x => {
        switch (x) {
            case 1:
                return true;
            case -1:
                return false;
            default:
                return null;
        }
    };

    return (
        <div className={`col-md-${column}`}>
            <div className="form-group">
                <label title={toolTip}>{labelText}</label>
                <MultiToggle className="toggle-wrapper form-control"
                             options={groupOptions}
                             selectedOption={convertIn(value)}
                             onSelectOption={x => {
                                 setValue(convertOut(x));
                                 setTouched(true);
                             }}
                    //label="Select Group Size"
                />
            </div>
        </div>
    );
}

export const NullableToggleInput = ({
                                        field,
                                        labelText,
                                        toolTip,
                                        column = 12,
                                        trueText = 'True',
                                        nullText = 'Ignore',
                                        falseText = 'False',
                                        ...props
                                    }) => {

    const groupOptions = [
        {
            displayName: trueText,
            value: 1  // => will be converted to [true]
        },
        {
            displayName: nullText,
            value: 0  // => will be converted to [null]
        },
        {
            displayName: falseText,
            value: -1 // => will be converted to [false]
        }
    ];

    return <MultiToggleInput {...{groupOptions, field, labelText, toolTip, column, ...props}} />;
}


export const BoolToggleInput = ({
                                    field,
                                    labelText,
                                    toolTip,
                                    column = 12,
                                    trueText = 'True',
                                    falseText = 'False',
                                    ...props
                                }) => {

    const groupOptions = [
        {
            displayName: trueText,
            value: 1  // => will be converted to [true]
        },
        {
            displayName: falseText,
            value: -1 // => will be converted to [false]
        }
    ];

    return <MultiToggleInput {...{groupOptions, field, labelText, toolTip, column, ...props}} />;
}

/**
 * Shows error validation messages as toast notifications
 * Each field of the error object will be show as a toast notification
 *
 * @param { Errors object: { ErrorField: "Error message" } } {errors}
 * @param { List of touched elements } {touched}
 * @param { Warnings object: { WarningField: "Warning message" } } {warnings}
 * @returns
 */
export const ErrorDisplay = ({errors, touched = null, warnings = null}) => {
    const toastsDictionary = useRef({}); // maintains references to current loaded toast notification Ids related to their corresponding error as { "errorKey": "toastId" }
    const wToastsDictionary = useRef({}); // current toasts for warnings
    const {addToast, removeToast, updateToast} = useToasts();

    function clearToasts(tDictionary) {
        for (const [ , toastId] of Object.entries(tDictionary.current)) {
            removeToast(toastId);
        }
    }

    useEffect(
        () => processToasts(errors, toastsDictionary, touched, 'warning')
        , [errors, touched]
    );
    
    useEffect(
        () => processToasts(warnings, wToastsDictionary, null, 'info')
        , [warnings]
    );

    useEffect(() => () => { clearToasts(toastsDictionary); clearToasts(wToastsDictionary); }
        , []    //Remove any existing toast when leaving the page (componentWillUnmount)
    ); 
    
    function processToasts(messages, tDictionary, touched, toastAppearance)
    {
        if (!messages) 
        {   //If there is no errors, removes any remaining toast
            clearToasts(tDictionary);
            tDictionary.current = {};
        } 
        else 
        {
            let oldToastsDictionary = tDictionary.current;
            tDictionary.current = {};

            const messageEntries = Object.entries(messages);
            messageEntries.forEach(([messageKey, value]) => 
            {
                if(typeof value !== 'string') 
                {
                    return;
                }

                if (touched === null || touched[messageKey]) 
                {
                    const toastContent = <span title={messageKey}>{value}</span>;
                    if (oldToastsDictionary.hasOwnProperty(messageKey)) 
                    {   //If there is already a toast notification loaded for the error, it is updated
                        let toastId = oldToastsDictionary[messageKey];
                        updateToast(toastId, {content: toastContent});
                        tDictionary.current[messageKey] = toastId;
                    } 
                    else 
                    {   //A new toast is created
                        addToast(toastContent, {
                                appearance: toastAppearance, autoDismiss: true,
                                onDismiss: tId => delete tDictionary.current[messageKey]
                            },
                            tId => tDictionary.current[messageKey] = tId);
                    }
                }
            });

            for (const [messageKey, toastId] of Object.entries(oldToastsDictionary)) 
            {
                if (!tDictionary.current.hasOwnProperty(messageKey))    //Clear messages that don't exists anymore
                    removeToast(toastId);
            }
        }
    }

    return null;
}