import React, {useState, useEffect} from 'react'
import {AuthorizedGet} from "../AuthorizedRequest";
import {Loading} from "../shared/Loading";
import {Card} from "../shared/Card";
import {useHistory} from "react-router-dom";

export default function UserIdentityViewer({userId}) { 
    
    const [userIdentifiers, setUserIdentifiers] = useState([]);
    const [loading, setLoading] = useState(true);
    const history = useHistory();
    
    useEffect(() => {
        AuthorizedGet(`api/User/GetIdentifiers/${userId}`, history)
            .then(result =>
            {
                setUserIdentifiers(result);
                setLoading(false);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    return (
        <Card title={"Linked Identifiers"}>
            {   loading ?
                <Loading /> : 
                userIdentifiers.length ? 
                <>
                    <p>This user has the following external providers linked to their account:</p>
                    <ul>
                        {
                            userIdentifiers.map(x => <li key={x}>{x}</li>)
                        }
                    </ul>
                </> 
                : 
                <p>This user doesn't have any external providers linked to their account.</p>
            }
        </Card>
    )
    
}