import React, {useState} from "react";
import {IconButton} from "../shared/Buttons";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import MKNetSettingsStringArray from "./MKNetSettingsStringArray";


export default function MKNetSettingsStringArrayPopUp({MKNetSettingsKey, title, subtitle, valueNamePattern})
{
    const [modalOpen, setModalOpen] = useState(false);

    const toggleModal = () => {
        setModalOpen(v => !v);
    }
    
    return (
        <>
            <div style={{display: 'flex', justifyContent: 'end'}}>
                <div>
                    <IconButton onClick={toggleModal} toolTip={title} icon="cog">Settings</IconButton>
                </div>
            </div>
            <Modal isOpen={modalOpen} toggle={toggleModal} contentClassName="card" size="lg">
                <ModalHeader toggle={toggleModal}>{title}</ModalHeader>
                <ModalBody>
                    <MKNetSettingsStringArray MKNetSettingsKey={MKNetSettingsKey} title={subtitle} valueNamePattern={valueNamePattern}/>
                </ModalBody>
            </Modal>
        </>
    );
}