import React from 'react'
import {Redirect, Route, Switch, NavLink} from 'react-router-dom'
import SoloEvents from "./SoloEvents";
import Leaderboards from "../Leaderboards/Leaderboards";
import SoloEventCreate from "./SoloEventCreate";
import SoloEventTemplates from "./SoloEventTemplates";
import SoloEventEdit from "./SoloEventEdit";
import SoloEventTemplateCreate from "./SoloEventTemplateCreate";
import SoloEventTemplateEdit from "./SoloEventTemplateEdit";
import LeaderboardTemplateEdit from "../Leaderboards/LeaderboardTemplateEdit";
import SoloEventCreateFromTemplate from "./SoloEventCreateFromTemplate";
import LeaderboardEdit from "../Leaderboards/LeaderboardEdit";

const menuItems = [
    {key: 'Events', name: 'Events', title: 'List of Solo Events'},
    {key: 'Leaderboards', name: 'Leaderboards', title: 'Leaderboards related to events'},
    {key: 'Templates', name: 'Templates', title: 'Templates for standardized events generation'}
];

export default function SoloEventsSubmenu(props)
{    
    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <div>

            <div className="row align-items-center">
                <div className="md-8 col">
                    <h3>Solo events</h3>
                </div>
            </div>
            <br/>
            
            <div className="row">
                <div className="col-lg-2 col-md-12">
                    <div className="card card-plain">
                        <div className="card-content">
                            <ul className="nav nav-stacked" role="tablist">
                                {menuItems.map(x =>
                                    <li key={x.key} className="w-100">
                                        <NavLink to={'/SoloEvents/' + x.key} className="nav-link" activeClassName="active" >
                                            <p title={x.title}>{x.name}</p>
                                        </NavLink>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-lg-10 col-md-12">
                    <Switch>
                        <Route exact path='/SoloEvents/' component={() => <Redirect to="/SoloEvents/Events"/>} />
                        <Route exact path='/SoloEvents/Events' component={SoloEvents} />
                        <Route exact path='/SoloEvents/Leaderboards' component={props => <Leaderboards type="Solo" onRowClickURL="/SoloEvents/Leaderboards" {...props}/>} />
                        <Route exact path='/SoloEvents/Leaderboards/Templates/:leaderboardTemplateId' component={LeaderboardTemplateEdit} />
                        <Route exact path='/SoloEvents/Leaderboards/:leaderboardId' component={LeaderboardEdit} />
                        <Route exact path='/SoloEvents/Create' component={SoloEventCreate} />
                        <Route exact path='/SoloEvents/CreateFromTemplate/:eventTemplateId' component={SoloEventCreateFromTemplate} />
                        <Route exact path='/SoloEvents/Templates' component={SoloEventTemplates} />
                        <Route exact path='/SoloEvents/Templates/Create' component={SoloEventTemplateCreate} />
                        <Route exact path='/SoloEvents/Templates/:eventTemplateId' component={SoloEventTemplateEdit} />
                        <Route exact path='/SoloEvents/:eventId' component={SoloEventEdit} />
                    </Switch>
                </div>
            </div>
        </div>
    )
}

