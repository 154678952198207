import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { AuthorizedGet } from '../AuthorizedRequest'
import { Card } from '../shared/Card'
import {composeQueryString, mapQueryStringValuesToObject} from '../shared/Utils'
import {mkPagedResult, MKPaginationWithTotal} from "../shared/MKPagination";
import {leaderboardEntriesSearchCriteriaObject} from "./LeaderboardEntriesSearchCriteria";
import LeaderboardEntriesSearch from "./LeaderboardEntriesSearch";
import LeaderboardEntriesList from "./LeaderboardEntriesList";

let pagedResultBase = {...mkPagedResult, ...{pageSize: 25}};

export default function LeaderboardEntries({leaderboard, showHeader = true, ...props})
{
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [initialValues, setInitialValues] = useState({...leaderboardEntriesSearchCriteriaObject, ...{leaderboardId: leaderboard?.id ?? ''}});
    const [pagedResult, setPagedResult] = useState(pagedResultBase);
   
    useEffect(() =>
    {
        if (mapQueryStringValuesToObject(initialValues, history.location.search))   // There are allowed search parameters in QueryString
        {
            searchEntries(initialValues);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    const searchEntries = (searchValues, formikBag = { setSubmitting: x => {} }, pageNumber = 1) => {
        searchValues.pageNumber = pageNumber;
        setLoading(true);
        setInitialValues(Object.assign({}, searchValues));
        
        AuthorizedGet('api/Leaderboard/SearchEntries/?' + composeQueryString(searchValues), history)
            .then(result => 
            {
                setPagedResult(result ?? pagedResultBase);
                formikBag.setSubmitting(false);
                setLoading(false);
            });
    };
    
    const onPageClick = (e, pageNumber) => searchEntries(initialValues, undefined, pageNumber);    

    return (
        <div>
            { showHeader &&
                <div className="row align-items-center">
                    <div className="md-12 col">
                        <h3>&nbsp;Leaderboards' score entries</h3>
                    </div>
                </div>
            }
            
            <Card>
                <LeaderboardEntriesSearch handleSearch={searchEntries} initialValues={initialValues} leaderboard={leaderboard}/>
            </Card>
            <span/>
            <Card>
                <LeaderboardEntriesList entries={pagedResult.items} loading={loading} showLeaderboardId={!leaderboard}
                                        showBucketButton={true}/>
                <MKPaginationWithTotal currentPage={pagedResult.pageNumber} total={pagedResult.pagesTotalCount}
                                       totalItems={pagedResult.itemsTotalCount} onClick={onPageClick}
                                       itemsName="Leaderboard entry"/>
            </Card>
        </div>
    )
}
