import React, {useEffect, useState} from 'react';
import {Card} from "../shared/Card";
import {Loading} from "../shared/Loading";
import {AuthorizedGet, AuthorizedPost} from "../AuthorizedRequest";
import {Field, Form, Formik} from "formik";
import {SelectField, TextFieldInputColumn, ToggleInput} from "../shared/FormElements";
import {
    azureContainerFlavor,
    azureVmSizes,
    defaultPortConfig,
    defaultRegionConfig
} from "./PlayFabConstants";
import PlayFabPortConfigurationSection from "./PlayFabPortConfigurationSection";
import PlayFabRegionConfigurationSection from "./PlayFabRegionConfigurationSection";
import PlayFabMetadataConfigurationSection from "./PlayFabMetadataConfigurationSection";
import {SubmitButton} from "../shared/Buttons";
import {useHistory} from "react-router-dom";

export default function PlayFabCreate(props) {
    
    const initialFormValues = {
        hasCustomName: false,
        customName: '',
        selectedAsset: '',
        assetMountPath: 'C:\\Assets',
        startCommand: '',
        serverCountPerVm: 1,
        vmSize: azureVmSizes.Standard_D2as_v4,
        containerFlavor: azureContainerFlavor.ManagedWindowsServerCore,
        ports: [{
            ...defaultPortConfig
        }]
        ,
        metadata: [],
        regions: [{
            ...defaultRegionConfig
        }]
    };

    const history = useHistory();
    
    const [loading, setLoading] = useState(true);
    const [assetNames, setAssetNames] = useState([]);
    
    useEffect(() => {
        AuthorizedGet('api/PlayFab/ListBuildAssets')
            .then(result => {
                setAssetNames(result ?? []);
                setLoading(false);
            });
    }, []);
    
    // Perform some basic form validation to make sure we have required fields.
    // This is not exhaustive (you can set available ports less to the number you need for example),
    // the API will just fail to process the result and throw an error toast. Given most of those cases
    // are based on default values anyway I think that's OK, I'm just trying to capture all required fields not make sure
    // they match any particular business logic.
    const validateForm = (values) => {
      const errors = {};
      
      if (!values.selectedAsset)
          errors.selectedAsset = "You must select a build asset";
      
      if (!values.assetMountPath)
          errors.assetMountPath = "You must specify a mount path";
      
      if (!values.startCommand)
          errors.startCommand = "You must specify a start command";
      
      if (values.hasCustomName && !values.customName)
          errors.customName = "You must specify a custom name or switch to the default value";
        

      for (let i = 0; i < values.ports.length; i++) {
          const err = {};
          
          const port = values.ports[i];

          if (!port.name)
              err.name = "You must specify a port name";
          
          if (Object.keys(err).length > 0) {
              if (!errors.ports)
                  errors.ports = {};
              
              errors.ports[i] = err;
          }
      }
      
      return errors;
    };
    
    // Submit the form then route directly to the details page.
    // I assume we want to do something with it now we have created it.
    const submitForm = (values, actions) => {
        AuthorizedPost(values, 'api/PlayFab/CreateBuild')
            .then(result => {
                actions.setSubmitting(false);

                if (!result)
                    return;

                const path = `${encodeURIComponent(result)}`;
                history.push(path);
            });
    };

    return (
        <div>
            <div className="row align-items-center">
                <div className="col-md-12">
                    <h3>&nbsp;Create New PlayFab Server</h3>
                </div>
            </div>
            {
                loading && <Loading />
            }
            {
                !loading &&
                <Card>
                    <Formik
                        initialValues={initialFormValues}
                        validate={validateForm}
                        onSubmit={submitForm}
                    >

                        {({ handleSubmit, handleChange, values, errors , touched, isSubmitting}) => (
                            <Form onSubmit={handleSubmit}>
                                <h5>Asset Details</h5>
                                <Field name="selectedAsset" component={SelectField} labelText="Build Asset"
                                       errors={errors.selectedAsset}
                                >
                                    <option key="-1" value={undefined}>Select a build asset...</option>
                                    {
                                        assetNames.map((build, index) =>
                                            <option key={index} value={build}>{build}</option>
                                        )
                                    }
                                </Field>
                                <small>The server will default to the Build Asset name unless overriden below...</small>
                                <Field component={ToggleInput} name="hasCustomName" labelText="Custom Server Name" 
                                       checked={values.hasCustomName} onToggle={(e) => {
                                           // Override the onToggle functionality to better return me a bool with the correct target name
                                           e.target.name = "hasCustomName";
                                           e.target.value = !values.hasCustomName
                                           handleChange(e);
                                       }}
                                />
                                {
                                    values.hasCustomName && <Field name="customName" errors={errors.customName} component={TextFieldInputColumn} labelText="Custom Server Name" />
                                }
                                <Field name="assetMountPath" component={TextFieldInputColumn} labelText="Asset Mount Path"
                                       errors={errors.assetMountPath}
                                />
                                <Field name="startCommand" 
                                       errors={errors.startCommand}
                                       component={TextFieldInputColumn} labelText="Start Command" 
                                />
                                <PlayFabMetadataConfigurationSection values={values} handleChange={handleChange} />

                                <br />
                                <h5>VM Details</h5>
                                <Field name="containerFlavor" component={SelectField} labelText="Container Type">
                                    {
                                        Object.keys(azureContainerFlavor).map((flavour, index) =>
                                            <option key={index} value={flavour}>{flavour}</option>
                                        )
                                    }
                                </Field>
                                <Field name="vmSize" component={SelectField} labelText="VM Size">
                                    {
                                        Object.keys(azureVmSizes).map((vmSize, index) =>
                                            <option key={index} value={vmSize}>{azureVmSizes[vmSize]}</option>
                                        )
                                    }
                                </Field>
                                <div className="row">
                                    <div className="col-12 text-end">
                                        <small className="text-end"><a href="https://docs.microsoft.com/en-us/gaming/playfab/features/multiplayer/servers/multiplayer-servers-detailed-price-sheet" target="_blank" rel="noreferrer">VM Pricing Page.</a></small>
                                    </div>
                                </div>
                                <Field name="serverCountPerVm" component={TextFieldInputColumn} type="number" min="1" step="1" labelText="Server Count Per VM" />

                                <PlayFabPortConfigurationSection values={values} handleChange={handleChange} errors={errors.ports ?? []} />
                                <PlayFabRegionConfigurationSection values={values} handleChange={handleChange} />
                                <div className="row">
                                    <div className="col-md-12 text-end">
                                        <SubmitButton errors={errors} isSubmitting={isSubmitting} touched={touched} />
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Card>
            }
        </div>
    );
}