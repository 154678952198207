import React from 'react'
import { Field } from 'formik'
import {NullableToggleInput, TextFieldInputColumn} from '../shared/FormElements'
import { mkPaginationObject } from '../shared/MKPagination'

export const targetedOffersSearchCriteriaObject = Object.assign({
    id : '', 
    name : '',
    userSegmentId : '',
    userSegmentName : ''
}, mkPaginationObject);

export default function TargetedOffersSearchCriteria(props)
{
    return (
        <>           
            <div className="row">
                <Field name="id" component={TextFieldInputColumn} labelText="Id" type="number" column={3}/>
                <Field name="name" component={TextFieldInputColumn} labelText="Name" column={6}/>
                <Field name="isEnabled" component={NullableToggleInput} trueText="Enable" falseText="Disabled" labelText="Is Enabled" column={3}/>
            </div>
            <div className="row">
                <Field name="userSegmentId" component={TextFieldInputColumn} labelText="User Segment Id" type="number" column={4}/>
                <Field name="userSegmentName" component={TextFieldInputColumn} labelText="User Segment Name" column={8}/>
            </div>
        </>
    );
}