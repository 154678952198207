import React, {useState} from 'react'
import { Loading } from '../shared/Loading'
import {descriptionToolTip} from "./AssetBundleUploadNew";
import AssetBundlesListRow from "./AssetBundlesListRow";
import {DeleteButtonText, IconButton, IconButtonAlt} from "../shared/Buttons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function AssetBundlesList({ assetBundles, loading, onDelete, onDeleteMany, onIsActiveChanged = _ => {} }) {
    
    const [deleteMode, setDeleteMode] = useState(false);
    const [selectedBundlesToDelete, setSelectedBundlesToDelete] = useState({});
    
    const disableDeleteMode = () => {
        setSelectedBundlesToDelete({});
        setDeleteMode(false);
    }
    
    const getAssetBundleIdentifier = (assetBundle) => {
        return `${assetBundle.appVersion.toString()};${assetBundle.bundleVersionInt.toString()};${assetBundle.platform}`;
    }
    
    const toggleBundleForDelete = (assetBundle) => {
        const assetBundleKey = getAssetBundleIdentifier(assetBundle);
        if (selectedBundlesToDelete.hasOwnProperty(assetBundleKey)) {
            setSelectedBundlesToDelete(current => {
                const copy = {...current};
                delete copy[assetBundleKey];
                return copy;
            });
        } else {
            setSelectedBundlesToDelete(current => {
                const copy = {...current};
                copy[assetBundleKey] = {
                    AppVersion: assetBundle.appVersion,
                    BundleVersion: assetBundle.bundleVersionInt,
                    Platform: assetBundle.platform
                };
                return copy;
            });
        }
    }
    
    const deleteSelectedBundles = () => {
        var bundlesToDelete = Object.values(selectedBundlesToDelete);
        console.log(bundlesToDelete);
        if (bundlesToDelete.length === 0) {
            return;
        }
        onDeleteMany(bundlesToDelete);
        disableDeleteMode();
    }
    
    const bundleIsSelectedForDelete = 
        (assetBundle) => bundleIsSelectedForDeleteByKey(getAssetBundleIdentifier(assetBundle));
    
    const bundleIsSelectedForDeleteByKey =
        (assetBundleKey) => selectedBundlesToDelete.hasOwnProperty(assetBundleKey);
    
    if (loading)
    {
        return <Loading loading={true} />
    }

    if (assetBundles.length > 0 && !assetBundles[0].rowSpan)
    {
        let i = 0;
        while (i < assetBundles.length) {
            if (i % 2 === 1)
            {
                assetBundles.splice(i, 0, { rowSeparator: true }); //Adds a "separator row" just to maintain the same color different from the last "version row" (all version row are even)
                i++;     
            }
            let versionRow = { rowSpan: 2, version: assetBundles[i].version, appVersion: assetBundles[i].appVersion }; //Add a new "version row" with only the first column (acting as a "separator")
            assetBundles.splice(i, 0, versionRow); 
            i += 2;
            while (i < assetBundles.length && assetBundles[i].appVersion === versionRow.appVersion) {
                versionRow.rowSpan++;
                i++;
            }
        }
    }

    return (
        <>
            { deleteMode ? 
                <div className={"text-end"}>
                    <DeleteButtonText onDelete={deleteSelectedBundles} 
                                      tooltip = 'Delete Selected Bundles' 
                                      disabledMessage={'Select Asset Bundles To Delete'}
                                      disabled={Object.keys(selectedBundlesToDelete).length === 0}
                                      confirmationMessageTitle={`Delete ${Object.keys(selectedBundlesToDelete).length} Asset Bundles?`}>
                        <FontAwesomeIcon icon='trash'/>&nbsp; Delete {Object.keys(selectedBundlesToDelete).length} Asset Bundles
                    </DeleteButtonText>
                                  
                </div>
                : <></> }
        <table className='table table-full-width table-hover'>
            <thead>
                <tr>
                    <th>App Version</th>
                    <th>Bundle Version</th>
                    <th>Platform</th>
                    <th title={descriptionToolTip}>Description</th>
                    <th>Build Date</th>
                    <th title="Number of Bundles included">Bundles</th>
                    <th>Size</th>
                    <th>Active</th>
                    <th/>
                    <th className="text-end">
                        {
                            deleteMode ?
                                <IconButtonAlt toolTip={"Cancel"} icon={"times"} onClick={disableDeleteMode} />
                                :
                                <IconButton toolTip={"Delete Multiple Bundles"} icon={"trash"} onClick={() => setDeleteMode(true)} />
                        }
                    </th>
                </tr>
            </thead>
            <tbody>
                { assetBundles.map((assetBundle, index) => 
                    assetBundle.rowSeparator ?  //The row is a "separator" just to maintain the color different from the last "version row"
                    <tr key={index}/>
                    : assetBundle.rowSpan ?  //The row is a "version row", only the first column with rowspan
                    <tr key={`${index}_${assetBundle.version}`}>
                        <td rowSpan={assetBundle.rowSpan}><span className="text-monospace">{assetBundle.version}</span></td>
                    </tr>
                    :                       //The row is a normal row (without the first "expanded" column)
                    <AssetBundlesListRow
                        key={getAssetBundleIdentifier(assetBundle)}
                        assetBundle={assetBundle} onIsActiveChanged={result => onIsActiveChanged(index, result)}
                        onDelete={onDelete} deleteMode={deleteMode} 
                        selectedForDelete={bundleIsSelectedForDelete(assetBundle)} toggleSelectedForDelete={toggleBundleForDelete}/>
                )}
            </tbody>
        </table>
        </>
        );
}