import React from 'react'
import {Form, Formik} from "formik";
import {SearchButton} from "../shared/Buttons";
import LeaderboardEntriesSearchCriteria from "./LeaderboardEntriesSearchCriteria";


export default function LeaderboardEntriesSearch({ initialValues, handleSearch, leaderboard })
{    
    return (
        <Formik
            enableReinitialize
            //validate={validate}
            initialValues={initialValues}
            onSubmit={handleSearch}
        >
            {({ errors, status, touched, isSubmitting }) => (
                <Form>
                    <LeaderboardEntriesSearchCriteria leaderboard={leaderboard} />
                    <div className="row">
                        <div className="md-2 col">
                        </div>
                        <div className="md-8 col text-center">
                            <SearchButton isSubmitting={isSubmitting} touched={touched} forceTouched={true} column={6} />
                        </div>
                        <div className="md-2 col">
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
}
