import React from 'react'
import { Field } from 'formik'
import {NullableToggleInput, TextFieldInputColumn} from '../shared/FormElements'
import { DateTimeInput } from '../shared/MKDateTime'
import { mkPaginationObject } from '../shared/MKPagination'

export const NewsAlertsSearchCriteriaObject = Object.assign({
    id : '', 
    name : '',
    startDateFrom : '',
    startDateTo : '',
    endDateFrom : '',
    endDateTo : '',
    isEnabled : ''
}, mkPaginationObject);

export default function NewsAlertsSearchCriteria(props)
{
    return (
        <>
            <div className="row">
                <Field name="id" component={TextFieldInputColumn} labelText="Id" type="number" column={3}/>
                <Field name="name" component={TextFieldInputColumn} labelText="Name" column={6}/>
                <Field name="isEnabled" component={NullableToggleInput} trueText="Enable" falseText="Disabled" labelText="Is Enabled" column={3}/>
            </div>
            <div className="row">
                <Field name="startDateFrom" component={DateTimeInput} useUTC={true} labelText="Start Date (UTC) From" column={3}/>
                <Field name="startDateTo" component={DateTimeInput} useUTC={true} labelText="Start Date (UTC) To" column={3}/>
                <Field name="endDateFrom" component={DateTimeInput} useUTC={true} labelText="End Date (UTC) From" column={3}/>
                <Field name="endDateTo" component={DateTimeInput} useUTC={true} labelText="End Date (UTC) To" column={3}/>
            </div>
        </>
    );
}