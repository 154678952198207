import React from 'react'
import { Field } from 'formik'
import {NullableToggleInput, TextFieldInputColumn} from '../shared/FormElements'
import { DateTimeInput } from '../shared/MKDateTime'
import { mkPaginationObject } from '../shared/MKPagination'

export const aBTestsSearchCriteriaObject = Object.assign({
    id : '', 
    name : '',
    nextGroup : '',
    maxUsersFrom : '', 
    maxUsersTo : '',
    assignedUsersFrom : '',
    assignedUsersTo : '',
    startDateFrom : '',
    startDateTo : '',
    endDateFrom : '',
    endDateTo : '',
    isEnabled : ''
}, mkPaginationObject);

export default function ABTestsSearchCriteria(props)
{
    return (
        <>
            <div className="row">
                <Field name="id" component={TextFieldInputColumn} labelText="Id" type="number" column={2}/>
                <Field name="name" component={TextFieldInputColumn} labelText="Name" column={4}/>
                <Field name="nextGroup" component={TextFieldInputColumn} labelText="Next Group" column={3}/>
                <Field name="isEnabled" component={NullableToggleInput} trueText="Enable" falseText="Disabled" labelText="Is Enabled" column={3}/>
            </div>
            <div className="row">
                <Field name="maxUsersFrom" component={TextFieldInputColumn} type="number" labelText="Max Participants From" column={3}/>
                <Field name="maxUsersTo" component={TextFieldInputColumn} type="number" labelText="Max Participants To" column={3}/>
                <Field name="assignedUsersFrom" component={TextFieldInputColumn} type="number" labelText="Total Participants From" column={3}/>
                <Field name="assignedUsersTo" component={TextFieldInputColumn} type="number" labelText="Total Participants To" column={3}/>
            </div>
            <div className="row">
                <Field name="startDateFrom" component={DateTimeInput} labelText="Start Date From" column={3}/>
                <Field name="startDateTo" component={DateTimeInput} labelText="Start Date To" column={3}/>
                <Field name="endDateFrom" component={DateTimeInput} labelText="End Date From" column={3}/>
                <Field name="endDateTo" component={DateTimeInput} labelText="End Date To" column={3}/>
            </div>
        </>
    );
}