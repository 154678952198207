import React, { useState, useEffect } from 'react'
import { AuthorizedGet } from '../../AuthorizedRequest'
import {useHistory, useLocation} from 'react-router-dom'
import { Card } from '../../shared/Card'
import { transactionsSearchCriteriaObject } from '../elements/TransactionsSearchCriteria'
import { receiptsSearchCriteriaObject } from '../elements/ReceiptsSearchCriteria'
import ReceiptsSearch from '../elements/ReceiptsSearch'
import ReceiptsList from '../elements/ReceiptsList'
import { composeQueryString, mapQueryStringValuesToObject, useSessionState, useQueryStringAndSessionState, useCache } from '../../shared/Utils'
import { CollapseSearchButton } from '../../shared/Buttons'
import { Collapse } from 'reactstrap'
import MKPaginationUncounted, {mkPagedResultUncounted} from "../../shared/MKPaginationUncounted";

export default function Receipts(props)
{ 
    const initialEmptyValues = Object.assign({}, Object.assign(receiptsSearchCriteriaObject, transactionsSearchCriteriaObject));
    
    let history = useHistory()
    let location = useLocation()

    const [loading, setLoading] = useState(true);
    const [initialValues, setInitialValues] = useQueryStringAndSessionState(() => Object.assign({}, initialEmptyValues), history, location.search, "receiptsInitialValues");
    const [pagedResult, setPagedResult] = useSessionState(mkPagedResultUncounted, 'receipts');
    const [searchCollapsed, setSearchCollapsed] = useCache('receiptsSearchCollapsed', false);
    
    useEffect(() =>
    {
        setLoading(false)
        if (mapQueryStringValuesToObject(initialEmptyValues, location.search))   // There is allowed search parameters in QueryString  [&&]
        {
            if (JSON.stringify(initialEmptyValues) !== JSON.stringify(initialValues))  // Those parameters means a different search tan the one saved in sessionStorage
            {
                searchReceipts(initialEmptyValues);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const searchReceipts = (searchValues, formikBag = { setSubmitting: x => {} }, pageNumber = 1) => {
        searchValues.pageNumber = pageNumber;
        setLoading(true);
        setInitialValues(Object.assign({}, searchValues));
        AuthorizedGet('api/Receipt/Search?' + composeQueryString(searchValues), history)
            .then(result => 
            {
                setPagedResult(result ?? mkPagedResultUncounted);
                formikBag.setSubmitting(false);
                setLoading(false);
            });
    };

    const onPageClick = (e, pageNumber) => searchReceipts(initialValues, undefined, pageNumber);
        
    const toggleSearch = e =>  {
        e.preventDefault();
        setSearchCollapsed(!searchCollapsed);
    };


    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const paginationComponent = !loading && <MKPaginationUncounted currentPage={pagedResult.pageNumber} currentPageCount={pagedResult.currentPageCount} onClick={onPageClick}/>;

    return (
        <div>
            <Card headerComponent={
                <div className="row">
                    <div className="col-md-10">
                        <h5 className="mt-2 mb-0">
                            Search Parameters
                        </h5>                        
                    </div>
                    <div className="col-md-2 text-end">
                        <CollapseSearchButton collapsed={searchCollapsed} onClick={toggleSearch}/>
                    </div>
                </div>
                
            }>
                <Collapse isOpen={!searchCollapsed}>
                    <ReceiptsSearch handleSearch={searchReceipts} initialValues={initialValues}/>
                </Collapse>
            </Card>

            <div>
                <Card>
                    {paginationComponent}
                    <ReceiptsList receipts={pagedResult.items} loading={loading}/>
                    {paginationComponent}
                </Card>
            </div>
        </div>
    );
}
