import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Formik, Field, Form } from 'formik'
import { AuthorizedPost } from '../AuthorizedRequest'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { AddButtonText, CreateButton } from '../shared/Buttons'
import { ErrorDisplay } from '../shared/FormElements'
import { TextFieldInputColumn, ToggleInputManaged } from '../shared/FormElements'
import { useCache } from './../shared/Utils'

export default function VersionAddNew({ onVersionAdded })
{
    const [modal, setModal] = useState(false);
    const [errorsServer, setErrorsServer] = useState({});
    const [newVersion, setNewVersion] = useCache('VersionAddNew', getNewVersion());
    const history = useHistory();

    const toggleModal = () => setModal(!modal);
    
    const createNewVersion = (values, actions) => 
    {
        //console.log('createNewVersion', { values, actions });
        AuthorizedPost(values, 'api/Version', history)
            .then(result => 
            {
                if (result == null || result.errorMessage)
                {
                    setErrorsServer({ Error: result?.errorMessage ?? 'An unknown error occurred' });
                }
                else
                {
                    setErrorsServer({});
                    setNewVersion(getNewVersion());
                    toggleModal();
                    onVersionAdded(result);
                } 
                actions.setSubmitting(false);
            });
    };

    const validate = (values, props) =>
    {
        let errors = {};
        if (!values.name)
          errors.name = "Name required";
        return errors
    };

    function getNewVersion() { return { name: '', forceUpdate: false }; }
    
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <>
            <AddButtonText onClick={toggleModal} toolTip="Add New Version">Add New</AddButtonText>
            <Modal isOpen={modal} toggle={toggleModal} contentClassName="card"> 
                <Formik
                    enableReinitialize
                    validate={validate}
                    initialValues={newVersion}
                    onSubmit={createNewVersion}>
                    {({ handleSubmit, isSubmitting, errors }) =>  
                    <Form className="d-inline" onSubmit={handleSubmit}>
                        <ModalHeader toggle={toggleModal}>Add New Version</ModalHeader>
                        <ModalBody>
                            <div className="row">
                                <Field name="name" autofocus={true} component={TextFieldInputColumn} labelText="Name" readOnly={false} errors={errors.name} column={8}/>
                                <Field name="forceUpdate" component={ToggleInputManaged} labelText="Force Update" readOnly={false} errors={errors.forceUpdate} column={4}/>
                                <small>If this is the most recent build, turning on Force Update might make the game unplayable.</small>
                            </div>
                            <ErrorDisplay errors={errorsServer} />
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={() => setTimeout(toggleModal)} className="col-md-4">Cancel</Button>&nbsp;
                            <CreateButton errors={errors} isSubmitting={isSubmitting} forceTouched={true} column="4" />
                        </ModalFooter>
                    </Form> }
                </Formik>
            </Modal>
        </>
    );
}