import React from 'react'
import Configurations from "./Configurations";


export default function ConfigurationsByKey(props)
{    
    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <Configurations byKey={true}></Configurations>
    );
}
