import React from 'react'
import { UncontrolledTooltip, Badge } from 'reactstrap';

export default function TotalCount({ children, itemsName })
{
    if (children == null)
        return null;

    return (
        <>
            <span id="TotalCountListGroupItem">Total:&nbsp;<Badge pill>{children}</Badge></span>
            <UncontrolledTooltip target="TotalCountListGroupItem">Total number of {itemsName ?? "Items"}: <b>{children}</b></UncontrolledTooltip>
        </>
        );
}