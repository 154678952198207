import React from 'react'

export const CardStats = (props) =>
(
    <div className="card card-stats">
        {props.title && <div className="card-header"><h3>{props.title}</h3></div>}
        <div className="card-body">
            <div className="row">
                <div className="col-5 col-md-4">
                    <div className="icon-big text-center icon-warning">
                        <i className={`${props.icon} text-warning`}/>
                    </div>
                </div>
                <div className="col-7 col-md-8">
                    <div className="numbers">
                        <p className="card-category">{props.statsTitle}</p>
                        <p className="card-title">{props.value}</p>
                        <p/>
                    </div>
                </div>
            </div>
        </div>
    </div>
)