import React, {useRef} from 'react'
import { useHistory } from 'react-router-dom'
import { AuthorizedPost } from '../AuthorizedRequest'
import TeamDataEdit from './TeamDataEdit'

export default function TeamCreate()
{
    const teamRef = useRef({ leaderUserId: '', coLeaderUserId: '', open: true, minLevel: 0, maxMembers: 0 });
    const history = useHistory();

    const saveTeam = (values, actions) =>
    {
        AuthorizedPost(values, `api/Team/?leaderUserId=${values.leaderUserId}&coLeaderUserId=${values.coLeaderUserId}`, history)
            .then(result =>
            {
                if (result?.id)
                {
                    history.replace('/teams/' + result.id);
                }
                actions.setSubmitting(false);
                actions.setTouched({});
            });
    };

    const validate = (values, props) =>
    {
        let errors = {}
        if (!values.leaderUserId)
        {
          errors.leaderUserId = "Leader User Id is required";
        }
        
        return errors
    }

    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <div>

            <TeamDataEdit team={teamRef.current} {...{ saveTeam, validate }} loading={false} forceTouched={true} creating={true} />

        </div>
    )
}