import React from 'react'
import { Formik, Form, Field } from 'formik'
import { SearchButton } from '../../shared/Buttons'
import { mkPaginationObject } from "../../shared/MKPaginationUncounted"
import { TextFieldInputColumn, NullableToggleInput } from "../../shared/FormElements"
import { UserIdFieldInputColumn } from '../../Users/UserIdFieldInputColumn'
import { DateTimeInput } from '../../shared/MKDateTime'

export const contentCodesSearchCriteriaObject = Object.assign({
    id : '',
    userId: '',
    usesFrom : '',
    usesTo : '',
    isActive : '',
    createdAtFrom : '',
    createdAtTo : '',
    updatedAtFrom : '',
    updatedAtTo : ''
}, mkPaginationObject);

export const supportContentCodesSearchCriteriaObject = Object.assign({
    id : '',
    isActive : '',
    createdAtFrom : '',
    createdAtTo : '',
    updatedAtFrom : '',
    updatedAtTo : '',
    userId: '',
    supportTicketId: ''
}, mkPaginationObject);

function ContentCodesSearchCriteria(props)
{
    return (
        <>
            <div className="row">
                <Field name="id" component={TextFieldInputColumn} labelText="Id" column={6}/>
                <Field name="isActive" component={NullableToggleInput} trueText="Yes" falseText="No" labelText="Is Active" column={6}/>
            </div>
            <div className="row">
                <Field name="userId" component={UserIdFieldInputColumn} labelText="User Id" column={6}/>
                <Field name="usesFrom" component={TextFieldInputColumn} labelText="Uses From" type="number" min="-1" max="2147483647" column={3}/>
                <Field name="usesTo" component={TextFieldInputColumn} labelText="Uses To" type="number" min="-1" max="2147483647" column={3}/>
            </div>
            <div className="row">
               <Field name="createdAtFrom" component={DateTimeInput} useTime={true} labelText="Created At From" column={3}/>
               <Field name="createdAtTo" component={DateTimeInput} useTime={true} labelText="Created At To" column={3}/>
               <Field name="updatedAtFrom" component={DateTimeInput} useTime={true} labelText="Updated At From" column={3}/>
               <Field name="updatedAtTo" component={DateTimeInput} useTime={true} labelText="Updated At To" column={3}/>
            </div>
        </>
    );
}

function SupportContentCodesSearchCriteria(props)
{
    return (
        <>
            <div className="row">
                <Field name="id" component={TextFieldInputColumn} labelText="Id" column={6}/>
                <Field name="isActive" component={NullableToggleInput} trueText="Yes" falseText="No" labelText="Is Active" column={6}/>
                <Field name="userId" component={UserIdFieldInputColumn} labelText="User Id" column={6}/>
                <Field name="supportTicketId" component={TextFieldInputColumn} labelText="Support Ticket Id" column={6}/>
            </div>
            <div className="row">
               <Field name="createdAtFrom" component={DateTimeInput} useTime={true} labelText="Created At From" column={3}/>
               <Field name="createdAtTo" component={DateTimeInput} useTime={true} labelText="Created At To" column={3}/>
               <Field name="updatedAtFrom" component={DateTimeInput} useTime={true} labelText="Updated At From" column={3}/>
               <Field name="updatedAtTo" component={DateTimeInput} useTime={true} labelText="Updated At To" column={3}/>
            </div>
        </>
    );
}


export function ContentCodesSearch({ initialValues, handleSearch })
{
    return (
        <Formik
            enableReinitialize
            //validate={validate}
            initialValues={initialValues}
            onSubmit={handleSearch} 
        >
            {({ errors, status, touched, isSubmitting }) => (
            <Form>
                <ContentCodesSearchCriteria />
                <div className="row">
                    <div className="md-12 col text-center">
                        <SearchButton isSubmitting={isSubmitting} touched={touched} forceTouched={true} column={3} />
                    </div>
                </div>
            </Form>
            )} 
        </Formik>
    );
}

export function SupportContentCodesSearch({ initialValues, handleSearch })
{
    return (
        <Formik
            enableReinitialize
            //validate={validate}
            initialValues={initialValues}
            onSubmit={handleSearch}
        >
            {({ errors, status, touched, isSubmitting }) => (
                <Form>
                    <SupportContentCodesSearchCriteria />
                    <div className="row">
                        <div className="md-12 col text-center">
                            <SearchButton isSubmitting={isSubmitting} touched={touched} forceTouched={true} column={3} />
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
}
