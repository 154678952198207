import React from 'react'
import {Card} from "../../shared/Card";
import RateLimiting from "./RateLimiting";
import AutoRefresh from "./AutoRefresh";


export default function ExternalConfiguration() 
{    
    return (
        <Card title="External configuration">
            <AutoRefresh/>
            <span/>
            <RateLimiting/>
        </Card>
    );    
}