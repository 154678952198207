import React, {useEffect, useState} from 'react'
import {AuthorizedGet, AuthorizedPost, AuthorizedPut} from '../../../AuthorizedRequest'
import {useHistory, useParams} from 'react-router-dom'
import {Card, CardContrast} from '../../../shared/Card'
import {LabeledDetail} from '../../../shared/LabeledDetail'
import {getDateTimeToolTip, shortYearDateTime} from '../../../shared/MKDateTime'
import {Button, ButtonToolbar, Collapse} from 'reactstrap'
import {SupportMessageEdit} from '../../elements/SupportMessageEdit'
import {Severity} from "../../elements/Severity";
import {Loading} from "../../../shared/Loading";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {SupportTicketComment} from "../../elements/SupportTicketComment";
import TransactionsButton from "../../../IAP/elements/TransactionsButton";
import {jsonSecureParse} from "../../../shared/Utils";
import SupportTicketCategoryEditor from "../../elements/SupportTicketCategoryEditor";
import TranslateButton from "../../../shared/TranslateButton";
import SupportTicketMessages from "./SupportTicketMessages";
import {useSupportTicketLocationState} from "../../elements/SupportTicketsNavigator";
import SupportTicketAssignedAgentEditor from "../../elements/SupportTicketAssignedAgentEditor";
import UserIdValue from "../../../Users/UserIdValue";

export default function SupportTicketDetails(props) {
    const history = useHistory()
    const tId = useParams().supportTicketId;
    const [supportTicketId, setSupportTicketId] = useState(tId)
    const [loading, setLoading] = useState(true)
    const [showMeta, setShowMeta] = useState(false)
    const [showComment, setShowComment] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [isSending, setIsSending] = useState(false)
    const [supportTicket, setSupportTicket] = useSupportTicketLocationState()
    
    if (tId !== supportTicketId)
    {
        setSupportTicketId(tId);
        setLoading(true);
    }

    useEffect(() => {
        AuthorizedGet(`api/Support/GetTicket/${supportTicketId}?markAsRead=true`, history)
            .then(result => {
                if (result) {
                    setSupportTicket(result);
                    if (showComment === false) // if user has manually set value to true no need to false it again
                    {
                        setShowComment(result.comment != null && result.comment.length > 0);
                    }
                }
                setLoading(false)
            })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [supportTicketId])


    const sendResponse = (message, contentCodeJson, contentCodePrefix, language, onSendOK) => {
        setIsSending(true)
        AuthorizedPost({
            message: message,
            contentCodeJson: contentCodeJson,
            contentCodePrefix: contentCodePrefix,
            language: language
        }, `api/Support/CreateMessage/${supportTicket.id}`, history)
            .then(result => {
                if (result) {
                    setSupportTicket(result);
                    onSendOK();
                    setIsEditing(false);
                }
            })
            .finally(() => setIsSending(false));
    }

    const updateComment = (values) => {
        setLoading(true)
        return new Promise((resolve, reject) => {
            AuthorizedPut(values.comment, `api/Support/UpdateComment/${supportTicket.id}`, history)
                .then(result => {
                    if (result) {
                        setSupportTicket(result)
                        setLoading(false)
                        resolve()
                    }
                }).catch(error => {
                reject(error)
            })
        })
    }

    // const updateLanguage = (language) => (e) => {
    //     const copy = Object.assign({}, supportTicket)
    //     copy.language = language
    //     setSupportTicket(copy)
    // }

    function updateSeverity(newValue) {
        const copy = Object.assign({}, supportTicket)
        copy.severity = newValue
        setSupportTicket(copy)
    }

    function openEmailThread(threadId) {
        const path = `/Support/Emails/${encodeURIComponent(threadId)}`
        history.push(path)
    }

    const deleteSupportTicket = (values) => {
        setLoading(true)

        AuthorizedPut(values, `api/Support/MarkAsDeleted`, history)
            .then(result => {
                if (result) {
                    const path = `/Support/Inbox`
                    history.push(path)
                }
            })
            .catch(error => {
                console.log(error)
                setLoading(false)
            })
    }

    const archiveSupportTicket = (values) => {
        setLoading(true)

        AuthorizedPut(null, `api/Support/MarkAsArchived/${supportTicket.id}`, history)
            .then(result => {
                if (result) {
                    const path = `/Support/Inbox`
                    history.push(path)
                }
            })
            .catch(error => {
                console.log(error)
                setLoading(false)
            })
    }

    const unArchiveSupportTicket = (values) => {
        setLoading(true)
        AuthorizedPut(null, `api/Support/MarkAsUnarchived/${supportTicket.id}`, history)
            .then(result => {
                if (result) {
                    const path = `/Support/Inbox`
                    history.push(path)
                }
            })
            .catch(error => {
                console.log(error)
                setLoading(false)
            })
    }

    const undeleteSupportTicket = () => {
        setLoading(true)
        AuthorizedPut(null, `api/Support/MarkAsRead/${supportTicket.id}`, history)
            .then(result => {
                if (result) {
                    const path = `/Support/Trash`
                    history.push(path)
                }
            })
            .catch(error => {
                console.log(error)
                setLoading(false)
            })
    }
    
    const editManualTicket = () => {
        const path = `/Support/SupportTicketModify/${supportTicket.id}`
        history.push(path, {supportTicket: supportTicket})
    }
    
    if (!supportTicket) {
        return null
    }

    let isPayingCustomer = supportTicket.user && supportTicket.user.transactions.length > 0
    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <>
            <Card>
                <div className="row">
                    <h3 className="col-md-9">
                        <div className="row">
                            {`Support Ticket #${supportTicket.id}`}
                            {supportTicket.status === "Closed" && <>&nbsp;&nbsp;&nbsp;&nbsp;<small><i>(Archived)</i></small></>}
                            {loading && <>&nbsp;&nbsp;<Loading margin={5} size={20}/></>}
                        </div>
                    </h3>
                    <div className="col-md-1 text-end">
                        {(supportTicket.source === "Manual" || supportTicket.source === "Email") &&
                        <button className="icon-button" title="Edit" onClick={editManualTicket}>
                            <FontAwesomeIcon size="2x" icon='edit'/>
                        </button>
                        }
                    </div>
                    <div className="col-md-1 text-end">
                        {isPayingCustomer && <TransactionsButton searchObject={{userId: supportTicket.userId}}/> }
                    </div>
                    <div className="col-md-1 text-end">
                        <Severity severity={supportTicket.severity} supportTicketId={supportTicket.id}
                                  updateSeverity={updateSeverity}/>
                    </div>
                </div>
                <br/>
                <div className="row">
                    <LabeledDetail label="Created" value={shortYearDateTime(supportTicket.timeStamp)} columns={2}
                                   toolTip={getDateTimeToolTip(supportTicket.timeStamp)}/>
                    <LabeledDetail label="User" value={<UserIdValue userId={supportTicket.userId} cheater={supportTicket.user?.cheater}
                                                                    deleted={supportTicket.user?.deleted}>{supportTicket.userId}</UserIdValue>}
                                   columns={4}
                                   path={`/Users/${supportTicket.userId}`}/>
                    <LabeledDetail label="Platform" value={supportTicket.platform} columns={2}/>
                    <LabeledDetail label="Language" value={supportTicket.language ?? <>[ <i>Unknown</i> ]</>}
                                   columns={2}/>
                    <LabeledDetail label="App Version" value={supportTicket.appVersion} columns={2}/>
                </div>
                <CardContrast>
                    <div className="row">
                        <div className="col-md-11">
                            Original Message
                        </div>
                        { supportTicket.language === 'English' ||
                        <div className="col-md-1 text-end">
                            <TranslateButton text={supportTicket.message}/>
                        </div>
                        }                                              
                    </div>
                    <hr className="mt-1" />
                    <div className="row">
                        <div className="col-md-12">
                            <big>{supportTicket.message}</big>
                        </div>
                    </div>
                </CardContrast>
                <div className="row">
                    <div className="col-md-2">
                        <ButtonToolbar>
                            <Button size="sm" onClick={() => setShowMeta(!showMeta)}>
                                {showMeta ? "Hide Meta" : "Show Meta"}
                            </Button>
                        </ButtonToolbar>         
                    </div>
                    <div className="col-md-1 pt-3 ps-0 pe-0 text-end">
                        <label>Category:&nbsp;</label>
                    </div>
                    <div className="col-md-3 pt-2 text-end">
                        <SupportTicketCategoryEditor ticket={supportTicket}/>
                    </div>
                    <div className="col-md-2 pt-3 pe-0 text-end">
                        <label>Assigned to:&nbsp;</label>
                    </div>
                    <div className="col-md-4 pt-2 text-end">
                        <SupportTicketAssignedAgentEditor ticket={supportTicket}/>
                    </div>
                </div>
                <Collapse isOpen={showMeta}>
                    <CardContrast>
                        <pre><small>{JSON.stringify(jsonSecureParse(supportTicket.metaData), null, 4)}</small></pre>
                    </CardContrast>
                </Collapse>
            </Card>

            <SupportTicketMessages messages={supportTicket.messages} isSending={isSending} ticketLanguage={supportTicket.language}/>

            {supportTicket.status !== "Closed" && supportTicket.status !== "Deleted" &&
            <button className="btn btn-primary" onClick={e => setIsEditing(!isEditing)} disabled={isEditing}>
                <FontAwesomeIcon icon='reply'/>
                &nbsp;Respond
            </button>}
            {showComment === false &&
            <button className="btn btn-primary" onClick={e => setShowComment(!showComment)}>
                <FontAwesomeIcon icon='comment'/>
                &nbsp;Add Comment
            </button>}
            {supportTicket.threadId &&
            <button className="btn btn-primary" onClick={e => openEmailThread(supportTicket.threadId)}>
                <FontAwesomeIcon icon='at'/>
                &nbsp;Email Thread
            </button>}
            {supportTicket.status !== "Closed" && supportTicket.status !== "Deleted" &&
            <button className="btn btn-info" onClick={e => archiveSupportTicket(supportTicket)}>
                <FontAwesomeIcon icon='archive'/>
                &nbsp;Archive
            </button>}
            {supportTicket.status === "Closed" &&
            <button className="btn btn-info" onClick={e => unArchiveSupportTicket(supportTicket)}>
                <FontAwesomeIcon icon='envelope-open'/>
                &nbsp;Move to Inbox
            </button>}
            {supportTicket.status !== "Deleted" &&
            <button className="btn btn-danger" onClick={e => deleteSupportTicket(supportTicket)}>
                <FontAwesomeIcon icon='trash'/>
                &nbsp;Delete
            </button>}
            {supportTicket.status === "Deleted" && <button className="btn btn-info" onClick={undeleteSupportTicket}>
                <FontAwesomeIcon icon='envelope-open'/>
                &nbsp;Move to Inbox
            </button>}
            
            <Collapse isOpen={isEditing}>
                <SupportMessageEdit sendResponse={sendResponse} toggleEdit={(e) => {
                    setIsEditing(!isEditing)
                }} language={supportTicket.language}/>
            </Collapse>
            
            <Collapse isOpen={showComment}>
                <SupportTicketComment ticket={supportTicket} updateComment={updateComment}/>
            </Collapse>
        </>
    )
}

