import React, {useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {useHistory, useLocation} from 'react-router-dom'
import {mkPagedResultUncounted} from "../../shared/MKPaginationUncounted";

export const useSupportTicketLocationState = (ticket) => {
    const location = useLocation();
    const [supportTicket, setSupportTicket] = useState(ticket ?? location?.state?.supportTicket);

    const updateLocationState = ticket => {
        setSupportTicket(ticket);
        if (location?.state?.supportTicketsList?.items) {
            let index = location.state.supportTicketsList.items.findIndex(x => x.id === ticket.id);
            if (index >= 0) {
                location.state.supportTicketsList.items[index] = ticket;
                location.state.supportTicket = ticket;
                //history.replace({pathname: location.pathname, state: location.state});                
            }
        }
    };
    
    return [supportTicket, updateLocationState];
}

export const useSupportTicketsListLocationState = (pagedResult) => {
    const location = useLocation();
    const [list, setList] = useState(pagedResult ?? location?.state?.supportTicketsList ?? mkPagedResultUncounted);

    const updateLocationState = pResult => {
        setList(pResult);
        if (location?.state?.supportTicketsList) {
            location.state.supportTicketsList = pResult;
            location.state.supportTicket = null;
            //history.replace({pathname: location.pathname, state: location.state});                
        }
    };

    return [list, updateLocationState];
}


export default function SupportTicketsNavigator() 
{
    const lState = useLocation().state;
    const history = useHistory();

    let currentIndex = null;
    let prevIndex = null;
    let nextIndex = null;
    
    if (lState?.supportTicketsList?.items && lState?.supportTicket)
    {
        currentIndex = lState.supportTicketsList.items.findIndex(x => x.id === lState.supportTicket.id);
        prevIndex = currentIndex > 0 ? currentIndex - 1 : null;
        nextIndex = currentIndex < lState.supportTicketsList.items.length - 1 ? currentIndex + 1 : null; 
    }
    
    const getLocationState = index => Object.assign(lState, { supportTicket: index != null ? lState.supportTicketsList.items[index] : null });
    
    const getTicketTitle = index => index != null ? `${lState.supportTicketsList.items[index].id} - ${lState.supportTicketsList.items[index].message.substring(0, 50)}...` : 'N/A'; 

    const homeClick = e =>
    {
        e.stopPropagation();
        history.push(lState.supportTicketsListHome, getLocationState(null));
    }
    
    const prevNextClick = index => e =>
    {
        e.stopPropagation();
        history.push(`/Support/SupportTickets/${encodeURIComponent(lState.supportTicketsList.items[index].id)}`, getLocationState(index));
    }
    
    if (currentIndex == null)
        return null;
    
    return (
        <>
            <button className="btn-round btn btn-primary" onClick={prevNextClick(prevIndex)} title={'Previous ticket: ' + getTicketTitle(prevIndex)} disabled={prevIndex == null}>
                <FontAwesomeIcon size="lg" icon='backward'/>
            </button>
            <button className="btn-round btn btn-primary" onClick={homeClick} title="Go back to tickets list">
                <FontAwesomeIcon size="lg" icon='th-list'/>
            </button>
            <button className="btn-round btn btn-primary" onClick={prevNextClick(nextIndex)} title={'Next ticket: ' + getTicketTitle(nextIndex)} disabled={nextIndex == null}>
                <FontAwesomeIcon size="lg" icon='forward'/>
            </button>
            &nbsp;
        </>
    )
}