import React, { useState } from 'react'
import { Input, InputGroup, InputGroupAddon, InputGroupButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useField } from 'formik';


export function DropdownInputColumn({ field, form: { touched, errors }, valuesList, labelText, readOnly, toolTip, width = '160px', column = '12', ...props })
{
    const [ , , { setValue, setTouched } ] = useField(field);
    const [ dropdownOpen, setDropdownOpen ] = useState(false);
    
    let selectedValue = valuesList.find(x => x.key === field.value).value;
    
    const toggleDropDown = () => setDropdownOpen(!dropdownOpen);
    
    const valueChange = value => () =>
    {
        setValue(value);
        setTouched(true);
    };
    
    return (
        <div className={`col-md-${column}`}>
            <div className="form-group">
                <label title={toolTip}>{labelText}</label>
                
                <InputGroup className="m-0" style={{ width: width }}>
                    <Input value={selectedValue} readOnly={true} />
                    <InputGroupAddon addonType="append">
                        <InputGroupButtonDropdown addonType="append" className="m-0" isOpen={dropdownOpen} toggle={toggleDropDown} disabled={readOnly}>
                            <DropdownToggle caret className="mt-0 ps-3 pe-3 inline-button" style={{height: '30px'}} />
                            <DropdownMenu>
                                { valuesList.map(x =>
                                    <DropdownItem key={x.key} onClick={valueChange(x.key)} title={x.key + ': ' + x.value}>
                                        {x.value}
                                    </DropdownItem>)}                                
                            </DropdownMenu>
                        </InputGroupButtonDropdown>
                    </InputGroupAddon>
                </InputGroup>
            </div>
        </div>
    );
}


const appStoreIcons = [
    { name: "GooglePlay", icon: ["fab", "google-play"] },
    { name: "AppleAppStore", icon: ["fab", "app-store"] }
];

export function AppStoreIcon({children})
{
    let icon = appStoreIcons.find(x => x.name === children);

    return icon ? 
            <FontAwesomeIcon icon={icon.icon} title={children} size="lg"/> 
            : 
            children;
}

export const AppStoreInputColumn = props => 
    <DropdownMenuIconsInputColumn {...props} icons={appStoreIcons} />


const platformIcons = [
    { name: "Android", icon: ["fab", "android"] },
    { name: "iOS", icon: ["fab", "apple"] },
    { name: "StandaloneWindows", icon: ["fab", "windows"] },
    { name: "StandaloneWindows64", icon: ["fab", "windows"] },
    { name: "StandaloneOSX", icon: ["fa", "apple-alt"] },
];

export function PlatformIconText({children})
{
    let icon = platformIcons.find(x => x.name === children);

    return ( 
        <>
        { icon && <><FontAwesomeIcon icon={icon.icon} title={children} size="lg" />&nbsp;&nbsp;</> }
        { children }
        </>
    );          
}

export const PlatformInputColumn = props => 
    <DropdownMenuIconsInputColumn {...props} icons={platformIcons} />

const DropdownMenuIconsInputColumn = ({ field, form: { touched, errors }, icons, column = 12, ...props }) => {
    
    const [ , , { setValue, setTouched } ] = useField(field);
    const [ dropdownOpen, setDropdownOpen ] = useState(false);
    const [ currentIcon, setCurrentIcon ] = useState(() => getCurrentIcon(field.value));
  
    const valueChange = value => () =>
    {
        setValue(value);
        setCurrentIcon(getCurrentIcon(value));
        setTouched(true);
    };

    function getCurrentIcon(name) 
    {
        return icons.find(x => x.name === name)?.icon;
    } 

    const toggleDropDown = () => setDropdownOpen(!dropdownOpen);

    return (
        <div className={`col-md-${column}`}>
            <div className="form-group">
                <label>{props.labelText ?? "Store"}</label>
                <InputGroup >
                    <InputGroupAddon addonType="prepend">
                        <span className="input-group-text" style={{height: '30px'}}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{ currentIcon && <FontAwesomeIcon icon={currentIcon} size="lg" /> } 
                        </span>
                    </InputGroupAddon>
                    <Input {...field} placeholder={props.placeholder} onBlur={e => setCurrentIcon(getCurrentIcon(e.target.value))} readOnly={props.readOnly} className={props.errors ? "error" : ""} /> 
                    <InputGroupAddon addonType="append">
                        <InputGroupButtonDropdown addonType="append" className="m-0" isOpen={dropdownOpen} toggle={toggleDropDown} disabled={props.readOnly}>
                            <DropdownToggle caret className="mt-0 pt-0 ps-3 pe-3 inline-button" style={{height: '30px'}} />
                            <DropdownMenu>
                                {icons.map(x => 
                                    <DropdownItem key={x.name} onClick={valueChange(x.name)} title={x.name}>
                                        <FontAwesomeIcon icon={x.icon} size="sm" />&nbsp;&nbsp;{x.name}
                                    </DropdownItem>
                                )}
                                <DropdownItem divider />
                                <DropdownItem>
                                    <div onClick={valueChange("")} title="Clear field">
                                        <FontAwesomeIcon icon="trash" size="lg" />&nbsp;&nbsp;(<i>clear</i>)
                                    </div>
                                </DropdownItem>
                            </DropdownMenu>
                        </InputGroupButtonDropdown>
                    </InputGroupAddon>
                </InputGroup>
            </div>
        </div>
    );
}
