import React, {useRef} from 'react'
import { useHistory } from 'react-router-dom'
import { Formik, Field, Form } from 'formik'
import { AuthorizedPost } from '../AuthorizedRequest'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { CreateButton } from '../shared/Buttons'
import { ErrorDisplay } from '../shared/FormElements'
import { TextFieldInputColumn } from '../shared/FormElements'
import { configurationValidate } from './ConfigurationEdit'

export default function ConfigurationDuplicate({ configurationToDuplicate, onDuplicated, onCancel, byKey = false })
{
    const duplicatedRef = useRef({
        appVersion: byKey ? configurationToDuplicate.appVersion : '',
        configKey: '',
        configVersion: configurationToDuplicate.configVersion + 1,
        config: configurationToDuplicate.config
    });
    const history = useHistory();

    const duplicate = (values, actions) => 
    {
        AuthorizedPost(values, 'api/Configuration', history)
            .then(result => 
            {
                actions.setSubmitting(false);
                if (result)
                    onDuplicated(result);
            });
    };
    
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <>
            <Modal isOpen={true} toggle={onCancel} contentClassName="card"> 
                <Formik
                    enableReinitialize
                    validate={configurationValidate}
                    initialValues={duplicatedRef.current}
                    onSubmit={duplicate}>
                    {({ handleSubmit, isSubmitting, errors }) =>  
                    <Form className="d-inline" onSubmit={handleSubmit}>
                        <ModalHeader toggle={onCancel}>
                            {`Duplicate configuration ${byKey ? '' : ` App Version ${configurationToDuplicate.appVersion}`} ConfigKey=“${configurationToDuplicate.configKey}”`}
                        </ModalHeader>
                        <ModalBody>
                            <div className="row">
                                { byKey ||
                                    <Field name="appVersion" autofocus={true} component={TextFieldInputColumn} labelText="Duplicate as version" errors={errors.appVersion} column={4}/>                                    
                                }
                                <Field name="configKey" component={TextFieldInputColumn} labelText="Config Key" maxLength={50} column={4} errors={errors.configKey} />
                                <Field name="configVersion" component={TextFieldInputColumn} labelText="Config Version" type="number" column={4} />
                            </div>
                            <ErrorDisplay errors={errors} />
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={() => setTimeout(onCancel)} className="col-md-4">Cancel</Button>&nbsp;
                            <CreateButton errors={errors} isSubmitting={isSubmitting} forceTouched={true} column={4} />
                        </ModalFooter>
                    </Form> 
                    }
                </Formik>
            </Modal>
        </>
    );
}