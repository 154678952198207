import React from "react";
import {useField} from "formik";
import SelectAgents from "./SelectAgents";

export function SelectAgentsInput({
                                      field,
                                      form: {touched, errors},
                                      labelText,
                                      toolTip,
                                      placeholder,
                                      showCleaner1stItem,
                                      ...props
                                  }) 
{
    const [/* input */, meta, helpers] = useField(field.name);
    const {value, /*error*/} = meta;
    const {setValue, setTouched, /*setError*/} = helpers;
    
    const onChange = agentId => {
        setValue(agentId); 
        setTouched(true);
    };
    
    return (
        <div className={`col-md-${props.column ? props.column : 12}`}>
            <div className="form-group">
                <label title={toolTip}>{labelText}</label>
                <SelectAgents selectedAgentId={value} onChange={onChange} processing={props.processing}
                              readOnly={props.readOnly} recentAgentIdsList={props.recentAgentIdsList}
                              placeholder={placeholder} showCleaner1stItem={showCleaner1stItem} />
            </div>
        </div>
    );
}