import React, { useState, useEffect } from 'react'
import { AuthorizedGet, AuthorizedPut } from '../AuthorizedRequest'
import { useHistory, useParams } from 'react-router-dom'
import { Card } from '../shared/Card'
import { Loading } from '../shared/Loading'
import SoloEventsList from '../SoloEvents/SoloEventsList'
import LeaderboardDetails from "./LeaderboardDetails";
import LeaderboardBuckets from "./LeaderboardBuckets";
import LeaderboardEntriesSearchPopUp from "./LeaderboardEntriesSearchPopUp";
import leaderboardsTypes from "./LeaderboardsTypes.json";

export default function LeaderboardEdit()
{
    let { leaderboardId, type } = useParams();
    const [leaderboard, setLeaderboard] = useState(null);
    const [bucketsRefreshSignal, setBucketsRefreshSignal] = useState(0);    //Allows to refresh the buckets when the leaderboard changes (eg: BucketSize is changed, so the bucked should be regenerated)
    const [loading, setLoading] = useState(true);
    const [relatedEvent, setRelatedEvent] = useState(null);
    const [loadingRelatedEvent, setLoadingRelatedEvent] = useState(true);
    const [leaderboardType, setLeaderboardType] = useState(type ?? leaderboardsTypes[1].Type);
    const history = useHistory();

    // Fetch
    useEffect(() =>
    {
        AuthorizedGet(`api/Leaderboard/${leaderboardId}`, history)
            .then(result => 
            {
                setLeaderboard(result);
                if (result && result.soloEventId !== undefined)
                {
                    setLeaderboardType('Solo');
                    setLoadingRelatedEvent(true);
                    AuthorizedGet(`api/SoloEvent/${result.soloEventId}?includeLeaderboards=false`, history)
                        .then(result => 
                        {
                            setRelatedEvent(result);
                            setLoadingRelatedEvent(false);
                        });
                }
                setLoading(false);
            });
    }, [history, leaderboardId]);

    const save = (values, actions) =>
    {
        AuthorizedPut(values, `api/Leaderboard/${leaderboardType}Update`, history)
            .then(result =>
            {
                actions.setSubmitting(false)                
                if (result)
                {
                    setBucketsRefreshSignal(bucketsRefreshSignal + 1);
                    actions.setTouched({})
                }
            })
    }

    if (loading)
        return <Loading />
    
    if (!leaderboard)
        return null
    
    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <div>
            <LeaderboardDetails leaderboard={leaderboard} saveMethod={save} editSoloEventId={leaderboardType === 'Solo'}/>

            {leaderboard.soloEventId &&
            <Card title="Related Event">
                <SoloEventsList soloEvents={[relatedEvent]} loading={loadingRelatedEvent}/>
            </Card>
            }
            <span/>            
            
            <Card headerComponent={
                <div className="row">
                    <div className="col-md-10">
                        <h3>Results</h3>
                    </div>
                    <div className="col-md-2 text-end">
                        <LeaderboardEntriesSearchPopUp leaderboard={leaderboard} />
                    </div>
                </div>
            }>
                <LeaderboardBuckets leaderboardId={leaderboardId} refreshSignal={bucketsRefreshSignal} />
            </Card>
        </div>
    )
}
