import React, {useState, useEffect} from 'react'
import {AuthorizedGet, AuthorizedPut} from '../AuthorizedRequest'
import {useHistory} from 'react-router-dom'
import {Loading} from '../shared/Loading'
import {ErrorMessage, Field, FieldArray, Form, Formik} from "formik";
import {IconButton, SubmitButton} from "../shared/Buttons";
import {Card} from '../shared/Card'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ErrorDisplay} from "../shared/FormElements";


export default function MKNetSettingsStringArray({MKNetSettingsKey, title, valueNamePattern = /[a-z_]+/ }) 
{
    const [submitting, setSubmitting] = useState(false);
    const [form, setForm] = useState(null);
    const history = useHistory();

    useEffect(() => {
        AuthorizedGet('api/Settings/' + MKNetSettingsKey, history)
            .then(result => {
                setForm({
                    stringArray: result
                });
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const saveSettings = (values, actions) => {
        setSubmitting(true);
        AuthorizedPut(values.stringArray, 'api/Settings/' + MKNetSettingsKey, history)
            .then(() => {
                setForm({
                    stringArray: values.stringArray
                });
            })
            .finally(() => setSubmitting(false));
    };
    //
    // const addBlackListItem = () => {
    //     setBlacklist(value => [...value, '']);
    // }
    //
    // const updateValue = (index, newValue) => {
    //     setBlacklist(v => v.map((item, i) => i === index ? newValue : v));
    // }

    const validate = ({stringArray}) => {
        let errors = {};

        if (valueNamePattern)
        {
            const stringArrayErrors = [];
            stringArray.forEach((item, index) => {
                if (!valueNamePattern.test(item)) {
                    stringArrayErrors[index] = 'Invalid value name';
                }
            });

            if (stringArrayErrors.length !== 0) {
                errors.stringArray = stringArrayErrors;
            }            
        }

        return errors;
    }

    if (!form)
        return <Loading loading={true}/>

    return (
        <Formik
            enableReinitialize
            validateOnChange
            validate={validate}
            initialValues={form}
            onSubmit={saveSettings}
        >
            {
                (formikProps) => (
                    <Form>
                        <FieldArray
                            name="stringArray"
                            render={arrayHelpers => (
                                <>
                                    <Card headerComponent={<HeaderComponent title={title} onAddClick={() => arrayHelpers.push('')}/>}>
                                        <div>
                                            {formikProps.values.stringArray.map((stringArrayItem, index) => (
                                                <StringArrayItem key={index} index={index} submitting={submitting}
                                                                 onDeleteClick={() => arrayHelpers.remove(index)}/>
                                            ))}
                                        </div>
                                        <ErrorDisplay errors={formikProps.errors}/>
                                    </Card>
                                    <div className="row">
                                        <div className="col-md-12 text-center">
                                            <SubmitButton errors={formikProps.errors} isSubmitting={submitting}
                                                          touched={formikProps.touched}
                                                          forceTouched={formikProps.values.stringArray.length !== form.stringArray.length ? true : undefined}
                                            />
                                        </div>
                                    </div>
                                </>
                            )}
                        />
                    </Form>
                )
            }
        </Formik>
    );
}

function HeaderComponent({title, onAddClick})
{
    return (
        <div className="row">
            <div className="col-md-8 col">
                <h5>{title}</h5>
            </div>
            <div className="col-md-4 text-end">
                <IconButton onClick={onAddClick} toolTip={title} icon="plus">Add new value</IconButton>
            </div>
        </div>
    );
}

function StringArrayItem({index, submitting, onDeleteClick})
{
    return (
        <div style={{display: 'flex', marginBottom: 8}}>
            <Field name={`stringArray[${index}]`} className="form-control"/>
            <ErrorMessage name={`stringArray[${index}]`}/>
            <button title="Delete" className="icon-button" disabled={submitting}>
                <FontAwesomeIcon className="trash-sign" icon="trash" onClick={onDeleteClick}/>
            </button>
        </div>
    );
}