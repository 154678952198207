import React from 'react'
import { AuthorizedPost } from '../AuthorizedRequest'
import ConfigurationEdit from './ConfigurationEdit'

export default function ConfigurationCreate({ byKey = false })
{
    let newConfig = {
        appVersion: byKey ? '0.0.0' : '1.0.0',
        configVersion: 1,
        configKey: '',
        config: '{}'
    };
    
    return (
        <ConfigurationEdit configuration={newConfig} saveAction={AuthorizedPost} creating={true} byKey={byKey} />
    );
}