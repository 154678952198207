import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { AuthorizedGet } from '../AuthorizedRequest'
import { Loading } from '../shared/Loading'
import {Card} from "../shared/Card";
import LeaderboardBucketEntries from "./LeaderboardBucketEntries";
import {MKPaginationWithTotal, mkPagedResult, setPagedResultPage} from '../shared/MKPagination'

export default function LeaderboardBuckets({ leaderboardId, refreshSignal = 0 })
{
    const [pagedBuckets, setPagedBuckets] = useState({ ...mkPagedResult, pageSize: 10 });
    const [bucketList, setBucketList] = useState(null);
    const [loading, setLoading] = useState(true);
    const history = useHistory();

    // Fetch
    useEffect(() =>
    {
        setLoading(true);
        AuthorizedGet(`api/Leaderboard/Buckets/${leaderboardId}`, history)
            .then(result =>
            {
                if (result)
                {
                    setBucketList(result);
                    onPageClick(null, 1, result);
                }
            })
            .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [leaderboardId, refreshSignal]);

    const onPageClick = (e, pageNumber, buckets = bucketList) => {
        setPagedResultPage(pagedBuckets, buckets, pageNumber);
        setPagedBuckets({...pagedBuckets});
    };
    
    if (loading)
    {
        return <Loading loading={true} />
    }
 
    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <>
            {pagedBuckets.items.map(bucket =>
                <Bucket key={bucket.entriesType + bucket.bucketNumber} leaderboardId={leaderboardId} bucket={bucket} />
            )}
            <MKPaginationWithTotal currentPage={pagedBuckets.pageNumber} total={pagedBuckets.pagesTotalCount}
                                   totalItems={pagedBuckets.itemsTotalCount} onClick={onPageClick}
                                   itemsName={'Buckets'}/>
        </>
    );
}

function Bucket({leaderboardId, bucket})
{
    const [isOpen, setIsOpen] = useState(false);
    const [cheatingEntriesCount, setCheatingEntriesCount] = useState(null);
    
    const BucketEntriesLoaded = itemsTotalCount => {
        setCheatingEntriesCount(bucket.entriesCount - itemsTotalCount);
    };
    
    return (
        <>
            <Card headerComponent={<BucketHeader bucket={bucket} cheatingEntriesCount={cheatingEntriesCount}
                                                 onClick={() => setIsOpen(!isOpen)}/>}>
                {isOpen &&
                    <LeaderboardBucketEntries leaderboardId={leaderboardId} bucketNumber={bucket.bucketNumber}
                                              resultType={bucket.entriesType} bucketName={bucket.bucketName}
                                              entriesLoaded={BucketEntriesLoaded}/>
                }
            </Card>
            <span />
        </>
    );
}

function BucketHeader({bucket, onClick, cheatingEntriesCount})
{
    return (
        <div onClick={onClick} className="row" style={{cursor: "pointer"}}>
            <div className="col-md-10">
                <h5>{bucket.bucketName}</h5>
            </div>
            <div className="col-md-2 text-end">
                Entries:&nbsp;
                { cheatingEntriesCount ?
                    <>
                        <span title="Actual entries in the bucket (excluding cheaters)">{bucket.entriesCount - cheatingEntriesCount}</span> 
                        <sup>(+<span title='Cheating entries excluded from the bucket'>{cheatingEntriesCount}</span>)</sup>                        
                    </>
                    :
                    <span title={`Total entries in the bucket (${cheatingEntriesCount === 0 ? 'no cheaters in the bucket' : 'possibly including cheaters'})`}>{bucket.entriesCount}</span>
                }
            </div>
        </div>
    );
}
