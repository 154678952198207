import React from 'react'
import { useHistory } from 'react-router-dom'
import { composeQueryString } from '../shared/Utils'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ModuleConditional} from "../shared/ModuleConditional";

export default function AuditLogButton({searchObject})
{    
    let history = useHistory();
    
    const onClick = e =>
    {
        e.stopPropagation();
        const path = '/AuditLogs?' + composeQueryString(searchObject);
        history.push(path);
    };

    return (
        <button type="button" title="View Audit Logs" className="icon-button">
            <FontAwesomeIcon className="tasks" size="2x" icon="tasks" onClick={ onClick }/>
        </button>
    )
}

export function AuditLogButtonConditional({searchObject})
{
    return (
        <ModuleConditional modules={['AuditLogs']}>
            <AuditLogButton searchObject={searchObject}/>
        </ModuleConditional>
    );
}