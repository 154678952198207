import React from "react";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import languagesAssets from "../../assets/languages.json";

export default function LanguageDropdownButton({language, onChange, languages = languagesAssets})
{
    return (
        <DropdownButton title={language} size="sm" variant="white" drop="down" id="ddbLanguage">
            {languages.map((language, index) =>
                <Dropdown.Item as="button" type="button" eventKey={index} onClick={() => onChange(language)}
                               key={index}>{language}</Dropdown.Item>)
            }
        </DropdownButton>
    );
}