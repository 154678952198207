import React from 'react'
import { useHistory } from "react-router-dom"
import { Loading } from '../shared/Loading'
import { ShortYearDateTime } from '../shared/MKDateTime'
import { DeleteButton } from '../shared/Buttons'

export default function GiftsList({ gifts, loading, onDelete }) {
    
    const history = useHistory();

    const onRowClick = gift => e =>
    {
        e.stopPropagation();
        const path = `Gifts/${gift.id}`;
        history.push(path);
    }

    if (loading)
    {
        return <Loading loading={true} />
    }

    return (
        <table className='table table-full-width table-striped table-hover'>
            <thead>
                <tr>
                    <th>Id</th>
                    <th>Sender User Id</th>
                    <th>Recipient User Id</th>
                    <th>Category</th>
                    <th>Send Time</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {gifts?.map((gift, index) =>
                <tr className="tr-link" onClick={ onRowClick(gift) } key={index}>
                    <td>{gift.id}</td>
                    <td>{gift.senderId}</td>
                    <td>{gift.recipientId}</td>
                    <td>{gift.category}</td>
                    <td><ShortYearDateTime>{gift.sendTime}</ShortYearDateTime></td>
                    <td className="text-end">
                        <DeleteButton onDelete={onDelete(gift.id)} entityName="Gift"/>
                    </td>
                </tr>
                )}
            </tbody>
        </table>
    );
}