import React from 'react'
import { Card } from '../../shared/Card'
import { Formik, Field, Form } from 'formik'
import { TextAreaInput } from '../../shared/FormElements'
import { SubmitButton  } from '../../shared/Buttons'

export const SupportTicketComment = ({ticket, updateComment, ...props}) =>
{
    const onSubmit = (values, actions) => { 
        updateComment(values)
            .then(() => actions.setTouched(false)); 
    };

    return (
        <Card>
            <h5>Comment  </h5>
            <small>Internal use only, this will not be sent to user</small>
            <Formik
                enableReinitialize
                initialValues={ticket}
                onSubmit={onSubmit}
            >
                {({handleSubmit, isSubmitting, errors, touched}) =>
                    <Form onSubmit={handleSubmit}>
                        <Field name="comment" component={TextAreaInput} labelText="Comment"/>
                        <SubmitButton errors={errors} isSubmitting={isSubmitting} touched={touched} forceTouched={false}/>
                    </Form>
                }
            </Formik>
        </Card>
    )
}