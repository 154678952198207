import React from 'react'
import { useHistory } from 'react-router-dom'
import {AuthorizedPost } from '../AuthorizedRequest'
import ApiKeyForm from "./ApiKeyForm";

export default function CreateApiKey()
{
    const history = useHistory()
    const apiKey = {
        id: null,
        name: 'A new API Key',
        expiresUTC: null,
        key: null
    };
    
    const saveEntity = (apiKey) =>
        AuthorizedPost(apiKey, 'api/ApiKey', history);
    
    return (
        <div>
            <ApiKeyForm
                apiKey={apiKey}
                onSubmit={saveEntity}/>
        </div>
    )
}