import React, { useState, useEffect } from 'react'
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import {mapType} from "./AuditLogTypeMap";
import {JSONEditorReadOnly} from "../shared/JSONEditor";
import {CardContrast} from "../shared/Card";
import {Loading} from "../shared/Loading";
import {AuthorizedGet} from "../AuthorizedRequest";
import {useHistory} from "react-router-dom";
import {ShortYearDateTime} from "../shared/MKDateTime";


export default function AuditLogDetails({id, isOpen, toggleModal})
{
    const [logDetails, setLogDetails]  = useState(null);
    let history = useHistory();
    
    useEffect(() => {
        if (id)
        {
            setLogDetails(null);
            AuthorizedGet(`api/AuditLog/${id}?`, history)
                .then(result => setLogDetails(result));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);
    
    return (
        <Modal isOpen={isOpen} toggle={toggleModal} contentClassName="card" size="xl">
            <ModalHeader toggle={toggleModal}>
                {logDetails ? 
                    <>
                        <ShortYearDateTime>{logDetails.timestamp}</ShortYearDateTime>{` | ${mapType(logDetails.type)} | ${logDetails.entityName} | ${logDetails.adDisplayName}`}
                    </> 
                    :
                    <>[ <i>Loading...</i> ]</>
                }
            </ModalHeader>
            <ModalBody>
                <div style={{height: '100%'}}>
                    {logDetails ?
                        <PrettyPrintJson data={logDetails.data}/>
                        :
                        <Loading/>
                    }
                </div>
            </ModalBody>
        </Modal>
    );    
}

function PrettyPrintJson({data})
{
    let parsedJson = null;
    try {
        parsedJson = JSON.parse(data);        
    }
    catch(e)
    {}
    
    if (parsedJson)
    {
        return (
            <JSONEditorReadOnly value={parsedJson} />
        );        
    }
            
    return (
        <CardContrast>
            <small><p style={{fontFamily: 'monospace'}}>{data}</p></small>
        </CardContrast>
    );
}