import React, { useState, useEffect } from 'react'
import {AuthorizedGet, AuthorizedDelete, AuthorizedPost} from '../../AuthorizedRequest'
import { useHistory } from 'react-router-dom'
import { Card } from '../../shared/Card'
import ContentCodesList from '../elements/ContentCodesList';
import {ContentCodesSearch, contentCodesSearchCriteriaObject} from '../elements/ContentCodesSearch'
import { composeQueryString, useSessionState, useQueryStringAndSessionState, useCache } from '../../shared/Utils'
import { mkPagedResult } from '../../shared/MKPagination'
import {AddButtonText, CollapseSearchButton} from '../../shared/Buttons'
import { Collapse } from 'reactstrap'
import ContentCodeAddBulk from '../elements/ContentCodeAddBulk'
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import MKPaginationUncounted from "../../shared/MKPaginationUncounted";
import {Loading} from "../../shared/Loading";
import ContentCodeInsertCSV from "../elements/ContentCodeImportCSV";

export default function ContentCodes(props)
{
    const initialEmptyValues = Object.assign({}, contentCodesSearchCriteriaObject);
    
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [contentCodeTemplates, setContentCodeTemplates] = useState(null)
    const [initialValues, setInitialValues] = useQueryStringAndSessionState(() => Object.assign({}, initialEmptyValues), history, history.location.search, "contentCodesInitialValues");
    const [pagedResult, setPagedResult] = useSessionState(mkPagedResult, 'contentCodes');
    const [searchCollapsed, setSearchCollapsed] = useCache('contentCodesSearchCollapsed', false);


    useEffect(() => {
        AuthorizedGet(`api/Redeemable/GetTemplates`, history)
            .then(result =>
            {
                if (result)
                {
                    setContentCodeTemplates(result)
                }
            })

        searchContentCodes(initialValues, null, initialValues.pageNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const searchContentCodes = (searchValues, formikBag = { setSubmitting: x => {} }, pageNumber = 1) => {
        searchValues.pageNumber = pageNumber;
        setLoading(true);
        setInitialValues(Object.assign({}, searchValues));
        AuthorizedGet('api/Redeemable/SearchContentCodes?' + composeQueryString(searchValues), history)
            .then(result => 
            {
                setPagedResult(result ?? mkPagedResult);
                if (formikBag) {
                    formikBag.setSubmitting(false);
                }
                setLoading(false);
            });
    };

    const onPageClick = (e, pageNumber) => searchContentCodes(initialValues, undefined, pageNumber);
        
    const toggleSearch = e => {
        e.preventDefault();
        setSearchCollapsed(!searchCollapsed);
    };

    const onDelete = contentCodeId => () =>
    {
        AuthorizedDelete(contentCodeId, `api/Redeemable/${encodeURIComponent(contentCodeId)}`, history)
            .then(result => 
            {
                pagedResult.items = pagedResult.items.filter(function (s) { return s.id !== result.id });
                pagedResult.itemsTotalCount--;
                setPagedResult(Object.assign({}, pagedResult));
            });
    };

    const addContentCode = template => (e) =>
    {
        setLoading(true)
        AuthorizedPost(template, 'api/Redeemable', history)
            .then(result =>
            {
                setLoading(false);
                if (!result)
                {
                    console.log("No result")
                }
                if (result && result.id)
                {
                    history.push("ContentCodes/" + encodeURIComponent(result.id), { redeemable: result })
                }
            });
    };
    
    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const paginationComponent = <MKPaginationUncounted currentPage={pagedResult.pageNumber} currentPageCount={pagedResult.currentPageCount} onClick={onPageClick} itemsName="ContentCodes"/>;

    return (
        <div>
            <div className="row">
                <div className="col-md-12 text-end">
                    <AddButtonText onClick={addContentCode({})} toolTip="Add New">Add New</AddButtonText>
                    &nbsp;
                    <DropdownButton title="Add From Template" variant="primary" drop="down"
                                    style={{display: "inline-block"}}>
                        {contentCodeTemplates && contentCodeTemplates.map((template, index) =>
                            <Dropdown.Item key={index} as="button" type="button" eventKey={index} 
                                           onClick={addContentCode(template)}>
                               {template.name}
                            </Dropdown.Item>)}
                    </DropdownButton>
                    &nbsp;
                    <ContentCodeAddBulk contentCodeTemplates={contentCodeTemplates}/>
                    &nbsp;
                    <ContentCodeInsertCSV/>
                    &nbsp;
                    <CollapseSearchButton collapsed={searchCollapsed} onClick={toggleSearch}/>
                </div>
            </div>
            
            <Collapse isOpen={!searchCollapsed}>
                <Card>
                    <div className="row">
                        <h5 className="col-md-10">
                            <div className="row">Search Content Codes</div>
                        </h5>
                        <div className="col-md-2 text-end">
                            {loading && <Loading size={20} margin={5}/>}
                        </div>
                    </div>
                    <ContentCodesSearch handleSearch={searchContentCodes} initialValues={initialValues}/>
                </Card>
            </Collapse>
            
            <div>
                <Card>
                    {paginationComponent}
                    <ContentCodesList contentCodes={pagedResult.items} onDelete={onDelete}/>
                    {paginationComponent}
                </Card>
            </div>
        </div>
    );
}
