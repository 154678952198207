import React, {useState} from 'react'
import { useHistory } from 'react-router-dom'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faFlag } from "@fortawesome/free-solid-svg-icons";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import {AuthorizedPost} from "../AuthorizedRequest";
import {useToasts} from "react-toast-notifications";

export default function UserCheaterButton({user, onCheaterValueUpdated})
{
    const [isOpen, setIsOpen] = useState(false);
    const [processing, setProcessing] = useState(false);
    const history = useHistory();
    const toastsHook = useToasts();
    
    const toggleModal = () => setIsOpen(!isOpen);
    
    const onUpdateCheater = (e, isCheater) => {
        
        e.preventDefault();
        
        setProcessing(true);
        console.log("Sending isCheater = ", isCheater);
        AuthorizedPost(isCheater, 'api/User/SetIsUserCheater/' + user.userId, history)
            .then(result => {
                if (result === null) {
                    toastsHook.addToast(`Something went wrong while updating the user.`, {appearance: 'error'});
                    return;
                }
                toastsHook.addToast(`User was updated successfully.`, { appearance: 'info', autoDismiss: true });
                onCheaterValueUpdated(result);
                toggleModal();
            })
            .catch(error => {
                toastsHook.addToast(`Error while updating user: ${error}`, {appearance: 'error'});
            })
            .finally(() => setProcessing(false));
        
    }
    
    const isCheater = user.cheater;
    
    let actionLabel = isCheater ? 'Remove Cheater Flag from User' : 'Flag User as Cheater';
    
    let modalMessage = isCheater ?
        <p className="alert alert-warning text-black">
            User <span className="font-monospace">{user.userId}</span> is currently flagged as a cheater. Do you want to remove this flag? This will remove the user from cheater-only leaderboard buckets and include them in regular buckets.
        </p>
    :
        <p>Do you want to flag user <span className="font-monospace">{user.userId}</span> as a cheater? This will remove them from regular leaderboard buckets and group them in cheater-only buckets.</p>;

    return (
        <>
            <button type="button" title={"Flag / Un-flag Cheater"} className="icon-button">
                <FontAwesomeIcon className="cheater" size="2x" icon={faFlag} onClick={ toggleModal }/>
            </button>
            
            <Modal isOpen={isOpen} toggle={toggleModal} contentClassName="card">
                <ModalHeader toggle={toggleModal}><p>{ actionLabel }</p></ModalHeader>
                <ModalBody>
                    <div className="container ">
                        <div className="row">
                            <div className="col-md-12">
                                { modalMessage }
                                <p><em>This action can be undone at any time.</em></p>
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter style={{justifyContent: 'center'}}>
                    <button type="button" className="btn btn-primary" onClick={e => onUpdateCheater(e, !isCheater)} title={actionLabel} disabled={processing}>
                        {processing ? '[ ... Processing ... ]' : actionLabel}
                    </button>
                </ModalFooter>
            </Modal>
        </>
    )
}