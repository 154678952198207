import React from "react";


const getCurrentMonthDay1 = () => {
    let date = new Date();
    return new Date(date.getFullYear(), date.getMonth(), 1); 
};

const getLastNDaysFrom = (n) => {
    let date = new Date();
    date.setDate(date.getDate() - n);
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};

const lastsNMonths = {
    getFromDate: (n) => { 
        let date = new Date();
        date.setMonth(date.getMonth() - n);
        return new Date(date.getFullYear(), date.getMonth(), 1);
        },
    getToDate: () => {
        let date = new Date();
        date.setDate(0);    //Set to last day of the previous month
        return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    }
};

const getCurrentYearDay1 = () => {
    let date = new Date();
    return new Date(date.getFullYear(), 0, 1);
};

const getLastYearDay1 = () => {
    let date = new Date();
    return new Date(date.getFullYear() - 1, 0, 1);
};

const getFinancialYearYearStart = () => {
    let date = new Date();
    return date.getMonth() < 6 ? date.getFullYear() - 1 : date.getFullYear();
};


const dateRanges = [
    {
        value: 'AllDates',
        label: <>[ <span className="font-italic text-info">All dates</span> ]</>,
        getFromDate: () => null,
        getToDate: () => null,
    },
    {
        value: 'Last30Days',
        label: 'Last 30 days',
        getFromDate: () => getLastNDaysFrom(30),
        getToDate: () => null,
    },
    {
        value: 'Last60Days',
        label: 'Last 60 days',
        getFromDate: () => getLastNDaysFrom(60),
        getToDate: () => null,
    },
    {
        value: 'Last90Days',
        label: 'Last 90 days',
        getFromDate: () => getLastNDaysFrom(90),
        getToDate: () => null,
    },
    {
        value: 'CurrentMonth',
        label: 'Current month',
        getFromDate: getCurrentMonthDay1,
        getToDate: () => null,
    },
    {
        value: 'LastMonth',
        label: 'Last month',
        getFromDate: () => lastsNMonths.getFromDate(1),
        getToDate: () => lastsNMonths.getToDate(1),
    },
    {
        value: 'Lasts3Months',
        label: 'Lasts 3 months',
        getFromDate: () => lastsNMonths.getFromDate(3),
        getToDate: () => lastsNMonths.getToDate(3),
    },
    {
        value: 'Lasts6Months',
        label: 'Lasts 6 months',
        getFromDate: () => lastsNMonths.getFromDate(6),
        getToDate: () => lastsNMonths.getToDate(6),
    },
    {
        value: 'CurrentYear',
        label: 'Current Year',
        getFromDate: getCurrentYearDay1,
        getToDate: () => null,
    },
    {
        value: 'LastYear',
        label: 'Last year',
        getFromDate: getLastYearDay1,
        getToDate: () => new Date(getLastYearDay1().getFullYear(), 11, 31),
    },
    {
        value: 'LastFinancialYear',
        label: 'Last financial year',
        getFromDate: () => new Date(getFinancialYearYearStart() - 1, 6, 1),
        getToDate: () => new Date(getFinancialYearYearStart(), 5, 30),
    },
];

export default dateRanges;

//dateRanges.forEach(x => console.log(x.label, { From: x.getFromDate(), To: x.getToDate()}));