import React, {useState} from 'react'
import {Redirect, Route, Switch, NavLink, useHistory} from 'react-router-dom'
import {AddButtonText} from '../shared/Buttons'
import SupportTickets from './Submenus/SupportTickets'
import Emails from './Submenus/Emails'
import MessageTemplate from './Submenus/Details/MessageTemplate'
import MessageTemplateCreate from "./Submenus/Details/MessageTemplateCreate"
import Statistics from './Submenus/Statistics'
import Templates from './Submenus/Templates'
import EmailConversation from "./Submenus/Details/EmailConversation"
import SupportTicketDetails from "./Submenus/Details/SupportTicketDetails"
import SupportTicketCreate from "./Submenus/Details/SupportTicketCreate"
import SupportTicketModify from "./Submenus/Details/SupportTicketModify"
import RedeemableDetails from './../ContentCodes/Submenus/RedeemableDetails'
import SupportTicketsNavigator from "./elements/SupportTicketsNavigator"

export default function SupportSubmenu(props)
{
    const [menuItems] = useState(["Inbox", "Archived", "Emails", "Templates", "Statistics", "Trash"])
    const history = useHistory()

    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <div>
            <div className="row align-items-center">
                <div className="md-8 col">
                    <h3>&nbsp;Support Tickets</h3>
                </div>
                <div className="md-4 col text-end">
                    <SupportTicketsNavigator/>
                    <AddButtonText onClick={() => history.push('/Support/SupportTicketCreate')} toolTip="Create New Support Ticket">Create New Ticket</AddButtonText>
                </div>
            </div>
            <hr className="mt-0 mb-4" />

            <div className="row">
                <div className="col-lg-2 col-md-12">
                    <div className="card card-plain">
                        <div className="card-content">
                            <ul className="nav nav-stacked flex-lg-column" role="tablist">
                                { menuItems.map((item, index) => 
                                <li className="nav-item" key={index}>
                                    <NavLink to={"/Support/" + item} className="nav-link" activeClassName="active" >
                                        <p>{item}</p>
                                    </NavLink>
                                </li>)
                                }
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-lg-10 col-md-12">
                    <Switch>
                        <Route exact path='/Support' component={() => <Redirect to="/Support/Inbox"/>} />
                        <Route exact path='/Support/Search' component={() => <SupportTickets folder="Search"/>} />
                        <Route exact path='/Support/Inbox' component={() => <SupportTickets folder="Inbox"/>} />
                        <Route exact path='/Support/Emails' component={Emails} />
                        <Route exact path='/Support/Emails/:conversationId' component={EmailConversation} />
                        <Route exact path='/Support/Archived' component={() => <SupportTickets folder="Archived"/>} />
                        <Route exact path='/Support/Trash' component={() => <SupportTickets folder="Trash"/>} />
                        <Route exact path='/Support/SupportTickets/:supportTicketId' component={SupportTicketDetails} />
                        <Route exact path='/Support/SupportTicketCreate' component={SupportTicketCreate} />
                        <Route exact path='/Support/SupportTicketModify/:supportTicketId' component={SupportTicketModify} />
                        <Route exact path='/Support/Templates' component={Templates} />
                        <Route exact path='/Support/Templates/ContentCode/:contentCodeTemplateId' component={() => <RedeemableDetails type="template" redirectPath="/Support/Templates"/>} />
                        <Route exact path='/Support/Templates/Message/Create' component={MessageTemplateCreate} />
                        <Route exact path='/Support/Templates/Message/:messageTemplateId' component={MessageTemplate} />
                        <Route exact path='/Support/Statistics' component={Statistics} />
                    </Switch>
                </div>
            </div>
        </div>
    )
}
