import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {AuthorizedPut} from "../../AuthorizedRequest";
import categories from "./SupportTicketCategories.json";
import {useSupportTicketLocationState} from "./SupportTicketsNavigator";
import Select from "react-select";


export default function SupportTicketCategoryEditor({ ticket, readOnly = false })
{
    const history = useHistory();
    const [ t, setT ] = useSupportTicketLocationState(ticket);
    const [ category, setCategory ] = useState(ticket.category);
    const [ updating, setUpdating ] = useState(false);
    
    useEffect(() => setT(ticket), 
    // eslint-disable-next-line react-hooks/exhaustive-deps
        [ticket]);

    useEffect(() => setCategory(t.category), [t]);
    
    const onChange = newCategory => {
        setUpdating(true);
        AuthorizedPut(null, `api/Support/UpdateCategory/${t.id}/${newCategory}`, history)
            .then(r => {
                if (r) setT(r);                    
            })
            .finally(() => setUpdating(false));
    };

    function mapListItem(item) {
        if (item)
            return {value: item.key, label: item.value};
    }

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            textAlign: 'left',
        })
    }
    
    return (
        <Select
            value={mapListItem(categories?.find(x => x.key === category))}
            onChange={selectedOption => onChange(selectedOption.value)}
            options={categories?.map(mapListItem)}
            isSearchable={false}
            isDisabled={updating}
            isLoading={updating}
            styles={customStyles}
        />
    );
}