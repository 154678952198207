/*!

=========================================================
* Paper Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { Link } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  NavLink,
  NavItem,

  Container
 
} from "reactstrap";

import React, { useState, useEffect, useRef } from 'react'
import authService from '../api-authorization/AuthorizeService';
import { ApplicationPaths } from '../api-authorization/ApiAuthorizationConstants';

Header.logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };
Header.loginPath = { pathname: `${ApplicationPaths.Login}`, state: { local: true } };

function toProperCase(value){
    if(!value)
    {
        return value;
    }
    
    return value.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
}

export default function Header(props)
{
    const [isOpen, setIsOpen] = useState(false)
    const sidebarToggle = useRef(null)

    const [user, setUser] = useState(null);
    const [/*project*/, setProject] = useState(null);
    const [projectDisplayName, setProjectDisplayName] = useState(null);
    const [environment, setEnvironment] = useState(null);
    const [environmentSwapUrl, setEnvironmentSwapUrl] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => 
    {
        async function populateState() 
        {
            const [auth, authUser, proj, projName, swapUrl, environment] =
                await Promise.all([authService.isAuthenticated(), authService.getUser(), authService.getProjectName(), authService.getProjectDisplayName(), authService.getEnvironmentSwapUrl(),authService.getEnvironment()])
            setUser(authUser);
            setProject(proj);
            setProjectDisplayName(projName);
            setEnvironment(environment);
            setEnvironmentSwapUrl(swapUrl);
            setIsAuthenticated(auth);
        }
        populateState();
    }, []);
    
    const toggle = () => 
    {
      setIsOpen(!isOpen)
    }

    const getBrand = () =>
    {
      if (projectDisplayName === null) return ""
      return projectDisplayName + (" " + toProperCase(environment));
    }

    const openSidebar = (e) => 
    {
      document.documentElement.classList.toggle("nav-open");
      sidebarToggle.current.classList.toggle("toggled");
    }

  
    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) 
    {
      document.documentElement.classList.toggle("nav-open");
      sidebarToggle.current.classList.toggle("toggled");
    }
  
    return (
      // add or remove classes depending if we are on full-screen-maps page or not
      <Navbar
        expand="lg"
        className={`navbar-absolute fixed-top navbar-${environment}`}
      >
        <Container fluid>
          <div className="navbar-wrapper">
            <div className="navbar-toggle">
              <button
                type="button"
                ref={sidebarToggle}
                className={`navbar-toggler${isOpen ? " toggled" : ""}`}
                onClick={openSidebar}
              >
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </button>
            </div>
            <NavbarBrand href="/">{getBrand()}</NavbarBrand>
          </div>
          <NavbarToggler onClick={toggle}>
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
          </NavbarToggler>
          <Collapse
            isOpen={isOpen}
            navbar
            className="justify-content-end"
          >
            <ul className="navbar-nav flex-grow">
             {!isAuthenticated &&
               <NavItem>
                 <NavLink tag={Link} className="text-dark" to={Header.loginPath}>Login</NavLink>
               </NavItem>
             }
             {isAuthenticated && 
               <>
                 <NavItem>
                   <a className="text-primary nav-link" href={environmentSwapUrl}>{environment == 'production' ? 'Staging' : 'Production'}</a>
                 </NavItem>
                 <NavItem>
                   <NavLink tag={Link} className="text-dark" to="/openapi">API Reference</NavLink>
                 </NavItem>
                 <NavItem>
                   <NavLink tag={Link} className="text-dark" to={Header.logoutPath}>Logout {user?.name?.replace("@mightykingdom.com", "")}</NavLink>
                 </NavItem>
               </>
             }
             </ul>
          </Collapse>
        </Container>
      </Navbar>
    )
  }
