import React, {useState, useRef} from 'react'
import {DateTime} from 'luxon'
import {useField} from 'formik'
import {default as ReactDateTime} from 'react-datetime'


/* ********************************************************************************* *
 * ==============       DateTime format and TimeZone approaches       ============== *
 * ********************************************************************************* *
 *                                                                                   *
 *  Remarks:                                                                         *
 *   # TimeZone: always assume and treat as UTC all across the application.          *
 *     > The only place where conversions between UTC / user zone happens is         *
 *       inside the components here.                                                 *
 *     > DateTime at server side should always be generated and assumed as UTC.      *
 *       Use: DateTime.UtcNow / DateTime.UtcNow.Date                                 *
 *     > TimeZone is specified in [dateTimeConfig.zone] and is used independently    *
 *       of user's browser/device configuration.                                     *
 *   # Format: always ISO 8601                                                       *
 *     > Conversions to custom format should only happen in this module.             *
 *     > Is always the specified in [dateTimeConfig.locale]                          *
 *                                                                                   *
 *  Issues:                                                                          *
 *   # TimeZone/Format are fixed to the ones in [dateTimeConfig], so it can be       *
 *     confusing for users used to different format or accessing from locations      *
 *     with different timezone.                                                      *
 *   # The project has two different DateTime libraries dependencies:                *
 *     · moment.js: used internally by react-datetime                                *
 *     · Luxon: used for TimeZone and localization                                   *
 *     > Ideally it would be better to unify everything in Luxon as it is a more     *
 *       modern library, but a React DateTimePicker component that doesn't use       * 
 *       moment.js couldn't be found. Interaction between libraries requires a       *
 *       format conversion which is performed within the DateTimeInput component.    *
 *                                                                                   *
/* ********************************************************************************* */


/**
 * Custom TimeZone and DateFormat (locale) to be used all around the application, overriding user's browser configuration.
 */
const dateTimeConfig = {
    zone: 'Australia/Adelaide',
    locale: 'en-AU'
};

function setCustomLocal(dateTime) {
    return dateTime.setZone(dateTimeConfig.zone).setLocale(dateTimeConfig.locale);   //reconfigure(dateTimeConfig);    //
}

function getBaseDateTime(isoString) {
    return setCustomLocal(DateTime.fromISO(isoString, {zone: 'utc'}));
}

function getBaseDateTimeAlreadyInUTC(isoString) {
    return DateTime.fromISO(isoString).setLocale(dateTimeConfig.locale);
}

export function shortYearDateTime(isoString) {
    return getBaseDateTime(isoString).toLocaleString(DateTime.DATETIME_SHORT);
}

export function shortYearDateTimeUTC(isoString) {
    return DateTime.fromISO(isoString, {zone: 'utc'}).setLocale(dateTimeConfig.locale).toLocaleString(DateTime.DATETIME_SHORT);
}

export function ShortYearDateTime({children}) {
    return SpanWrapper(children, shortYearDateTime);
}

export function ShortYearDateTimeUTC({children}) {
    return SpanWrapperUTC(children, shortYearDateTimeUTC);
}

export function shortYearDate(isoString) {
    return getBaseDateTime(isoString).toLocaleString(DateTime.DATE_SHORT);
}

export function ShortYearDate({children}) {
    return SpanWrapper(children, shortYearDate);
}

export function shortDateTime(isoString) {
    return getBaseDateTime(isoString).toLocaleString({
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
    });
}

export function shortDateTimeUTC(isoString) {
    return getBaseDateTimeAlreadyInUTC(isoString).toLocaleString({
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
    });
}

export function ShortDateTime({children}) {
    return SpanWrapper(children, shortDateTime);
}

export function ShortDateTimeUTC({children}) {
    return SpanWrapperUTC(children, shortDateTimeUTC);
}
export function shortTime(dateTime) {
    return dateTime.toLocaleString({hour: '2-digit', minute: '2-digit'});
}

export function shortDate(dateTime) {
    return dateTime.toLocaleString({month: 'short', day: '2-digit'});
}

var dateTimeCustomLocal = DateTime.fromObject(null, dateTimeConfig);
var timeZoneLegend = dateTimeCustomLocal.toFormat("'GMT'Z '('z')'");

var dateTimeCustomUTC = DateTime.fromObject(null).toUTC();
var timeZoneLegendUTC = dateTimeCustomUTC.toFormat("'GMT'Z '('z')'");

export function flexiDateTime(isoString) {
    const dateTime = getBaseDateTime(isoString);
    const diffDays = dateTimeCustomLocal.startOf("day").diff(dateTime.startOf("day"), 'day').days;
    if (diffDays < 1) {
        return `Today ${shortTime(dateTime)}`;
    }
    if (diffDays < 2) {
        return `Yesterday ${shortTime(dateTime)}`;
    }
    return shortDate(dateTime);
}

export function FlexiDateTime({children}) {
    return SpanWrapper(children, flexiDateTime);
}

export function getDateTimeToolTip(isoDateTimeString) {
    return `Time zone: ${timeZoneLegend} \n[UTC DateTime: ${isoDateTimeString ? isoDateTimeString : 'N/A'}]`;
}

export function getDateTimeToolTipUTC(isoDateTimeString) {
    return `Time zone: UTC ${isoDateTimeString ? `(${isoDateTimeString})` : ''}\n[${timeZoneLegend}: ${isoDateTimeString ? shortYearDateTime(isoDateTimeString) : 'N/A'}]`;
}

function SpanWrapper(isoDateTimeString, getStringFunction) {
    return <span
        title={getDateTimeToolTip(isoDateTimeString)}>{isoDateTimeString && getStringFunction(isoDateTimeString)}</span>
}

function SpanWrapperUTC(isoDateTimeString, getStringFunction) {
    return <span
        title={getDateTimeToolTipUTC(isoDateTimeString)}>{isoDateTimeString && getStringFunction(isoDateTimeString)}</span>
}

/**
 * Returns Date and Time formats to be used by react-datetime component
 * IMPORTANT: formats according moment.js (not exactly as Luxon formats)
 *
 * @returns Array: [0]=Date format; [1]=Time format
 */
function getMomentDateTimeFormats(useLocalConfig = true) {
    let localeDate;
    if (useLocalConfig) {
        localeDate = DateTime.fromObject({year: 2002, month: 12, day: 23, hour: 22, minute: 37}, dateTimeConfig);
    } else {
        localeDate = DateTime.fromObject({year: 2002, month: 12, day: 23, hour: 22, minute: 37}).toUTC().setLocale(dateTimeConfig.locale);
    }
    let dateShortString = localeDate.toLocaleString(DateTime.DATE_SHORT);
    let dateShortFormat = dateShortString.replace('2002', 'YYYY')
                                         .replace('12', 'MM')
                                         .replace('23', 'DD');

    let timeShortString = localeDate.toLocaleString(DateTime.TIME_SIMPLE);
    let timeShortFormat;
    if (timeShortString.includes('22'))
        timeShortFormat = 'HH:mm';
    else if (timeShortString.includes('AM') || timeShortString.includes('PM'))
        timeShortFormat = 'h:mm A';
    else
        timeShortFormat = 'h:mm a';
    return [dateShortFormat, timeShortFormat];
}


/**
 * DateTime picker based on https://github.com/YouCanBookMe/react-datetime and depending on Formik's field object.
 * DateTime value is automatically mapped to field via Formik useField() hook.
 * TimeZone conversions are managed inside the component
 *
 * @export
 * @param field
 * @param touched
 * @param errors
 * @param labelText
 * @param toolTip
 * @param {bool} useTime Show Time picker
 * @param {function} onDayChange For managed components (and for compatibility)
 * @param useUTC Indicated if the date/time are edited in the UTC TimeZone (they are always saved in UTC)
 * @param column
 * @param props
 */
export function DateTimeInput({
                                  field,
                                  form: {touched, errors},
                                  onDayChange,
                                  labelText,
                                  toolTip = labelText,
                                  useTime = false,
                                  useUTC = false,
                                  column = 12,
                                  ...props
                              }) {
    const [/* input */, meta, helpers] = useField(field.name);
    const {value, error} = meta;
    const {setValue, setTouched, setError} = helpers;

    const [internalError, setInternalError] = useState(error || props.errors);

    const setErrs = err => {
        setError(err);
        setInternalError(err)
    };

    const valueLastUncontrolled = useRef(null); //Allows to avoid changing the value meanwhile the user is entering a date (it could be an invalid date during that time)
    const dateFormatRef = useRef(null);
    const timeFormatRef = useRef(null);
    const titleRef = useRef(null);

    if (dateFormatRef.current == null) {
        [dateFormatRef.current, timeFormatRef.current] = getMomentDateTimeFormats(!useUTC);
        titleRef.current = 'Format: ' + dateFormatRef.current;
        if (useTime)
            titleRef.current += ` [Time zone: ${useUTC ? timeZoneLegendUTC : timeZoneLegend}]`;
    }

    const convertIn = isoDateTimeString => {
        if (isoDateTimeString) {
            
            let valueDateTime = useUTC ?
                DateTime.fromISO(isoDateTimeString, {zone: 'utc', locale: dateTimeConfig.locale}) :
                DateTime.fromISO(isoDateTimeString).setLocale(dateTimeConfig.locale);
            
            if (useTime) {
                if (!useUTC) {
                    valueDateTime = valueDateTime.plus({minutes: dateTimeCustomLocal.o}); // [dateTimeCustomLocal.o] = UTC offset in minutes => moving from UTC to CustomLocal time zone
                }
                return valueDateTime.toLocaleString(DateTime.DATE_SHORT) + ' ' + valueDateTime.toLocaleString(DateTime.TIME_SIMPLE);
            } else {
                return valueDateTime.toLocaleString(DateTime.DATE_SHORT);
            }
        }
        return '';
    };

    /**
     *  Converts dateTime (which can be in custom TimeZone/Format [dateTimeConfig], if not using UTC) to ISO format and UTC TimeZone
     *
     * @param {moment object} dateTime (if there is an invalid value, this parameter comes as string -instead of as moment object-)
     */
    const convertOut = dateTime => {
        let dateTimeUTCISO;
        if (typeof dateTime === 'string') {   //Invalid or clear ('') value
            // eslint-disable-next-line eqeqeq
            setErrs(dateTime == '' ? null : 'Invalid DateTime');
            dateTimeUTCISO = '';
            valueLastUncontrolled.current = dateTime;
        } else {
            setErrs(null);
            if (useTime && !useUTC) {
                dateTime = dateTime.clone().add(-dateTimeCustomLocal.o, 'm'); // [dateTimeCustomLocal.o] = UTC offset in minutes => moving from CustomLocal to UTC time zone
            }
            dateTimeUTCISO = dateTime.format('YYYY-MM-DDTHH:mm:ss');
            valueLastUncontrolled.current = null;
        }

        if (typeof onDayChange === 'function')
            onDayChange(dateTimeUTCISO);

        setValue(dateTimeUTCISO);
        setTouched(true);
    };

    const setControlledValue = x => {
        // eslint-disable-next-line eqeqeq
        if (x == '' && valueLastUncontrolled.current != null) {
            return valueLastUncontrolled.current;   //To avoid “clearing” the value while the user is entering a date
        } else {
            return convertIn(x);
        }
    };


    return (
        <div className={`col-md-${column}`}>
            <div className="form-group">
                <label title={toolTip}>{labelText}</label>
                <ReactDateTime
                    value={setControlledValue(value)}
                    utc={true}
                    dateFormat={dateFormatRef.current}
                    timeFormat={useTime && timeFormatRef.current}
                    onChange={convertOut}   //{props.onDayChange}
                    inputProps={{
                        className: internalError ? "form-control error" : "form-control",
                        title: titleRef.current
                    }}
                />
            </div>
        </div>
    )
}
