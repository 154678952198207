import React, { useState, useEffect } from 'react'
import { AuthorizedGet } from '../AuthorizedRequest'
import { useHistory } from 'react-router-dom'
import { Card } from '../shared/Card'
import TransactionsList from '../IAP/elements/TransactionsList'
import TotalCount from '../shared/TotalCount'
import { mkPagedResult } from '../shared/MKPagination'


export default function UserTransactions({ userId })
{
    const [loading, setLoading] = useState(true)
    const [transactionsResult, setTransactionsResult] = useState(mkPagedResult)
    const history = useHistory()

    // Fetch users
    useEffect(() =>
    {
        AuthorizedGet('api/Transaction/Search?pageSize=500&userId=' + userId, history)
            .then(result => 
            {
                setTransactionsResult(result ?? mkPagedResult);
                setLoading(false);
            });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ userId ])

    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <Card title="Transactions">
            <TransactionsList transactions={transactionsResult.items} loading={loading}/>
            <div className="row">
                <div className="md-12 col d-flex align-items-center justify-content-end">
                    <TotalCount itemsName="Transactions for the User">{loading ? null : transactionsResult.itemsTotalCount}</TotalCount>
                </div>
            </div>
        </Card>
    )
}