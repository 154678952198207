import React, {useEffect, useState} from "react";
import Select from "react-select";
import {useCacheLocal} from "../../../shared/Utils";
import dateRanges from "./StatisticsDateRanges"
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import {CreateButton} from "../../../shared/Buttons";
import { Formik, Form, Field } from "formik";
import {DateTimeInput} from "../../../shared/MKDateTime";
import {ErrorDisplay} from "../../../shared/FormElements";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


const defaultDateRange = 'Last30Days';


export default function StatisticsDateRangesSelector({ onChange = dateRange => {}, processing = false, readOnly = false })
{
    const [selectedValue, setSelectedValue] = useCacheLocal('mkSupportStatisticsDateRangeSelectedValue', defaultDateRange);
    const [customsList, setCustomsList] = useCacheLocal('mkSupportStatisticsDateRangeCustoms', []);
    const [options, setOptions] = useState(getInitialOption(customsList));
    const [customRangeModal, setCustomRangeModal] = useState(false);

    useEffect(() => {
        onChangeInternal(getSelectedOption());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    function getInitialOption(customs) {
        return [...dateRanges, ...customs.map(mapCustomToDateRange)]
    }
    
    function getSelectedOption() {
        return options.find(x => x.value === selectedValue);
    }
    
    function mapCustomToDateRange(custom) {
        const mapValues = isoDate => {
            if (isoDate) {
                let date = new Date(isoDate);
                return {
                    date,
                    value: isoDate,
                    label: date.toLocaleDateString()                    
                };
            } else {
                return {
                    date: null,
                    value: 'ANY',
                    label: <>[ <span className="font-italic">Any</span> ]</>          
                };
            }  
        };
        
        let fromValues = mapValues(custom.fromDate);
        let toValues = mapValues(custom.toDate);
        
        return {
            value: `${fromValues.value}_to_${toValues.value}`,
            label: <>{fromValues.label} to {toValues.label}</>,
            getFromDate: () => fromValues.date,
            getToDate: () => toValues.date,
        };
    }
    
    function onChangeInternal(selectedOption) {
        setSelectedValue(selectedOption.value);
        onChange(getFromDateToDateObject(selectedOption));            
    }
    
    function getFromDateToDateObject(selectedOption) {
        let fromDate = selectedOption.getFromDate()?.toISOString() ?? '';
        let toDate = selectedOption.getToDate()?.toISOString() ?? '';
        return {fromDate, toDate};   
    }
    
    const handleCustomRangeCreate = (fromDateToDate, formikBag = { setSubmitting: x => {} }) => {
        
        customsList.unshift(fromDateToDate);
        if (customsList.length > 4)
            customsList.pop();
        
        setOptions(getInitialOption(customsList));
        setCustomsList([...customsList]);
        setSelectedValue(mapCustomToDateRange(fromDateToDate).value);

        toggleCustomRangeModal();
        formikBag.setSubmitting(false);
        
        onChange(fromDateToDate);        
    };

    const toggleCustomRangeModal = () => setCustomRangeModal(!customRangeModal);

    const validateInternal = (values, props /* only available when using withFormik */ ) =>
    {
        let errors = {};
        if (!values.fromDate && !values.toDate)
        {
            errors.fromDate = 'At least one of the From/To dates is required for a valid range';
        }
        else if (values.fromDate && values.toDate && new Date(values.fromDate) >= new Date(values.toDate))
        {
            errors.fromDate = 'From date must be less than To date';            
        }
        return errors;
    };

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            textAlign: 'left',
        })
    };

    return (
        <>
            <div className="row align-items-center pb-1">
                <div className="col-md-2 text-end">
                    <label>Date range:</label>                    
                </div>
                <div className="col-md-9 pr-1">
                    <Select
                        value={getSelectedOption()}
                        onChange={onChangeInternal}
                        options={options}
                        isSearchable={false}
                        isDisabled={processing || readOnly}
                        isLoading={processing}
                        styles={customStyles}
                    />
                </div>
                <div className="col-md-1 p-0 text-left">
                    <button type="button" className="btn btn-sm no-margin shadow-none btn-link" title="Create new custom date range"
                            onClick={toggleCustomRangeModal}>
                        <FontAwesomeIcon className="green" icon="plus"/>
                    </button>
                </div>
            </div>

            <Modal isOpen={customRangeModal} toggle={toggleCustomRangeModal} contentClassName="card">
                <ModalHeader toggle={toggleCustomRangeModal}>Create custom date range</ModalHeader>
                <ModalBody>
                    <Formik
                        enableReinitialize
                        validate={validateInternal}
                        initialValues={getFromDateToDateObject(getSelectedOption())}
                        onSubmit={handleCustomRangeCreate}
                    >
                        {({ errors, status, touched, isSubmitting }) => (
                            <Form>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Field name="fromDate" component={DateTimeInput} useTime={false} labelText="From" />
                                        </div>
                                        <div className="col-md-6">
                                            <Field name="toDate" component={DateTimeInput} useTime={false} labelText="To" />
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-12 text-center">
                                            <CreateButton isSubmitting={isSubmitting} touched={touched} forceTouched={true} column={4} />
                                        </div>
                                    </div>
                                </div>

                                <ErrorDisplay errors={errors} touched={touched} />
                            </Form>
                        )}
                    </Formik>
                </ModalBody>
            </Modal>
        </>
    );
}