import React, {useRef, useState} from 'react'
import { useHistory } from 'react-router-dom'
import {AuthorizedDelete} from '../AuthorizedRequest'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import {useToasts} from 'react-toast-notifications'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


export default function UserDelete({user, onUserDeleted})
{
    const [processing, setProcessing] = useState(false);
    const [modal, setModal] = useState(false);
    const userIdConfirmRef = useRef();
    const history = useHistory();
    const toastsHook = useToasts();

    const toggleModal = () => setModal(!modal);

    
    const onDelete = e =>
    {
        e.preventDefault();
        if (userIdConfirmRef.current.value != user.userId)
        {
            alert('⚠ Entered UserId doesn\'t match');
            return;
        }      
        
        setProcessing(true);
        AuthorizedDelete(null,'api/User/' + user.userId, history)
            .then(result =>
            {
                if (result)
                {
                    toastsHook.addToast(`User ${result.userId} deleted correctly`, { appearance: 'info', autoDismiss: true });
                    onUserDeleted(result);
                    toggleModal();
                }
            })
            .finally(() => setProcessing(false));
    };

    if (user.deleted) {
        return (
            <button type="button" title="User already deleted" className="icon-button" disabled={true}>
                <FontAwesomeIcon className="trash-sign" icon="trash" size="2x" color={"#ccc"} aria-disabled={true}/>
            </button>
        )
    }

    return (
        <>
            <button type="button" title="Delete User (account data removal)" className="btn btn-sm shadow-none btn-link m-0" >
                <FontAwesomeIcon className="trash-sign" icon="trash" size="2x" onClick={toggleModal} />
            </button>

            <Modal isOpen={modal} toggle={toggleModal} contentClassName="card">
                <ModalHeader toggle={toggleModal}>Delete User (account data removal)</ModalHeader>
                <ModalBody>
                    <div className="container ">
                        <div className="row">
                            <div className="col-md-12">
                                <p className="alert alert-warning text-black">
                                    User <span className="font-monospace">{user.userId}</span>'s data will be removed and access will be restricted.<br/>
                                    <b>This action can not be undone</b>
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Enter <span className="font-monospace">UserId</span> to validate deletion</label>
                                    <input id="userIdConfirm" ref={userIdConfirmRef} className="form-control" maxLength={150}
                                           title="UserId needs to match to validate deletion" autoFocus />
                                </div>
                            </div>
                        </div>                        
                    </div>
                </ModalBody>
                <ModalFooter style={{justifyContent: 'center'}}>
                    <button type="button" className="btn btn-primary" onClick={onDelete} title="Proceed with user data deletion" disabled={processing}>
                        {processing ? '[ ... Processing ... ]' : 'Proceed with deletion'}
                    </button>
                </ModalFooter>
            </Modal>
        </>
    );
}

