import React from 'react'
import { useHistory } from 'react-router-dom'
import TargetedOfferForm from "./TargetedOfferForm";
import {AuthorizedPost} from "../AuthorizedRequest";

export default function TargetedOfferCreate()
{
    const targetedOffer =  { data: '{}', enabled: true, userSegments: [] }
    const history = useHistory()
    
    // Save changes
    const saveMethod = values => 
    {        
        return AuthorizedPost(values, 'api/TargetedOffer', history)
            .then(result => {
                if (result) {
                    history.replace('/TargetedOffers/' + result.id);
                }
                return result
            });
    };

    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <TargetedOfferForm targetedOffer={targetedOffer} saveMethod={saveMethod} loading={false} />
    );
}