import React from 'react'
import { useHistory } from 'react-router-dom'
import { composeQueryString } from '../../shared/Utils'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function TransactionsButton({searchObject})
{    
    let history = useHistory();
    
    const onClick = e =>
    {
        e.stopPropagation();
        const path = '/IAP/Transactions/?' + composeQueryString(searchObject);
        history.push(path);
    };

    return (
        <button type="button" title={"View Transactions"} className="icon-button">
            <FontAwesomeIcon className="dollar-sign" size="2x" icon="dollar-sign" onClick={ onClick }/>
        </button>
    )
}