import React from 'react'
import GoogleTranslate_png from '../../assets/images/GoogleTranslate.png'

export default function TranslateButton({text, toolTip = 'Translate to English'})
{
    const onClick = () => window.open('https://translate.google.com/?sl=auto&tl=en&op=translate&text=' + encodeURI(text), '_blank');

    return (
        <button className="icon-button" onClick={onClick} title={toolTip}>
            <img src={GoogleTranslate_png} alt="GT" height="19px" width="19px"/>
        </button>
    );
}