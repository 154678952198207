import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { AuthorizedGet, AuthorizedPost, AuthorizedDelete } from '../AuthorizedRequest'
import { useHistory } from 'react-router-dom'
import { Card } from '../shared/Card'
import { MKPaginationWithTotal, mkPagedResult } from '../shared/MKPagination';
import { composeQueryString, mapQueryStringValuesToObject, useQueryStringAndSessionState, useCache } from '../shared/Utils'
import UserSegmentsList from './UserSegmentsList'
import UserSegmentsSearch from './UserSegmentsSearch'
import { userSegmentsSearchCriteriaObject } from './UserSegmentsSearchCriteria'
import { CollapseSearchButton, AddButtonText } from '../shared/Buttons'
import { Collapse } from 'reactstrap';

export default function UserSegments()
{
    const initialEmptyValues = Object.assign({}, userSegmentsSearchCriteriaObject);

    let history = useHistory();
    const [loading, setLoading] = useState(true);
    const [initialValues, setInitialValues] = useQueryStringAndSessionState(() => Object.assign({}, initialEmptyValues), history, history.location.search, 'userSegmentsInitialValues');
    const [pagedResult, setPagedResult] = useState(mkPagedResult);
    const [redirectId, setRedirectId] = useState(-1);
    const [searchCollapsed, setSearchCollapsed] = useCache('userSegmentsSearchCollapsed', true);
    
    useEffect(() => {
        if (mapQueryStringValuesToObject(initialEmptyValues, history.location.search))   // There is allowed search parameters in QueryString
        {             
            if (JSON.stringify(initialEmptyValues) !== JSON.stringify(initialValues))  // Those parameters means a different search tan the one saved in sessionStorage
                searchUserSegments(initialEmptyValues);
            else
                setLoading(false);
        }
        else 
        {
            if (pagedResult.itemsTotalCount == null)
                searchUserSegments(initialValues);
            else
                setLoading(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    const searchUserSegments = (searchValues, formikBag = { setSubmitting: x => {} }, pageNumber = 1) => {
        searchValues.pageNumber = pageNumber;
        setLoading(true);
        setInitialValues(Object.assign({}, searchValues));
        AuthorizedGet('api/UserSegment/?' + composeQueryString(searchValues), history)
            .then(result => 
            {
                setPagedResult(result ?? mkPagedResult);
                formikBag.setSubmitting(false);
                setLoading(false);
            });
    };

    const onPageClick = (e, pageNumber) => searchUserSegments(initialValues, undefined, pageNumber);
    
    const toggleSearch = e =>  {
        e.preventDefault();
        setSearchCollapsed(!searchCollapsed);
    };

    const createSegment = () =>
    {
        AuthorizedPost(null, 'api/UserSegment', history)
            .then(data =>
            {
                setInitialValues(Object.assign({}, initialEmptyValues));
                setPagedResult(mkPagedResult);
                setRedirectId(data.id);
            });
    };

    const onDelete = segmentId => () =>
    {
        AuthorizedDelete(null, `api/UserSegment/${segmentId}`, history)
            .then(data =>
            {
                if (data)
                {
                    pagedResult.items = pagedResult.items.filter(function(s) { return s.id !== data.id });
                    pagedResult.itemsTotalCount--;
                    setPagedResult(Object.assign({}, pagedResult));
                }
            });
    };

    if (redirectId > -1) 
        return <Redirect push to={"/UserSegments/" + redirectId}/>

    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <div>
            <div className="row align-items-center">
                <div className="md-8 col">
                    <h3>&nbsp;User Segments</h3>
                </div>
                <div className="md-4 col text-end">
                    <AddButtonText onClick={createSegment} toolTip="Add New User Segment">Add New</AddButtonText>
                    &nbsp;
                    <CollapseSearchButton collapsed={searchCollapsed} onClick={toggleSearch}/>
                </div>
            </div>

            <Collapse isOpen={!searchCollapsed}>
                <Card>
                    <UserSegmentsSearch handleSearch={searchUserSegments} initialValues={initialValues}/>
                </Card>
            </Collapse>

            <Card>
                <UserSegmentsList userSegments={pagedResult.items} loading={loading} onDelete={onDelete}/>
                <MKPaginationWithTotal currentPage={pagedResult.pageNumber} total={pagedResult.pagesTotalCount} totalItems={pagedResult.itemsTotalCount} onClick={onPageClick} itemsName="User Segments"/>
            </Card>
        </div>
    );
}
