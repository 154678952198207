import React, {useRef, useState} from 'react';
import {useHistory} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {AuthorizedDelete, AuthorizedPost} from "../../AuthorizedRequest";
import { Card } from '../../shared/Card';
import UserPrefsAdd from "./UserPrefsAdd";
import UserPrefsClone from "./UserPrefsClone";
import UserPrefsDownload from "./UserPrefsDownload";
import UserPrefsUpload from "./UserPrefsUpload";
import UserPrefsFilter from "./UserPrefsFilter";
import UserPrefsDashboard from "./UserPrefsDashboard";


const withBlacklist = (userId, userPrefs) => {
    if(!userPrefs.filter(p => p.key === "Support_isBlacklisted")[0]) {
        userPrefs.push({
            userId,
            key: 'Support_isBlacklisted',
            value: '0'
        })
    }
    
    return userPrefs;
}

export default function UserPrefs({ userId, userPrefs, setSaveMethod, submitButton, setForceTouched, setUserPrefsSaving, metadataKeys, saving })
{
    const [uPrefs, setUPrefs] = useState(withBlacklist(userId, userPrefs));
    const [filteredPrefs, setFilteredPrefs] = useState(null);
    const userPrefsModifiedRef = useRef([]);
    const userPrefsToDeleteRef = useRef([]);
    const history = useHistory();

    setSaveMethod(save);

    function save() {   //Called from parent component
        if (userPrefsModifiedRef.current.length > 0) {
            setUserPrefsSaving(true);
            let endpoint = 'api/User/SaveUserPrefs/' + userId;
            if (userPrefsToDeleteRef.current.length > 0)
                endpoint += '?' + userPrefsToDeleteRef.current.map(x => 'userPrefsKeysToDelete=' + encodeURI(x.key)).join('&');
            AuthorizedPost(userPrefsModifiedRef.current, endpoint, history)
                .then(result => {
                    if (result) {
                        userPrefsModifiedRef.current = [];
                        userPrefsToDeleteRef.current = [];
                        refreshMetaData(result);
                        setForceTouched(false);
                    }
                })
                .finally(() => setUserPrefsSaving(false));
        } else if (userPrefsToDeleteRef.current.length > 0) {
            setUserPrefsSaving(true);
            AuthorizedDelete(userPrefsToDeleteRef.current.map(x => x.key), 'api/User/DeleteUserPrefs/' + userId, history)
                .then(result => {
                    if (result) {
                        userPrefsToDeleteRef.current = [];
                        refreshMetaData(result);
                        setForceTouched(false);
                    }
                })
                .finally(() => setUserPrefsSaving(false));
        }
    }

    function uPrefsUpdateValue(modPref)
    {
        // eslint-disable-next-line eqeqeq
        let uPref = uPrefs.find(x => x.key == modPref.key);
        if (uPref == null)
        {
            uPref = modPref;
            uPrefs.push(uPref);
        }
        else
        {
            uPref.value = modPref.value;
        }
        uPref.modified = (new Date()).getTime().toString();   //To get a different key for the array's map() and be able to refresh the ui
    }
    
    function refreshMetaData(userPrefMetadata) {
        for (const property in userPrefMetadata) {
            const key = 'userPref' + property.charAt(0).toUpperCase() + property.slice(1);
            uPrefsUpdateValue({ key: key, value: userPrefMetadata[property] });
        }
        setUPrefs([ ...uPrefs]);
    }
    
    function userPrefsSaved(userPrefsSaved, userPrefMetadata) {
        for (const userPref of userPrefsSaved) {
            uPrefsUpdateValue(userPref);            
        }
        refreshMetaData(userPrefMetadata);
    }

    const onUserPrefModified = (index, needRefresh = false) => {
        // eslint-disable-next-line eqeqeq
        if (!userPrefsModifiedRef.current.find(x => x.key == uPrefs[index].key))
            userPrefsModifiedRef.current.push(uPrefs[index]);
        if (needRefresh)
        {
            let pref = uPrefs.splice(index, 1)[0];
            pref.modified = (new Date()).getTime().toString();   //To get a different key for the array's map() and be able to refresh the ui
            uPrefs.unshift(pref);   //Put the item at the beginning for visibility
            setUPrefs([ ...uPrefs]);
        }
        setForceTouched(true);
    };

    const onUserPrefAdded = userPref => {
        uPrefs.unshift(userPref);
        setUPrefs([ ...uPrefs]);
        userPrefsModifiedRef.current.push(userPref);
        if (filteredPrefs) {
            setFilteredPrefs([userPref, ...filteredPrefs]);
        }
        setForceTouched(true);
    };

    const onUserPrefDeleted = index => {
        userPrefsToDeleteRef.current.push(uPrefs[index]);
        // eslint-disable-next-line eqeqeq
        let modifiedIndex = userPrefsModifiedRef.current.findIndex(x => x.key == uPrefs[index].key);
        if (modifiedIndex >= 0)
            userPrefsModifiedRef.current.splice(modifiedIndex, 1);
        uPrefs.splice(index, 1);
        setUPrefs([ ...uPrefs]);
        if (filteredPrefs) {
            // eslint-disable-next-line eqeqeq
            setFilteredPrefs(filteredPrefs.filter(y => y.key != uPrefs[index].key))
        }
        setForceTouched(true);
    };

    const onUserPrefsDeleteClick = e => {
        e.preventDefault();
        if (window.confirm('ALL the UserPrefs will be scheduled for removal when saving (“Save” button is pressed). \nConfirm?'))
        {
            userPrefsToDeleteRef.current = [...userPrefsToDeleteRef.current, ...uPrefs.filter(x => !metadataKeys.includes(x.key))];
            userPrefsModifiedRef.current = [];
            setUPrefs(uPrefs.filter(x => metadataKeys.includes(x.key)));
            setFilteredPrefs(null);
            setForceTouched(true);
        }
    };

    const onUserPrefsChanged = ups => {
        setFilteredPrefs(null);
        userPrefsModifiedRef.current = [];
        userPrefsToDeleteRef.current = [];
        setUPrefs(ups)
        setForceTouched(false);
    };

    const onChangeInternal = index => e => {
        uPrefs[index].value = e.target.value;
        onUserPrefModified(index);
    };

    const onDeleteInternal = index => e => {
        e.preventDefault();
        onUserPrefDeleted(index);
    };

    const isMetadata = x => metadataKeys.includes(x.pref.key);
    
    const savePending = () => userPrefsModifiedRef.current.length > 0 || userPrefsToDeleteRef.current.length > 0;

    // eslint-disable-next-line eqeqeq
    const prefsToRender = filteredPrefs ? filteredPrefs.map(x => ({ pref: x, index: uPrefs.findIndex(y => y.key == x.key) })) :
                                          uPrefs.map((x, i) => ({ pref: x, index: i }));
    
    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <Card headerComponent={
            <div className="row">
                <div className="col-md-8 col">
                    <h3 className="d-inline-block">User Prefs</h3>
                    { savePending() &&
                    <h6 className="d-inline-block mt-2">
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        (<span className="font-italic text-info"
                               title="Modifications to UserPrefs have not been persisted. Use the save button to persist the changes.">save pending</span>)
                    </h6>
                    }
                </div>
                <div className="col-md-4 text-end">
                    <button type="button" title="UserPrefs Backups fot the current user" className="btn btn-sm shadow-none btn-link m-0">
                        <FontAwesomeIcon className="archive" icon="archive" size="2x" onClick={() => history.push(`/Users/${userId}/UserPrefsBackup`)} style={{fontSize: '2em'}}/>
                    </button>
                    &nbsp;
                    <UserPrefsDownload userId={userId} savePending={savePending()} />
                    &nbsp;
                    <UserPrefsUpload userId={userId} onUserPrefsChanged={onUserPrefsChanged}/>
                    &nbsp;
                    <UserPrefsClone userId={userId} onUserPrefsChanged={onUserPrefsChanged}/>
                    &nbsp;                    
                    <button type="button" title="Clear Preferences" className="btn btn-sm shadow-none btn-link m-0" disabled={saving}>
                        <FontAwesomeIcon className="trash-sign" icon="trash" size="2x" onClick={onUserPrefsDeleteClick} style={{fontSize: '2em'}}/>
                    </button>
                    &nbsp;
                    <UserPrefsAdd userPrefs={userPrefs}
                                  onUserPrefModified={onUserPrefModified}
                                  onUserPrefAdded={onUserPrefAdded}
                                  metadataKeys={metadataKeys}
                                  saving={saving}/>
                </div>
            </div>
        }>
        
            <UserPrefsDashboard userId={userId} userPrefs={uPrefs} userPrefsSaved={userPrefsSaved}/>

            <UserPrefsFilter uPrefs={uPrefs} setFilteredPrefs={setFilteredPrefs} disabled={saving}/>

            <table className='table table-sm'>
                <tbody>
                {prefsToRender.map(x =>
                    <tr key={x.pref.key + (x.pref.modified ?? '')}>
                        <td>{x.pref.key}</td>
                        <td style={{width:'40%'}}>
                            <input defaultValue={x.pref.value} onChange={onChangeInternal(x.index)}
                                   className="form-control" readOnly={saving || isMetadata(x)}/>
                        </td>
                        <td>
                            { !isMetadata(x) &&
                            <button title="Delete" className="icon-button" disabled={saving}>
                                <FontAwesomeIcon className="trash-sign" icon="trash" onClick={onDeleteInternal(x.index)}/>
                            </button>
                            }
                        </td>
                    </tr>
                )}
                </tbody>
            </table>

            <div className="row text-center">
                <div className="col-md-12">
                    {submitButton}
                </div>
            </div>
        </Card>
    );
}