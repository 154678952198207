import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { AuthorizedPut } from '../../../AuthorizedRequest'
import SupportTicketEdit from "./SupportTicketEdit";

export default function SupportTicketModify()
{
    const location = useLocation()
    const history = useHistory()
    
    const saveTicket = (values, actions) =>
    {
        AuthorizedPut(values, "api/Support/UpdateTicket", history)
            .then(result =>
            {
                actions.setSubmitting(false)
                if (result)
                {
                    actions.setTouched({})

                    const path = `/Support/SupportTickets/${encodeURIComponent(result.id)}`
                    history.push(path, { supportTicket: result })
                }
            })
    }
    
    return (
        <SupportTicketEdit supportTicket={location?.state?.supportTicket} saveTicket={saveTicket}/>
    );
}