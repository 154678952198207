import React, { useState } from 'react'
import { JSONEditor } from '../../../shared/JSONEditor'
import { KeyValueEditorUnmanaged } from '../../../shared/KeyValueEditor'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import { ButtonToolbar } from 'reactstrap'
import Button from 'react-bootstrap/Button'
import languagesAssets from '../../../../assets/languages.json'

export default function MessageTemplateContent({ content, onChange })
{
    const [editJson, setEditJson] = useState(false);
    const [contentJson, setContentJson] = useState(() => JSON.parse(content || "{}"));
    const [languages, setLanguages] = useState(getLanguages);
    
    function getLanguages()
    {
        return languagesAssets.filter(x => contentJson[x] === undefined);
    }
    
    const addLanguage = language => e =>
    {
        if (contentJson[language] === undefined)
        {
            contentJson[language] = "";
            setLanguages(getLanguages());
            setContentJson(Object.assign({}, contentJson));
            onChange(contentJson);
        }
    };

    const updateLanguage = language => e =>
    {
        contentJson[language] = e.target.value;
        onChange(contentJson);
    };

    const removeLanguage = language => e =>
    {
        delete contentJson[language];
        setLanguages(getLanguages());
        setContentJson(Object.assign({}, contentJson));
        onChange(contentJson);
    };

    const onChangeJSONEditor = json =>
    {
        setContentJson(json);
        onChange(json);
    };

    return (
        <>
            { editJson ?
            <>
                <JSONEditor value={contentJson} onChange={onChangeJSONEditor} />
                <Button size="sm" variant="secondary" onClick={e => setEditJson(false)}>Edit as Text</Button>
                <br/>
            </>
            :
            <>
                { Object.keys(contentJson).map((language, index) => 
                <KeyValueEditorUnmanaged key={language} editKey={false} aKey={language} aValue={contentJson[language]} index={index} 
                    onChange={updateLanguage(language)} removeKey={removeLanguage(language)}/>)
                }
                <ButtonToolbar>
                    <DropdownButton title="Add Language" size="sm" variant="white" drop="down" id="ddbAddLanguage">
                        { languages.map((language, index) =>
                        <Dropdown.Item as="button" type="button" eventKey={index} onClick={addLanguage(language)} key={index}>{language}</Dropdown.Item>)}
                    </DropdownButton>
                    <Button size="sm" variant="secondary" onClick={e => setEditJson(true)}>Edit as JSON</Button>
                </ButtonToolbar>
            </>
            }
        </>
    )
}