import React from 'react'
import { useHistory } from "react-router-dom"
import { ShortDateTime } from '../shared/MKDateTime'
import { Loading } from '../shared/Loading'
import { DeleteButton } from '../shared/Buttons'
import {Input} from "reactstrap";

export default function ABTestsList({ abTests, loading, onDelete }) {
    
    const history = useHistory();
    
    function onRowClick(itemId)
    {
        const path = `ABTests/${encodeURIComponent(itemId)}`;
        history.push(path);
    }

    if (loading)
    {
        return <Loading loading={true} />
    }

    return (
        <table className='table table-full-width table-striped table-hover'>
            <thead>
                <tr>
                    <th>Id</th>
                    <th>Name</th>
                    <th>From</th>
                    <th>Till</th>
                    <th>Groups No</th>
                    <th>Max Users</th>
                    <th>Assigned Users</th>
                    <th>Enabled</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {abTests.map((abTest, index) =>
                <tr className="tr-link" onClick={() => onRowClick(abTest.id)} key={index}>
                    <td>{abTest.id}</td>
                    <td>{abTest.name}</td>
                    <td><ShortDateTime>{abTest.startDate}</ShortDateTime></td>
                    <td><ShortDateTime>{abTest.endDate}</ShortDateTime></td>
                    <td>{abTest.groups}</td>
                    <td>{abTest.maxUsers}</td>
                    <td>{abTest.assignedUsers}</td>
                    <td>
                        <Input type="checkbox" disabled={true} checked={abTest.enabled}
                               title={abTest.enabled ? 'Enabled' : 'Disabled'} />
                    </td>
                    <td className="text-end">
                        <DeleteButton onDelete={onDelete(abTest.id)} entityName="A/B Test" />
                    </td>
                </tr>
                )}
            </tbody>
        </table>
        );
}