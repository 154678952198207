import React from 'react'
import { Field } from 'formik'
import { TextFieldInputColumn, NullableToggleInput } from '../shared/FormElements'
import { DateTimeInput } from '../shared/MKDateTime'
import { mkPaginationObject } from '../shared/MKPagination'
import {DropdownInputColumn} from "../shared/FormElementsDropdowns";

export const usersSearchCriteriaObject = Object.assign({
    userId : '',
    name : '', 
    createdFrom : '', 
    createdTo : '', 
    lastLoginFrom : '', 
    lastLoginTo : '', 
    installationId : '', 
    localeCode : '',
    linkedIdentifier : '',
    cloudSaveActive : '',
    userPrefKey : '', 
    userPrefValue : '',
    hasTransactions : '',
    hasSupportTickets : '',
    // isInATeam : '',
    isCheater : '',
    isDeleted : ''
}, mkPaginationObject);

//From: MKNet.Models.IdentifierType
let identifiersList = [
    {key: '', value: '[ Ignore ]'},
    {key: 'any', value: '( Any )'},
    {key: 'none', value: '( None )'},
    {key: 'Facebook', value: 'Facebook'},
    {key: 'GameCenter', value: 'Apple GameCenter'},
    {key: 'GooglePlayGames', value: 'Google PlayGames'},
    {key: 'Device', value: 'Device'},
    {key: 'User', value: 'User'},
    {key: 'Firebase', value: 'Firebase'},
    {key: 'PlayFab', value: 'PlayFab'},
    {key: 'Steam', value: 'Steam'}
];

export default function UsersSearchCriteria(props)
{
    return (
        <>
            <div className="row">
                <Field name="userId" component={TextFieldInputColumn} labelText="User Id" column={3}/>
                <Field name="name" component={TextFieldInputColumn} labelText="Name" column={3}/>
                <Field name="installationId" component={TextFieldInputColumn} labelText="Installation Id" column={3}/>
                <Field name="localeCode" component={TextFieldInputColumn} labelText="Locale Code" maxLength={3} column={3}/>
            </div>
            <div className="row">
                <Field name="hasTransactions" component={NullableToggleInput} trueText="Yes" falseText="No" labelText="Has Transactions" column={3}/>
                <Field name="hasSupportTickets" component={NullableToggleInput} trueText="Yes" falseText="No" labelText="Has Support Tickets" column={3}/>
                <Field name="isCheater" component={NullableToggleInput} trueText="Yes" falseText="No" labelText="Is Cheater" column={3}/>
                <Field name="isDeleted" component={NullableToggleInput} trueText="Yes" falseText="No" labelText="Is deleted" column={3}/>
            </div>
            <div className="row">
                <Field name="linkedIdentifier" component={DropdownInputColumn} width={null} labelText="Linked Identifiers" valuesList={identifiersList} column={3}/>
                <Field name="cloudSaveActive" component={NullableToggleInput} trueText="Yes" falseText="No" labelText="Cloud Saves active" column={3}/>              
                <Field name="userPrefKey" component={TextFieldInputColumn} labelText="User Pref Key" column={3}/>
                <Field name="userPrefValue" component={TextFieldInputColumn} labelText="User Pref Value" column={3}/>
            </div>
            <div className="row">
                <Field name="createdFrom" component={DateTimeInput} useTime={true} labelText="Created From" column={3}/>
                <Field name="createdTo" component={DateTimeInput} useTime={true} labelText="Created To" column={3}/>
                <Field name="lastLoginFrom" component={DateTimeInput} useTime={true} labelText="Last Login From" column={3}/>
                <Field name="lastLoginTo" component={DateTimeInput} useTime={true} labelText="Last Login To" column={3}/>
            </div>
        </>
    );
}