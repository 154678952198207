// These have been ported from the documentation to make life easier when structuring requests
// List taken from https://docs.microsoft.com/en-gb/rest/api/playfab/multiplayer/multiplayer-server/update-build-region?view=playfab-rest#azureregion
export const azureRegions = {
    AustraliaEast: "AustraliaEast",
    AustraliaSoutheast: "AustraliaSoutheast",
    BrazilSouth: "BrazilSouth",
    CentralIndia: "CentralIndia",
    CentralUs: "CentralUs",
    EastAsia: "EastAsia",
    EastUs: "EastUs",
    EastUs2: "EastUs2",
    FranceCentral: "FranceCentral",
    JapanEast: "JapanEast",
    JapanWest: "JapanWest",
    KoreaCentral: "KoreaCentral",
    NorthCentralUs: "NorthCentralUs",
    NorthEurope: "NorthEurope",
    SouthAfricaNorth: "SouthAfricaNorth",
    SouthCentralUs: "SouthCentralUs",
    SoutheastAsia: "SoutheastAsia",
    UaeNorth: "UaeNorth",
    UkSouth: "UkSouth",
    WestCentralUs: "WestCentralUs",
    WestEurope: "WestEurope",
    WestUs: "WestUs",
    WestUs2: "WestUs2"
}

// These have been ported from the documentation to make life easier when structuring requests
// List taken from https://docs.microsoft.com/en-gb/rest/api/playfab/multiplayer/multiplayer-server/update-build-region?view=playfab-rest#azurevmsize
export const azureVmSizes = {
    Standard_A1: "Standard_A1",
    Standard_A1_v2: "Standard_A1_v2",
    Standard_A2: "Standard_A2",
    Standard_A2_v2: "Standard_A2_v2",
    Standard_A3: "Standard_A3",
    Standard_A4: "Standard_A4",
    Standard_A4_v2: "Standard_A4_v2",
    Standard_A8_v2: "Standard_A8_v2",
    Standard_D16_v3: "Standard_D16_v3",
    Standard_D16a_v4: "Standard_D16a_v4",
    Standard_D16as_v4: "Standard_D16as_v4",
    Standard_D16d_v4: "Standard_D16d_v4",
    Standard_D16ds_v4: "Standard_D16ds_v4",
    Standard_D16s_v3: "Standard_D16s_v3",
    Standard_D1_v2: "Standard_D1_v2",
    Standard_D2_v2: "Standard_D2_v2",
    Standard_D2_v3: "Standard_D2_v3",
    Standard_D2a_v4: "Standard_D2a_v4",
    Standard_D2as_v4: "Standard_D2as_v4",
    Standard_D2d_v4: "Standard_D2d_v4",
    Standard_D2ds_v4: "Standard_D2ds_v4",
    Standard_D2s_v3: "Standard_D2s_v3",
    Standard_D3_v2: "Standard_D3_v2",
    Standard_D4_v2: "Standard_D4_v2",
    Standard_D4_v3: "Standard_D4_v3",
    Standard_D4a_v4: "Standard_D4a_v4",
    Standard_D4as_v4: "Standard_D4as_v4",
    Standard_D4d_v4: "Standard_D4d_v4",
    Standard_D4ds_v4: "Standard_D4ds_v4",
    Standard_D4s_v3: "Standard_D4s_v3",
    Standard_D5_v2: "Standard_D5_v2",
    Standard_D8_v3: "Standard_D8_v3",
    Standard_D8a_v4: "Standard_D8a_v4",
    Standard_D8as_v4: "Standard_D8as_v4",
    Standard_D8d_v4: "Standard_D8d_v4",
    Standard_D8ds_v4: "Standard_D8ds_v4",
    Standard_D8s_v3: "Standard_D8s_v3",
    Standard_DS1_v2: "Standard_DS1_v2",
    Standard_DS2_v2: "Standard_DS2_v2",
    Standard_DS3_v2: "Standard_DS3_v2",
    Standard_DS4_v2: "Standard_DS4_v2",
    Standard_DS5_v2: "Standard_DS5_v2",
    Standard_E16a_v4: "Standard_E16a_v4",
    Standard_E16as_v4: "Standard_E16as_v4",
    Standard_E2a_v4: "Standard_E2a_v4",
    Standard_E2as_v4: "Standard_E2as_v4",
    Standard_E4a_v4: "Standard_E4a_v4",
    Standard_E4as_v4: "Standard_E4as_v4",
    Standard_E8a_v4: "Standard_E8a_v4",
    Standard_E8as_v4: "Standard_E8as_v4",
    Standard_F1: "Standard_F1",
    Standard_F16: "Standard_F16",
    Standard_F16s_v2: "Standard_F16s_v2",
    Standard_F2: "Standard_F2",
    Standard_F2s_v2: "Standard_F2s_v2",
    Standard_F4: "Standard_F4",
    Standard_F4s_v2: "Standard_F4s_v2",
    Standard_F8: "Standard_F8",
    Standard_F8s_v2: "Standard_F8s_v2",
    Standard_HB120_16rs_v3: "Standard_HB120_16rs_v3",
    Standard_HB120_32rs_v3: "Standard_HB120_32rs_v3",
    Standard_HB120_64rs_v3: "Standard_HB120_64rs_v3",
    Standard_HB120_96rs_v3: "Standard_HB120_96rs_v3",
    Standard_HB120rs_v3: "Standard_HB120rs_v3",
    Standard_NC4as_T4_v3: "Standard_NC4as_T4_v3"
}

export const azurePortTypes = {
    Tcp: "TCP",
    Udp: "UDP"
}

export const azureContainerFlavor = {
    CustomLinux: "CustomLinux",
    ManagedWindowsServerCore: "ManagedWindowsServerCore",
    ManagedWindowsServerCorePreview: "ManagedWindowsServerCorePreview"
}

export const defaultPortConfig = {
    num: 7777,
    name: 'udp_port',
    protocol: azurePortTypes.Udp
};

export const defaultRegionConfig = {
    maxServers: 5,
    region: azureRegions.AustraliaEast,
    standbyServers: 1
};