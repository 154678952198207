import React from 'react'
import {AuthorizedPost} from '../../../AuthorizedRequest'
import MessageTemplate from "./MessageTemplate";

export default function MessageTemplateCreate() {
 
    let newTemplate = {
        Name: '',
        content: '{ "English": "" }'
    };
    
    return (
        <MessageTemplate saveMethod={AuthorizedPost} saveEndPoint="api/Support/CreateMessageTemplate" editTemplate={newTemplate} />
    );
}