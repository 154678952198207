import React from "react";
import {Field, FieldArray} from "formik";
import {AddButtonText, IconButtonAlt} from "../shared/Buttons";
import {Card} from "../shared/Card";
import {TextFieldInputColumn} from "../shared/FormElements";

export default function PlayFabMetadataConfigurationSection({values}) {
    return (
        <FieldArray name="metadata"
            render={arrayHelpers => (
                <>
                    <br />
                    <div className="row align-items-center">
                        <div className="col-md-8">
                            <h5>Metadata</h5>
                        </div>
                        <div className="col-md-4 text-end">
                            <AddButtonText onClick={() => arrayHelpers.push({
                                key: "",
                                value: ""
                            })} />
                        </div>
                    </div>
                    {
                        values.metadata.map((kvp, index) => (
                            <Card key={index}>
                                <div className="row align-items-center">
                                    <div className="col-md-5">
                                        <Field name={`metadata.${index}.key`} component={TextFieldInputColumn} labelText="Key" />
                                    </div>
                                    <div className="col-md-5">
                                        <Field name={`metadata.${index}.value`} component={TextFieldInputColumn} labelText="Value" />
                                    </div>
                                    <div className="col-md-2 text-center">
                                        <IconButtonAlt icon="trash" toolTip="Delete Port Configuration" onClick={() => arrayHelpers.remove(index)}>
                                            DELETE
                                        </IconButtonAlt>
                                    </div>
                                </div>
                            </Card>
                        ))
                    }
                </>
            )}
        />
    );
}