import React, {useEffect, useState} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {AuthorizedPut, AuthorizedGet } from '../AuthorizedRequest'
import {Loading} from "../shared/Loading";
import {useToasts} from "react-toast-notifications";
import CurrencyForm from "./CurrencyForm";

export default function EditCurrency()
{
    const history = useHistory()
    const [currency, setCurrency] = useState();
    const toast = useToasts()
    const {currencyId} = useParams();
    
    useEffect(() => {
        AuthorizedGet(`api/Currency/${currencyId}`, history)
            .then(result =>
            {
                setCurrency(result);
            });
    }, [currencyId, history, setCurrency]);
    
    const saveEntity = (catalogueType) =>
        AuthorizedPut(catalogueType, `api/Currency/${currencyId}`, history)
            .then(() => {
                toast.addToast("Saved", {
                    autoDismiss: true
                });
            });

    if (!currency) {
        return <Loading />;
    }
        
    return (
        <div>
            <CurrencyForm 
                currency={currency}
                onSubmit={saveEntity}/>
        </div>
    )
}