import React, { useState, useEffect } from 'react'
import { AuthorizedGet, AuthorizedDelete } from '../AuthorizedRequest'
import { useHistory } from 'react-router-dom'
import { Card } from '../shared/Card'
import { giftsSearchCriteriaObject } from './GiftsSearchCriteria'
import GiftsSearch from './GiftsSearch'
import GiftsList from './GiftsList'
import { composeQueryString, mapQueryStringValuesToObject, useSessionState, useQueryStringAndSessionState, useCache } from '../shared/Utils'
import { CollapseSearchButton } from '../shared/Buttons'
import { Collapse } from 'reactstrap'
import MKPaginationUncounted, {mkPagedResultUncounted} from "../shared/MKPaginationUncounted";

export default function Gifts(props)
{ 
    const initialEmptyValues = Object.assign({}, giftsSearchCriteriaObject);
    
    let history = useHistory();
    const [loading, setLoading] = useState(true);
    const [initialValues, setInitialValues] = useQueryStringAndSessionState(() => Object.assign({}, initialEmptyValues), history, history.location.search, "giftsInitialValues");
    const [pagedResult, setPagedResult] = useSessionState(mkPagedResultUncounted, "gifts");
    const [searchCollapsed, setSearchCollapsed] = useCache('giftsSearchCollapsed', false);
    
    useEffect(() =>
    {
        setLoading(false)
        if (mapQueryStringValuesToObject(initialEmptyValues, history.location.search))   // There is allowed search paramenters in QueryString  [&&]
        {
            if (JSON.stringify(initialEmptyValues) !== JSON.stringify(initialValues))  // Those parameters means a diferent search tan the one saved in sessionStorage
            {
                searchGifts(initialEmptyValues);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const searchGifts = (searchValues, formikBag = { setSubmitting: x => {} }, pageNumber = 1) => {
        searchValues.pageNumber = pageNumber;
        setLoading(true);
        setInitialValues(Object.assign({}, searchValues));
        AuthorizedGet('api/Gift/Search?' + composeQueryString(searchValues), history)
            .then(result => 
            {
                setPagedResult(result ?? mkPagedResultUncounted);
                formikBag.setSubmitting(false);
                setLoading(false);
            });
    };
    
    const handleDelete = giftId => () =>
    {
        AuthorizedDelete(null, `api/Gift/${giftId}`, history)
            .then(resultId => {
                pagedResult.items = pagedResult.items.filter(g => g.id !== resultId);
                setPagedResult(Object.assign({}, pagedResult));
            });
    }
    
    const onPageClick = (e, pageNumber) => searchGifts(initialValues, undefined, pageNumber);    

    const toggleSearch = e =>  {
        e.preventDefault();
        setSearchCollapsed(!searchCollapsed);
    };


    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const paginationComponent = <MKPaginationUncounted currentPage={pagedResult.pageNumber} currentPageCount={pagedResult.currentPageCount} onClick={onPageClick}/>

    return (
        <div>
            <div className="row align-items-center">
                <div className="md-8 col">
                    <h3>&nbsp;Gifts</h3>
                </div>
                <div className="md-4 col text-end">
                    <CollapseSearchButton collapsed={searchCollapsed} onClick={toggleSearch}/>
                </div>
            </div>

            <Collapse isOpen={!searchCollapsed}>
                <Card>
                    <GiftsSearch handleSearch={searchGifts} initialValues={initialValues}/>
                </Card>
            </Collapse>

            <Card>
                {paginationComponent}
                <GiftsList gifts={pagedResult.items} loading={loading} onDelete={handleDelete}/>
                {paginationComponent}
            </Card>
        </div>
    );
}
