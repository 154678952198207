import authService from './api-authorization/AuthorizeService'
import {ApplicationPaths, QueryParameterNames} from './api-authorization/ApiAuthorizationConstants'
import { saveAs } from 'file-saver';

var retry = 0;

var useToastsHook;
/**
 * Allows to use react-toast-notifications from outside functional components
 * Setter is called from [Layout/Layout.js]
 *
 * @export
 * @param {useToasts() return value from react-toast-notifications} useToastHookInstance
 */
export function setUseToastHook(useToastHookInstance)
{
    useToastsHook = useToastHookInstance;
}

export async function AuthorizedGet(endpoint, history, signal)
{
    const extraInit = signal ? { signal: signal } : { };

    return await authorizedFetch(endpoint, extraInit, history);
}

export async function AuthorizedPost(data, endpoint, history, method = "POST")
{
    return await AuthorizedPostRaw(JSON.stringify(data), endpoint, history, method);
}

export async function AuthorizedPostRaw(rawData, endpoint, history, method = "POST", contentType)
{
    const extraInit = {
        method: method,
        body: rawData
    };

    return await authorizedFetch(endpoint, extraInit, history, contentType);
}

export async function AuthorizedDelete(data, endpoint, history)
{
    return await AuthorizedPost(data, endpoint, history, "DELETE");
}

export async function AuthorizedPut(data, endpoint, history)
{
    return await AuthorizedPost(data, endpoint, history, "PUT");
}

export async function AuthorizedDownload(endpoint, fileName, history)
{
    let blobResponse = await authorizedFetch(endpoint, null, history, null, async r => await r.blob());
    if (blobResponse)
        saveAs(blobResponse, fileName);
}

async function authorizedFetch(endpoint, extraInit, history, contentType = 'application/json', readResponseAsync = async r => await r.json())
{
    const baseHeaders = {
        'Accept': 'application/json'
    };
    
    if (contentType != null)  //For correct file upload, the Content-Type header must be leave empty, then it's completed automatically by the browser including the “boundary” part. Ref: https://stackoverflow.com/questions/39280438/fetch-missing-boundary-in-multipart-form-data-post
        baseHeaders['Content-Type'] = contentType;

    const fetchMethod = async () => {
        const id_token = await authService.getIdToken();

        const headers = id_token ? Object.assign({ 'Authorization': `Bearer ${id_token}` }, baseHeaders) : baseHeaders;
        const fetchInitParam = Object.assign({ headers: headers }, extraInit)

        try
        {
            const resp = await fetch(endpoint, fetchInitParam);
            return resp;
        } catch (error) {
            processFetchError(error);
            return null;
        }
    };

    let response = await fetchMethod();

    return await processResponse(response, history, fetchMethod, readResponseAsync);
}

async function processResponse(response, history, retryMethod, readResponseAsync)
{
    if (!response)
    {
        return null;
    }

    if (response.ok)
    {
        try
        {
            const data = await readResponseAsync(response);
            return data;
        } catch (error) {
            processFetchError(error);
            return null;
        }
    }
    else if (response.status === 401)   //Unauthorized
    {
        if (retryMethod && (await refreshToken())) //ToDo: Check this method
        {
            //let's try again
            let response2 = await retryMethod();
            return await processResponse(response2, history, null, readResponseAsync);
        }
        else
        {
            // log out / log in again
            history.push(`${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURI(window.location.href)}`, {local: true});
        }
    }
    else
    {
        await processResponseError(response);   //Other errors (400's, 500's, etc)
    }
    return null
}

function processFetchError(error)
{
    if (error.name === "AbortError")
    {
        // this is NOT an error user should see
        console.warn("Fetch aborted")
        return
    }
    console.error(error);
    addToastError('ERROR: ' + error.message);
}

async function processResponseError(response)
{
    if (response.status == 409 || (response.status >= 459 && response.status < 500))
    {   //409 = "Conflict"; 459~499 = Custom codes
        //Custom "expected" errors / validations
        addToastError(await response.text(), 'warning');
    }
    else {
        let errorMessage = 'ERROR ' + response.status;
        try {
            let textResponse = await response.text();
            let jsonMessage;
            try {
                let jsonResponse = JSON.parse(textResponse);
                jsonMessage = jsonResponse.title ?? jsonResponse.message ?? jsonResponse.value?.message ?? jsonResponse.value;
            } catch (eJson) {}
            if (jsonMessage)
                errorMessage += ': ' + jsonMessage;
            else
                errorMessage += ': ' + textResponse.substr(0, 250);
        } catch (error) { }
        addToastError(errorMessage);
    }
}

function addToastError(errorMessage, appearance = 'error')
{
    if (window.navigator.onLine === false)
    {
        setTimeout(() => {
            if (window.navigator.onLine === false)
            {
                addOfflineToast();
            }
        }, 1000);
    }
    useToastsHook.addToast(errorMessage, { appearance: appearance, autoDismiss: true, autoDismissTimeout: 15000 });
}

var offlineToastId = null;

function addOfflineToast()
{
    if (offlineToastId === null)
    {
        const onlineHandler = () => {
            useToastsHook.removeToast(offlineToastId);
            offlineToastRemoved();
        };

        const offlineToastRemoved = () => {
            window.removeEventListener('online', onlineHandler);
            offlineToastId = null;
        };

        useToastsHook.addToast('OFFLINE: Internet connection unavailable',
            { appearance: 'warning', autoDismiss: false, onDismiss: offlineToastRemoved },
            toastId => offlineToastId = toastId);

        window.addEventListener('online', onlineHandler);
    }
}

async function refreshToken()
{
    // ===================================================================== //   
    //  Disabling the Refresh token functionality (it never worked anyways)  // 
    return false;
    // ===================================================================== //
    
    console.log("Refresh token attempt " + (retry +1))
    // try refresh
    var refreshResponse = fetch("/.auth/refresh", {method: "GET"})
    console.log("Refresh token response " + refreshResponse.status)
    if (refreshResponse.status === 200 && retry < 5)
    {   //ToDo (revise):
        //  # Since the call to fetch() in line 137 returns a Promise, [refreshResponse.status] is always [undefined],
        //    therefore this conditional is never executed.
        //  # Adding await to fetch(), refreshResponse.status is 200, but then the retry (line 86) fails the 5 times and finally it goes for the login (line 91)
        //    => It looks like the token should be somehow updated in [authService] to allow the method call [authService.getIdToken()] returns the updated token
        //       (requires further review)
        retry += 1
        return true
    }
    return false
}
