import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { AuthorizedGet, AuthorizedPost, AuthorizedDelete } from '../../AuthorizedRequest'
import { Card } from '../../shared/Card'
import { Loading } from '../../shared/Loading'
import { DeleteButton } from '../../shared/Buttons'

export default function Templates(props)
{
    const [contentCodeTemplates, setContentCodeTemplates] = useState(null)
    const [loading, setLoading] = useState(true)

    const history = useHistory()

    useEffect(() =>
    {
        AuthorizedGet(`api/Redeemable/GetTemplates`, history)
            .then(result =>
            {
                if (result)
                {
                    setContentCodeTemplates(result)
                }
                setLoading(false)
            })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    function onRowClickContentCode(contentCodeTemplate)
    {
        const path = `Templates/${encodeURIComponent(contentCodeTemplate.id)}`
        history.push(path, {redeemable: contentCodeTemplate})
    }


    function createContentCodeTemplate()
    {
        AuthorizedPost(null, `api/Redeemable/CreateTemplate`, history)
            .then(result =>
            {
                console.log("Content code template created " + JSON.stringify(result))
                if (result)
                {
                    const path = `Templates/${encodeURIComponent(result.id)}`
                    history.push(path, { redeemable: result })
                }
            })
    }


    const deleteContentCodeTemplate = (template) => () =>
    {
        AuthorizedDelete(template, `api/Redeemable/DeleteTemplate`, history)
            .then(result =>
            {
                console.log("Content code template deleted " + JSON.stringify(result))
                if (result)
                {
                    setContentCodeTemplates(contentCodeTemplates.filter(t => t.id !== result.id))
                }
            })
    }


    if (loading)
    {
        return <Loading loading={loading}/>
    }
    return (<>
            <Card>
                <button className="btn btn-primary" onClick={createContentCodeTemplate}>Create New</button>
                <table className="table">
                    <tbody>
                    {contentCodeTemplates && contentCodeTemplates.map((template, index) =>
                        <tr key={index} onClick={(e) => onRowClickContentCode(template)} className="tr-link">
                            <td>{template.name}</td>
                            <td>{template.prefix}</td>
                            <td className="td-actions text-end">
                                <DeleteButton className='btn no-margin shadow-none btn-link' onDelete={deleteContentCodeTemplate(template)} entityName="Content Code Template"/>
                            </td>
                        </tr>)}
                    </tbody>
                </table>
            </Card>
        </>
    )
}