import {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {AuthorizedGet, AuthorizedPut} from "../AuthorizedRequest";
import ABTestForm from "./ABTestForm";


export default function ABTestEdit() 
{
    const [loading, setLoading] = useState(true)
    const [abTest, setAbTest] = useState()
    const history = useHistory()
    let {abTestId} = useParams()

    // Fetch ABTests
    useEffect(() =>
    {
        AuthorizedGet(`api/ABTest/${abTestId}`, history)
            .then(result => {
                if (result) {
                    setAbTest(result)
                }
                setLoading(false)
            })
    }, [abTestId, history])

    const saveMethod = values => {
        return AuthorizedPut(values, 'api/ABTest', history)
            .then(result =>
            {
                if (result)
                {
                    setAbTest(result);
                }
                return result;
            });
    };

    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <ABTestForm abTest={abTest} saveMethod={saveMethod} loading={loading}/>
    );
}
