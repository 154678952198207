import {createContext, useCallback, useEffect, useState} from "react";
import {AuthorizedGet} from "../components/AuthorizedRequest";
import {useHistory} from "react-router-dom";

const ActiveModulesContext = createContext({
    activeModules: [],
    loadActiveModules: () => Promise.resolve([]),
    activeModulesLoading: false
});

export default ActiveModulesContext;

export function ActiveModulesProvider({children}) {
    
    const [activeModules, setActiveModules] = useState([]);
    const [activeModulesLoading, setActiveModulesLoading] = useState(false);
    const history = useHistory();
    
    const loadActiveModules = useCallback(() => {
        setActiveModulesLoading(true);
        return AuthorizedGet(`api/KeyVault/ActiveModules`, history)
            .then(result =>
            {
                if (result) {
                    setActiveModules(result);
                }
                setActiveModulesLoading(false)
            });
    }, [history, setActiveModulesLoading, setActiveModules]);

    // Get active modules
    useEffect(() =>
    {
        loadActiveModules().catch(console.error);
    }, [loadActiveModules]);
    
    return (
        <ActiveModulesContext.Provider value={{activeModules, loadActiveModules, activeModulesLoading}}>
            {children}
        </ActiveModulesContext.Provider>
    );
    
}

