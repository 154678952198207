import React, { useState, useEffect } from 'react'
import { AuthorizedGet, AuthorizedDelete } from '../../AuthorizedRequest'
import { useHistory } from 'react-router-dom'
import { Card } from '../../shared/Card'
import ContentCodesList from '../elements/ContentCodesList';
import { SupportContentCodesSearch, supportContentCodesSearchCriteriaObject } from '../elements/ContentCodesSearch'
import { composeQueryString, useSessionState, useQueryStringAndSessionState, useCache } from '../../shared/Utils'
import { mkPagedResult } from '../../shared/MKPagination'
import { CollapseSearchButton } from '../../shared/Buttons'
import { Collapse } from 'reactstrap'
import MKPaginationUncounted from "../../shared/MKPaginationUncounted";
import {Loading} from "../../shared/Loading";

export default function SupportContentCodes(props)
{
    const initialEmptyValues = Object.assign({}, supportContentCodesSearchCriteriaObject);
    
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [initialValues, setInitialValues] = useQueryStringAndSessionState(() => Object.assign({}, initialEmptyValues), history, history.location.search, "supportContentCodesInitialValues");
    const [pagedResult, setPagedResult] = useSessionState(mkPagedResult, 'supportContentCodes');
    const [searchCollapsed, setSearchCollapsed] = useCache('supportContentCodesSearchCollapsed', false);
    
    useEffect(() => {
        searchContentCodes(initialValues, null, initialValues.pageNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const searchContentCodes = (searchValues, formikBag = { setSubmitting: x => {} }, pageNumber = 1) => {
        searchValues.pageNumber = pageNumber;
        setLoading(true);
        setInitialValues(Object.assign({}, searchValues));
        AuthorizedGet('api/Redeemable/SearchSupportContentCodes?' + composeQueryString(searchValues), history)
            .then(result => 
            {
                setPagedResult(result ?? mkPagedResult);
                if (formikBag) {
                    formikBag.setSubmitting(false);
                }
                setLoading(false);
            });
    };

    const onPageClick = (e, pageNumber) => searchContentCodes(initialValues, undefined, pageNumber);
        
    const toggleSearch = e => {
        e.preventDefault();
        setSearchCollapsed(!searchCollapsed);
    };

    const onDelete = contentCodeId => () =>
    {
        AuthorizedDelete(contentCodeId, `api/Redeemable/${encodeURIComponent(contentCodeId)}`, history)
            .then(result => 
            {
                pagedResult.items = pagedResult.items.filter(function (s) { return s.id !== result.id });
                pagedResult.itemsTotalCount--;
                setPagedResult(Object.assign({}, pagedResult));
            });
    };


    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const paginationComponent = <MKPaginationUncounted currentPage={pagedResult.pageNumber} currentPageCount={pagedResult.currentPageCount} onClick={onPageClick} itemsName="ContentCodes"/>;

    return (
        <div>
            <Card>
                <div className="row">
                    <h5 className="col-md-9">
                        <div className="row">Search Support Content Codes</div>
                    </h5>
                    <div className="col-md-1 text-end">
                        {loading && <Loading size={20} margin={5}/>}
                    </div>
                    <div className="col-md-2 text-end">
                        <div style={{marginTop: '-0.5rem'}}>
                            <CollapseSearchButton collapsed={searchCollapsed} onClick={toggleSearch}/>                            
                        </div>
                    </div>
                </div>
                <Collapse isOpen={!searchCollapsed}>
                    <SupportContentCodesSearch handleSearch={searchContentCodes} initialValues={initialValues}/>
                </Collapse>
            </Card>

            <Card>
                {paginationComponent}
                <ContentCodesList contentCodes={pagedResult.items} onDelete={onDelete}/>
                {paginationComponent}
            </Card>
        </div>
    );
}
