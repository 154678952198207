import React, { Suspense, lazy } from "react";
import {Loading} from "./Loading";
const ReactDiffViewer = lazy(() => import('react-diff-viewer-continued'));

export default function DiffViewer({valueOne, valueTwo}) {
    
    if (valueOne == "" || valueTwo == "") {
        return <p>Please select values to compare.</p>
    }
    
    return (
        <Suspense fallback={<Loading/>}>
            <ReactDiffViewer oldValue={valueOne} newValue={valueTwo} splitView={true} showDiffOnly={false} />            
        </Suspense>
    )
    
}