import React, { useEffect, useState } from 'react'
import { AuthorizedGet } from '../../../AuthorizedRequest'
import { useHistory, useLocation, Redirect, useParams } from 'react-router-dom'
import { Card, CardContrast } from '../../../shared/Card'
import { LabeledDetail } from '../../../shared/LabeledDetail'
import { shortYearDateTime, getDateTimeToolTip } from '../../../shared/MKDateTime'
import ReactHtmlParser from 'react-html-parser'
import { AddButtonText } from "../../../shared/Buttons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Loading } from "../../../shared/Loading"

export default function EmailConversation(props)
{
    const location = useLocation()
    const history = useHistory()
    let {conversationId} = useParams()

    const [emailConversation, setEmailConversation] = useState(location.state ? location.state.emailConversation : null)
    const [relatedTicket, setRelatedTicket] = useState(null)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    
    useEffect(() =>
    {
        AuthorizedGet(`api/Support/EmailConversation/${conversationId}`, history)
            .then(result =>
            {
                if (result)
                {
                    setEmailConversation(result.emails)
                    setRelatedTicket(result.relatedTicket)
                }
                else
                {
                    setError("No emails found. It is possible this email thread was created in Gmail. Only Outlook emails will be visible here")
                }
            })
            .catch(e =>
            {
                setError(e)
            })
            .finally(() => {
                setLoading(false)})
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const createSupportTicket = () =>
    {
        const newTicket = {source: "Email", threadId: conversationId, message: emailConversation[0].bodyPreview}
        
        history.push('/Support/SupportTicketCreate', {supportTicket: newTicket})
    }

    function openSupportTicket(supportTicket)
    {
        const path = `/Support/SupportTickets/${encodeURIComponent(supportTicket.id)}`
        history.push(path, {supportTicket: supportTicket})
    }

    if (emailConversation == null && conversationId == null) 
        return <Redirect to="/Support/Emails"/>
    
    if (error) 
        return <p>{error}</p>
    
    if (loading) 
        return <Loading/>

    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
    <>
        <div className="row">
            <h3 className="col-md-8"> {`Email Thread`} </h3>
            {relatedTicket ?
            <div className="col-md-4 text-end">
                <button className="btn btn-primary" onClick={() => openSupportTicket(relatedTicket)}
                        title="Open Related Support Ticket">
                    <FontAwesomeIcon icon='concierge-bell'/>Open Ticket
                </button>
            </div>
            :
            <div className="col-md-4 text-end">
                <AddButtonText onClick={createSupportTicket} toolTip="Create new support ticket from this email">Convert to Ticket</AddButtonText>
            </div>
            }
        </div>

        {emailConversation.map((email, index) =>
            <Card key={index}>
                <div className="row">
                </div>
                <div className="row">
                    <LabeledDetail label="Sent" value={shortYearDateTime(email.sentDateTime)}
                                   toolTip={getDateTimeToolTip(email.sentDateTime)} columns={2}/>
                    <LabeledDetail label="From" value={email.from && email.from.emailAddress.name} columns={4}/>
                    <LabeledDetail label="To"
                                   value={email.toRecipients.length > 0 && email.toRecipients[0].emailAddress.name}
                                   columns={4}/>
                </div>
                <CardContrast>
                    <p><b>{email.subject}</b></p>
                    <br/>
                    {ReactHtmlParser(email.body.content.replace("<html>", "").replace("</html>", ""))}
                </CardContrast>
            </Card>)
        }
    </> )
}