import React, { useState, useRef } from 'react'
import { useHistory, useLocation, Redirect } from 'react-router-dom'
import { Card } from '../../shared/Card'
import { Formik, Field, Form } from 'formik'
import {TextFieldInputColumn, ErrorDisplay, ToggleInputManaged} from '../../shared/FormElements'
import { SubmitButton } from '../../shared/Buttons'
import { LabeledDetail } from '../../shared/LabeledDetail'
import { AuthorizedPut } from '../../AuthorizedRequest'
import { shortYearDateTime, getDateTimeToolTip } from '../../shared/MKDateTime'
import JSONKeyValueEditor from '../../shared/JSONKeyValueEditor'
import {UserIdFieldInputColumn} from "../../Users/UserIdFieldInputColumn";

export default function RedeemableDetails({ type, redirectPath })
{
    const location = useLocation()
    const history = useHistory()
    const [forceTouched, setForceTouched] = useState(false)
    const jsonObject = useRef(null)
    const [redeemable, /* setRedeemable */] = useState(location.state ? location.state.redeemable : null)

    const onChangeJsonObject = jObj =>
    {
        jsonObject.current = jObj
        setForceTouched(true)
    }

    const validate = (values, props) =>
    {
        let errors = {}
        if (type === "template")
        {
            if (!values.name)
            {
                errors.name = "Name required"
            }

            if (!values.prefix)
            {
                errors.prefix = "Prefix required"
            }
        }
        return errors
    }

    const saveRedeemable = (values, actions) =>
    {
        if (jsonObject.current)
            values["json"] = JSON.stringify(jsonObject.current);
            
        const path = type === "template" ? "api/Redeemable/UpdateTemplate" : "api/Redeemable"
        AuthorizedPut(values, path, history)
            .then(result =>
            {
                actions.setSubmitting(false)
                if (result)
                {
                    actions.setTouched({})
                    setForceTouched(false)
                    history.replace({ pathname: location.pathname, state: { redeemable: result } })
                }
            })
    }


    if (redeemable == null) 
        return <Redirect to={redirectPath}/>

    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const title = type === "template" ? "Template Details" : type === "contentCode" ? "Content Code Details" : "Support Content Code Details"

    return (
        <Formik
            enableReinitialize
            validate={validate}
            initialValues={redeemable}
            onSubmit={saveRedeemable}>
            {({handleSubmit, isSubmitting, errors, touched, setFieldTouched}) =>
                <Form onSubmit={handleSubmit}>
                    <Card title={title}>
                        <div className="row">
                            <LabeledDetail label="Id" value={redeemable.id} columns={3}/>
                            <LabeledDetail label="Created" value={shortYearDateTime(redeemable.createdAt)}
                                           toolTip={getDateTimeToolTip(redeemable.createdAt)} columns={3}/>
                            {redeemable.updatedAt &&
                            <LabeledDetail label="Updated" value={shortYearDateTime(redeemable.updatedAt)}
                                           toolTip={getDateTimeToolTip(redeemable.updatedAt)} columns={3}/>}
                            {type !== "template" &&
                            <Field name="isActive" component={ToggleInputManaged} labelText="Is Active"
                                   errors={errors.isActive} column={3}/>}
                            {type === "supportContentCode" &&
                            <LabeledDetail label="Support Message Id" value={`${redeemable.supportMessageId}`}
                                           columns={3}/>}
                        </div>
                        <div className="row">
                            {type === "template" ?
                                <>
                                    <Field name="name" component={TextFieldInputColumn} labelText="Name" column={5}
                                           maxLength={120} errors={errors.name}/>
                                    <Field name="prefix" component={TextFieldInputColumn}
                                           labelText="Prefix (must be unique, max 3 chars)" column={5} maxLength={3}
                                           errors={errors.prefix}/>
                                </>
                                :
                                <Field name="uses" component={TextFieldInputColumn} type="number" labelText="Uses"
                                       readOnly={false} errors={errors.uses} min="-1" max="2147483647" column={3}/>
                            }
                            {type === "contentCode" &&
                                <Field name="userId" component={UserIdFieldInputColumn} labelText="User Id" column={5}/>}
                        </div>

                        <ErrorDisplay errors={errors}/>
                        
                    </Card>
                    <span/>
                    <Card title="Content">
                        <div className="row">
                            <JSONKeyValueEditor jsonString={redeemable.json} onChange={onChangeJsonObject}/>
                        </div>
                        <div className="row">
                            <div className={"col-md-12 text-center"}>
                                <SubmitButton errors={errors} isSubmitting={isSubmitting} touched={touched}
                                              forceTouched={forceTouched}/>
                            </div>
                        </div>
                    </Card>
                </Form>
            }
        </Formik>
    )
}