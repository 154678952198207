import "bootstrap/dist/css/bootstrap.css"
import "./assets/scss/paper-dashboard.scss"
import 'jsoneditor-react/es/editor.min.css'
import 'react-perfect-scrollbar/dist/css/styles.css'
import "swagger-ui-react/swagger-ui.css"
import "react-toggle/style.css"
import 'react-datetime/css/react-datetime.css'
import "./assets/css/swagger.css"
import './assets/css/navMenu.css'
import './assets/css/custom.css'
import './assets/css/userSegments.css'
import 'rc-checkbox/assets/index.css'
import './assets/css/react-multi-toggle_custom.css'

import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import App from './App'

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href')
const rootElement = document.getElementById('root')

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <App />
  </BrowserRouter>,
  rootElement)


