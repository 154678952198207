import React from 'react'
import { Field } from 'formik'
import { TextFieldInputColumn, NullableToggleInput } from '../shared/FormElements'
import { UserIdFieldInputColumn } from '../Users/UserIdFieldInputColumn'
import { DateTimeInput } from '../shared/MKDateTime'
import { mkPaginationObject } from '../shared/MKPagination'

export const teamsSearchCriteriaObject = Object.assign({
    id : '',
    name : '', 
    open : '', 
    dead : '', 
    localeCode : '', 
    minLevelFrom : '', 
    minLevelTo : '', 
    membersFrom : '', 
    membersTo : '',
    createdFrom : '', 
    createdTo : '',
    userId : '',
    userName : '',
    userInstallationId : ''
}, mkPaginationObject);

export default function TeamsSearchCriteria(props)
{
    return (
        <>
            <div className="row">
                <Field name="id" component={TextFieldInputColumn} labelText="Id" type="number" column={2}/>
                <Field name="name" component={TextFieldInputColumn} labelText="Name" column={4}/>
                <Field name="open" component={NullableToggleInput} labelText="Team type" trueText="Open" nullText="Any" falseText="Close" column={3}/>
                <Field name="dead" component={NullableToggleInput} labelText="Status" trueText="Dead" falseText="Alive" column={3}/>
            </div>
            <div className="row">                
                <Field name="localeCode" component={TextFieldInputColumn} labelText="Locale" maxLength={3} toolTip="Locale code (3 characters lenght)" column={4} />                
                <Field name="createdFrom" component={DateTimeInput} useTime={true} labelText="Created From" column={4} />
                <Field name="createdTo" component={DateTimeInput} useTime={true} labelText="Created To" column={4} />
            </div>
            <div className="row">
                <Field name="minLevelFrom" component={TextFieldInputColumn} type="number" labelText="Min Level From" toolTip="Minimum Level for joining the team" column={3}/>
                <Field name="minLevelTo" component={TextFieldInputColumn} type="number" labelText="Min Level To" toolTip="Minimum Level for joining the team" column={3}/>
                <Field name="membersFrom" component={TextFieldInputColumn} type="number" labelText="Members From" toolTip="Current quantity of members in the team" column={3}/>
                <Field name="membersTo" component={TextFieldInputColumn} type="number" labelText="Members To" toolTip="Current quantity of members in the team" column={3}/>
            </div>
            <div className="row">
                <Field name="userId" component={UserIdFieldInputColumn} labelText="User Id" column={4}/>
                <Field name="userName" component={TextFieldInputColumn} labelText="User Name" column={4}/>
                <Field name="userInstallationId" component={TextFieldInputColumn} labelText="Installation Id" column={4}/>
            </div>
        </>
    );
}