import React from 'react'
import { Field } from 'formik'
import { NullableToggleInput, TextFieldInputColumn } from '../shared/FormElements'
import { PlatformInputColumn } from '../shared/FormElementsDropdowns'
import { mkPaginationObject } from '../shared/MKPagination'

export const assetBundlesSearchCriteriaObject = Object.assign({
    platform : '',
    isActive : '',
    appVersionFrom : '', 
    appVersionTo : '',
    bundleVersionFrom : '',
    bundleVersionTo : ''
}, mkPaginationObject);

export default function AssetBundlesSearchCriteria(props)
{
    return (
        <>
            <div className="row">
                <Field name="platform" component={PlatformInputColumn} labelText="Platform" column={6}/>
                <Field name="isActive" component={NullableToggleInput} labelText="Active" trueText="Yes" nullText="Any" falseText="No" column={6}/>
            </div>
            <div className="row">
                <Field name="appVersionFrom" component={TextFieldInputColumn} labelText="App Version From" column={3} toolTip="Format: ##.##.##"/>
                <Field name="appVersionTo" component={TextFieldInputColumn} labelText="App Version To" column={3} toolTip="Format: ##.##.##"/>
                <Field name="bundleVersionFrom" component={TextFieldInputColumn} labelText="Bundle Version From" column={3} toolTip="Format: ##.##.##"/>
                <Field name="bundleVersionTo" component={TextFieldInputColumn} labelText="Bundle Version To" column={3} toolTip="Format: ##.##.##"/>
            </div>
        </>
    );
}