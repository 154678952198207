import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { AuthorizedGet, AuthorizedPut, AuthorizedPost } from '../AuthorizedRequest'
import { Card } from '../shared/Card'
import { AddButton } from '../shared/Buttons'
import { Loading } from '../shared/Loading'
import LeaderboardsList from '../Leaderboards/LeaderboardsList'
import TotalCount from '../shared/TotalCount'
import SoloEventDetails from "./SoloEventDetails";

export default function SoloEventTemplateEdit()
{
    const [eventTemplate, setEventTemplate] = useState(null);
    const [loading, setLoading] = useState(true);

    const history = useHistory();
    let {eventTemplateId} = useParams();
        
    // Fetch event
    useEffect(() => 
    {
        AuthorizedGet(`api/SoloEvent/GetTemplate/${eventTemplateId}`, history)
            .then(result => 
            {
                setEventTemplate(result);
                setLoading(false);
            });
    }, [eventTemplateId, history]);

    // Save changes
    const saveEvent = (values, actions) => 
    {
        AuthorizedPut(values, 'api/SoloEvent/UpdateTemplate', history)
            .then(result => {
                actions.setSubmitting(false);
                if (result) {
                    actions.setTouched({});
                }
            });
    }

    // Create Leaderboard
    const createLeaderboard = () => 
    {
        AuthorizedPost({ name: 'New Leaderboard template', bucketSize: 20, soloEventTemplateId: eventTemplateId }, 'api/Leaderboard/TemplateCreate', history)
            .then(result => {
                history.push('/SoloEvents/Leaderboards/Templates/' + result);
            });
    }

    if (loading)
    { return <Loading /> }    
    
    if (!eventTemplate)
    { return null }
    
    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <div>
            
            <SoloEventDetails soloEvent={eventTemplate} saveMethod={saveEvent} editPauseUntil={false} showCreated={false} title="Solo Event Template" />

            <Card headerComponent={
                <div className="row">
                    <div className="col-md-10">
                        <h3>
                            Leaderboards templates
                        </h3>
                    </div>
                    <div className="col-md-2 text-end">
                        <AddButton onClick={createLeaderboard} title="Add New Leaderboard Template"/>
                    </div>
                </div>
            }>

                <LeaderboardsList leaderboards={eventTemplate.leaderboardTemplates} showEventColumn={false} entityType="LeaderboardTemplate" onRowClickURL="/SoloEvents/Leaderboards/Templates"/>

                <div className="row">
                    <div className="col-md-12 d-flex align-items-center justify-content-end">
                        <TotalCount itemsName="Leaderboards Templates">{eventTemplate.leaderboardTemplates.length}</TotalCount>
                    </div>
                </div>
            </Card>

        </div>
    );
}