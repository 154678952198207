import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { AuthorizedGet, AuthorizedDelete } from '../AuthorizedRequest'
import { Card } from '../shared/Card'
import TargetedOffersList from './TargetedOffersList'
import TargetedOffersSearch from './TargetedOffersSearch'
import { targetedOffersSearchCriteriaObject } from './TargetedOffersSearchCriteria'
import { MKPaginationWithTotal, mkPagedResult } from '../shared/MKPagination';
import { composeQueryString, mapQueryStringValuesToObject, usePagedResultSessionState, useQueryStringAndSessionState, useCache } from '../shared/Utils'
import { CollapseSearchButton, AddButtonText } from '../shared/Buttons'
import { Collapse } from 'reactstrap'


export function useTargetedOffersListCache()
{
    return usePagedResultSessionState('targetedOffers');
}


export default function TargetedOffers(props)
{
    const initialEmptyValues = Object.assign({}, targetedOffersSearchCriteriaObject);

    let history = useHistory();
    const [loading, setLoading] = useState(true);
    const [initialValues, setInitialValues] = useQueryStringAndSessionState(() => Object.assign({}, initialEmptyValues), history, history.location.search, 'targetedOffersInitialValues');
    const [pagedResult, setPagedResult] = useTargetedOffersListCache();
    const [searchCollapsed, setSearchCollapsed] = useCache('targetedOffersSearchCollapsed', true);
    
    useEffect(() => {
        if (mapQueryStringValuesToObject(initialEmptyValues, history.location.search))   // There is allowed search parameters in QueryString  [&&]
        {
            if (JSON.stringify(initialEmptyValues) !== JSON.stringify(initialValues))  // Those parameters means a different search tan the one saved in sessionStorage
                searchTargetedOffers(initialEmptyValues); 
            else
                setLoading(false);
        }
        else 
        {
            if (pagedResult.itemsTotalCount == null)
                searchTargetedOffers(initialValues);
            else
                setLoading(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    const searchTargetedOffers = (searchValues, formikBag = { setSubmitting: x => {} }, pageNumber = 1) => {
        searchValues.pageNumber = pageNumber;
        setLoading(true);
        setInitialValues(Object.assign({}, searchValues));
        AuthorizedGet('api/TargetedOffer/Search?' + composeQueryString(searchValues), history)
            .then(result => 
            {
                setPagedResult(result ?? mkPagedResult);
                formikBag.setSubmitting(false);
                setLoading(false);
            });
    };

    const onPageClick = (e, pageNumber) => searchTargetedOffers(initialValues, undefined, pageNumber);
        
    const toggleSearch = e =>  {
        e.preventDefault();
        setSearchCollapsed(!searchCollapsed);
    };

    const deleteEntity = targetedOfferId => () =>
    {
        AuthorizedDelete(null, `api/TargetedOffer/${targetedOfferId}`, history)
            .then(data =>
            {
                if (data)
                {
                    pagedResult.items = pagedResult.items.filter(function(s) { return s.id !== data.id });
                    pagedResult.itemsTotalCount--;
                    setPagedResult(Object.assign({}, pagedResult));
                }
                setLoading(false);
            });
    };
 
    // Render /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////   
    return (
        <div>
            <div className="row align-items-center">
                <div className="md-8 col">
                    <h3>&nbsp;Targeted Offers</h3>
                </div>
                <div className="md-4 col text-end">
                    <AddButtonText onClick={_ => history.push('/TargetedOffers/Create')}
                                   toolTip="Add New Targeted Offer">Add New</AddButtonText>
                    &nbsp;
                    <CollapseSearchButton collapsed={searchCollapsed} onClick={toggleSearch} />
                </div>
            </div>

            <Collapse isOpen={!searchCollapsed}>
                <Card>
                    <TargetedOffersSearch handleSearch={searchTargetedOffers} initialValues={initialValues} />
                </Card>
            </Collapse>

            <Card>
                <TargetedOffersList abTests={pagedResult.items} loading={loading} onDelete={deleteEntity} />
                <MKPaginationWithTotal currentPage={pagedResult.pageNumber} total={pagedResult.pagesTotalCount}
                                       totalItems={pagedResult.itemsTotalCount} onClick={onPageClick}
                                       itemsName="Targeted Offers" />
            </Card>
        </div>
    )
}
