import React, {useState} from "react";
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupButtonDropdown
} from "reactstrap";
import teamMembershipStatus from "./TeamMembershipStatus.json";

/**
 * Edit a TeamMembershipStatus
 * 
 * @param {Object} props - Component properties
 * @param {integer} props.selectedKey - Selected key
 * @param {string} props.selectedValue - Selected value (name) (optional)
 * @param {Function} props.onChange - CallBack function  called when the value is modified. Parameters: newKey, newValue
 * @param props.readOnly
 * @returns {JSX.Element}
 * @constructor
 */
export default function TeamMembershipStatusEditor({ selectedKey, selectedValue, specialItems, onChange, readOnly = false })
{
    const [ dropdownOpen, setDropdownOpen ] = useState(false);
    
    let sv = selectedValue ?? teamMembershipStatus.find(x => x.key === selectedKey)?.value ?? specialItems?.find(x => x.key === selectedKey)?.value;
    
    const toggleDropDown = () => setDropdownOpen(!dropdownOpen);
    
    const mapItems = items => items.map(x =>
        <DropdownItem key={x.key} onClick={() => onChange(x.key, x.value)} title={`${x.key}: ${x.value}`}>
            {x.value}
        </DropdownItem>);
    
    return (
        <InputGroup className="m-0" style={{ width: '160px' }}>
            <Input readOnly={true} value={sv} />
            <InputGroupAddon addonType="append">
                <InputGroupButtonDropdown addonType="append" className="m-0" isOpen={dropdownOpen} toggle={toggleDropDown} disabled={readOnly}>
                    <DropdownToggle caret className="mt-0 ps-3 pe-3 inline-button" style={{height: '30px'}} />
                    <DropdownMenu>
                        { specialItems &&
                            <>
                            {mapItems(specialItems)}
                            <DropdownItem divider />
                            </>                            
                        }
                        { 
                            mapItems(teamMembershipStatus)
                        }
                    </DropdownMenu>
                </InputGroupButtonDropdown>
            </InputGroupAddon>
        </InputGroup>
    );
}