import React from 'react'
import { Field } from 'formik'
import { TextFieldInputColumn, NullableToggleInput } from '../shared/FormElements'
import { DateTimeInput } from '../shared/MKDateTime'
import { mkPaginationObject } from '../shared/MKPagination'

export const soloEventsSearchCriteriaObject = Object.assign({
    id : '',
    name : '',
    active : '',
    durationFrom : '', 
    durationTo : '',
    createdFrom : '',
    createdTo : ''
}, mkPaginationObject);

export default function SoloEventsSearchCriteria(props)
{
    return (
        <>
            <div className="row">
                <Field name="id" component={TextFieldInputColumn} labelText="Id" column={4}/>
                <Field name="name" component={TextFieldInputColumn} labelText="Name" column={4}/>
                <Field name="active" component={NullableToggleInput} trueText="Yes" falseText="No" labelText="Active" column={4}/>
            </div>
            <div className="row">
                <Field name="durationFrom" component={TextFieldInputColumn} type="number" labelText="Duration From" column={3}/>
                <Field name="durationTo" component={TextFieldInputColumn} type="number" labelText="Duration To" column={3}/>
                <Field name="createdFrom" component={DateTimeInput} useTime={true} labelText="Created From" column={3} />
                <Field name="createdTo" component={DateTimeInput} useTime={true} labelText="Created To" column={3} />
            </div>
        </>
    );
}